import React, { useState, useEffect } from 'react'

import {
  Grid,
  Skeleton,
  Pagination,
  Typography,
  IconButton,
} from '@mui/material'

import { DataGridPro } from '@mui/x-data-grid-pro';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { getuserRoles, deleteUserRole } from '../../../redux/slices/profile/role';

import ConfirmationDialog from '../../ConfirmationDialog'

import Iconify from '../../Iconify';

import RoleCreate from './RoleCreate';
import RoleUpdate from './RoleUpdate';

export default function RoleDataGrid({ }) {

  const dispatch = useDispatch();
  let AccountSid = localStorage.getItem('authID')

  const [tableData, setTableData] = useState([])

  // loading
  const [loading, setLoading] = useState(true)

  // pagination
  const [count, setCount] = useState(0);
  const pageCount = Math.ceil(count / 10);
  const [page, setPage] = useState(1);
  const [offsetCount, setOffsetCount] = useState(0);

  // confirmation
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [confirmDialogMessage, setConfirmDialogMessage] = useState(false)
  const [deleteId, setDeleteId] = useState('')



  useEffect(() => {
    dispatch(getuserRoles(AccountSid));
  }, [dispatch, AccountSid]);

  const { getRoleResponse } = useSelector(state => state.userRole)

  useEffect(() => {
    console.log('getRoleResponse', getRoleResponse)
    if (getRoleResponse.status === true && getRoleResponse.status !== undefined) {
      setTableData(getRoleResponse.data.data)
      setCount(getRoleResponse.data.totalCount)
      setLoading(false)
    }
  }, [getRoleResponse])

  const LoadingSkeleton = () => (
    <>
      <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
      <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
      <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
      <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
    </>
  );

  const handleDelete = () => {
    console.log('trigger delete',)
    dispatch(deleteUserRole(deleteId))
    setConfirmDialog(false)
}

  const confirmationDialogOpen = (params) =>{
    setDeleteId(params.row._id)
    setConfirmDialog(true)
    setConfirmDialogMessage('Are you Sure want to delete Role')
  }

  const getActions = (params) => {
    return (
      <>

         <RoleUpdate props={params} />


        <IconButton onClick={() => confirmationDialogOpen(params)}>
          <Iconify icon={'fluent:delete-32-regular'} width={20} height={20} />
        </IconButton>
      </>
    )
  }

  const columns = [


    {
      field: 'name',
      headerName: 'Role Name',
      editable: true,
      flex: 1
    },

    {
      field: 'type',
      headerName: 'Type',
      editable: true,
      flex: 1
    },

    {
      field: 'description',
      headerName: 'Description',
      editable: true,
      flex: 1
    },


    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 150,
      renderCell: getActions,
    }

  ]

  const renderedStudio = (

    <>

      <DataGridPro
        // {...numberData}
        // loading={numberData.rows.length === 0}
        rows={tableData}
        columns={columns}
        rowHeight={48}
        disableSelectionOnClick
        getRowId={(r) => r._id}
        style={{ height: '550px' }}

        initialState={{
          pinnedColumns: {
            left: [''], right: ['action']
          },

        }}

        components={{
          LoadingOverlay: LoadingSkeleton
        }}
        loading={loading}   // you need to set your boolean loading
        hideFooter


      />

      {pageCount > 1 && (
        <Grid container spacing={3} style={{ marginBottom: "10px" }} justifyContent='center'>

          <Grid item>
            <Pagination count={pageCount} page={page} showFirstButton showLastButton onChange={handleChangePage} color="primary" shape="rounded" />
          </Grid>

        </Grid>
      )}
    </>
  )

  const skeltonLoaders = (
    <>
      <Grid container>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>

        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
      </Grid>
    </>
  )



  return (
    <div className='maindiv'>
      <Grid container direction='row' className='createrolebtn'>
        <Grid item xs></Grid>
        <Grid item>
          <RoleCreate />
        </Grid>
      </Grid>

      {loading ? (
        <>
          {skeltonLoaders}
        </>
      ) : <>
        {renderedStudio}
        {/* {count === 0 ? <>
              <div style={{ width: "50%", margin: "auto" }}>
                
                <Typography variant='h5' style={{ marginLeft: "30%" }}>Role</Typography>
              

              </div>
            </> : <>
              
            </>} */}

      </>
      }

<ConfirmationDialog openDialog={confirmDialog} setOpenDialog={setConfirmDialog} triggerConfirm={handleDelete} message={confirmDialogMessage} />

    </div>
  )
}