import React, { useState } from 'react';
import {
    Collapse,
    Paper,
    Grid,
    Fab,
    Button,
} from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
export default function IncomingCall({incomingCallToggle, answerCall, declineCall , number} ){
    return(
        <span>
            <Collapse in={incomingCallToggle} className="dial-pad incoming-call">
                <Paper>

                    <Grid container>
                        <Grid item xs>
                        </Grid>
                        <Grid item>
                        <div className="dialing-icon-cover">
                            <div className="dialing-icon-animate">
                            <div className='phone-dialing animate-call-dialing'>
                                <span className="material-icons">
                                    <CallIcon />
                                </span>
                            </div>
                            <div className="circle1 animate-call-dialing"></div>
                            <div className="circle2 animate-call-dialing"></div>
                            </div>
                        </div>
                        </Grid>
                        <Grid item xs>
                        </Grid>
                    </Grid>

                    <h4 className="incoming-call-h4">{number}</h4>
                    <h5 className="incoming-call-h5">Incoming Call</h5>
                    <h6 className="incoming-call-h6">from USA</h6>

                    <Grid container direction="row" spacing={1} alignItems="center">
                        <Grid item xs>
                        </Grid>
                        
                        <Grid item>
                            <Button className="accept-btn" variant="outlined"  onClick={answerCall}>
                                <CallIcon /> Answer
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button className="decline-btn" variant="outlined" onClick={declineCall}>
                                <CallEndIcon /> Decline
                            </Button>
                        </Grid>
                        
                        <Grid item xs>
                        </Grid>
                    </Grid>

                </Paper>
            </Collapse>

        </span>
    )
}