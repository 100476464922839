import React, { useState } from "react";
import {Button, CircularProgress, makeStyles, Menu, MenuItem, Snackbar, Switch} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from "styled-components";
import { create_sip_account, edit_app_config } from "src/redux/userReducer";

export function General({props, setProps }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [field_name, setFieldName] = useState("");
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [option_data, setOptionData] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [disabled1, setDisabled1] = useState(false);
    const open = Boolean(anchorEl)
    const change_active = (is_active, field) => {
        console.log("field", field)
        var app_details = props;
        app_details[field] = is_active
        setProps(app_details)
        setAnchorEl(null)
    }

    const create_sip_id = () => {
        setDisabled1(true)
        const token = localStorage.getItem("redux-token");
        create_sip_account(token, props.user_id.FirstName).then(async(res) => {
            console.log("sip_response", res)
            if (res.status === true) {
                const sip_id = `sip:${res.data.username}@${res.data.domain}`;
            const sip_password = res.data.password;
            var app = props;
            app.sip_id = sip_id;
            app.sip_password = sip_password;
            app.sip_domain = res.data.domain;
            const config_id = props._id;
            setProps(app);
            setDisabled1(false)
            await edit_app_config(config_id, app, token).then((res) => {
                setErrorMessage("App config is updated successfully");
                setShowError(true)
            }).catch((err) => {
                console.log("error", err);
                setErrorMessage("App config is not updated");
                setShowError(true)
            })
            } else {
                setDisabled1(false)
                setErrorMessage("Unable to activate sip account");
                setShowError(true)
            }
        }).catch((err) => {
            console.log("error", err);
            setDisabled1(false);
        })
    }

    const edit_app = (body) => {
        setDisabled(true)
        const token = localStorage.getItem("redux-token");
        const config_id = props._id;
        edit_app_config(config_id, body, token).then((res) => {
            setErrorMessage("App config is updated successfully");
            setShowError(true)
            setDisabled(false)
        }).catch((err) => {
            console.log("error", err);
            setErrorMessage("App config is not updated");
            setShowError(true)
            setDisabled(false)
        })
    }
    const Menu_Component = ({ field }) => {
        return (
            <Menu 
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl} 
            onClose={() => setAnchorEl(null)}
            open={open}
            sx={{ marginTop: 5, }}
            anchorOrigin={{ 
                vertical: 'top', 
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
                <MenuItem onClick={() => change_active(true, field)}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                        <div style={{ width: 20, height: 20, backgroundColor: "rgb(163, 235, 177)", borderRadius: 5, marginLeft: 10 }}/>
                        <div style={{ marginLeft: 15}}>
                            Active
                        </div>
                    </div>
                </MenuItem>
                <MenuItem onClick={() => change_active(false, field)}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                        <div style={{ width: 20, height: 20, backgroundColor: "red", borderRadius: 5, marginLeft: 10 }}/>
                        <div style={{ marginLeft: 15}}>
                            Inactive
                        </div>
                    </div>
                </MenuItem>
            </Menu>
        )
    }

    const Menu_component = ({ field, option_data }) => {
        return (
            <Menu 
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl} 
            onClose={() => setAnchorEl(null)}
            open={open}
            sx={{ marginTop: 5, }}
            anchorOrigin={{ 
                vertical: 'top', 
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
                {option_data.map((item, index) => (
                    <MenuItem onClick={() => {
                        if (field === "call_allow") {
                            if (item.type === "Both") {
                                change_active("both", field)
                            } else if (item.type === "Inbound") {
                                change_active("inb", field)
                            } else if (item.type === "Outbound") {
                                change_active("out", field)
                            }
                        } else if (field === "type_allow") {
                            if (item.type === "Both") {
                                change_active("both", field)
                            } else if (item.type === "Phone") {
                                change_active("phone", field)
                            } else if (item.type === "Sip") {
                                change_active("sip", field)
                            }
                        } else if (field === "phone_mode") {
                            if (item.type === "Default") {
                                change_active("default", field)
                            } else if (item.type === "CC") {
                                change_active("cc", field)
                            }
                        } else if (field === "sip_mode") {
                            if (item.type === "Default") {
                                change_active("default", field)
                            } else if (item.type === "CC") {
                                change_active("cc", field)
                            }
                        }
                    }} key={index}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                        <div style={{ marginLeft: 15}}>
                            {item.type}
                        </div>
                    </div>
                    </MenuItem>
                ))}
            </Menu>
        )
    }
    return (
        <div style={{ display: "flex", width: "100%", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ display: "flex", width: "100%", flexDirection: "row", }}>
                <div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Extension
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Phone App
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Call Allow
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Internet Calling
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Call Type Allow
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Phone Call Mode
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Internet Call Mode
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Hide Contact
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Disable Contact
                        </div>
                    </div>
                </div>
                <div style={{ marginLeft: 40 }}>
                   <div style={{height: 40,  display: "flex", alignItems: "center", marginTop: 5}}>
                      <Button style={{ height: 35, display: "flex", flexDirection: "row", alignItems: "center", border: "1px solid lightgrey", borderRadius: 10}} onClick={(event) => {
                        setFieldName("extension_active");
                        setAnchorEl(event.currentTarget)
                      }}>
                        <div style={{ width: 20, height: 20, backgroundColor: props.extension_active === true ? "rgb(163, 235, 177)" : "red", borderRadius: 5, marginLeft: 10 }}/>
                        <div style={{ color: "#000", marginLeft: 10 }}>
                            {props.extension_active === true ? "Active" : "Inactive"}
                        </div>
                        <KeyboardArrowDownIcon style={{ color: "#000", width: 15, height: 15, marginLeft: 10, marginRight: 10}}/>
                      </Button>
                      {field_name === "extension_active" && <Menu_Component field={"extension_active"}/>}
                   </div>
                   <div style={{height: 40, display: "flex", alignItems: "center", marginTop: 5 }}>
                      <Button style={{ height: 35, display: "flex", flexDirection: "row", alignItems: "center", border: "1px solid lightgrey", borderRadius: 10}} onClick={(event) => {
                        setFieldName("phoneApp_active");
                        setAnchorEl(event.currentTarget)
                      }}>
                        <div style={{ width: 20, height: 20, backgroundColor: props.phoneApp_active === true ? "rgb(163, 235, 177)" : "red", borderRadius: 5, marginLeft: 10 }}/>
                        <div style={{ color: "#000", marginLeft: 10 }}>
                            {props.phoneApp_active === true ? "Active" : "Inactive"}
                        </div>
                        <KeyboardArrowDownIcon style={{ color: "#000", width: 15, height: 15, marginLeft: 10, marginRight: 10}}/>
                      </Button>
                      {field_name === "phoneApp_active" && <Menu_Component field={"phoneApp_active"}/>}
                   </div>
                   <div style={{height: 40, display: "flex", alignItems: "center", marginTop: 5 }}>
                      <Button style={{ height: 35, display: "flex", flexDirection: "row", alignItems: "center", border: "1px solid lightgrey", borderRadius: 10}} onClick={(e) => {
                        setOptionData([
                            {type: "Both"},
                            {type: "Inbound"},
                            {type: "Outbound"}
                        ])
                        setFieldName("call_allow");
                        setAnchorEl(e.currentTarget);
                      }}>
                        
                        <div style={{ color: "#000", marginLeft: 10 }}>
                            {props.call_allow === "both" ? "Both" : props.call_allow === "inb" ? "Inbound" : "Outbound"}
                        </div>
                        <KeyboardArrowDownIcon style={{ color: "#000", width: 15, height: 15, marginLeft: 10, marginRight: 10}}/>
                      </Button>
                      {field_name === "call_allow" && <Menu_component field={"call_allow"} option_data={option_data}/>}
                   </div>
                   <div style={{height: 40, display: "flex", alignItems: "center",marginTop: 5 }}>
                      {props.sip_id !== undefined && props.sip_id !== null && props.sip_id !== "" ? (
                        <Button style={{ height: 35, display: "flex", flexDirection: "row", alignItems: "center", border: "1px solid lightgrey", borderRadius: 10}} onClick={(event) => {
                            if (anchorEl === null) {
                                setFieldName("sip_active");
                                setAnchorEl(event.currentTarget)
                            } else {
                                setAnchorEl(null)
                            }
                        }}>
                            <div style={{ width: 20, height: 20, backgroundColor: props.sip_active === true ? "rgb(163, 235, 177)" : "red", borderRadius: 5, marginLeft: 10 }}/>
                            <div style={{ color: "#000", marginLeft: 10 }}>
                                {props.sip_active === true ? "Active" : "Inactive"}
                            </div>
                            <KeyboardArrowDownIcon style={{ color: "#000", width: 15, height: 15, marginLeft: 10, marginRight: 10}}/>
                            {field_name === "sip_active" && <Menu_Component field={'sip_active'}/>}
                        </Button>
                      ): (
                        <>
                          {disabled1 === true ? (
                            <CircularProgress style={{ width: 30, height: 30, color: "#0046BB",  }}/>
                          ): (
                            <Button style={{ height: 35, display: "flex", flexDirection: "row", alignItems: "center", border: "1px solid lightgrey", borderRadius: 10}} onClick={() => create_sip_id()}>
                            <div style={{ color: "#000", marginLeft: 10, marginRight: 10, fontWeight: "bold", fontSize: 15}}>
                                Activate Now
                            </div>
                           </Button>
                          )}
                        </>
                      )}
                   </div>
                   <div style={{height: 40, display: "flex", alignItems: "center", marginTop: 5 }}>
                      <Button style={{ height: 35, display: "flex", flexDirection: "row", alignItems: "center", border: "1px solid lightgrey", borderRadius: 10}} onClick={(e) => {
                        setOptionData([
                            {type: "Both"},
                            {type: "Phone"},
                            {type: "Sip"}
                        ])
                        setFieldName("type_allow");
                        setAnchorEl(e.currentTarget);
                      }}>
                        
                        <div style={{ color: "#000", marginLeft: 10 }}>
                            {props.type_allow === "both" ? "Both" : props.type_allow === "phone" ? "Phone" : "Sip"}
                        </div>
                        <KeyboardArrowDownIcon style={{ color: "#000", width: 15, height: 15, marginLeft: 10, marginRight: 10}}/>
                      </Button>
                      {field_name === "type_allow" && <Menu_component field={"type_allow"} option_data={option_data}/>}
                   </div>
                   <div style={{height: 40, display: "flex", alignItems: "center", marginTop: 5 }}>
                      <Button style={{ height: 35, display: "flex", flexDirection: "row", alignItems: "center", border: "1px solid lightgrey", borderRadius: 10}} onClick={(e) => {
                        setOptionData([
                            {type: "Default"},
                            {type: "CC"}
                        ])
                        setFieldName("phone_mode");
                        setAnchorEl(e.currentTarget);
                      }}>
                        
                        <div style={{ color: "#000", marginLeft: 10 }}>
                            {props.phone_mode === "default" ? "Default" : "CC"}
                        </div>
                        <KeyboardArrowDownIcon style={{ color: "#000", width: 15, height: 15, marginLeft: 10, marginRight: 10}}/>
                      </Button>
                      {field_name === "phone_mode" && <Menu_component field={"phone_mode"} option_data={option_data}/>}
                   </div>
                   <div style={{height: 40, display: "flex", alignItems: "center", marginTop: 5 }}>
                      <Button style={{ height: 35, display: "flex", flexDirection: "row", alignItems: "center", border: "1px solid lightgrey", borderRadius: 10}} onClick={(e) => {
                        setOptionData([
                            {type: "Default"},
                            {type: "CC"}
                        ])
                        setFieldName("sip_mode");
                        setAnchorEl(e.currentTarget);
                      }}>
                        
                        <div style={{ color: "#000", marginLeft: 10 }}>
                            {props.sip_mode === "default" ? "Default" : "CC"}
                        </div>
                        <KeyboardArrowDownIcon style={{ color: "#000", width: 15, height: 15, marginLeft: 10, marginRight: 10}}/>
                      </Button>
                      {field_name === "sip_mode" && <Menu_component field={"sip_mode"} option_data={option_data}/>}
                   </div>
                   <div style={{height: 40, display: "flex", alignItems: "center", marginTop: 5 }}>
                     <Switch checked={props.hide_contact} onChange={(e) => {
                        change_active(e.target.checked, "hide_contact")
                     }}/>
                   </div>
                   <div style={{height: 40, display: "flex", alignItems: "center", marginTop: 5 }}>
                     <Switch checked={props.disable_contact} onChange={(e) => {
                        change_active(e.target.checked, "disable_contact")
                     }}/>
                   </div>
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end", flexDirection: "row", marginBottom: 50 }}>
                <Button style={{ marginRight: 20, height: 40, borderRadius: 10, backgroundColor: "#fff", }} variant="outlined" size='small'>
                    <div style={{ color: "#0046BB", marginLeft: 10, marginRight: 10, fontWeight: "600"}}>
                        Cancel
                    </div>
                </Button>
                <Button style={{ marginRight: 20, height: 40, borderRadius: 10, }} variant="contained" size='small' onClick={() => edit_app(props)} disabled={disabled}>
                    <div style={{ color: "#fff", marginLeft: 10, marginRight: 10, fontWeight: "600"}}>
                        Save Changes
                    </div>
                </Button>
            </div>
            <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        message={errorMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => setShowError(false)}>
              OK
            </Button>

          </React.Fragment>
        }
      />
        </div>
    )
}