import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TimeZone from 'src/components/TimeZone';
import moment from 'moment'
import { useDispatch } from 'react-redux'
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Stack, Drawer, IconButton, List, ListItemAvatar, Avatar,
ListItemText, Badge, Typography, Button, ListItem, Grid, Tooltip} from '@mui/material';
// redux
import { useSelector } from '../../../../redux/store';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
// utils
import axios from '../../../../utils/axios';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// components
import Iconify from '../../../Iconify';
import Scrollbar from '../../../Scrollbar';
import Label from '../../../Label';
//
import ChatAccount from './ChatAccount';
import ChatSearchResults from './ChatSearchResults';
import ChatContactSearch from './ChatContactSearch';
import ChatConversationList from './ChatConversationList';

import { selectedConversationId, selectedConversationIdName } from 'src/redux/slices/whatsapp/whatsapp'

// ----------------------------------------------------------------------

const ToggleButtonStyle = styled((props) => <IconButton disableRipple {...props} />)(({ theme }) => ({
  left: 0,
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  top: theme.spacing(13),
  borderRadius: `0 12px 12px 0`,
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.customShadows.primary,
  '&:hover': {
    backgroundColor: theme.palette.primary.darker,
  },
}));

// ----------------------------------------------------------------------

const SIDEBAR_WIDTH = 320;
const SIDEBAR_COLLAPSE_WIDTH = 96;

export default function ChatSidebar() {

  const theme = useTheme();

  const dispatch = useDispatch()

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [openSidebar, setOpenSidebar] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');

  const [searchResults, setSearchResults] = useState([]);

  const [isSearchFocused, setSearchFocused] = useState(false);

  // const { conversations, activeConversationId } = useSelector((state) => state.chat);

  const { conversations } = useSelector((state) => state.whatsapp)

  const isDesktop = useResponsive('up', 'md');

  const displayResults = searchQuery && isSearchFocused;

  const isCollapse = isDesktop && !openSidebar;

  useEffect(() => {
    if (!isDesktop) {
      return handleCloseSidebar();
    }
    return handleOpenSidebar();
  }, [isDesktop, pathname]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!openSidebar) {
      return setSearchFocused(false);
    }
  }, [openSidebar]);

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleToggleSidebar = () => {
    setOpenSidebar((prev) => !prev);
  };

  const handleClickAwaySearch = () => {
    setSearchFocused(false);
    setSearchQuery('');
  };

  const handleChangeSearch = async (event) => {
    try {
      const { value } = event.target;
      setSearchQuery(value);
      if (value) {
        const response = await axios.get('/api/chat/search', {
          params: { query: value },
        });
        setSearchResults(response.data.results);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchFocus = () => {
    setSearchFocused(true);
  };

  const handleSearchSelect = (username) => {
    setSearchFocused(false);
    setSearchQuery('');
    navigate(PATH_DASHBOARD.chat.view(username));
  };

  const handleSelectContact = (result) => {
    if (handleSearchSelect) {
      handleSearchSelect(result.username);
    }
  };

  const [conversation, setConversation] = useState([])

  useEffect(() => {
    console.log('conversations', conversations)
    if(conversations.count > 0){
      setConversation(conversations.data)
      dispatch(selectedConversationId(conversations.data[0].id))
      dispatch(selectedConversationIdName(conversations.data[0].customer.name))
    }
  }, [conversations])

  const selectChat = (id, name) =>{
    dispatch(selectedConversationId(id))
    dispatch(selectedConversationIdName(name))
  }
  

  const renderContent = (
    <>
      <Box sx={{ py: 2, px: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          {!isCollapse && (
            <>
              <ChatAccount />
              <Box sx={{ flexGrow: 1 }} />
            </>
          )}

          <IconButton onClick={handleToggleSidebar}>
            <Iconify
              width={20}
              height={20}
              icon={openSidebar ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'}
            />
          </IconButton>

          {!isCollapse && (
            <IconButton onClick={() => navigate(PATH_DASHBOARD.chat.new)}>
              <Iconify icon={'eva:edit-fill'} width={20} height={20} />
            </IconButton>
          )}
        </Stack>

        {!isCollapse && (
          <ChatContactSearch
            query={searchQuery}
            onFocus={handleSearchFocus}
            onChange={handleChangeSearch}
            onClickAway={handleClickAwaySearch}
          />
        )}
      </Box>

      <Scrollbar>
          {!displayResults ? (
          
          <List>
            {conversation.map((item, index)=>{
              return(
                <ListItem alignItems="flex-start" button onClick={()=>selectChat(item.id, item.customer.name)}>
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                  primary={
                  <React.Fragment>

                  <Grid container direction='row'>
                    <Grid item xs>
                      <Typography variant='subtitle2'>
                        {item.customer.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                    
                    <Tooltip title={
                       <React.Fragment>
                          <TimeZone date={item.last_customer_message_at} />
                       </React.Fragment>
                    }>
                      <Button className='date-moment-whatsapp'>
                      {moment(item.last_customer_message_at).fromNow()}
                      </Button>
                    </Tooltip>
                    
                    </Grid>
                  </Grid>
                  
                  
                </React.Fragment>
              }
                  secondary={
                    <React.Fragment>

                      <Grid container direction='row'>
                        <Grid item xs>
                          {item.labels.map((label)=>{
                            return(
                              <span key={label.label_id} className='label-whatsapp' style={{background: `#${label.color}`}}>{label.name}</span>
                            )
                          })}
                        </Grid>
                        <Grid item>
                        
                        {item.unread_count !== 0 && (
                          <Badge badgeContent={item.unread_count} color="primary" className='badge-whatsapp' />
                        )}
                        
                        </Grid>
                      </Grid>
                      
                      
                    </React.Fragment>
                  }
                />
                </ListItem>
              )
            })}
            
          </List>
        ) : (
          <ChatSearchResults query={searchQuery} results={searchResults} onSelectContact={handleSelectContact} />
        )} 
      </Scrollbar>
    </>
  );

  return (
    <>
      {!isDesktop && (
        <ToggleButtonStyle onClick={handleToggleSidebar}>
          <Iconify width={16} height={16} icon={'eva:people-fill'} />
        </ToggleButtonStyle>
      )}

      {isDesktop ? (
        <Drawer
          open={openSidebar}
          variant="persistent"
          sx={{
            width: SIDEBAR_WIDTH,
            transition: theme.transitions.create('width'),
            '& .MuiDrawer-paper': {
              position: 'static',
              width: SIDEBAR_WIDTH,
            },
            ...(isCollapse && {
              width: SIDEBAR_COLLAPSE_WIDTH,
              '& .MuiDrawer-paper': {
                width: SIDEBAR_COLLAPSE_WIDTH,
                position: 'static',
                transform: 'none !important',
                visibility: 'visible !important',
              },
            }),
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          ModalProps={{ keepMounted: true }}
          open={openSidebar}
          onClose={handleCloseSidebar}
          sx={{
            '& .MuiDrawer-paper': { width: SIDEBAR_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}
