import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { Button, Grid, IconButton, Snackbar, Tab, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
// import Iconpicker from '../Inbox/Iconpicker'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import Inboxuser from './Inboxuser'
import { useParams } from 'react-router'
import axiosInstance, { baseUrl } from 'src/utils/axios'
import CloseIcon from '@mui/icons-material/Close';
import './VoiceSetting.css'
import { useSelector } from 'react-redux'
import Whatsappuser from './Whatsappuser'
import Facebookicon from './Facebookicon'
import Instagramicon from './Instagramicon'
import Iconpicker from '../IconPicker/Index'
const InstagramEdit = () => {
    const [open, setOpen] = useState(false)
    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }

        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };


    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    const getParams = useParams();

    const iconpickerslice = useSelector(state => state.iconpickerslice.value)
    const getuserid = () => {
        console.log("getParamsid", getParams.id)
        axiosInstance.get(`${baseUrl}/users/${getParams.id}`)
            .then((res) => {
                console.log("getParamsid", getParams.id)
                console.log("checkboxuserdata", res.data)
            }).catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        getuserid()
    }, [])

    const [number, setnumber] = useState('')
    const [inboxname, setinboxname] = useState('')
    const [inboxicon, setinboxicon] = useState('')
    const [acesstoken, setacesstoken] = useState('')
    const [graphdomain, setgraphdomain] = useState('')
    const [userId, setuserId] = useState('')
    const [loadings, setloadings] = useState(false)
    const getdata = () => {

        const authid = localStorage.getItem('authID')
        axiosInstance.get(`${baseUrl}/inbox-view/?AccountSid=${authid}&_id=${getParams.id}`)
            .then((res) => {
                console.log("getdatauserdata", res.data.data)
                // console.log("getdatauserdatanumber", res.data.data.data[0].data[0].number)
                setinboxname(res.data.data.data[0].InboxName)
                setinboxicon(res.data.data.data[0].InboxIcon)
                setnumber(res.data.data.data[0].data[0].number ? res.data.data.data[0].data[0].number : res.data.data.data[0].data[0].whatsAppNumber)
                setacesstoken(res.data.data.data[0].data[0].AcessToken)
                setgraphdomain(res.data.data.data[0].data[0].graphDomain)
                setuserId(res.data.data.data[0].data[0].userId)

            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        getdata()
    }, [])

    const [value, setValue] = useState('general')
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
        // if (newValue === 'general') {
        //     navigate('/dashboard/profile/general')
        // } else
        //     if (newValue === 'users') {
        //         navigate('/dashboard/profile/billing')
        //     }
    }
    const iconpng = localStorage.getItem('iconpng')
    console.log("iconpng", iconpng)
    const obj = {


        InboxName: inboxname,
        InboxIcon: iconpickerslice,


    }
    const getapiedit = () => {
        setloadings(true)
        axiosInstance.patch(`inbox-view/${getParams.id}`, obj)
            .then((res) => {
                setloadings(false)
                console.log("finalresponseedit", res)
                handleClick()
            }).catch((err) => {
                setloadings(false)
                console.log(err)
            })
    }


    return (
        <div>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: "4%" }}>
                    <TabList onChange={handleChange}>
                        <Tab label="General" value='general' />
                        <Tab label="Users" value='users' />

                    </TabList>

                </Box>

                <TabPanel value='general'>

                    <Grid
                        className='voiceinboxname'
                    // style={{ paddingLeft: "5%", marginTop: "5%", display: "flex", marginRight: "30%" }}
                    >
                        <TextField
                            name='Inbox name'
                            label='Inbox name'
                            fullWidth
                            style={{
                                marginTop: "6px",
                                maxWidth: "340px"
                            }}
                            value={inboxname}
                            onChange={(e) => setinboxname(e.target.value)}
                        />
                        <div
                            className='iconpickervoice'
                        //  style={{ marginLeft: "20px", marginRight: "20px" }}
                        >
                            <Iconpicker setIcon={iconpickerslice} />
                        </div>

                        {/* 
                        <div
                            className='phonenumbervoice'
                        // style={{ width: "400px", marginTop: "-17px" }}
                        >
                            Cloud Number

                            <PhoneInput

                                country={'in'}
                                value={number}
                                specialLabel=""
                                enableSearch={true}

                                disabled
                                containerStyle={{

                                    width: "100%",
                                    height: "50px",
                                    // marginTop: "6px"

                                }}
                                inputStyle={{
                                    // background: "lightblue"
                                    width: "100%",
                                    height: "50px",
                                    // marginTop: "6px"

                                }}

                                inputProps={{
                                    name: 'phonenumber',
                                    required: true,
                                }}

                            // isValid={phonenumber.length <= 2 ? false : true}

                            // disabled={is_admin !== 'true' && !permissionContactsManage.edit}

                            />
                        </div> */}
                    </Grid>
                    <Grid
                        className='secondfbdiv'
                    >
                        <div
                            className='subfbdiv'

                        >
                            <TextField
                                name="AccessToken"
                                label="AccessToken"
                                value={acesstoken}
                                disabled
                                onChange={(e) => setacesstoken(e.target.value)}
                            />
                        </div>
                        {/* <div
                            className='subfbdiv'
                        >
                            <TextField
                                name='GraphDomain'
                                label='GraphDomain'
                                value={graphdomain}
                                onChange={(e) => setgraphdomain(e.target.value)}


                            />
                        </div> */}
                        <div
                            className='subfbdiv'
                        >
                            <TextField
                                name='UserId'
                                label='UserId'
                                value={userId}
                                disabled
                                onChange={(e) => setuserId(e.target.value)}
                            />
                        </div>

                    </Grid>
                    <Grid
                        className='secondfbdiv'
                    >
                        <Instagramicon />
                    </Grid>
                    <div
                        className='loadingbutton'
                    // style={{ display: "flex", justifyContent: "center", marginTop: "10%" }}
                    >
                        <LoadingButton loading={loadings} variant='contained' onClick={() => getapiedit()}>Save Changes</LoadingButton>
                    </div>

                </TabPanel>
                <TabPanel value='users'>
                    <Whatsappuser />
                </TabPanel>
            </TabContext>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: "right"
                }}
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                message="user edit successfully"
                action={action}
            />


        </div>
    )
}

export default InstagramEdit