import React, {useState} from 'react';
import {
    IconButton,
    Tooltip
} from '@mui/material'
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getIvrVoice} from '../../../../redux/slices/Inbox/voice';

import TagSelect, {TagForm} from './TagSelect';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import './Tags.css'
export default ({props, statusFlag, setStatusFlag}) => {

    let rowData = props.row;
    const dispatch = useDispatch();
    // console.log('props tags', props, rowData.Tags.length)

    const[tags,setTags] = useState([]);
    const [dmyId, setDmyId] = useState('');
    const [userId, setUserId] = useState('');
    const[tagSelectopen,setTagSelectopen] = useState(false);
    const [originFromWhereTagsIsCalled , setOriginFromWhereTagsIsCalled] = useState('Ivr');
    const [tagFormOpen, setTagFormOpen] = useState(false);
    const[colorSelectopen,setColorSelectopen] = useState(false);
    const [updateTag, setUpdateTag] = useState({});
    const [rowDetails , setRowDetails ] = useState(null);
    const [colorSelectvalue, setColorSelectValue] = useState(false);
    const [message, setMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false) 
    const [flag, setFlag] = useState(false);
    let toggle = () => {return setFlag(!flag)};

    const handleCloseTagSelect = () => {
        setTagSelectopen(false);
        setColorSelectopen(false);
      };
      const handleFormDialogClose = () => {
        setDmyId('');
        setTagFormOpen(false);
        console.log('closed popup')
        setStatusFlag(!statusFlag)
      }

      const handleCloseColorSelect = () => {
        setColorSelectopen(false);
      };
      const handleOpenColorSelect = () => {
        setColorSelectopen(true);
      };
      const handleChangeColor = (event) => {
        setColorSelectValue(event.target.value);
      };

      const handleFormSubmit = (values) => {
        console.log("We are the form values....", values);
        setTags([...tags, values])
      }

      const handleMessageNotify = (msg) => {
        setMessage(msg);
        handleNotification();
      } 
      const handleNotification = () => {
        setShowNotification(true)
      }

      const afterTagAddition = () => { 
      return toggle();
      } 
// A function to Open the list of Tags after a new Tag is posted To the user_auth_id backend.
      const openTagAfterPost = (user_id, parentuid) => {
        console.log("parent uid : ", parentuid)
        console.log("props: 68 : ", props)
        let reportsViewFilter = "Ivr"
        if(reportsViewFilter === "Ivr"){
            console.log("Global tags after adding a new tag to user reportViewFilter", reportsViewFilter)
            // let i = Object.values(props.data).find(value => value.ParentCall.ParentCallSid === parentuid)
            setTimeout(() =>{
              //  return handleOpenTagSelect(i.Tags,i.ParentCall.ParentCallSid, i.AccountSid); old without ag-grid
              return handleOpenTagSelect(props.data.Tags,props.data.ParentCall.ParentCallSid, props.data.AccountSid);
            }, (2000));
            return 
        }else{
            return 
        }

      }

      const handleTagFormDialog = (item, user_id) => {
        setUpdateTag(item);
        setUserId(user_id);
        setTagFormOpen(true);
        handleCloseTagSelect();
      }

    const handleOpenTagSelect = (tags, parent_id, user_id) => {
        setTags(tags)
        setDmyId(parent_id);
        setUserId(user_id);
        setTagSelectopen(true);
      };
 
  return (
    <span style={{paddingTop: '17px', display: 'block'}}>
            <div style={{display:"flex", alignItems: 'center'}}>

                { rowData.Tags.length > 0 ?
                <>
                {rowData.Tags?.slice(0).reverse().map((v,k) => 
                { if (k < 3) {
                    return (
                    <Tooltip title={v.name} >


                        <span  key = {k} style={{background:v.backgroundColor}} 
                        onClick={() => {handleOpenTagSelect(rowData.Tags,rowData.ParentCall.ParentCallSid, rowData.AccountSid)}} className="tag-circle"
                        />
                    </Tooltip>
                        )} }
                )}
                {rowData.Tags.length>3 ? 
                (<span className='Tagspan' 
                    onClick={() => {handleOpenTagSelect(rowData.Tags,rowData.ParentCall.ParentCallSid,  rowData.AccountSid)}}>
                    {(rowData.Tags.length - 3) < 0 ? '' : `+${rowData.Tags.length - 3}`}
                </span>)
                : ''
                } 
                </>
                : <>
                <Tooltip title='Add A Tag'>
                <IconButton onClick = {()=>{handleOpenTagSelect(rowData.Tags,rowData.ParentCall.ParentCallSid, rowData.AccountSid)}} variant="contained">
                  <LocalOfferIcon />
                </IconButton>
                </Tooltip>
                   
                </>}

                </div>


                <TagSelect 
                        tagSelectopen={tagSelectopen}
                        setTagSelectopen={setTagSelectopen}
                        originFromWhereTagsIsCalled={originFromWhereTagsIsCalled}
                        rowData={rowDetails}
                        handleCloseTagSelect={handleCloseTagSelect}
                        tags={tags}
                        colorSelectopen={colorSelectopen}
                        handleCloseColorSelect={handleCloseColorSelect}
                        handleOpenColorSelect={handleOpenColorSelect}
                        handleFormSubmit={handleFormSubmit}
                        parentId = {dmyId}
                        userId = {userId}
                        handleMessageNotify = {handleMessageNotify}
                        handleNotification = {handleNotification}
                        handleTagFormDialog = {handleTagFormDialog}
                        afterTagAddition = {afterTagAddition}
                        openTagAfterPost = {openTagAfterPost}
                   />
             
             <TagForm
                     tagFormOpen={tagFormOpen}
                     setTagFormOpen={setTagFormOpen}
                     handleCloseTagSelect={handleCloseTagSelect}
                     handleCloseColorSelect={handleCloseColorSelect}
                     parentId= {dmyId}
                     userId={userId}
                     item={updateTag}
                     handleFormSubmit= {handleFormSubmit}
                     handleFormDialogClose={handleFormDialogClose}
                     afterTagAddition={afterTagAddition}
                     setColorSelectopen={setColorSelectopen}
             />  
    </span>
  );
};