import React, { useState, useEffect } from "react";

import "./style.css";

// mui

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  DialogTitle,
  Fab,
  Grid,
  TextField,
  Box,
  Chip,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  FormControl,
  Radio,
  Typography,
  Tooltip,
  Alert,
  AlertTitle,
} from "@mui/material";

import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";

import ListItemButton from "@mui/material/ListItemButton";

import ListItemIcon from "@mui/material/ListItemIcon";

import ListItemText from "@mui/material/ListItemText";

import Checkbox from "@mui/material/Checkbox";

import IconButton from "@mui/material/IconButton";

import CommentIcon from "@mui/icons-material/Comment";

// redux

import { useSelector, useDispatch } from "react-redux";

import {
  getTagsUser,
  patchTagsUser,
  deleteTagUser,
} from "../../redux/slices/tags";

// components

import Iconify from "../Iconify";

export default function Tags({ tags, fromTable, saveTag, editTagId }) {

  const dispatch = useDispatch();

  const authID = localStorage.getItem("authID");

  const [currentTags, setCurrentTags] = useState([])

  const [tagState, setTagState] = useState([])
  const [userTags, setUserTags] = useState([])

  const [showList, setShowList] = useState(true);

  const [tagSearch, setTagSearch] = useState("");

  const [tagName, setTagName] = useState("");

  const [tagColor, setTagColor] = useState("#DEB3AD");

  const [openDialog, setOpenDialog] = useState(false);

  const [deleteFlag, setDeleteFlag] = useState(false);

  const [deleteAlert, setDeleteAlert] = useState(false);

  const [existAlert, setExistsAlert] = useState(false);

  const [deleteID, setDeleteID] = useState("");

  const [tableDataLoader, setTableDataLoader] = useState(true);

  // hacking

  const [state, setState] = useState({});

  function getUserTags() {
    dispatch(getTagsUser(authID));
  }

  useEffect(() => {
    if(authID !== '' && authID !== undefined){ 
        getUserTags();   
    }
  }, [dispatch, authID]);


  const { getTagsUserData } = useSelector((state) => state.tags);

  const currenTagsLenght = (tags !== null ? (Object.keys(tags).length) : (0))
  const userTagsLength = Object.keys(getTagsUserData).length

  useEffect(() => {
    if(tags !== null){
      setCurrentTags(JSON.parse(JSON.stringify(tags)))
    }
    
    setUserTags(JSON.parse(JSON.stringify(getTagsUserData)))
    console.log('MutableCurrentTags, MutableUserTags', JSON.parse(JSON.stringify(tags)), JSON.parse(JSON.stringify(getTagsUserData)))
    if(Object.keys(getTagsUserData).length !== 0){
        tagsMerge(currentTags, getTagsUserData)
     }else{
        setTableDataLoader(false)
     }
    
  }, [getTagsUserData])



  function tagsMerge(ctags, utags){

    console.log('MutableCurrentTags, MutableUserTags', ctags.length, userTagsLength)
    


    if(currenTagsLenght === 0 && utags.length !==0){

        let mergedUserArray = []

        utags.map((item)=>{
            if(item.name !== ''){
                mergedUserArray.push({name: item.name, backgroundColor: item.backgroundColor, _id: item._id, checked: false})
            }
        })
        setTagState(mergedUserArray)

        setTableDataLoader(false)

        console.log('mergedUserArray before', tagState)

    }else
    if(currenTagsLenght !==0 && utags.length !==0){

        let mergedUserArray = []

        utags.map((item)=>{
            if(item.name !== ''){
                mergedUserArray.push({name: item.name, backgroundColor: item.backgroundColor, _id: item._id, checked: false})
            }
        })

        

        ctags.map((item)=>{

            if(item.checked === true && item.name !== ''){
                let findInd = mergedUserArray.findIndex(indItem => indItem._id === item._id)
                if(findInd !== -1){
                    mergedUserArray[findInd].checked = true
                }
            }
        })

        setTagState(mergedUserArray)

        setTableDataLoader(false)

        console.log('mergedUserArray after', tagState)


    }
    
   
    
    
  }



  const handleEditTag = () => {
      if(fromTable) {
        localStorage.setItem("editTagID", editTagId);
      }
      setOpenDialog(true);
      setEditTag(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    localStorage.removeItem("editTagID");
  };


  const handleOpenDialog = () =>{
    setOpenDialog(true);
    if (fromTable) {
      localStorage.setItem("editTagID", editTagId);
    }
  }

  const refresh = () => {
    getUserTags()
  };

  const deleteTag = (id) => {
    setDeleteID(id);
    setDeleteAlert(true);
  };

  const cancelDelete = () => {
    setDeleteID("");
    setDeleteAlert(false);
  };

  const confirmDelete = () => {
    dispatch(deleteTagUser(authID, deleteID));
    setDeleteID("");
    setDeleteAlert(false);
    setDeleteFlag(!deleteFlag);
    refresh();
    // setuserDataFlag(true);
  };


  const showAddTag = () => {
    setShowList(false);

    setTagName("");

    setTagColor("#DEB3AD");
  };

  const cancelAddTag = () => {
    setShowList(true);

    dispatch(getTagsUser(authID));
  };
  
  const colorList = [
    "#DEB3AD",
    "#DE847B",
    "#B95C50",
    "#D3B1C2",
    "#C197D2",
    "#E1C16E",
    "#FFFF00",
    "#F86F15",
    "#A2C4E0",
    "#52688F",
    "#39918C",
    "#D4F1F4",
    "#A3EBB1",
  ];
  

  
  const handleCheckboxChange = (event, index) => {
    console.log("tagState checkbox", tagState, index);

    if (tagState[index].checked === true) {
      tagState[index].checked = false;
    } else {
      tagState[index].checked = true;
    }

    setTagState(tagState);

    // hackline

    setState({ ...state, [event.target.name]: event.target.checked });

    saveTags()
  };

  const handleTagName = (event) => {
    setTagName(event.target.value);

    if (
      getTagsUserData.filter((e) => e.name === event.target.value).length > 0
    ) {
      if (event.target.value !== "") {
        setExistsAlert(true);
      }
    } else {
      setExistsAlert(false);
    }
  };


  const addTag = () => {
    let addArray = [
      { name: tagName, backgroundColor: tagColor, checked: false },
    ];

    dispatch(patchTagsUser(authID, addArray));

    setShowList(true);

    dispatch(getTagsUser(authID));

    // setuserDataFlag(true);

    refresh();

  };

  const saveTags = () => {

    localStorage.setItem("saveTagUpdatedData", JSON.stringify(tagState));

    if (fromTable) {
      console.log("save tags trigger");

      saveTag();
    }

    // handleCloseDialog();
  };



  return (
    <>

    {tableDataLoader ? 'loading' : (<>
   

{tagState.length !== 0 &&
          tagState.filter((item) => item.checked === true).length !== 0 ? (
            <>
              <Grid
                container
                direction="row"
                spacing={1}
                style={{ marginTop: "5px" }}
              >
                {tagState
                  .filter(
                    (check) => check.checked === true && check.name !== ""
                  )
                  .slice(0, 3)
                  .map((item, index) => {
                    return (
                      <>
                        {item.checked === true ? (
                          <Grid item>
                            <Chip
                              label={fromTable ? "" : item.name}
                              className={fromTable ? "table-tag-chips" : ""}
                              style={{
                                background: `${item.backgroundColor}`,
                                borderRadius: "8px",
                              }}
                              onClick={handleEditTag}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}

                <Grid item>
                  {tagState.filter((check) => check.checked === true).length >
                    3 && (
                    <div className="tags-count" onClick={handleEditTag}>
                      +
                      {tagState.filter((check) => check.checked === true)
                        .length - 3}
                    </div>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid
              container
              direction="row"
              spacing={1}
              style={{ marginTop: "5px" }}
            >
              <Grid item xs></Grid>

              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={handleOpenDialog}
                >
                  <Iconify icon={"akar-icons:plus"} width={14} height={14} />
                  Add Tags
                </Button>
              </Grid>

              <Grid item xs></Grid>
            </Grid>
          )}



<Dialog
        open={openDialog}
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <h3 style={{ flexGrow: 1 }}>Tags</h3>

            {showList && (
              <Tooltip title="Refresh Tags">
                <IconButton onClick={refresh}>
                  <Iconify icon="eva:refresh-fill" />
                </IconButton>
              </Tooltip>
            )}

            <IconButton onClick={handleCloseDialog}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box style={{ padding: "20px 0 0 0" }}>
            {showList ? (
              <>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs>
                    <TextField
                      label="Filter Tags"
                      value={tagSearch}
                      onChange={(event) => setTagSearch(event.target.value)}
                      fullWidth
                    />
                  </Grid>

                  <Grid item>
                    <Tooltip title="Create a New Tags">
                      <Fab color="primary" onClick={showAddTag}>
                        <Iconify
                          icon={"akar-icons:plus"}
                          width={20}
                          height={20}
                        />
                      </Fab>
                    </Tooltip>
                  </Grid>
                </Grid>

                {deleteAlert && (
                  <Alert severity="error" style={{ marginTop: "20px" }}>
                    <AlertTitle>Warning</AlertTitle>
                    Deleting this tag will remove it from everywhere.
                    <strong>
                      <br /> Are you sure want to Continue?
                    </strong>{" "}
                    <br />
                    <Button
                      color="inherit"
                      size="small"
                      variant="outlined"
                      style={{ marginRight: "10px", marginTop: "20px" }}
                      onClick={cancelDelete}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      style={{ marginRight: "10px", marginTop: "20px" }}
                      onClick={confirmDelete}
                    >
                      Confirm
                    </Button>
                  </Alert>
                )}

                {tagState.length > 0 && (
                  <>
                    <List
                      sx={{
                        width: "100%",
                        maxHeight: "300px",
                        overflowY: "auto",
                        marginTop: "15px",
                      }}
                    >
                      {tagState
                        .filter((item) => {
                          if (!tagSearch) return true;

                          if (item.name.toLowerCase().includes(tagSearch.toLowerCase())) {
                            return true;
                          }
                        })
                        .map((item, index) => {
                          return (
                            <ListItem
                              style={{
                                background: `${item.backgroundColor}`,
                                width: "100%",
                                borderRadius: "10px",
                                marginTop: "10px",
                              }}
                              key={item._id}
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="comments"
                                  className="delete-tag-btn"
                                  onClick={() => deleteTag(item.name)}
                                >
                                  <Iconify icon="eva:trash-2-outline" />
                                </IconButton>
                              }
                              disablePadding
                            >
                              <ListItemButton
                                role={undefined}
                                dense
                                onClick={(event) =>
                                  handleCheckboxChange(event, index)
                                }
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    // checked={checked.indexOf(value) !== -1}

                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": item.name,
                                    }}
                                    // onChange={(event)=>handleCheckboxChange(event, index)}

                                    checked={item.checked}
                                  />
                                </ListItemIcon>

                                <ListItemText primary={item.name} />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                    </List>

                    {tagState.filter((item) => {
                      if (!tagSearch) return true;

                      if (item.name.includes(tagSearch)) {
                        return true;
                      }
                    }).length !== 0
                      ? ""
                      : "No Tags Found"}
                  </>
                )}
              </>
            ) : (
              <>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Tag Name"
                      value={tagName}
                      //onChange={(event) => setTagName(event.target.value)}

                      onChange={handleTagName}
                      fullWidth
                    />

                    {existAlert && (
                      <>
                        <Alert severity="error" style={{ marginTop: "5px" }}>
                          Tag Already Exists!
                        </Alert>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel id="demo-customized-radios">
                        Tag Color
                      </FormLabel>

                      <RadioGroup
                        defaultValue="female"
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                        value={tagColor}
                        onChange={(event) => setTagColor(event.target.value)}
                      >
                        <Grid container direction="row" spacing={1}>
                          {colorList.map((item) => {
                            return (
                              <Grid item xs={3}>
                                <FormControlLabel
                                  value={item}
                                  className={
                                    tagColor === item
                                      ? "tag-select-item active"
                                      : "tag-select-item"
                                  }
                                  style={{ background: `${item}` }}
                                  control={
                                    <Radio style={{ display: "none" }} />
                                  }
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={2}>
                  <Grid item xs></Grid>

                  <Grid item>
                    <Button onClick={cancelAddTag}>Cancel</Button>
                  </Grid>

                  <Grid item>
                    <Button
                      onClick={addTag}
                      disabled={existAlert === true || tagName === ""}
                      color="primary"
                      variant="contained"
                    >
                      <Iconify
                        icon={"akar-icons:plus"}
                        width={20}
                        height={20}
                      />{" "}
                      Create Tag
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </DialogContent>

        {/* {showList ? (
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>

            <Button
              onClick={saveTags}
              color="primary"
              variant="contained"
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        ) : (
          ""
        )} */}
      </Dialog>

      </>)}

     </>
  );
}
