import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    messages : [],
    id : '',
}


export const telegramSocketSlice = createSlice({
    name:'telegram_socket_messages',
    initialState,
    reducers: {
        addMessageById(state, {payload}) {
            console.log("telegram payload receive from socket to add message ", payload)
            
         
                state.id = payload.id;
                state.messages.push(payload.message);


        },

        clearMessageById(state, {payload}) {
            console.log("payload receive from socket to clear message  ", payload)
            state.messages = [];
            state.id = '';
        }
    }
});

export const { addMessageById , clearMessageById } = telegramSocketSlice.actions;

export default telegramSocketSlice.reducer;