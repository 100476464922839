import { useState, useEffect } from 'react';
// @mui
import { Card, Container } from '@mui/material';
// redux
import { useDispatch } from '../../redux/store';
import { getConversationsMessenger, getContacts, getMessengerConversations } from '../../redux/slices/chat';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { ChatSidebarMessenger, ChatWindowMessenger } from '../../sections/@dashboard/chat';
import io from "socket.io-client";
import { useSelector } from 'react-redux';
import { REACT_APP_BASE_URL } from 'src/utils/axios';
import { useParams } from 'react-router';
import {socket} from 'src/utils/socketInst'


// const socket = io.connect("http://localhost:3000");
// const socket = io.connect("https://intense-wildwood-75978.herokuapp.com/");
//const socket = io.connect("https://mibsback.vibtree.com");

// ----------------------------------------------------------------------

export default function Chat() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [user, setUser] = useState({})
  const { name } = useParams()
  const no_of_fetch_count = useSelector((state) => state.WhatsappConversationList.no_of_fetch_count);

  const updatedConversation = useSelector((state) => state.WhatsappConversationList.list);

  const changeInConversation = useSelector((state) => state.WhatsappConversationList.changeInConversation)

  const newConversationLength = useSelector((state) => state.WhatsappConversationList.no_of_fetched_conversations);
  const sendmessage = useSelector((state) => state.chat.converstions);

  console.log("newConversationLength : ", newConversationLength);
  console.log("changeInConversation InboxWhataspp : ", changeInConversation, no_of_fetch_count)

  useEffect(() => {
    const baseUrl = REACT_APP_BASE_URL;
    //const baseUrl = 'https://mibsback.vibtree.com';

    const telegramSocket = io(`${baseUrl}/messenger`, {

      path: "/websockets",
      transports: ["polling", "websocket"],
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: "ApiKey <Key>",
          },
        },
      },
    });

    console.log("telegramSocket", telegramSocket)
    let telegramToken = "106528341166399"
    // localStorage.getItem("telegram-token") || '5549169131:AAFe2xPK6G78jCf52wg5MoaCcA5MtxFoKnM'

    telegramSocket.emit("join_room", {
      request: "chats",
      token: "106528341166399",
    });


    telegramSocket.emit(`telegramDashboard_${telegramToken}`)

    telegramSocket.on(`telegram_data`, (data) => {
      console.log("telegramSocket data only of this account : ", data);
      if (data.Direction === "Inbound") {
        const converstion_id = data.message.chat.id.toString();
        dispatch(addMessageById({ id: converstion_id, message: data }));
      }
    })

    telegramSocket.on('conversation_list', (data) => {
      console.log("telegramSocket realtime_conversation list data :: ", data)
      dispatch(getMessengerConversations())
      dispatch(checkIfConversationIsNew(data.change))
    });

    telegramSocket.on('incomingMessage', (data) => {
      console.log("public data : ", data);
      dispatch(getMessengerConversations())
    })


    if (no_of_fetch_count === 0) {
      dispatch(getMessengerConversations());
      dispatch(getContacts());
    }

    if (no_of_fetch_count >= 1) {
      dispatch(getUpdatedConversationFromSocket(updatedConversation));
    }

  }, [dispatch, no_of_fetch_count]);
  useEffect(() => {
    dispatch(getMessengerConversations())
  }, [sendmessage])


  // useEffect(() => {
  //   socket.on("test-message", (data) => {
  //     console.log("test data : ", typeof data, data)
  //     setUser(data)
  //   })
  //   dispatch(getConversationsMessenger());
  //   // dispatch(getContacts());
  // }, [dispatch]);
  const names = name.slice(0, 1).toUpperCase() + name.slice(1)
  return (
    <Page title={names}>
      {/* <Container maxWidth={themeStretch ? false : 'xl'}> */}
      {/* <HeaderBreadcrumbs
          heading="Messenger"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Messenger' }]}
        /> */}
      <Card sx={{ height: '100vh', display: 'flex' }}>
        <ChatSidebarMessenger user={user} />
        <ChatWindowMessenger />
      </Card>
      {/* </Container> */}
    </Page>
  );
}
