import { Button, Switch, CircularProgress, Card, Typography } from "@mui/material";
import './style.css'
import React from "react";
import Iconify from "src/components/Iconify";
import Grid from '@mui/material/Grid';

const Application_component = ({ app_name, app_detail, checked, loading_config, open_configure, handleChange, callIcon }) => {
    return (
        <Grid item lg={4} xs={12} sm={6} >
            <Card className='application-cards' variant="outlined">
                <Grid container direction='row'>
                    <Grid item xs>
                        <img src={require(`../assets/${callIcon}.png`)} style={{ width: 60, height: 60,}}/>
                    </Grid>
                    <Grid item>
                        <Switch checked={checked} onChange={(e) => handleChange(e, app_name)} inputProps={{ 'aria-label': 'controlled' }}/>
                    </Grid>
                </Grid>

                <Typography variant='h4' color='primary' display='block'>
                    {app_name}
                </Typography>

                <Typography variant='caption' display='block' color='primary'>
                    {app_detail}
                </Typography>

                <div style={{width: '100%', height: '50px'}}>
                {checked === true && <div>
                         {loading_config === true ? (
                            <CircularProgress color={'primary'} style={{ width: 25, height: 25, marginTop: 10, display: "flex", alignSelf: "flex-start", marginLeft: 15 }}/>
                         ): (
                            <Button variant='outlined' color='primary' style={{ marginTop: 10}} onClick={() => open_configure(app_name)}>
                              <Iconify icon={'tabler:database-import'} sx={{width: 20, height: 20, }}/>
                              
                                Configure
                          
                            </Button>
                         )}
                     </div>}

                     </div>
            
                </Card>
        </Grid>
    )
}

export default Application_component;