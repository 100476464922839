import { Button, Dialog, IconButton, Snackbar } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axiosInstance from 'src/utils/axios'
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

import { incrementRefreshpage } from 'src/redux/Refreshpage';
import { useDispatch } from 'react-redux';
const StatusCheck = ({ params, handleClickstatus }) => {
    const selectedassignedid = useSelector(state => state.assignedidSlice.value)
    console.log("selectedassignedid", selectedassignedid)

    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleClick1 = () => {
        setOpen(true);
    };

    const action = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );




    const [drawerOpen, setDrawerOpen] = useState(false)
    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }
    const handleDrawerOpen = () => {
        setDrawerOpen(true)
    }
    const [status, setStatus] = useState('InActive')
    const [loading, setloadings] = useState(false)
    const obj = {


        Status: status

    }
    const getapiedit = (value) => {
        setloadings(true)
        axiosInstance.patch(`inbox-view/${value}`, {
            Status: params.row.Status == "Active" ? "InActive" : "Active"
        })
            .then((res) => {
                setloadings(false)
                // handleClick1()
                console.log("finalresponseedit", res)
                handleDrawerClose()
                dispatch(incrementRefreshpage(value))
                // handleClick()
            }).catch((err) => {
                setloadings(false)
                console.log(err)
            })
    }


    return (
        <div>
            {params.row.Status == "Active" || params.row.Status == "active" ? <>
                <Button style={{ backgroundColor: "#dff3ea", color: "#1b806a" }} onClick={() => { setDrawerOpen(true) }} size='small'>
                    {params.row.Status}
                </Button>
            </> : <>
                <Button style={{ backgroundColor: "#ffe5df", color: "#c6423d" }} size='small' onClick={() => { setDrawerOpen(true) }}>
                    {params.row.Status}
                </Button>
            </>}


            <Dialog
                open={drawerOpen}
                onClose={handleDrawerClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div
                    style={{ width: "400px", display: "flex", flexDirection: "column", marginTop: "10%", alignItems: "center", padding: "2%", marginBottom: "10%" }}
                >
                    {params.row.Status == "Active" || params.row.Status == "active" ? <>
                        <h3>Do you really want Inactive "{params.row.InboxName}"</h3>
                    </> : <><h3>Do you really want active "{params.row.InboxName}"</h3></>}
                    <div style={{ marginTop: "10%" }}>
                        <LoadingButton loading={loading} variant='contained' onClick={() => {
                            getapiedit(params.row._id)

                        }} style={{ marginRight: "30px" }}>Yes</LoadingButton>
                        <Button variant='contained' onClick={() => handleDrawerClose()}>Cancel</Button>
                    </div>
                </div>

            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: "right"
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Inbox Inactive successfully"
                action={action}

            />

        </div>
    )
}

export default StatusCheck