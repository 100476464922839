import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Avatar, ListItemText, ListItemAvatar, ListItemButton } from '@mui/material';
//
import BadgeStatus from '../../../components/BadgeStatus';
import axiosInstance, { baseUrl } from '../../../utils/axios'

// ----------------------------------------------------------------------

const AVATAR_SIZE = 48;
const AVATAR_SIZE_GROUP = 32;

const RootStyle = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  transition: theme.transitions.create('all'),
}));

const AvatarWrapperStyle = styled('div')({
  position: 'relative',
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  '& .MuiAvatar-img': { borderRadius: '50%' },
  '& .MuiAvatar-root': { width: '100%', height: '100%' },
});

// ----------------------------------------------------------------------

const getDetails = (conversation, currentUserId) => {
  // console.log('getDetails', conversation, currentUserId);
  const otherParticipants = conversation.participants.filter((participant) => participant.conversation_id !== currentUserId);
  // console.log('getDetails: otherParticipants', otherParticipants);
  // const displayNames = otherParticipants.reduce((names, participant) => [...names, participant.user_name], []).join(', ');
  //new display names
  let whatsappNumber = localStorage.getItem('whatsappNumber');
  const displayNamesArray = otherParticipants.filter((participant) => participant.number !== whatsappNumber)
  console.log("displayNamesArray", displayNamesArray)
  const displayNames = displayNamesArray[1] ? displayNamesArray[1].user_name : displayNamesArray[0].user_name;
  const displayNumber = displayNamesArray[1] ? displayNamesArray[1].number : displayNamesArray[0].number;
  let displayText = '';
  console.log("Messenge : diplaynames ", displayNames, displayNamesArray)
  const lastMessage = conversation.messages[conversation.messages.length - 1];

  let sender
  if (lastMessage) {
    // sender = lastMessage.Direction === 'Inbound' ? lastMessage.contactName : 'You';
    let message
    if (lastMessage.messageBody.length > 20) {
      message = lastMessage.messageBody.slice(0, 10) + '...'

    } else {
      message = lastMessage.messageBody
    }
    // const message = lastMessage.Direction === 'Inbound' ? lastMessage.messageBody : lastMessage.templateName;
    displayText = `${message}`;
  }
  return { otherParticipants, displayNames, displayText, sender, displayNumber };
};

const getImageLink = async (participant) => {

  const src = participant.avatar ? participant.avatar : 'https://vibtreedan.s3.eu-central-1.amazonaws.com/vibtree-logo-thumbnail.png'
  console.log('getImageLink', participant, src);
  return src
}

ChatConversationItemMessenger.propTypes = {
  isSelected: PropTypes.bool,
  conversation: PropTypes.object.isRequired,
  isOpenSidebar: PropTypes.bool,
  onSelectConversation: PropTypes.func,
};

export default function ChatConversationItemMessenger({ isSelected, conversation, isOpenSidebar, onSelectConversation }) {
  const details = getDetails(conversation, '912235316004-918420014466--');
  console.log("ChatConversationItem.js: conversation", conversation, details);
  // const displayLastActivity = conversation.messages[conversation.messages.length - 1].createdAt ? ; conversation.messages[conversation.messages.length - 1].createdAt ? "2022-08-04T03:58:03.143Z"
  const displayLastActivity = "2022-08-04T03:58:03.143Z"

  const isGroup = details.otherParticipants.length > 2;
  const isUnread = conversation.unreadCount > 0;
  const isOnlineGroup = isGroup && details.otherParticipants.map((item) => item.status).includes('online');

  return (
    <RootStyle
      onClick={onSelectConversation}
      sx={{
        ...(isSelected && { bgcolor: 'action.selected' }),
      }}
    >
      <ListItemAvatar>
        <Box
          sx={{
            ...(isGroup && {
              position: 'relative',
              width: AVATAR_SIZE,
              height: AVATAR_SIZE,
              '& .avatarWrapper': {
                position: 'absolute',
                width: AVATAR_SIZE_GROUP,
                height: AVATAR_SIZE_GROUP,
                '&:nth-of-type(1)': {
                  left: 0,
                  zIndex: 9,
                  bottom: 2,
                  '& .MuiAvatar-root': {
                    border: (theme) => `solid 2px ${theme.palette.background.paper}`,
                  },
                },
                '&:nth-of-type(2)': { top: 2, right: 0 },
              },
            }),
          }}
        >
          {details.otherParticipants.slice(1, 2).map((participant) => (
            <AvatarWrapperStyle className="avatarWrapper" key={participant._id}>
              {/* <Avatar alt={participant.user_name} src={getImageLink(participant)} /> */}
              <Avatar alt={participant.user_name} src={participant.avatar} />
              {!isGroup && (
                <BadgeStatus status={participant.status ? participant.status : 'online'} sx={{ right: 2, bottom: 2, position: 'absolute' }} />
              )}
            </AvatarWrapperStyle>
          ))}

          {isOnlineGroup && <BadgeStatus status="online" sx={{ right: 2, bottom: 2, position: 'absolute' }} />}
        </Box>
      </ListItemAvatar>

      {isOpenSidebar && (
        <>
          <ListItemText
            primary={details.displayNames !== 'unknown' ? details.displayNames : details.displayNumber}
            // primary={details.displayNames === 'unknown' ? details.sender : details.displayNames} // if you want to display the sender name accoirding to whatsapp name 
            primaryTypographyProps={{
              noWrap: true,
              variant: 'subtitle2',
            }}
            secondary={details.displayText}
            secondaryTypographyProps={{
              noWrap: true,
              variant: isUnread ? 'subtitle2' : 'body2',
              color: isUnread ? 'textPrimary' : 'textSecondary',
            }}
          />

          <Box
            sx={{
              ml: 2,
              height: 44,
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                mb: 1.25,
                fontSize: 12,
                lineHeight: '22px',
                whiteSpace: 'nowrap',
                color: 'text.disabled',
              }}
            >
              {formatDistanceToNowStrict(new Date(displayLastActivity), {
                addSuffix: false,
              })}
            </Box>
            {isUnread && <BadgeStatus status="unread" size="small" />}
          </Box>
        </>
      )}
    </RootStyle>
  );
}
