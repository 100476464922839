
import { Button, CircularProgress } from '@mui/material'
import React, {useEffect} from 'react'
import { REACT_APP_BASE_URL } from 'src/utils/axios';

function PowerDialerComponent({ dialer, setModalStatus, setPowerDialerStatus, modal_status, loading_numbers, load_more_data, load_more_power_dialer }) {
  console.log("dialer", dialer)
  
  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} id="modal">
      {modal_status === true ? (
        <div style={{ width: 100, height: 150, boxShadow: "2px 2px 2px 2px lightgrey", display: "flex", flexDirection: "column", position: "relative"}}>
        <Button style={{ minWidth: 20, width: "100%", display: "flex", height: "33%", borderRadius: 0, alignItems: "center", justifyContent: "center"}} onClick={() => {
          setPowerDialerStatus("New");
          setModalStatus(false);

        }}>
          <div style={{ color: "#0046BB", fontSize: 14, fontWeight: "400"}}>
            New
          </div>
        </Button>
        <Button style={{ minWidth: 20, width: "100%", display: "flex", height: "33%", borderRadius: 0, alignItems: "center", justifyContent: "center"}} onClick={() => {
          setPowerDialerStatus("completed");
          setModalStatus(false);
          
        }}>
          <div style={{ color: "#0046BB", fontSize: 14, fontWeight: "400"}}>
            Completed
          </div>
        </Button>
        <Button style={{ minWidth: 20, width: "100%", display: "flex", height: "34%", borderRadius: 0, alignItems: "center", justifyContent: "center"}} onClick={() => {
          setPowerDialerStatus("no-answer");
          setModalStatus(false);
          
        }}>
          <div style={{ color: "#0046BB", fontSize: 14, fontWeight: "400"}}>
            No Answer
          </div>
        </Button>
      </div>
      ): (
        <div style={{ width: "100%", alignItems: "center", display: "flex", flexDirection: "column"}}>
          {loading_numbers === true ? (
            <CircularProgress style={{ color: "#0046BB", marginTop: 50, alignSelf: "center", display: "flex"}}/>
          ): (
            <div style={{ width: "100%", alignItems: "center", display: "flex", flexDirection: "column"}}>
              {dialer.length === 0 ? (
                <div style={{ marginTop: 50, alignSelf: "center", color: "#0046BB", fontWeight: "600", display: "flex"}}>
                  No power dialer numbers are found
                </div>
              ): (
                <>
         {dialer.map((item, index) => (
          <div style={{ width: "100%", display: "flex", flexDirection: "row", height: 70, alignItems: "center", justifyContent: "space-between"}} key={index}>
          <div style={{ height: "100%", display: "flex", flexDirection: "row", alignItems: "center"}}>
            {item.contactId !== null && item.contactId !== undefined && item.contactId !== "" ? (
              <>
                {item.contactId.photo !== undefined && item.contactId.photo != null && item.contactId.photo !== "undefined" ? (
              <img src={`${REACT_APP_BASE_URL}/api/contact/download?fileLocation=${item.contactId.photo}`} style={{ width: 45, height: 45, borderRadius: 40, marginLeft: 5 }}/>
            ): (
              <div style={{ width: 45, height: 45, marginLeft: 5, borderRadius: 40, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ color: "#fff", fontSize: 20, fontWeight: "bold"}}>
                  {item.contactId.firstName.substr(0, 1)}
                </div>
              </div>
            )}
              </>
            ): (
              <div style={{ width: 45, height: 45, marginLeft: 5, borderRadius: 40, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{ color: "#fff", fontSize: 20, fontWeight: "bold"}}>
                  U
                </div>
              </div>
            )}
            <div style={{ marginLeft: 10, height: "100%", justifyContent:"space-evenly", display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
              {item.contactId !== null && item.contactId !== undefined && item.contactId !== "" ? (
                <div style={{ color: "#000", fontSize: 14, textTransform: "none", fontWeight: "bold"}}>
                {item.contactId.firstName} {item.contactId.lastName}
                </div>
              ): (
                <div style={{ color: "#000", fontSize: 14, textTransform: "none", fontWeight: "bold"}}>
                  Unknown
                </div>
              )}
              {item.contactId !== null && item.contactId !== undefined && item.contactId !== "" ? (
                <div style={{ fontSize: 10, color: "#000", }}>
                {item.contactId.phoneNumber}
                </div>
              ): (
                <div style={{ fontSize: 10, color: "#000", }}>
                {item.contactNumber}
                </div>
              )}
            </div>
          </div>
          <div style={{ color: "#000", fontWeight: "bold", fontSize: 12, marginRight: 10 }}>
            {item.callStatus}
          </div>
        </div>
        ))}
          {dialer.length >= 10 && <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column"}}>
                      {load_more_data === true ? (
                        <CircularProgress color="primary" style={{ width: 25, height: 25, marginTop: 10, display: "flex", alignSelf: "center" }}/>
                      ): (
                        <Button style={{ height:40, width: 100, borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center", alignSelf: "center", marginBottom: 20, whiteSpace: "nowrap" }} onClick={() => load_more_power_dialer()}>
                          <div style={{ color: "#0046BB", fontSize: 14, fontWeight: "bold", textTransform: "none"}}>
                            Load More
                          </div>
                        </Button>
                      )}
                    </div>}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default PowerDialerComponent