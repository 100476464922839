import { Grid, MenuItem, FormHelperText, Typography, Card, CardHeader } from '@mui/material'
import './style.css'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import NumberBuy from 'src/pages/dashboard/NumberBuy'
import { incrementChanneladduser, incrementChanneladduserValidate } from 'src/redux/ChannelAdduser'
import axiosInstance from 'src/utils/axios'
import Multiselect from 'multiselect-react-dropdown';
import { incrementinboxuserid, incrementinboxuseridValidate } from 'src/redux/InboxUserid'
import InviteUsers from 'src/sections/@dashboard/user/account/InviteUsers'
const AddUser = () => {

    const [usersel, setusersel] = useState('')
    const inboxUsers = useSelector(state => state.Inboxuseridslice.value)
    const inboxUserValidate = useSelector(state => state.Inboxuseridslice.validate)


    const dispatch = useDispatch()
    // const [userobj, setuserobj] = useState(countries)
    const [code, setcode] = useState({})
    // const [phone, setphone] = useState("")
    const hanldeobj = (e) => {
        // setcode(e)
        console.log("selectedList user", e)
        dispatch(incrementinboxuserid(e))
       //  setSmsUsers(e)
        if(e.length === 0){
            dispatch(incrementinboxuseridValidate(true))
            //setUserRequired(true)
        }else{
            dispatch(incrementinboxuseridValidate(false))
            //setUserRequired(false)
        }
    }
    


    const [user, setuser] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])

    const [openDialog, setOpenDialog] = useState(false);

    const aduser = () => {
        axiosInstance.get('/users_of_company').then((res) => {
            console.log("user_of_company", res.data.data)
            setuser(res.data.data)
        })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        aduser()
    }, [])

    useEffect(() => {
        aduser()
    }, [openDialog])

  

    useEffect(() => {
      if(inboxUsers.length !== 0){
        setSelectedUsers(inboxUsers)
      }
    }, [inboxUsers])
    
    

    return (
        <div>
  

            <Grid container direction='row' style={{marginTop: '50px'}} spacing={2} alignItems='center'>
            <Grid item xs></Grid>
            <Grid item md={6}>
                <Multiselect
                    options={user}
                    displayValue='FirstName'
                    onSelect={(e) => hanldeobj(e)}
                    selectedValues={selectedUsers}
                    style={{height: '50px'}}
                    
                />
                {inboxUserValidate && (
                    <FormHelperText error>
                        At least one User is Required
                    </FormHelperText>
                )}
                </Grid>
                <Grid item>
                    <InviteUsers openDialog={openDialog} setOpenDialog={setOpenDialog} />
                </Grid>
                <Grid item xs></Grid>
            </Grid>

        </div >
    )
}

export default AddUser