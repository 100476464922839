import React, { useState, useEffect} from 'react'

import {
    Drawer,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Button,
    TextField,
    Avatar,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Grid,
    Snackbar,
    Divider,
    Chip,
    TablePagination
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { getAllContacts } from '../../redux/slices/contacts/contacts';
import { getAllGroups, createGroup } from '../../redux/slices/contacts/contactGroup'


import Iconify from '../Iconify';

export default function CreateGroup({}){


    const dispatch = useDispatch()
    const [dialog, setDialog] = useState(false)
    const [groupName, setGroupName] = useState('')
    const [searchContacts, setSearchContacts] = useState('')
    const [contactList, setContactList] = useState([])
    const [loading, setLoading] = useState(false)
    const [checked, setChecked] = useState([]);
    const [selectedChips, setSelectedChips] = useState([])
    const [state, setState] = useState([])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [filterFn, setFilterFn] = useState([])


    useEffect(() => {
        dispatch(getAllContacts())
    }, [dispatch])
    
    const {contactGroupData, isCreateLoading, isCreateLoadingText} = useSelector(state => state.contactsGroup)
    const {contactDataList} = useSelector(state => state.contacts)

    useEffect(() => {
      if(contactDataList.status === true && contactDataList.status !== undefined){
        let contactMutable = []
        contactDataList.data.data.map(item=>{
          contactMutable.push({id: item._id, firstName: `${item.firstName} ${item.lastName}`, phoneNumber: item.phoneNumber, checked: false})
        })
        setContactList(contactMutable)
        setFilterFn(contactMutable)
      }
    }, [contactDataList])

    useEffect(() =>{
      if(!isCreateLoading){
        if(isCreateLoadingText === 'Success'){
          dispatch(getAllGroups(0, 10))
          setLoading(false)
          setDialog(false)
        }else{
          setLoading(false)
        }
      }
    },[isCreateLoading])






    function groupCreate(){
      setLoading(true)
      let groupData = {
        contacts: checked,
        name: groupName,
      }
      dispatch(createGroup(groupData))
    }

    const handleCheckContact = (event) =>{
      var updatedList = [...checked];
      let findIndex = contactList.findIndex(item=> item.id === event.target.id)
      console.log('findIndex', findIndex)
      if (contactList[findIndex].checked === false) {
        updatedList = [...checked, event.target.id];
        contactList[findIndex].checked = true
        
      } else {
        
        updatedList.splice(checked.indexOf(event.target.id), 1);
        contactList[findIndex].checked = false
      }
      setChecked(updatedList);

       // hacking
    setState({ ...state, [event.target.name]: event.target.checked });
      
      console.log('updatedList', updatedList, contactList)

    }
    


    const checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + ", " + item;
      })
    : "";


    const openDialog = () =>{
      setDialog(true)
      setGroupName('')
      setChecked([])
    }
    const closeDialog = () =>{
      setDialog(false)
      setGroupName('')
      setChecked([])
    }

    // const handleChip = (id) =>{
    //   console.log('handleChip', id)
    //   checked.splice(checked.indexOf(id), 1);
    // }

    const handleChip = (id) => () => {
    //  var updatedList = [...checked];

      let findIndex = contactList.findIndex(item=> item.id === id)
      contactList[findIndex].checked = false

      let indexChecked = checked.findIndex(item=>item = id)
      checked.splice(indexChecked, 1);

    // hacking
    setState({ ...state, [event.target.name]: event.target.checked });

      console.log('handleChip', id, findIndex, contactList, checked)

    };


    const handleChangePage = (
      event, newPage,
    ) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    

 
    const handleSearch = e => {

      setSearchContacts(e.target.value)
      let target = e.target.value;
      if(isNaN(target)){
        if (target === ""){
          return setFilterFn(contactList)
        }else{
          return setFilterFn(contactList.filter(x => x.firstName.toLowerCase().includes(target)))
        }
      }else{
        if (target === ""){
          return setFilterFn(contactList)
        }else{
          return setFilterFn(contactList.filter(x => x.phoneNumber.toLowerCase().includes(target)))
        }
      }
      
        
  }

    

    return(
        <>

        <Button variant='contained' onClick={openDialog} startIcon={<Iconify icon={'fluent-mdl2:add-group'} width={20} height={20} /> }>

            Create Group
          </Button>
        <Drawer anchor="right" open={dialog} onClose={closeDialog} className="numberSettigns">
        <AppBar position="static" color="transparent" elevation={0}>
                  
                  <Toolbar style={{padding: "0 30px",}}>
  
          
           
                      <Typography variant="subtitle1" style={{marginRight: "0px", flexGrow: "1", lineHeight: "18px"}}>
                          Create Group
                      </Typography>

                      <IconButton onClick={closeDialog}  >
                        <Iconify icon={'ep:close'} width={20} height={20} />
                      </IconButton>
                    
                      
                      
                  </Toolbar>
                
               
              </AppBar>

              <Divider />

              <div style={{padding: "20px 30px 60px 20px"}}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label= "Group Name"
                      variant='outlined'
                      fullWidth
                      value={groupName}
                      onChange={(event)=>setGroupName(event.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label= "Type Name or Phone Number to Search"
                      variant='outlined'
                      fullWidth
                      value={searchContacts}
                      onChange={handleSearch}
                      //onChange={(event)=>setSearchContacts(event.target.value)}
                      />
                  </Grid>

                  <Grid item xs={12}>
                    {contactList.filter(item=> item.checked === true).map((item)=>{
                      return(
                        <Chip color="primary" variant="outlined" label={item.firstName === 'Unknown' ? item.phoneNumber : item.firstName} onDelete={handleChip(item.id)} style={{marginRight: '5px'}} />
                      )
                    })}
                  </Grid>

                  <Grid item xs={12}>

                  <div style={{height: '57vh', overflowY: 'auto'}}>

                  <TableContainer className="shadow" style={{background: "#fff"}}>
                    <Table aria-label="simple table">
                      <TableHead className="t-head">
							            <TableRow>
                                <TableCell align={'left'}>Contact Name</TableCell>
                                <TableCell align={'left'}>Phone Number</TableCell>

                                <TableCell padding="checkbox" style={{width:'80px',position:'relative'}}  align={'right'}>
                                    Action
                                </TableCell>
							            </TableRow>
						            </TableHead>
						            <TableBody>
                          {/* {contactList?.filter((item) => {
                          if (!searchContacts) return true;

                          if (item.firstName.toLowerCase().includes(searchContacts) || item.lastName.toLowerCase().includes(searchContacts)) {
                            return true;
                          }
                        })? */}
                        {filterFn.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((value, index)=>{
                            return(
                              <TableRow>
                                <TableCell align={'left'}>{value.firstName}</TableCell>
                                <TableCell align={'left'}>{value.phoneNumber}</TableCell>
                                  <TableCell padding="checkbox" style={{width:'80px',position:'relative'}}  align={'right'}>
                                      <Checkbox 
                                          color={'primary'}
                                          value={value.firstName}
                                          name={value.phoneNumber}
                                          checked={value.checked}
                                          id={value.id}
                                          onChange={handleCheckContact}  
                                          icon={<Iconify icon={'fluent:checkbox-unchecked-20-filled'} width={24} height={24} />}
                                          checkedIcon={<Iconify icon={'fluent:checkbox-checked-20-filled'} width={24} height={24} />}/> 
                                  </TableCell>
                            </TableRow>
                            )
                          })}
                          
                        </TableBody>
                      </Table>
                      </TableContainer>
                      </div>
                  </Grid>

                  {/* {checkedItems} */}

                  

                </Grid>

                <TablePagination
                  component="div"
                  count={contactList.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />

                <Divider style={{margin: '10px 0'}} />

                <Grid container direction="row" spacing={2} >
                  <Grid item xs></Grid>
                  <Grid item>
                    <Button onClick={closeDialog}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <LoadingButton loading={loading} variant="contained" color="primary" disabled={groupName === ''} onClick={groupCreate}>
                      Create Group
                    </LoadingButton>
                  </Grid>
                </Grid>
                  

              </div>

              </Drawer>



             
        </>
    )
}