import { createSlice } from "@reduxjs/toolkit";
import { useSnackbar } from 'notistack';
// utils
import axios, { REACT_APP_BASE_URL } from '../../../utils/axios';
//
import { dispatch, missed_call, callHistory } from '../../store';
import { showSnackbar } from '../snackbar'

const initialState = {
    isLoading: false,
    isStartLoadingStatus: false,
    error: null,
}

const ticketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
       // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
          // START LOADING
        stopLoadingStatus(state) {
            state.isStartLoadingStatus = false;
        },

         // START LOADING
        startLoadingStatus(state) {
        state.isStartLoadingStatus = true;
      },

    }
})

export const selectAllTicketsData = (state) => state.ticketsSlice;

export default ticketsSlice.reducer

export function updateTicketStatus(id, data) {

    return async () => {
      dispatch(ticketsSlice.actions.startLoadingStatus());
      try {
        const response = await axios.patch(`/ticket-list//${id}`, { "status": data });
        console.log('update status redux', response)
        dispatch(ticketsSlice.actions.stopLoadingStatus());
        if (response.data.status === true) {
          dispatch(showSnackbar('Status Changed Successfully'));
        } else {
          dispatch(showSnackbar('Oops.. Trouble while updating Status'));
        }
  
      } catch (error) {
        dispatch(ticketsSlice.actions.stopLoadingStatus());
        dispatch(ticketsSlice.actions.hasError(error));
        dispatch(showSnackbar('Oops.. Trouble while updating Status'));
      }
    };
  }