import { Switch, Button, TextField, Menu, MenuItem, IconButton, Snackbar } from '@mui/material'
import React, {useEffect, useState} from 'react'
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';
import { edit_app_config, get_inbox, SET_INBOX } from 'src/redux/userReducer';
import { useDispatch } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import { baseUrl } from 'src/utils/axios';

function Integration({ props, setProps }) {
  const [url_popup, setUrlPopup] = useState([]);
  const [type, setType] = useState("")
  const [inbox_id, setInboxId] = useState("");
  const [url_string, setUrlString] = useState("")
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl1, setAnchorEl1] = useState(null)
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMesssage] = useState("")
  const [popup, setPopup] = useState(false)
  const {inbox} = useSelector((state) => state.user)
  const change_props = (value, field) => {
    var app_details = props;
    app_details[field] = value;
    setProps(app_details)
    const body = app_details;
    console.log("body", body)
    edit_app(body)
  }



  const edit_app = (body) => {
    console.log("props", props)
    const config_id = props._id;
    const token = localStorage.getItem("redux-token");
    edit_app_config(config_id, body, token).then(async(res) => {
      setErrorMesssage("App config is updated successfully");
      setShowError(true)
    }).catch((err) => {
      console.log("error", err);
      setErrorMesssage("App config update is failed");
      setShowError(true)
    })
  }

  useEffect(() => {
    if (props.urlPop_active === true) {
      setPopup(true)
    } else {
      setPopup(false)
    }
    if (props.urlPop !== undefined && props.urlPop !== null && props.urlPop !== "") {
      setType(props.urlPop.url_dir);
      setInboxId(props.urlPop.inbox_id);
      setUrlString(props.urlPop.url_string)
    }
  }, []);

  useEffect(() => {
    if (inbox.length === 0) {
      const authId = localStorage.getItem("authID");
      const token = localStorage.getItem("redux-token");
      get_inbox(authId, token).then(async(res) => {
        var response = res;
        dispatch({
          type: SET_INBOX,
          payload: response
        })
      }).catch((err) => {
        console.log("error", err);
      })
    }
  }, [])
  console.log("popup", popup)
  console.log("props", props);

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column", marginLeft: 20 }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
            <div style={{ color: "#000", fontSize: 15, fontWeight: "600"}}> URL Popup</div>
            <Switch checked={props.urlPop_active} sx={{ marginLeft: 5 }} onChange={(event) => change_props(event.target.checked, "urlPop_active")}/>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: 10 }}>
          {props.urlPop_active === true && <>
            <div style={{ backgroundColor: "#E8E8E8", width: "95%", height: 50, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderRadius: 10, }}>
            <Button style={{ height: 40, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderRadius: 10, border: '1px solid grey', marginLeft: 10}} onClick={(event) => setAnchorEl(event.currentTarget)}>
              {inbox_id !== undefined && inbox_id !== null && inbox_id !== "" ? (
                <>
                  {inbox.findIndex((value) => { return value._id === inbox_id }) !== -1 ? (
                    <div style={{ color: "grey", marginLeft: 5, fontSize: 12, }}>
                      {inbox[inbox.findIndex((value) => { return value._id === inbox_id })].InboxName}
                    </div>
                  ): (
                    <div style={{ color: "grey", marginLeft: 5, fontSize: 12, }}>
                      Select Inbox
                    </div>
                  )}
                </>
              ): (
                <div style={{ color: "grey", marginLeft: 5, fontSize: 12, }}>
                  Select Inbox
                </div>
              )}
              <ArrowDropDownIcon style={{ color: "grey", marginRight: 5, marginLeft: 5, }}/>
            </Button>
            <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl} 
            onClose={() => setAnchorEl(null)}
            open={Boolean(anchorEl)}
            sx={{ marginTop: 5, }}
            anchorOrigin={{ 
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
              {inbox.map((item, index) => (
                <MenuItem onClick={() => {
                  setInboxId(item._id)
                  setAnchorEl(null)
                }} key={index}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                    <div style={{ marginLeft: 10, marginRight: 10}}>
                      {item.InboxName}
                    </div>
                  </div>
                </MenuItem>
              ))}
            </Menu>
            <Button style={{ height: 40, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderRadius: 10, border: '1px solid grey'}} onClick={(event) => setAnchorEl1(event.currentTarget)}>
              {type !== undefined && type !== null && type !== "" ? (
                <>
                  {type === "out" ? (
                    <div style={{ color: "grey", marginLeft: 5, fontSize: 12 }}>
                      Outbound
                    </div>
                  ): (
                    <div style={{ color: "grey", marginLeft: 5, fontSize: 12 }}>
                      Inbound
                    </div>
                  )}
                </>
              ): (
                <div style={{ color: "grey", marginLeft: 5, fontSize: 12 }}>
                  Select Type
                </div>
              )}
              <ArrowDropDownIcon style={{ color: "grey", marginRight: 5, marginLeft: 5, }}/>
            </Button>
            <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl1} 
            onClose={() => setAnchorEl1(null)}
            open={Boolean(anchorEl1)}
            sx={{ marginTop: 5, }}
            anchorOrigin={{ 
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
              <MenuItem onClick={() => {
                setType("out");
                setAnchorEl1(null)
              }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                  <div style={{ marginLeft: 10, marginRight: 10}}>
                    Outbound
                  </div>
                </div>
              </MenuItem>
              <MenuItem onClick={() => {
                setType("inb");
                setAnchorEl1(null)
              }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                  <div style={{ marginLeft: 10, marginRight: 10}}>
                    Inbound
                  </div>
                </div>
              </MenuItem>
            </Menu>
            <TextField
              placeholder="Enter URL String"
              id="outlined-size-small"
              style={{ width: 170 }}
              value={url_string} 
              onChange={(e) => setUrlString(e.target.value)} 
              size="small"
            />
            <IconButton variant="contained" color="primary" onClick={() => {
              const body = { 
                "urlPop": {
                  url_dir: type,
                  inbox_id: inbox_id,
                  url_string: url_string
                },
                "urlPop_active": props.urlPop_active
              }
              edit_app(body)
            }}>
              <CheckCircleIcon />
            </IconButton>
           </div>
          </>}
        </div>
        {props.urlPop_active === true && <>
            <Button style={{ height: 40, borderRadius: 10, width: 130, display: "flex", marginTop: 10, flexDirection: "row", alignItems: "center",}}>
              <div style={{ width: 20, height: 20, borderRadius: 20, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <AddIcon style={{ width: 15, height: 15, color: "#fff"}}/>
              </div>
              <div style={{ marginLeft: 10, color: "#0046BB", fontSize: 15, fontWeight: "700", marginRight: 10}}>
                Add URL
              </div>
            </Button>
          </>}

          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 10 }}>
            <div style={{ color: "#000", fontSize: 15, fontWeight: "600"}}>Power Dialer</div>
            <Switch checked={props.pwd_allow} sx={{ marginLeft: 5 }} onChange={(event) => change_props(event.target.checked, "pwd_allow")}/>
          </div>

          <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={showError}
                autoHideDuration={6000}
                onClose={() => setShowError(false)}
                message={errorMessage}
                action={
                    <React.Fragment>
                        <Button color="secondary" size="small" onClick={() => setShowError(false)}>
                            OK
                        </Button>

                    </React.Fragment>
                }
            />
    </div>
  )
}

export default Integration