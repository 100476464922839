import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { List } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { SkeletonConversationItem } from '../../../components/skeleton';
//
import TelegramChatConversationItem from './TelegramChatConversationItem';
import { clearMessageById } from '../../../redux/slices/WhatsappSocketSlice';
import { useDispatch, useSelector } from '../../../redux/store';
import { getMoreConversations, setNewFetchConversationsToEmpty } from '../../../redux/slices/chat'


// ----------------------------------------------------------------------

TelegramChatConversationList.propTypes = {
  conversations: PropTypes.object,
  isOpenSidebar: PropTypes.bool,
  activeConversationId: PropTypes.string,
  sx: PropTypes.object,
};

export default function TelegramChatConversationList({ conversations, isOpenSidebar, activeConversationId, sx, ...other }) {

  console.log("Telegram ChatConversationList 28 : ", conversations)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let currentLengthOfConversation = 0

  const handleSelectConversation = (conversationId) => {
    // console.log("ChatConversationList.js: handleSelectConversation: conversationId", conversationId);
    let conversationKey = '';
    const conversation = conversations.byId[conversationId];
    // console.log("ChatConversationList.js: handleSelectConversation: conversation", conversation);
    if (conversation.type === 'GROUP') {
      conversationKey = conversation.id;
    } else {
      // const otherParticipant = conversation.participants.find(
      //   (participant) => participant.id !== '8864c717-587d-472a-929a-8e5f298024da-0'
      // );
      // if (otherParticipant?.username) {
      //   conversationKey = otherParticipant?.username;
      // }
      conversationKey = conversation.conversation_id
      dispatch(clearMessageById(conversationKey));
    }


    const encryptedConversationKey = window.btoa(conversationKey);
    console.log("ChatConversationList.js: handleSelectConversation: encryptedConversationKey", conversationKey, " + ", encryptedConversationKey);
    // navigate(PATH_DASHBOARD.chat.view(conversationKey));
    navigate(PATH_DASHBOARD.telegram.view(encryptedConversationKey));
  };

  const [loadCount, setLoadCount] = useState(10)

  const loadMoreItems = (event) => {
    console.log("ChatConversation Scroll : ", event.target.scrollHeight, event.target.scrollTop, event.target.clientHeight)
    // if (event.target.scrollTop > 400) {
    //   console.log("User id scrolling down fetch more data from conversation please : ", event.target)
    //  //user is at the end of the list so load more items
    //  dispatch(getMoreConversations('20'))
    // } 

    if (event.target.scrollTop + event.target.clientHeight + 1 >= event.target.scrollHeight) {
      console.log("User id scrolling down fetch more data from conversation please : ", loadCount)

      //user is at the end of the list so load more items
      dispatch(getMoreConversations(loadCount))
      setLoadCount(loadCount + 10)
    }
  }

  const loading = !conversations.allIds.length;

  return (
    <List disablePadding sx={sx} {...other}
      onScroll={loadMoreItems}
      style={{
        maxHeight: "100vh",
        overflowY: 'scroll'
      }}>
      {(loading ? [...Array(12)] : conversations.allIds).map((conversationId, index) =>
        conversationId ? (
          <TelegramChatConversationItem
            key={conversationId}
            isOpenSidebar={isOpenSidebar}
            conversation={conversations.byId[conversationId]}
            isSelected={activeConversationId === conversationId}
            onSelectConversation={() => handleSelectConversation(conversationId)}
          />
        ) : (
          <SkeletonConversationItem key={index} />
        )
      )}
    </List>
  );
}
