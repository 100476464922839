import { MenuItem, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

const CheckStatusvoice = ({ params, selectedassignedid }) => {


    let st;
    if (params.row._id === selectedassignedid) {
        st = "All"
        console.log("selectedassignedid", selectedassignedid)
    } else {
        st = params.row.viewType
        console.log("selectedassignedid", selectedassignedid)
    }
    const [status, setStatus] = useState(params.row.viewType)
    const handleStatus = (value) => {
        setStatus(value)
        console.log('handle status', status, value)
        // dispatch(updateStatus(statusId, value));
        // setStatusFlag(!statusFlag)
    }

    return (
        <div>

            <TextField
                select
                value={status}
                onChange={(event) => handleStatus(event.target.value)}
                size='small'
            >
                <MenuItem value="All">
                    <Typography>
                        All
                    </Typography>
                </MenuItem>
                <MenuItem value="Assigned">
                    <Typography>
                        Assigned only
                    </Typography>
                </MenuItem>
            </TextField>

        </div>
    )
}

export default CheckStatusvoice