import { Button, Card, Checkbox, FormControlLabel, IconButton, Link, Snackbar, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { CarouselSection } from 'src/pages/auth/Slider';
import axiosInstance from 'src/utils/axios';
import styled from 'styled-components';
import validator from "validator";
import CloseIcon from '@mui/icons-material/Close';
import Trustpilot from './../../../../assets/Trustpilot.png'
import Vikalp from './../../../../assets/vikalp.png'
import useResponsive from './../../../../hooks/useResponsive'
import homepage from './../../../../assets/homepage.png'

import { styled as styleds } from '@mui/material/styles';

const SectionStyle = styleds(Card)(({ theme }) => ({
    width: '100%',
    // height: "100%",
    maxWidth: 650,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));


const FormFieldsContainer = styled.div`

  /* display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
border: 1px solid black;
  height: ${props => props.small ? "20rem" : "30rem"}; */
  display: block;
  width: 24rem;
  margin-left: auto;
  margin-right:auto;
`;

const MainFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid black; */
  height: 35rem;
`;
const RightSectionLayout = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 10rem;
width: 50vw;
height: 90vh;
/* border: 1px solid black; */
@media (max-width: 768px) {
  width: 100%;
  height: auto;
  }
`;
const InvitedSignup = () => {
    const location = useLocation();
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    const navigate = useNavigate()
    let query = useQuery();
    // console.log('userId', query.get('userId'))
    // console.log('username', query.get('username'))
    // const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [isChecked, setChecked] = useState(false);
    const [firstName, setFirstName] = useState(query.get("fullName"));
    const [email, setEmail] = useState(query.get("email"));
    const [open4, setOpen4] = useState(false);
    const [isFirstNameValid, setFirstNameValidity] = useState(null);
    const [isLastNameValid, setLastNameValidity] = useState(null);

    const [isEmailValid, setEmailValidity] = useState(null);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(null);
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(null);
    const [isSubmitLoading, setSubmitLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [snack, setSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const handleClose4 = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen4(false);
    };

    const handleClick4 = () => {
        setOpen4(true);
    };

    const action4 = (
        <>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose4}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );
    const preRegister = () => {
        const dataOption = {
            _id: query.get("userId"),
            is_verified: true,
            blocked: false,
            username: query.get("username"),
            email: query.get("email"),
            fullName: firstName,
            phone: query.get("phoneNumber"),
            provider: query.get("phoneProvider"),
            createdAt: '2021-01-10T05:31:12.945Z',
            updatedAt: '2021-09-18T10:17:07.504Z',
            // company: query.get("isCompany"),
            // role: query.get("role"),
            user_type: query.get("accountType"),
            company_name: query.get("companyName"),
            company_id: query.get("companyId"),
            is_admin: query.get("isAdmin"),
            auth_id: query.get("authId"),
            auth_secret: query.get("authSecret"),
            password: password,
        }
        axiosInstance.post(`/user/register`, dataOption)

            .then((res) => {
                console.log("updated", res)
                handleClick4()
                navigate('/')
                return (
                    // console.log('Updated', res)
                    setSnackMessage('SignUp Success'),
                    setSnack(true)
                )

            })
            .catch(err => {
                return (
                    setSnackMessage('Oops, SignUp Failed'),
                    setSnack(true)
                )
            })
    }

    const smUp = useResponsive('up', 'sm')

    return (
        <div style={{
            display: "flex",
            backgroundImage: `url(${homepage})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "bottom",
            backgroundPositionX: "right",
        }}>

            {/* <div style={{ width: "40%", height: "95vh", position: "sticky", top: 0, display: "flex", flexDirection: "column", justifyContent: "center" }}> */}
            <SectionStyle>


                <img src={Trustpilot} style={{ width: "190px", position: "absolute", top: "-40px" }} alt="" />
                <CarouselSection />
            </SectionStyle>
            {/* </div> */}
            <div>
                <div style={{ display: "flex", position: "relative", top: "60px", width: "90%", justifyContent: "space-between" }}>
                    {/* <img src={Trustpilot} style={{ width: "150px", position: "relative", top: "-80px", left: "-20px" }} alt="" srcset="" /> */}

                    <img src={Vikalp} alt=""
                        style={{ width: "120px", position: "relative", top: "-30px" }}
                    />
                    {smUp && (
                        <Typography variant="body2" sx={{ mt: { md: -2 } }} style={{ position: "absolute", right: "-80px" }} >
                            Already have an account? {''}
                            <Link variant="subtitle2" component={RouterLink} to='/'>
                                Login
                            </Link>
                        </Typography>
                    )}
                </div>
                <RightSectionLayout>

                    <MainFormLayout>


                        {/* <Typography
                            style={{ display: "block", textAlign: "center", marginBottom: "10px" }}
                            variant="h4"
                            component="h4"
                        >
                        </Typography> */}

                        <FormFieldsContainer>
                            {/* <Typography
                                style={{
                                    fontSize: "1rem", fontWeight: "bold", textAlign: "center", marginBottom: "10px",
                                }}
                                variant="h4"
                                component="h4"
                            >
                                Invite SignUp
                            </Typography> */}

                            <TextField
                                style={{ width: "22rem", marginBottom: "10px" }}
                                id="firstname"
                                label="Full Name"
                                variant="outlined"
                                value={firstName}
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                    setFirstNameValidity(validator.isAlpha(e.target.value));
                                }}
                            // InputProps={{
                            //     endAdornment: (
                            //         <InputAdornment>

                            //             {(() => {
                            //                 if (isFirstNameValid === null) {
                            //                     return <img className="input-icon" src={userIcon} alt="User Icon" />;
                            //                 } if (isFirstNameValid) {
                            //                     return <TickMark />;
                            //                 }
                            //                 return <CrossMark />;
                            //             })()}
                            //         </InputAdornment>
                            //     ),
                            // }}
                            />


                            <div className="">
                                <TextField
                                    style={{ width: "22rem" }}
                                    id="email"
                                    label="Email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailValidity(validator.isEmail(e.target.value));
                                    }}
                                    disabled
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment>
                                //             {(() => {
                                //                 if (isEmailValid === null) {
                                //                     return <img className="input-icon" src={emailLogo} alt="Email Logo" />;
                                //                 } if (isEmailValid) {
                                //                     return <TickMark />;
                                //                 }
                                //                 return <CrossMark />;
                                //             })()}
                                //         </InputAdornment>
                                //     ),
                                // }}
                                />



                            </div>

                            <TextField
                                style={{ width: "22rem", margin: "10px 0" }}
                                // type={showPassword ? 'text' : 'password'}
                                id="password"
                                label="Password"
                                variant="outlined"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setPasswordValid(
                                        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&]{8,}$/.test(
                                            e.target.value,
                                        ) && e.target.value.length >= 8,
                                    );
                                }}
                            // InputProps={{
                            //     endAdornment: (
                            //         <InputAdornment>
                            //             {(() => {
                            //                 if (passwordValid === null) {
                            //                     return <img className="input-icon" src={lockIcon} alt="User Icon" />;
                            //                 } if (passwordValid) {
                            //                     return <TickMark />;
                            //                 }
                            //                 return <CrossMark />;
                            //             })()}

                            //             <IconButton
                            //                 aria-label="toggle password visibility"
                            //                 onClick={handleClickShowPassword}
                            //                 onMouseDown={handleMouseDownPassword}
                            //             >
                            //                 {showPassword ? <Visibility /> : <VisibilityOff />}
                            //             </IconButton>
                            //         </InputAdornment>
                            //     ),
                            // }}
                            />
                            {passwordValid === null ? '' : (
                                <p
                                    style={{
                                        fontWeight: "300",
                                        marginBottom: "10px",
                                        width: "20rem",
                                        fontSize: "10px",
                                        color: "grey",
                                    }}
                                >
                                    Password must contain atleast 8 characters including atleast one
                                    Alphabet and one Number. Example: ACBT124!
                                </p>
                            )}



                            <TextField
                                style={{ width: "22rem", marginBottom: "10px" }}
                                type="password"
                                id="confirmpassword"
                                label="Confirm Password"
                                variant="outlined"
                                value={confirmPassword}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                    setConfirmPasswordValid(e.target.value === password);
                                }}
                            // InputProps={{
                            //     endAdornment: (
                            //         <InputAdornment>
                            //             {(() => {
                            //                 if (confirmPasswordValid === null) {
                            //                     return <img className="input-icon" src={lockIcon} alt="User Icon" />;
                            //                 } if (confirmPasswordValid) {
                            //                     return <TickMark />;
                            //                 }
                            //                 return <CrossMark />;
                            //             })()}
                            //         </InputAdornment>
                            //     ),
                            // }}
                            />

                            {/* <FormControlLabel
                                style={{ alignSelf: "flex-start" }}
                                control={(
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={() => setChecked(!isChecked)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                )}
                                label="I accept Terms & Conditions"
                            /> */}
                            <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
                                By signing up, you confirm that you’ve read and accepted
                                our&nbsp;
                                <Link underline="always" color="text.primary" href="https://www.vibtree.com/terms-of-use/">
                                    Terms of Service
                                </Link>&nbsp;
                                and&nbsp;
                                <Link underline="always" color="text.primary" href="https://www.vibtree.com/privacy-policy/">
                                    Privacy Policy
                                </Link>
                                .
                            </Typography>
                            {isSubmitLoading ? (
                                <CircularProgress color="secondary" />
                            ) : (

                                <Typography
                                    style={{ display: "block", textAlign: "center", width: "22rem" }}
                                    variant="h4"
                                    component="h4"
                                >
                                    <Button
                                        // className={classes.root}
                                        style={{ marginTop: ".5rem" }}
                                        variant="contained"
                                        color="secondary"
                                        disabled={
                                            !(passwordValid && confirmPasswordValid)
                                        }
                                        onClick={() => preRegister()}
                                    >
                                        SignUp
                                    </Button>

                                </Typography>
                            )}



                        </FormFieldsContainer>
                    </MainFormLayout>
                </RightSectionLayout>
            </div>

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: "right"
                }}
                open={open4}
                autoHideDuration={3000}
                onClose={handleClose4}
                message="user create successfully "
                action={action4}

            />

        </div>
    )
}

export default InvitedSignup