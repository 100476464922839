import React,  {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import UpdateIcon from '@mui/icons-material/Update';
import { useNavigate } from 'react-router';
import { 
    Box,
    Fab,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    ListItemSecondaryAction,
    Typography,
    Grid,
    Tabs,
    IconButton,
    ButtonGroup,
    Button,
    CircularProgress,
    Tooltip,
 } from '@mui/material';
 import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
 import PersonIcon from "@mui/icons-material/Person";
 import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
 import WifiIcon from '@mui/icons-material/Wifi';
 import CallMadeIcon from '@mui/icons-material/CallMade';
 import CallReceivedIcon from '@mui/icons-material/CallReceived';
 import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
 import axiosInstance, { REACT_APP_BASE_URL } from '../../utils/axios';

 import moment from 'moment';
 import { useSelector , useDispatch } from 'react-redux';
 import { setPhoneNumber } from '../../redux/slices/cloudPhone/phoneNumber';
 import { setFromNumber } from '../../redux/slices/cloudPhone/setFromNumber';
 import {incrementMakeCallSwitch} from '../../redux/slices/cloudPhone/makeCall'; 
import axios from 'axios';
import { SET_CALL_HISTORY, SET_CALL_OFFSET, SET_MISSED_CALL, SET_MISSED_OFFSET, SET_SHOW_ALL_LIST } from 'src/redux/userReducer';
import CallDetails from '../InboxVoice/components/CallDetails';
import { createSearchParams } from 'react-router-dom';
import Iconify from '../Iconify';
import Notes from '../InboxVoice/components/Notes';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



export default function Conversations({ setWidjetMainToggle, setExpandContractClass, setExpandContractBtn, setMinimizeMaximize, scrollRef }) {


  const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  const [notes_data, setNotesData] = useState("");
  const [open_notes, setOpenNotes] = useState(false)
  const [open_drawer, setOpenDrawer] = useState(false);
  const [call_detail, setCallDetails] = useState("")
  const phoneNumber = useSelector(state => state.phoneNumber.value);
  //console.log("phoneNumber in conversation",phoneNumber);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const crossCheck = (time_obj)=>{
    let time 
    if(time_obj.StartTime === ""){
        time = time_obj.PC_intiated
    }else{
        time = time_obj.StartTime
    }
    return time
  }



  const backCalculation = (time_obj) =>{
    const time = crossCheck(time_obj)
    // const dateofvisit = moment(time).format('DD-MM-YYYY')
    var dateofvisit = moment(time, 'DD-MM-YYYY');
    const x = new Date(time)
    const y = new Date()
    var today = moment();
    const date_of_visit = x.getDate()
    const today_date = y.getDate()
    const formatted_time = moment(time).startOf('ss').fromNow()
      return formatted_time
  
}

  const dispatch = useDispatch();
  //const localStorage.getItem('redux-token') = localStorage.getItem('redux-token')
  const collectPhoneNumber = (item) => {
   const phone = item?.ContactNumber.replace("+", "")
   dispatch(incrementMakeCallSwitch())
    ////console.log("phoneNumber in collectPhoneNumber",item);
     dispatch(setPhoneNumber(phone))
     dispatch(setFromNumber(item?.CloudNumber))
  }


  const {callHistory, missed_call, show_all_list, call_offset, missed_offset, app} = useSelector((state) => state.user)



  const [showOutBoundList, setShowOutBoundList] = useState(false)
  const [showInBoundList, setShowInBoundList] = useState(false)


  const [load_more_all, setLoadMoreAll] = useState(false);
  const [load_more_missed, setLoadMoreMissed] = useState(false)
  const [load_all_call, setLoadAllCall] = useState(true)
  const [load_missed, setLoadMissed] = useState(true)
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    fetchDataForConversation()
  }, [])


  const fetchDataForConversation = () => {
    axios({
        method: "GET",
        url: `${REACT_APP_BASE_URL}/api/ivrstudios/flowChart?limit=10&offset=0&sort=-_id&populate=userID`,
        headers: {
            authorization: localStorage.getItem('redux-token')
        }
    }).then((res) => {
        const response = res.data.data.ivrFlow;
        const filtered_calls = response.filter(value => { return value.ContactNumber !== undefined });
        dispatch({
          type: SET_CALL_HISTORY,
          payload: filtered_calls
        })
        dispatch({
          type: SET_CALL_OFFSET,
          payload: 0
        })
        setLoadAllCall(false)
        ////console.log("filtered_calls", filtered_calls)
        axios({
            method: "GET",
            url: `${REACT_APP_BASE_URL}/api/ivrstudios/flowChart?limit=10&offset=0&sort=-_id&populate=userID&callStatus=no-answer`,
            headers: {
                authorization: localStorage.getItem('redux-token')
            }
        }).then((missed) => {
            const missed_response = missed.data.data.ivrFlow;
            const filtered_missed_call = missed_response.filter(item => { return item.ContactNumber !== undefined });
            dispatch({
              type: SET_MISSED_CALL,
              payload: filtered_missed_call
            })
            dispatch({
              type: SET_MISSED_OFFSET,
              payload: 0
            })
            setLoadMissed(false)
        }).catch((err) => {
            setLoadMissed(false)
            ////console.log("error_response", err.response.data)
        })
    }).catch((err) => {
        setLoadAllCall(false)
        ////console.log("error_response", err.response.data)
    })
  }

  const open_note = (item) => {
    const row_data = {
      "row": {
        "_id": item._id,
        "Notes": item.Notes,
        "ParentCallSid" : item.ParentCall.ParentCallSid
      }
    }

    setNotesData(row_data);
    setOpenNotes(true)
    setWidjetMainToggle(false)
    setMinimizeMaximize(false)
    // setExpandContractBtn(true);
    // setExpandContractClass(true);
  }

  const load_call_history = () => {
    const Offset = parseInt(call_offset) + 10;
    setLoadMoreAll(true);
    axios({
      method: "GET",
      url: `${REACT_APP_BASE_URL}/api/ivrstudios/flowChart?limit=10&offset=${Offset}&sort=-_id&populate=userID`,
      headers: {
          authorization: localStorage.getItem('redux-token')
      }
    }).then((res) => {
      const response = res.data.data.ivrFlow;
      const filtered_calls = response.filter(value => { return value.ContactNumber !== undefined });
      const previous_data = callHistory;
      Array.prototype.push.apply(previous_data, filtered_calls);
      dispatch({
        type: SET_CALL_HISTORY,
        payload: previous_data
      })
      setLoadMoreAll(false);
      dispatch({
        type: SET_CALL_OFFSET,
        payload: Offset
      })
    }).catch((err) => {
      ////console.log("error_response", err.response.data);
      setLoadMoreAll(false)
    })
  }

  const load_more_missed_call = () => {
    const Offset = parseInt(missed_offset) + 10;
    setLoadMoreMissed(true);
    axios({
      method: "GET",
      url: `${REACT_APP_BASE_URL}/api/ivrstudios/flowChart?limit=10&offset=${Offset}&sort=-_id&populate=userID&callStatus=no-answer`,
      headers: {
        authorization: localStorage.getItem('redux-token')
      }
    }).then((res) => {
      const response = res.data.data.ivrFlow;
      const filtered_calls = response.filter(value => { return value.ContactNumber !== undefined });
      const previous_data = missed_call;
      Array.prototype.push.apply(previous_data, filtered_calls);
      dispatch({
        type: SET_MISSED_CALL,
        payload: previous_data
      })
      setLoadMoreMissed(false);
      dispatch({
        type: SET_MISSED_OFFSET,
        payload: Offset
      })
    }).catch((err) => {
      ////console.log("error_response", err.response.data);
    })
  }


  const open_call_details = (item) => {
    const row_data = {
      "row" : {
        "ParentCall" : item.ParentCall,
        "CallerType" : item.CallerType,
        "CallStatus" : item.CallStatus,
        "ParentCallDuration" : item.ParentCallDuration,
        "CallerName" : item.CallerName,
        "ReceiverName" : item.ReceiverName,
        "CloudNumberName" : item.CloudNumberName,
        "QueueTime" : item.QueueTime,
        "Notes": item.Notes,
        "listOfChildCalls" : item.listOfChildCalls,
        "AccountSid" : item.AccountSid,
        "CloudNumber" : item.CloudNumber,
        "Tags" : item.Tags,
        "ivrDetails" : item.ivrDetails
      }
    }

    ////console.log("row_data", row_data)
    setCallDetails(row_data);
    setOpenDrawer(true)
    setWidjetMainToggle(false)
    setMinimizeMaximize(false)
    // setExpandContractClass(true);
  }
  return (
    <div>
      <div style={{ display: "flex", width: "100%"}}>
      <ButtonGroup className="conversation-btn-group" aria-label="small outlined button group">
        <Button style={{ width: "40%"}} className={show_all_list === true ? "tab-active" : ""} onClick={() => {
          dispatch({
            type: SET_SHOW_ALL_LIST,
            payload: true
          })
        }}>All</Button >
        <Button style={{ width: "40%"}} className={show_all_list === false ? "tab-active" : ""} onClick={() => {
          dispatch({
            type: SET_SHOW_ALL_LIST,
            payload: false
          })
        }}>Missed</Button>
      </ButtonGroup>
      {/* {loading === true ? (
        <CircularProgress color="primary"  style={{ marginTop: 20, width: 25, height: 25, position: "absolute", right: 5}}/>
      ): (
        <Button style={{ width: 50, height: 35, minWidth: 20, display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20, borderRadius: 2, position: "absolute", right: 5 }} onClick={() => refresh_calls()}>
          <UpdateIcon style={{ color: "#0046BB"}}/>
        </Button>
      )} */}
      </div>
      <div ref={scrollRef}/>

      <div style={{ minHeight: "330px", maxHeight: "330px", overflowY: "auto", display: "flex", alignItems: "center", flexDirection: "column", padding: 5, }} className="center-col">
      {show_all_list === true ?  (
              <>
                {load_all_call === true ? (
                  <CircularProgress color='primary' style={{ display: "flex", alignSelf: "center", marginTop: 50 }} />
                ): (
                  <>
                  {callHistory.length === 0 ? (
                    <Grid container direction="row" spacing={3} style={{textAlign: "center", marginTop: "0px", marginBottom: "30px"}}>
                    <Grid item xs={12}>
                        {/* <img src={require('./../../assets/no-call-history.png')} style={{maxWidth: "600px", width: "100%"}} /> */}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{marginTop: "10px", marginBottom: "10px"}}>
                            <strong>
                    No Call History
                            </strong>
                        </Typography>
                    </Grid>
                    
                   </Grid>
                  ): (
                    <>
                      {callHistory.map((item, index) => (
                        <div key={index} style={{ width: "100%", height: 60, marginTop: index === 0 ? 10 : 20, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: index+1 === callHistory.length ? 10 : 0, alignSelf: "center",  }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItens: "center", height: "100%", marginLeft: 10}}>
                            {item.contactId !== undefined && item.contactId !== null ? (
                                <div style={{ width: 48, height: 48, border: item.CallStatus === "no-answer" ? "2px solid red" : "2px solid green", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 35, alignSelf: "center" }}>
                                  {item.contactId.photo !== undefined && item.contactId.photo !== null && item.contactId.photo !== "undefined" ? (
                                    <img src={`${REACT_APP_BASE_URL}/api/contact/download?fileLocation=${item.contactId.photo}`} style={{  width: 40, height: 40, borderRadius: 30,  }} />
                                  ): (
                                    <div style={{ width: 40, height: 40, borderRadius: 30, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center", }}>
                                        <PersonIcon style={{ color: "#fff", width: 30, height: 30 }}/>
                                    </div>
                                  )}
                                </div>
                            ): (
                                <div style={{ width: 48, height: 48, border: item.CallStatus === "no-answer" ? "2px solid red" : "2px solid green", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 35, alignSelf: "center" }}>
                                    <div style={{ width: 40, height: 40, borderRadius: 30, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <PersonIcon style={{ color: "#fff", width: 30, height: 30 }}/>
                                    </div>
                                </div>
                            )}
                            <div style={{ display: "flex", flexDirection: "column", marginLeft: 15,alignItems: "flex-start", height: "100%", justifyContent: "space-evenly",  }}>
                                <div style={{ display: "flex", flexDirection: "row",}}>
                                {item.contactId !== undefined && item.contactId !== null ? (
                                    <Button style={{ minWidth: 20 }} onClick={() => {
                                      if (app[0].disable_contact === false) {
                                        const contactNumber = item.ContactNumber.replace('+', '')
                                        const paramsData= { phone: contactNumber}
                                        navigate({
                                          pathname: '/dashboard/contacts/manage/account',
                                          search: `?${createSearchParams(paramsData)}`,
                                        });
                                      }
                                    }}>
                                      <span style={{ fontSize: 13, fontWeight: "bold", whiteSpace: "nowrap", color: "#000"}}>
                                        {item.contactId.firstName} {item.contactId.lastName}
                                      </span>
                                    </Button>
                                ): (
                                    <Button style={{ minWidth: 20 }} onClick={() => {
                                      if (app[0].disable_contact === false) {
                                        const contactNumber = item.ContactNumber.replace('+', '')
                                        const paramsData= { phone: contactNumber}
                                        navigate({
                                          pathname: '/dashboard/contacts/manage/account',
                                          search: `?${createSearchParams(paramsData)}`,
                                        });
                                      }
                                    }}>
                                      <div style={{ fontSize: 13, fontWeight: "bold", color: "#000"}}>
                                        Unknown
                                      </div>
                                    </Button>
                                )}
                                <div style={{ width: 18, height: 18, backgroundColor: "#088DD8", marginLeft: 5, borderRadius: 10, display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                                {item.ParentCall.Direction === "inbound" ? (
                                  <CallReceivedIcon style={{ color: "#fff", width: 10, height: 10, alignSelf: "center" }}/>
                                ): (
                                  <CallMadeIcon style={{ color: "#fff", width: 10, height: 10, alignSelf: "center" }}/>
                                )}
                                </div>
                                </div>
                                {app[0].hide_contact === false ? (
                                  <div style={{ fontSize: 12, }}>
                                    {item.ContactNumber}
                                  </div>
                                ): (
                                  <div style={{ fontSize: 12, }}>
                                     XXXXXXXX{item.ContactNumber.slice(-3)}
                                  </div>
                                )}
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", height: "100%", alignItems: "center", marginRight: 5,}}>
                        <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "flex-end"}}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                            
                            <IconButton size="small" color="primary"  onClick={() => collectPhoneNumber(item)}>
                                <PhoneOutlinedIcon style={{ width: 18, height: 18}}/>
                            </IconButton>
                      
                            {item.Notes.length > 0 ? (
                              
                                <IconButton size='small' color='primary' onClick={() => open_note(item)}>
                                  <Iconify icon='uil:clipboard-notes'  />
                                </IconButton>
                              
                              ) : (
                                
                                  <IconButton size='small' onClick={() => open_note(item)}>
                                    <Iconify icon='uil:clipboard-notes'  />
                                  </IconButton>
                              
                              )}
                      
                            <Button style={{ minWidth: 20, width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center",  borderRadius: 20 }} onClick={() => {
                          open_call_details(item)
                        }}>
                          <InfoOutlinedIcon style={{ color: "#0046BB", width: 20, height: 20 }} />
                        </Button>
                        
                            </div>
                            {moment().diff(moment(item.createdAt), "days") < 1 ? (
                            <>
                       {moment().diff(moment(item.createdAt), "hours") < 1 ? (
                         <>
                           {moment().diff(moment(item.createdAt), "minutes") < 1 ? (
                             <Typography variant="caption" className="grey-text">
                               Few seconds ago
                             </Typography>
                           ): (
                             <Typography variant="caption" className="grey-text">
                               {moment().diff(moment(item.createdAt), "minutes")} minutes ago
                             </Typography>
                           )}
                         </>
                       ): (
                         <Typography variant="caption" className="grey-text">
                           {moment().diff(moment(item.createdAt), "hours")} hours ago
                         </Typography>
                       )}
                     </>
                   ): (
                    <Typography variant="caption" className="grey-text">
                       {moment().diff(moment(item.createdAt), "days")} days ago
                     </Typography>
                   )}
                        </div>
                        </div>
                    </div>
                      ))}

                      {callHistory.length > 0 && <>
                         {load_more_all === false ? (
                           <Button style={{ marginBottom: 10, display: "flex", alignSelf: "center" }} onClick={() => load_call_history()}>
                           <div style={{ fontWeight: "bold"}}>
                              Load More
                           </div>
                           </Button>
                         ): (
                          <CircularProgress color="primary" style={{ width: 25, height: 25 }}/>
                         )}
                      </>}
                    </>
                  )}
                </>
                )}
              </>
            ): (
               <>
                 {load_missed === true ? (
                  <CircularProgress color='primary' style={{display: "flex", alignSelf: "center", marginTop: 50 }}/>
                 ): (
                  <>
                 {missed_call.length === 0 ? (
                    <Grid container direction="row" spacing={3} style={{textAlign: "center", marginTop: "0px", marginBottom: "30px"}}>
                    <Grid item xs={12}>
                        {/* <img src={require('./../../assets/no-call-history.png')} style={{maxWidth: "600px", width: "100%"}} /> */}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{marginTop: "10px", marginBottom: "10px"}}>
                            <strong>
                    No Call History
                            </strong>
                        </Typography>
                    </Grid>
                    
                   </Grid>
                  ): (
                    <>
                      {missed_call.map((item, index) => (
                        <div key={index} style={{ width: "100%", height: 60, marginTop: index === 0 ? 10 : 20, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: index+1 === missed_call.length ? 10 : 0}}>
                        <div style={{ display: "flex", flexDirection: "row", alignItens: "center", height: "100%", marginLeft: 10}}>
                            {item.contactId !== undefined && item.contactId !== null ? (
                                <div style={{ width: 48, height: 48, border: item.CallStatus === "no-answer" ? "2px solid red" : "2px solid green", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 35, alignSelf: "center" }}>
                                  {item.contactId.photo !== undefined && item.contactId.photo !== null && item.contactId.photo !== "undefined" ? (
                                    <img src={`${REACT_APP_BASE_URL}/api/contact/download?fileLocation=${item.contactId.photo}`} style={{  width: 40, height: 40, borderRadius: 30, }} />
                                  ): (
                                    <div style={{ width: 40, height: 40, borderRadius: 30, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center",}}>
                                        <PersonIcon style={{ color: "#fff", width: 30, height: 30 }}/>
                                    </div>
                                  )}
                                </div>
                            ): (
                                <div style={{ width: 48, height: 48, border: item.CallStatus === "no-answer" ? "2px solid red" : "2px solid green", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 35, alignSelf: "center" }}>
                                    <div style={{ width: 40, height: 40, borderRadius: 30, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <PersonIcon style={{ color: "#fff", width: 30, height: 30 }}/>
                                    </div>
                                </div>
                            )}
                            <div style={{ display: "flex", flexDirection: "column", marginLeft: 15,alignItems: "flex-start", height: "100%", justifyContent: "space-evenly",  }}>
                              <div style={{ display: "flex", flexDirection: "row",}}>
                                {item.contactId !== undefined && item.contactId !== null ? (
                                    <Button style={{ minWidth: 20, }} onClick={() => {
                                      if (app[0].disable_contact === false) {
                                        const contactNumber = item.ContactNumber.replace('+', '')
                                        const paramsData= { phone: contactNumber}
                                        navigate({
                                          pathname: '/dashboard/contacts/manage/account',
                                          search: `?${createSearchParams(paramsData)}`,
                                        });
                                      }
                                    }}>
                                      <span style={{ fontSize: 13, fontWeight: "bold", whiteSpace: "nowrap", color: "#000"}}>
                                        {item.contactId.firstName} {item.contactId.lastName}
                                      </span>
                                    </Button>
                                ): (
                                    <Button style={{ minWidth: 20, }} onClick={() => {
                                      if (app[0].disable_contact === false) {
                                        const contactNumber = item.ContactNumber.replace('+', '')
                                        const paramsData= { phone: contactNumber}
                                        navigate({
                                          pathname: '/dashboard/contacts/manage/account',
                                          search: `?${createSearchParams(paramsData)}`,
                                        });
                                      }
                                    }}>
                                      <div style={{ fontSize: 13, fontWeight: "bold", color: "#000"}}>
                                        Unknown
                                      </div>
                                    </Button>
                                )}
                                <div style={{ width: 18, height: 18, backgroundColor: "#088DD8", marginLeft: 5, borderRadius: 10, display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                                {item.ParentCall.Direction === "inbound" ? (
                                  <CallReceivedIcon style={{ color: "#fff", width: 10, height: 10, alignSelf: "center" }}/>
                                ): (
                                  <CallMadeIcon style={{ color: "#fff", width: 10, height: 10, alignSelf: "center" }}/>
                                )}
                                </div>
                                </div>
                                {app[0].hide_contact === false ? (
                                  <div style={{ fontSize: 12, }}>
                                    {item.ContactNumber}
                                  </div>
                                ): (
                                  <div style={{ fontSize: 12, }}>
                                     XXXXXXXX{item.ContactNumber.slice(-3)}
                                  </div>
                                )}
                            </div>
                            
                        </div>
                        <div style={{ display: "flex", height: "100%", flexDirection: "row", alignItems: "center", marginRight: 5}}>
                        <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "flex-end"}}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                          
                            <IconButton size="small" color="primary" onClick={() => collectPhoneNumber(item)}>
                                <PhoneOutlinedIcon style={{ width: 18, height: 18}}/>
                            </IconButton>
                          
                            {item.Notes.length > 0 ? (
                              <IconButton size='small' color='primary' onClick={() => open_note(item)}>
                                <Iconify icon='uil:clipboard-notes'  />
                              </IconButton>
                              ) : (
                                <IconButton size='small' onClick={() => open_note(item)}>
                                  <Iconify icon='uil:clipboard-notes'  />
                                </IconButton>
                              )}
                            
                            <Button style={{ minWidth: 20, width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center",  borderRadius: 20 }} onClick={() => {
                              open_call_details(item)
                            }}>
                              <InfoOutlinedIcon style={{ color: "#0046BB", width: 20, height: 20 }} />
                            </Button>
                            
                        </div>
                            {moment().diff(moment(item.createdAt), "days") < 1 ? (
                            <>
                       {moment().diff(moment(item.createdAt), "hours") < 1 ? (
                         <>
                           {moment().diff(moment(item.createdAt), "minutes") < 1 ? (
                             <Typography variant="caption" className="grey-text">
                               Few seconds ago
                             </Typography>
                           ): (
                             <Typography variant="caption" className="grey-text" >
                               {moment().diff(moment(item.createdAt), "minutes")} minutes ago
                             </Typography>
                           )}
                         </>
                       ): (
                         <Typography variant="caption" className="grey-text" >
                           {moment().diff(moment(item.createdAt), "hours")} hours ago
                         </Typography>
                       )}
                     </>
                   ): (
                    <Typography variant="caption" className="grey-text">
                       {moment().diff(moment(item.createdAt), "days")} days ago
                     </Typography>
                   )}
                        </div>
                        </div>
                    </div>
                      ))}

                      {missed_call.length > 0 && <>
                         {load_more_missed === false ? (
                          <Button style={{ marginBottom: 10, display: "flex", alignSelf: "center" }} onClick={() => load_more_missed_call()}>
                            <div style={{ fontWeight: "bold"}}>
                               Load More
                            </div>
                          </Button>
                         ): (
                          <CircularProgress color={'primary'} style={{ width: 25, height: 25, }}/>
                         )}
                      </>}
                    </>
                  )}
               </>
                 )}
               </>
            )}
      </div>
      {open_drawer === true && call_detail !== "" && <CallDetails props={call_detail} open_drawer={true} setCloseDetails={setOpenDrawer}/>}
      {open_notes === true && notes_data !== "" && <Notes props={notes_data} open_notes={open_notes} setOpenNotes={setOpenNotes}/>}
    </div>
  );
}