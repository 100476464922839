import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Avatar, Card } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f2f3f5',
  ...theme.typography.body2,
  padding: theme.spacing(1),

  color: theme.palette.text.secondary,
}));
const General = () => {
  const [image, setImage] = React.useState({ preview: "", raw: "" });

  const handleImageChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
      console.log('image', e.target.files);
    }
  };

  return (
    <div >
      <Box sx={{ flexGrow: 1 }} >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Item>
              <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
                <input
                  onChange={handleImageChange}
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="icon-button-file"
                  type="file" />
                {/* <label htmlFor="icon-button-file" style={{cursor: 'pointer', color: "#0045BA"}}>
		              <Avatar src="/broken-image.jpg" style={{width: '100px', height: "100px", fontSize: '30px', marginLeft: "auto", marginRight: "auto", marginBottom: "10px", background: '#0045BA'}} />
			             Add a Photo
		               </label> */}


                {image.preview ? (
                  <>
                    <Avatar variant="rounded" src={image.preview} style={{ width: '135px', height: "135px", fontSize: '30px', marginLeft: "auto", marginRight: "auto", background: '#0045BA', borderRadius: "10px", color: "white" }} />

                  </>
                ) :
                  <label htmlFor="icon-button-file" style={{ cursor: 'pointer', color: "#0045BA" }}>
                    <Avatar src="/broken-image.jpg" style={{ width: '100px', height: "100px", fontSize: '30px', marginLeft: "auto", marginRight: "auto", background: '#0045BA', color: "white" }} />
                    Add a Photo
                  </label>
                }

              </Card>
            </Item>
          </Grid>
          <Grid item xs={8}>
            <Item>xs=4</Item>
          </Grid>

        </Grid>
      </Box>
    </div>
  )
}

export default General