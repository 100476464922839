import React from 'react'
import { useParams } from 'react-router-dom';
// @mui
// import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Tooltip,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  Container
} from '@mui/material';
// import { Container } from '@mui/material';
// routes
//_mock_
import { _invoices } from 'src/_mock';
//hooks 
import useSettings from 'src/hooks/useSettings';
//components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
//sections
import Invoice from './../../../@dashboard/invoice/details'
import PropTypes from 'prop-types';
import { fDate } from 'src/utils/formatTime';
import { fCurrency } from 'src/utils/formatNumber';
//components
import Label from 'src/components/Label';
import Image from 'src/components/Image';
import Scrollbar from 'src/components/Scrollbar';
//
import Iconify from 'src/components/Iconify';
//import InvoiceToolbar from '../../invoice/details/InvoiceToolbar';
const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

// InvoiceDetails.propTypes = {
//   invoice: PropTypes.object.isRequired,
// };

export default function InvoiceDetailsvik() {
  const { themeStretch } = useSettings();

  const theme = useTheme();


  return (
    <Page title="Invoice: View">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          // heading="Invoice Details"
          links={[
            // { name: 'Dashboard', href: PATH_DASHBOARD.root },
            // {
            //   name: 'Invoices',
            //   href: PATH_DASHBOARD.invoice.root,
            // },
            // { name: `INV-${invoice?.invoiceNumber}` || '' },
            { name: '' },
          ]}
        />
        <Tooltip title="View">
          <IconButton
          // onClick={onOpen}
          >
            <Iconify icon={'eva:eye-fill'} />
          </IconButton>
        </Tooltip>

        {/* <PDFDownloadLink
          // document={<InvoicePDF invoice={invoice} />}
          // fileName={invoice.invoiceNumber}
          style={{ textDecoration: 'none' }}
        >
          {({ loading }) => (
            <Tooltip title="Download">
              <IconButton>
               
              </IconButton>
            </Tooltip>
          )}
        </PDFDownloadLink> */}

        {/* <InvoiceToolbar /> */}

        <Card sx={{ pt: 5, px: 5 }}>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Image disabledEffect visibleByDefault alt="logo" src="/logo/logo_full.svg" sx={{ maxWidth: 120 }} />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Box sx={{ textAlign: { sm: 'right' } }}>
                <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={
                    (status === 'paid' && 'success') ||
                    (status === 'unpaid' && 'warning') ||
                    (status === 'overdue' && 'error') ||
                    'default'
                  }
                  sx={{ textTransform: 'uppercase', mb: 1 }}
                >
                  status
                </Label>

                <Typography variant="h6">{`INV-`}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                Invoice from
              </Typography>
              <Typography variant="body2">vikalp</Typography>
              <Typography variant="body2">addres</Typography>
              <Typography variant="body2">Phone: phone</Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                Invoice to
              </Typography>
              <Typography variant="body2">wde</Typography>
              <Typography variant="body2">er</Typography>
              <Typography variant="body2">Phone: 8445389</Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                date create
              </Typography>
              <Typography variant="body2">hu</Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                Due date
              </Typography>
              <Typography variant="body2">e</Typography>
            </Grid>
          </Grid>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 960 }}>
              <Table>
                <TableHead
                  sx={{
                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    '& th': { backgroundColor: 'transparent' },
                  }}
                >
                  <TableRow>
                    <TableCell width={40}>#</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Qty</TableCell>
                    <TableCell align="right">Unit price</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {/* {items.map((row, index) => ( */}
                  <TableRow
                    // key={index}
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell>
                      {/* {index + 1} */}
                      1
                    </TableCell>
                    <TableCell align="left">
                      <Box sx={{ maxWidth: 560 }}>
                        <Typography variant="subtitle2">
                          {/* {row.title} */}
                          title
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                          {/* {row.description} */}
                          description
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      {/* {row.quantity} */}
                      quantity
                    </TableCell>
                    <TableCell align="right">
                      {/* {fCurrency(row.price)} */}
                      price
                    </TableCell>
                    <TableCell align="right">
                      {/* {fCurrency(row.price * row.quantity)} */}
                      price quantity
                    </TableCell>
                  </TableRow>
                  {/* ))} */}

                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Box sx={{ mt: 2 }} />
                      <Typography>Subtotal</Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Box sx={{ mt: 2 }} />
                      <Typography>
                        {/* {fCurrency(subTotalPrice)} */}
                        Total Price
                      </Typography>
                    </TableCell>
                  </RowResultStyle>

                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography>Discount</Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Typography sx={{ color: 'error.main' }}>
                        {/* {discount && fCurrency(-discount)} */}
                        discount
                      </Typography>
                    </TableCell>
                  </RowResultStyle>

                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography>Taxes</Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Typography>
                        {/* {taxes && fCurrency(taxes)} */}
                        "taxes"
                      </Typography>
                    </TableCell>
                  </RowResultStyle>

                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography variant="h6">Total</Typography>
                    </TableCell>
                    <TableCell align="right" width={140}>
                      <Typography variant="h6">
                        {/* {fCurrency(totalPrice)} */}
                        "hy"
                      </Typography>
                    </TableCell>
                  </RowResultStyle>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Divider sx={{ mt: 5 }} />

          <Grid container>
            <Grid item xs={12} md={9} sx={{ py: 3 }}>
              <Typography variant="subtitle2">NOTES</Typography>
              <Typography variant="body2">
                We appreciate your business. Should you need us to add VAT or extra notes let us know!
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
              <Typography variant="subtitle2">Have a Question?</Typography>
              <Typography variant="body2">support@minimals.cc</Typography>
            </Grid>
          </Grid>
        </Card>
        {/* <Invoice invoice={invoice} /> */}
      </Container>
    </Page>
  )
}

