import { useEffect } from 'react';

// @mui
import { Card, Container } from '@mui/material';

import io from "socket.io-client";
// redux
import { useDispatch } from '../../../redux/store';
import { getConversations } from '../../../redux/slices/whatsapp/whatsapp';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { ChatSidebar, ChatWindow } from './chat';


import './style.css'

const whatsappTokenAccess =  localStorage.getItem('whatsappTokenAccess')

// ----------------------------------------------------------------------

export default function Chat() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();

  useEffect(() => {
     dispatch(getConversations(whatsappTokenAccess, 1));
  }, [dispatch, whatsappTokenAccess]);
  




  return (
    <Page title="Chat">
    
        <Card sx={{ height: 'calc(100vh - 50px)', display: 'flex' }}>
          <ChatSidebar />
          <ChatWindow />
        </Card>
      
    </Page>
  );
}
