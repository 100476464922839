import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
//
import Scrollbar from '../../../components/Scrollbar';
import LightboxModal from '../../../components/LightboxModal';
import ChatMessageItemMessenger from './ChatMessageItemMessenger';
import { useSelector } from 'react-redux';
import io from "socket.io-client";
import { socket } from '../../../utils/socketInst';

// const socket = io.connect("http://localhost:3000");
// const socket = io.connect("https://mibsback.vibtree.com/api");

// ----------------------------------------------------------------------

ChatMessageListMessenger.propTypes = {
  conversation: PropTypes.object.isRequired,
};

export default function ChatMessageListMessenger({ conversation }) {

  const scrollRef = useRef(null);

  const [openLightbox, setOpenLightbox] = useState(false);

  const [selectedImage, setSelectedImage] = useState(0);

  const messages_details = useSelector((state) => state.WhatsappSocketMessages);

  const [messageFromSocket, setMessageFromSocket] = useState({})



  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    socket.on("test-message", (data) => {
      console.log("test message in list : ", typeof data, data)
      if (data) {
        if (data.webhook) {
          if (data.webhook.message) {

            let singleMessage = {
              "id": `${data.webhook.message.mid}`,
              "body": `${data.webhook.message.text}`,
              "messageBody": `${data.webhook.message.text}`,
              "contentType": "text",
              "attachments": [
                "https://minimal-assets-api-dev.vercel.app/assets/images/feeds/feed_2.jpg"
              ],
              "createdAt": new Date(),
              "senderId": data.webhook.sender.id
            }
            console.log("Conversation found pushing message only : ", singleMessage)


            setMessageFromSocket(singleMessage)

          }
        }
      }

    })
    scrollMessagesToBottom();
  }, [conversation.messages, messageFromSocket]);

  const imagesLightbox = conversation.messages
    .filter((messages) => messages.contentType === 'image')
    .map((messages) => messages.body);

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };


  console.log('ChatMessageList.js: conversation messenger', conversation);
  let messagePresentInSocket = []
  console.log("Messenger messagePresentInSocket : ", messageFromSocket)
  const displayMessageIfDataInSocket = () => {
    // console.log('ChatMessageList.js: conversation messenger', conversation);
    console.log('ChatMessageList.js: messages messenger', messageFromSocket);

    const messagePresentInDB = conversation.messages


    messagePresentInSocket = [...messagePresentInSocket, messageFromSocket]


    console.log("Messenger alll message : ", messagePresentInDB, messageFromSocket, messagePresentInSocket)

    if (messagePresentInSocket.length > 0) {
      if (messageFromSocket.messageBody !== undefined) {
        console.log("push message to chat");
        return messagePresentInSocket?.map((message) => (
          <ChatMessageItemMessenger
            key={messages_details.id}
            message={message}
            conversation={conversation}
            onOpenLightbox={handleOpenLightbox}
          />
        ));
      }
    }

  }

  return (
    <>
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, height: 1 }}>
        {conversation.messages.map((message) => (
          <ChatMessageItemMessenger
            key={message.id}
            message={message}
            conversation={conversation}
            onOpenLightbox={handleOpenLightbox}
          />
        ))}
        {
          displayMessageIfDataInSocket()
        }
      </Scrollbar>

      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </>
  );
}
