import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { List } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { SkeletonConversationItem } from '../../../components/skeleton';
//
import ChatConversationItemMessenger from './ChatConversationItemMessenger';
import { clearMessageById } from '../../../redux/slices/WhatsappSocketSlice';
import { useDispatch, useSelector } from '../../../redux/store';

// ----------------------------------------------------------------------

ChatConversationListInstagram.propTypes = {
    conversations: PropTypes.object,
    isOpenSidebar: PropTypes.bool,
    activeConversationId: PropTypes.string,
    sx: PropTypes.object,
};

export default function ChatConversationListInstagram({ conversations, isOpenSidebar, activeConversationId, sx, ...other }) {
    // console.log("ChatConversationList.js: conversations", conversations);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSelectConversation = (conversationId) => {
        // console.log("ChatConversationList.js: handleSelectConversation: conversationId", conversationId);
        let conversationKey = '';
        const conversation = conversations.byId[conversationId];
        // console.log("ChatConversationList.js: handleSelectConversation: conversation", conversation);
        if (conversation.type === 'GROUP') {
            conversationKey = conversation.id;
        } else {
            // const otherParticipant = conversation.participants.find(
            //   (participant) => participant.id !== '8864c717-587d-472a-929a-8e5f298024da-0'
            // );
            // if (otherParticipant?.username) {
            //   conversationKey = otherParticipant?.username;
            // }
            conversationKey = conversation.conversation_id
            dispatch(clearMessageById(conversationKey));
        }


        const encryptedConversationKey = window.btoa(conversationKey);
        console.log("ChatConversationList.js: handleSelectConversation: encryptedConversationKey", conversationKey, " + ", encryptedConversationKey);
        // navigate(PATH_DASHBOARD.chat.view(conversationKey));
        navigate(`/dashboard/inbox/insta/${conversationKey}`);
        // navigate(PATH_DASHBOARD.chat.view(encryptedConversationKey));

    };

    const loading = !conversations.allIds.length;

    return (
        <List disablePadding sx={sx} {...other}>
            {(loading ? [...Array(12)] : conversations.allIds).map((conversationId, index) =>
                conversationId ? (
                    <ChatConversationItemMessenger
                        key={conversationId}
                        isOpenSidebar={isOpenSidebar}
                        conversation={conversations.byId[conversationId]}
                        isSelected={activeConversationId === conversationId}
                        onSelectConversation={() => handleSelectConversation(conversationId)}
                    />
                ) : (
                    <SkeletonConversationItem key={index} />
                )
            )}
        </List>
    );
}
