// @mui
import { useTheme } from '@mui/material/styles';
import { paramCase } from 'change-case';
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Container, Grid, Button, Stack, TableHead, TableBody, TableRow,
    Pagination,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem, FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,

} from '@mui/material';

import { DataGridPro } from '@mui/x-data-grid-pro';
// flag asset
import { findFlagUrlByIso3Code } from "country-flags-svg";
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getNumbers, selectAllNumbers, buySingleNumber } from '../../redux/slices/numbers/buy';
import { getAuth } from '../../redux/slices/getAuth';
// hooks
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Scrollbar from '../../components/Scrollbar';
import Label from '../../components/Label';
import AllowedCountry from '../../components/inputs/allowedCountry'

import Buynumber from './../../assets/Buynumber.png'

export default function NumberBuy() {

    const { themeStretch } = useSettings();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [numberData, setNumberData] = useState([])
    const [type, setType] = React.useState('Local');
    const [states, setstates] = React.useState('WestBengal');
    const [selectedRowsBulk, setSelectedRowsBulk] = useState({})
    const [selectedRowsLenght, setselectedRowsLenght] = useState(0)
    // loading
    const [loading, setLoading] = useState(true)
    // country
    const [defaultCountry, setDefaultCountry] = useState('');
    // pagination
    const [count, setCount] = useState(0);
    const pageCount = Math.ceil(count / 10);
    const [page, setPage] = useState(1);
    const [offsetCount, setOffsetCount] = useState(0);

    const [state, setState] = React.useState({
        sms: false,
        voice: false,
    });
    const { sms, voice } = state;
    const handleChangeCheck = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const pageOffSet = `${newPage - 1}0`
        if (newPage === 1) {
            setOffsetCount(0)
        }
        else {
            setOffsetCount(parseInt(pageOffSet, 10))
        }
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handlestateChange = (event) => {
        setstates(event.target.value);
    };

    // get numbers
    useEffect(() => {
        setLoading(true)
        console.log('defaultCountry', defaultCountry)
        dispatch(getNumbers(defaultCountry, type, states, offsetCount));
    }, [dispatch, page, defaultCountry]);





    const numbers = useSelector(state => state.buyNumber)
    // console.log("numbers", numbers.numberData.data.data)

    useEffect(() => {
        if (numbers.numberData.code === 200 && numbers.numberData.code !== undefined) {
            setNumberData(numbers.numberData.data.data)
            setCount(numbers.numberData.data.totalCount)
            setLoading(false)
        }
        console.log('numbers in', numbers)
    }, [numbers])

    console.log('numbers', numbers)



    const returnFlag = (flag) => {
        const value = flag.formattedValue;
        console.log('flag', flag, flag.formattedValue)
        const flagUrl = findFlagUrlByIso3Code(flag.formattedValue);
        return (
            <div className='hovershowhide-parent'>
                <div className='hovershow-child'>
                    {value}
                </div>
                <div className='hoverhide-child'>
                    <img src={flagUrl}
                        alt=''
                        style={{
                            maxHeight: '30px',
                            maxWidth: '30px'
                        }}
                    />
                </div>
            </div>

        )
    }

    const featuresimage = (params) => {
        const value = params.formattedValue;

        return (
            <div>
                <div className='hovershowhide-parent'>
                    <div className='hovershow-child'>
                        {value == "Voice" ? <>Voice</> : value}
                    </div>
                    <div className='hoverhide-child'>


                        <img src={`/assets/icons/navbar/ic_voice.svg`}
                            alt=''
                            style={{
                                maxHeight: '30px',
                                maxWidth: '30px'
                            }}
                        />
                    </div>
                </div>

            </div>
        )
    }

    useEffect(() => {
        console.log('selectedRowsBulk', selectedRowsBulk.size)
        setselectedRowsLenght(selectedRowsBulk.size)
    }, [selectedRowsBulk])



    const LoadingSkeleton = () => (
        <>
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </>
    );

    const columns = [

        {
            field: 'country_iso',
            headerName: 'Country',
            width: 110,
            editable: false,
            renderCell: (params) => {
                return <>

                    {returnFlag(params)}
                </>;
            }
        },
        {
            field: 'phone_number',
            headerName: 'Number',
            editable: false,
            flex: 1
        },
        {
            field: 'type',
            headerName: 'Type',
            editable: false,
            flex: 1
        },
        {
            field: 'capability',
            headerName: 'Feature',
            editable: false,
            renderCell: (params) => {
                return <>
                    {featuresimage(params)}
                </>
            },
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={(params.row.status === 'active' && 'success') || 'error'}
                        sx={{ textTransform: 'capitalize' }}
                    >
                        {params.row.status}
                    </Label>
                )
            }
        },
        {
            field: "mrc",
            headerName: "Rental Cost",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return <div>
                    {params.row.mrc.$numberDecimal}
                </div>
            }

        }
        ,
        {
            field: "rps",
            headerName: "Call Charges",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return <div>
                    {params.row.rps.$numberDecimal}
                </div>
            }

        }
        ,
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            renderCell: (params) => {
                return <Button variant="contained" size="small" onClick={() => buyNumberClick(params)}>Buy</Button>;
            }
        }


    ];



    const renderedNumbers = (

        <>

            <DataGridPro
                // {...numberData}
                // loading={numberData.rows.length === 0}
                rows={numberData}
                columns={columns}
                rowHeight={48}
                checkboxSelection
                disableSelectionOnClick
                getRowId={(r) => r.phone_number}
                style={{ height: '550px' }}
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    console.log('selected ids', selectedIDs)
                    setSelectedRowsBulk(selectedIDs)
                }}
                components={{
                    LoadingOverlay: LoadingSkeleton
                }}
                loading={loading}   // you need to set your boolean loading
                hideFooter

            />

            {pageCount > 1 && (
                <Grid container spacing={3} style={{ marginBottom: "10px" }} justifyContent='center'>

                    <Grid item>
                        <Pagination count={pageCount} page={page} showFirstButton showLastButton onChange={handleChangePage} color="primary" shape="rounded" />
                    </Grid>

                </Grid>
            )}


        </>
    )
    const countryobj = [
        {
            name: "ALL",
            value: "ALL"
        },
        {
            name: "Karnataka",
            value: "Banglore"
        },
        {
            name: "Mumbai",
            value: "Maharastra"
        },
        {
            name: "Rajasthan",
            value: "Rajasthan"
        },
    ]

    const filterToolBar = (
        <>
            <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                <Grid item xs style={{ padddingTop: "0px" }}>
                    <AllowedCountry selectedCountry={setDefaultCountry} />
                </Grid>
                <Grid item style={{ padddingTop: "0px" }}>
                    <FormControl variant="outlined" size="small" fullWidth style={{ minWidth: "100px" }}>
                        <InputLabel id="selectCountry">State</InputLabel>
                        <Select
                            labelId="selectCountry"
                            id="demo-simple-select-outlined"
                            label="State"
                            size="small"
                            defaultValue={states}
                            value={states}
                            onChange={handlestateChange}
                        >
                            {defaultCountry != "IND" ? (
                                <MenuItem value='All'>
                                    All
                                </MenuItem>
                            ) : ((
                                countryobj.map((item) => {
                                    return <MenuItem value={item.value} key={item.value}>
                                        {item.name}
                                    </MenuItem>
                                })
                            ))}

                            {/* <MenuItem value='WestBengal'>West Bengal</MenuItem>
                            <MenuItem value='Banglore'>Banglore</MenuItem>
                            <MenuItem value='Mumbai'>Mumbai</MenuItem>
                            <MenuItem value='AndhraPradesh'>Andhra Pradesh</MenuItem>
                            <MenuItem value='ArunachalPradesh'>Arunachal Pradesh</MenuItem>
                            <MenuItem value='Assam'>Assam</MenuItem>
                            <MenuItem value='Bihar'>Bihar</MenuItem>
                            <MenuItem value='Chhattisgarh'>Chhattisgarh</MenuItem>
                            <MenuItem value='Goa'>Goa</MenuItem>
                            <MenuItem value='Gujarat'>Gujarat</MenuItem>
                            <MenuItem value='Haryana'>Haryana</MenuItem>
                            <MenuItem value='HimachalPradesh'>Himachal Pradesh</MenuItem>
                            <MenuItem value='Jharkhand'>Jharkhand</MenuItem>

                            <MenuItem value='Kerala'>Kerala</MenuItem>

                            <MenuItem value='Maharastra'>Maharastra</MenuItem>
                            <MenuItem value='Manipur'>Manipur</MenuItem>
                            <MenuItem value='Meghalaya'>Meghalaya</MenuItem>
                            <MenuItem value='Mizoram'>Mizoram</MenuItem>
                            <MenuItem value='Nagaland'>Nagaland</MenuItem>
                            <MenuItem value='Odisha'>Odisha</MenuItem>
                            <MenuItem value='Punjab'>Punjab</MenuItem>
                            <MenuItem value='Rajasthan'>Rajasthan</MenuItem>
                            <MenuItem value='Sikkim'>Sikkim</MenuItem>
                            <MenuItem value='TamilNadu'>Tamil Nadu</MenuItem>
                            <MenuItem value='Telangana'>Telangana</MenuItem>
                            <MenuItem value='Tripura'>Tripura</MenuItem>
                            <MenuItem value='Uttarakhand'>Uttarakhand</MenuItem>
                            <MenuItem value='UttarPradesh'>Uttar Pradesh</MenuItem> */}


                        </Select>
                    </FormControl>
                </Grid>
                <Grid item style={{ padddingTop: "0px" }}>
                    <FormControl variant="outlined" size="small" fullWidth style={{ minWidth: "100px" }}>
                        <InputLabel id="selectCountry">Type</InputLabel>
                        <Select
                            labelId="selectCountry"
                            id="demo-simple-select-outlined"
                            label="Type"
                            size="small"
                            defaultValue={type}
                            value={type}
                            onChange={handleTypeChange}
                        >

                            <MenuItem value='Local'>Local</MenuItem>
                            <MenuItem value='Mobile'>Mobile</MenuItem>
                            <MenuItem value='Tollfree'>Toll Free</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs style={{ padddingTop: "0px" }}>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox checked={voice} onChange={handleChangeCheck} name="voice" />}
                            label="Voice"
                        />

                        <FormControlLabel
                            control={<Checkbox checked={sms} onChange={handleChangeCheck} name="sms" />}
                            label="SMS"
                        />
                    </FormGroup>
                </Grid>

            </Grid>
        </>
    )

    // buy number single
    const buyNumberClick = (params) => {
        console.log('click buyNumberClick', params.row.phone_number)
        dispatch(buySingleNumber(params.row.phone_number))
    }
    // buy number bulk
    const buyNumberBulk = () => {
        console.log('buyNumberBulk', selectedRowsBulk)
        const arrayConvert = [...selectedRowsBulk]
        arrayConvert?.map((item) => {
            return (
                dispatch(buySingleNumber(item))
            )
        })
    }

    return (
        // <Page title="User: List" style={{ padding: "10px 30px" }}>
        <Page title="Buy Number" style={{ padding: "10px 30px" }}>

            <HeaderBreadcrumbs
                // heading="Buy Numbers"
                links={[
                    // { name: 'Number', href: PATH_DASHBOARD.number.root },
                    // { name: 'Buy' },
                    { name: '' },
                ]}
                action={
                    <>
                        {selectedRowsLenght > 1 ? (
                            <Button
                                variant="contained"
                                startIcon={<Iconify icon={'ion:basket-outline'} />}
                                onClick={buyNumberBulk}
                            >
                                Bulk Buy
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                startIcon={<Iconify icon={'ion:basket-outline'} />}
                                disabled
                            >
                                Bulk Buy
                            </Button>
                        )}

                    </>

                }
            />
            {filterToolBar}
            {defaultCountry == '' ? <>
                <div style={{ width: "60%", height: "50%", margin: "auto", marginTop: "10%" }} >
                    <img src={Buynumber} alt="" style={{ height: "100%", width: "100%", margin: "auto" }} />
                    <Typography variant='h5' style={{ marginLeft: "35%" }}>Buy Number</Typography>
                    <Typography style={{ textAlign: "center", marginBottom: "20px" }}>Setup how to use your virtual or Toll-free number here. Point it to any application like Line Forward, Call Tracking, Contact Center etc. Learn More</Typography>

                </div>

            </> : <>

                {renderedNumbers}
            </>}

        </Page>
    )
}