import React, { useState } from 'react'
import FacebookLogin from "react-facebook-login";
import { useDispatch } from 'react-redux';
import { incrementfacebookacess } from 'src/redux/Facebookaceestoken';
import facebookmain from './../../assets/facebookmain.svg'
import './facebookm.css'
const Facebookicon = ({ setActiveStep }) => {

    const [isLoggedIn, setisLoggedIn] = useState(false)
    const [userID, setUserID] = useState('')
    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [picture, setpicture] = useState('')
    const componentClicked = (response) => {
        console.log("clicked")
    }
    const dispatch = useDispatch()

    const responseFacebook = (response) => {
        console.log("response", response)
        if (response.status != "unknown") {
            setisLoggedIn(true)
            setActiveStep(3.5)
        }
        dispatch(incrementfacebookacess(response))
        localStorage.setItem('facebookaccesstoken', response.accessToken)
        localStorage.setItem('facebookgraphDomain', response.graphDomain)
        localStorage.setItem('facebookUserid', response.userID)

        setUserID(response.userID)
        setname(response.name)
        setemail(response.email)
        setpicture(response.picture.data.url)
    }


    let fbContent;
    // if (isLoggedIn) {
    //     fbContent = (
    //         <div
    //             style={{
    //                 width: "400px",
    //                 margin: "auto",
    //                 background: "#f4f4f4",
    //                 padding: "20px"

    //             }}
    //         >
    //             {/* <img src={picture} alt={name} /> */}
    //             <h2>Welcome {name}</h2>
    //             {/* <h2>graphDomain: {graphDomain}</h2> */}
    //             {/* <h2>accessToken: {accessToken}</h2> */}
    //             {/* Email: {email} */}
    //         </div>
    //     )
    // }
    //  else {
    fbContent = (
        <div
            style={{
                width: "400px",
                margin: "auto",
                // background: "#f4f4f4",
                padding: "20px"

            }}
        >
            <FacebookLogin
                appId="5543547619077892"
                autoLoad={false}
                fields="name,email,picture"
                scope='pages_messaging,pages_show_list,pages_manage_metadata,pages_read_engagement'
                // scope='user_profile,user_messaging'
                onClick={() => componentClicked()}
                callback={responseFacebook}
                textButton={<p>Continue with Facebook </p>}
                icon={<img src={facebookmain} style={{ width: "30px", marginRight: "5%" }} />}
                cssClass='facebookcss'

            />
        </div>

    )
    // }

    return (
        <div
            style={{ display: "flex", marginRight: "50px" }}
        >
            <div>


                <div style={{ color: "#42a3ff" }}>
                    Reauthorize
                </div>
                <div style={{ width: "300px", color: "#525d6b" }}>

                    Your Facebook connection has expired, please reconnect your facebook page to continue services.

                </div>
            </div>
            <div>
                {fbContent}
            </div>


        </div>
    )
}

export default Facebookicon