import { useEffect } from 'react';
import io from "socket.io-client";
// @mui
import { Card, Container } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getTelegramConversations, getContacts, getUpdatedConversationFromSocket, checkIfConversationIsNew } from '../../redux/slices/chat';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TelegramChatSidebar, TelegramChatWindow } from '../../sections/@dashboard/chat';
import { REACT_APP_BASE_URL } from '../../utils/axios'
import { addMessageById } from '../../redux/slices/TelegramSocketSlice';
import { addConversationInList } from '../../redux/slices/WhatsappConversationListSlice';
import { useParams } from 'react-router';
// ----------------------------------------------------------------------



export default function InboxTelegram() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { token } = useParams()
  console.log("telegram token", token)
  const no_of_fetch_count = useSelector((state) => state.WhatsappConversationList.no_of_fetch_count);
  const { name } = useParams()
  const updatedConversation = useSelector((state) => state.WhatsappConversationList.list);

  const changeInConversation = useSelector((state) => state.WhatsappConversationList.changeInConversation)

  const newConversationLength = useSelector((state) => state.WhatsappConversationList.no_of_fetched_conversations);

  console.log("newConversationLength : ", newConversationLength);
  console.log("changeInConversation InboxWhataspp : ", changeInConversation, no_of_fetch_count)

  useEffect(() => {
    const baseUrl = REACT_APP_BASE_URL;
    //const baseUrl = 'https://mibsback.vibtree.com';

    const telegramSocket = io(`${baseUrl}/telegram`, {

      path: "/websockets",
      transports: ["polling", "websocket"],
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: "ApiKey <Key>",
          },
        },
      },
    });

    console.log("telegramSocket", telegramSocket)
    let telegramToken = localStorage.getItem("telegram-token") || '5549169131:AAFe2xPK6G78jCf52wg5MoaCcA5MtxFoKnM'

    telegramSocket.emit("join_room", {
      request: "chats",
      token: telegramToken,
    });


    telegramSocket.emit(`telegramDashboard_${telegramToken}`)

    telegramSocket.on(`telegram_data`, (data) => {
      console.log("telegramSocket data only of this account : ", data);
      if (data.Direction === "Inbound") {
        const converstion_id = data.message.chat.id.toString();
        dispatch(addMessageById({ id: converstion_id, message: data }));
      }
    })

    telegramSocket.on('conversation_list', (data) => {
      console.log("telegramSocket realtime_conversation list data :: ", data)
      dispatch(checkIfConversationIsNew(data.change))
    });

    telegramSocket.on('incomingMessage', (data) => {
      console.log("public data : ", data);
    })


    if (no_of_fetch_count === 0) {
      dispatch(getTelegramConversations(token));
      dispatch(getContacts());
    }

    if (no_of_fetch_count >= 1) {
      dispatch(getUpdatedConversationFromSocket(updatedConversation));
    }

  }, [dispatch]);
  const names = name.slice(0, 1).toUpperCase() + name.slice(1)
  return (
    <Page title={names}>
      {/* <Container maxWidth={themeStretch ? false : 'xl'}> */}

      <Card sx={{ height: '100vh', display: 'flex' }}>
        <TelegramChatSidebar />
        <TelegramChatWindow />
      </Card>
      {/* </Container> */}
    </Page>
  );
}
