import React, {useState, useEffect } from 'react'
import { Button, Card, Divider, Drawer, Grid, IconButton, MenuItem, Pagination, Skeleton, Snackbar, Stack, TextField, Typography, useTheme, Tooltip, Toolbar } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useSearchParams, useLocation, createSearchParams } from 'react-router-dom';



import CloseIcon from '@mui/icons-material/Close';
import { incrementpoweronbutton } from 'src/redux/Poweron';
import axiosInstance, { baseUrl } from 'src/utils/axios';
;
import Iconify from '../Iconify';
import { incrementpowerstatus } from 'src/redux/Powerdialerstatuschange';
import { Box } from '@mui/system';
import Scrollbar from '../Scrollbar';
import InboxAnalytics from '../InboxVoice/components/InboxAnalytics';

import { incrementpowerdialerpage } from 'src/redux/Powerdialerdata';

import ConfirmationDialog from '../../components/ConfirmationDialog';
import Label from '../Label';


const PowerDialer = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [power_dialer, setpowerdialer] = useState([])
    const [loading, setLoading] = useState(true)

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [fieldDialog, setFieldDialog] = useState(false)

    // confirmation
    const [confirmDialog, setConfirmDialog] = useState(false)
    const [confirmDialogMessage, setConfirmDialogMessage] = useState(false)

    const [deleteId, setDeleteId] = useState('')
    

    const handleCloseDialog = () => {
        setFieldDialog(false)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleClick1 = () => {
        setOpen(true);
    };

    const action = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen1(false);
    };

    const handleClick2 = () => {
        setOpen1(true);
    };

    const action1 = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose1}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    
     // pagination
     const [count, setCount] = useState(0);
     const [pageCount, setPageCount] = useState(0);
     const [page, setPage] = useState(1);
     const [offsetCount, setOffsetCount] = useState(0);
     const [rowsPerPage, setRowsPerPage] = useState(10)

    const [statuss, setstatuss] = useState('All')

    useEffect(() => {
        setPageCount(Math.ceil(count / rowsPerPage))
    }, [count, rowsPerPage])

    const statuscheck = [
        {
            value: 'All',
            label: 'All',
        },
        {
            value: 'New',
            label: 'New',
        },
        {
            value: 'completed',
            label: 'Completed',
        },

        {
            value: 'no-answer',
            label: 'No Answer',
        },

    ];


    useEffect(() => {
        console.log('location', location, searchParams.get("offsetCount"))
        if(location.pathname === '/dashboard/powerdialer'){
          setOffsetCount(searchParams.get("offsetCount"))
          setRowsPerPage(searchParams.get("limit"))
          if(searchParams.get("offsetCount") === 0){
              setPage(1)
          }else{
              setPage((searchParams.get("offsetCount")/10) + 1)
          }
        }
      }, [searchParams])



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const pageOffSet = `${newPage-1}0`
        if(newPage === 1){
            setOffsetCount(0)
            const paramsData= {offsetCount: 0, limit: rowsPerPage}

            navigate({
                pathname: '/dashboard/powerdialer',
                search: `?${createSearchParams(paramsData)}`,
              });
        }
        else{
            setOffsetCount(parseInt(pageOffSet, 10))
            const paramsData= {offsetCount: parseInt(pageOffSet, 10), limit: rowsPerPage}

            navigate({
                pathname: '/dashboard/powerdialer',
                search: `?${createSearchParams(paramsData)}`,
              });
        }
      };



    const authId = localStorage.getItem('authID')
    const handlestatus = () => {
        dispatch(incrementpowerstatus(statuss))
        getdata()
    }

    const powerstatus = useSelector(state => state.PowerdialerStatusSlice.value)

    const getdata = () => {
        setLoading(true)
        if (statuss == 'All') {
            axiosInstance.get(`${baseUrl}/power_dialer?populate=contactId&populate=userId&authId=${authId}&limit=${rowsPerPage}&offset=${offsetCount}`)
                .then((res) => {
                    setLoading(false)
                    console.log("responsepowerdialer", res.data.data)
                    setpowerdialer(res.data.data.data)
                    setCount(res.data.data.totalCount)
                    dispatch(incrementpowerdialerpage(res.data.data.data))
                    setDataStatsNew(res.data.data.new)
                    setDataStatscompleted(res.data.data.completed)
                    setDataStatsnoanswer(res.data.data.no-answer)
                })
                .catch((err) => {
                    setLoading(false)
                    console.log(err)
                })
        } else {


            axiosInstance.get(`${baseUrl}/power_dialer?populate=contactId&populate=userId&authId=${authId}&callStatus=${statuss}&limit=${rowsPerPage}&offset=${offsetCount}`)
                .then((res) => {
                    setLoading(false)
                    console.log("responsepowerdialer", res.data.data)
                    setpowerdialer(res.data.data.data)
                    setCount(res.data.data.totalCount)
                    dispatch(incrementpowerdialerpage(res.data.data.data))
                    setDataStatsNew(res.data.data.new)
                    setDataStatscompleted(res.data.data.completed)
                    setDataStatsnoanswer(res.data.data.no-answer)
                })
                .catch((err) => {
                    setLoading(false)
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        getdata()
    }, [])



    const [dataStatsNew, setDataStatsNew] = useState(0)
    const [dataStatscompleted, setDataStatscompleted] = useState(0)
    const [dataStatsnoanswer, setDataStatsnoanswer] = useState(0)


    const [arrIds, setArrIds] = useState([])

    useEffect(() => {
        getdata()
    }, [page, rowsPerPage])
    
    console.log("responsepowercount", pageCount)

    const deleteBulkPowerDialer = () => {

        setConfirmDialog(false)

        let arrayConvert = [...arrIds]
       
         console.log('deleteBulkPowerDialer', arrayConvert)


        for (var i = 0; i < arrayConvert.length; i++) {
            axiosInstance.delete(`${baseUrl}/power_dialer/${arrayConvert[i]}`)
                .then((res) => {
                    
                    console.log("responsedelete", res)

                })
                .catch((err) => {
                    console.log(err)
                })
        }
        getdata()
        handleClick1()
        setArrIds([])


    }


    const confirmDelete = (value) =>{
        setDeleteId(value)
        setConfirmDialog(true)
        setConfirmDialogMessage('Confirm Delete Selected PowerDialer')
    }

    const confirmBulkDelete = () =>{
        setDeleteId('')
        setConfirmDialog(true)
        setConfirmDialogMessage('Confirm Delete All Selected PowerDialers')
    }

    const deletePowerDialer = () => {
        setConfirmDialog(false)
        axiosInstance.delete(`${baseUrl}/power_dialer/${deleteId}`)
            .then((res) => {
                console.log('response power delete', res.data)
                if(res.data.status === true){
                    getdata()
                    handleClick1()
                    setDeleteId('')
                    
                }else{
                    
                }
                
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const conatctname = (params) => {

        return (
            <>
                {/* <div style={{ display: "flex", flexDirection: "column", margin: "2px" }}> */}

                <Box sx={{ alignItems: 'center', display: "flex", flexDirection: "column", m: 2 }}>

                    <Button style={{ padding: "0px", marginLeft: "-20px" }}>
                        <Typography variant="subtitle2" >
                            {params.row.contactId ? params.row.contactId.firstName : null}
                        </Typography>

                    </Button>

                    <Typography variant='p' style={{ margin: "0px", padding: "0px" }}>
                        {params.row.contactId ? (<>
                            +{params.row.contactId.phoneNumber}
                        </>) : params.row.contactNumber}
                        {/* {params.row.contactNumber} */}
                    </Typography>

                </Box>
                {/* </div> */}
            </>
        )

    }

    const deletebuttons = (params) => {
        return (
            <>
                <IconButton onClick={() => confirmDelete(params.row._id)}>
                    <Iconify icon={'fluent:delete-32-regular'} width={20} height={20} />
                </IconButton>
            </>
        )
    }

    const checkStatus = (params) => {
        return (
            <>

            {params.row.callStatus === 'completed' ? (
                <Label color='success' >
                {params.row.callStatus}
            </Label>
            ) : (
<Label color='info' >
                {params.row.callStatus}
            </Label>
            )}

         

            {/* <Label
                // variant='ghost'
                color={params.row.callStatus == 'completed' ? 'success' : 'danger'}
                sx={{ textTransform: 'capitalize', marginLeft: "5px" }}
            >
                {params.row.callStatus == 'completed' ? 'Completed' : params.row.callStatus}
            </Label> */}
            </>
            
        )
    }

    const { callHistory, missed_call, show_all_list, call_offset, missed_offset } = useSelector((state) => state.user)

    console.log("callHistory", callHistory)

    const columns = [
        {
            field: "contactNumber",
            headerName: 'Contacts',
            editable: false,
            flex: 1,
            renderCell: conatctname
        },
        {
            field: "callStatus",
            headerName: "Status",
            editable: false,
            flex: 1,
            renderCell: checkStatus
        },
        {
            field: "Action",
            headerName: "Action",
            editable: false,

            width: 100,
            renderCell: deletebuttons
        }
    ]

    const LoadingSkeleton = () => (
        <>
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </>
    );

    const renderedGrid = (
        <>
            <DataGridPro
                rows={power_dialer}
                columns={columns}
                rowHeight={60}
                disableSelectionOnClick
                checkboxSelection

                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    console.log('selected ids', selectedIDs)
                    setArrIds(selectedIDs)
                }}

                // onSelectionModelChange={itm => {
                //     console.log('for')
                //     setArrIds(itm)
                // }}
                getRowId={(r) => r._id}
                style={{ height: '550px' }}
                components={{
                    LoadingOverlay: LoadingSkeleton
                }}
                initialState={{
                    pinnedColumns: {
                        left: [''], right: ['Action']
                    }
                }}
                loading={loading}
                hideFooter
            />
           
        </>
    )

    const handleClickpowerdailer = () => {
        console.log("dialer")
        dispatch(incrementpoweronbutton(true))
    }

    function EnhancedTableToolbar(props) {
        const { numSelected } = props;
      
        return (
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              bgcolor: '#eee',
            }}
          >
          
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
           
        
              <Tooltip title="Delete">
                <IconButton onClick={confirmBulkDelete}>
                    <Iconify icon={'fluent:delete-32-regular'} width={20} height={20} />
                </IconButton>
              </Tooltip>
           
          </Toolbar>
        );
      }




    const skeltonLoaders = (
        <>
            <Grid container>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
            </Grid>
        </>
    )

    const theme = useTheme();

    function percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    }




    return (
        <>



            <div>

                <Button variant='outlined'
                    style={{ position: "relative", left: "77%", marginTop: "2%", marginRight: "10px" }}
                    onClick={() => setFieldDialog(true)}
                >
                    Filter
                </Button>
                <Button variant='contained'
                    style={{ position: "relative", left: "77%", marginTop: "2%" }}
                    onClick={() => handleClickpowerdailer()}
                >
                    Start Power Dialer
                </Button>


                <Card sx={{ mb: 5, mt: 3, ml: 3, mr: 3 }}>

                    <Scrollbar>

                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
                            sx={{ py: 2 }}
                        >
                            <InboxAnalytics
                                title="New"
                                total={dataStatsNew}
                                percent={100}
                                icon="fluent:call-32-regular"
                                color={theme.palette.info.main}
                            />

                            <InboxAnalytics
                                title="Completed"
                                total={dataStatscompleted}
                                percent={100}
                                icon="fluent:call-checkmark-24-regular"
                                color={theme.palette.success.main}
                            />

                            <InboxAnalytics
                                title="No Answer"
                                total={dataStatsnoanswer}
                                percent={100}
                                icon="uil:missed-call"
                                color={theme.palette.warning.main}
                            />



                        </Stack>
                    </Scrollbar>
                </Card>
                <div style={{ marginLeft: "3%", marginRight: "3%", marginTop: "3%", marginBottom: "7%" }}>
                    {loading == true ? <>
                        {skeltonLoaders}
                    </> : <>
                        {/* <button onClick={handleDeleteAll}>Delete</button> */}
                        {arrIds.size > 0 ? <EnhancedTableToolbar numSelected={arrIds.size} /> : ''}
                         {renderedGrid}
                        {/* <IconmakerTable power_dialer={power_dialer} count={count} getdata={getdata} /> */}

                        {count > 1 && (
           
           <Grid container spacing={3} style={{marginTop: "5px"}} alignItems="center" justifyContent='center'>
                <Grid item> Rows Per Page</Grid>
                <Grid item>
     
                   <TextField
                     value={rowsPerPage}
                     onChange={(event)=>{
                         setRowsPerPage(event.target.value)
                         const paramsData= {offsetCount, limit: event.target.value}
     
                         navigate({
                             pathname: '/dashboard/powerdialer',
                             search: `?${createSearchParams(paramsData)}`,
                           });
                     }}
                     size='small'
                     select>
                     <MenuItem value={10}>
                         10
                     </MenuItem>
                     <MenuItem value={25}>
                         25
                     </MenuItem>
                     <MenuItem value={50}>
                         50
                     </MenuItem>
                     <MenuItem value={100}>
                         100
                     </MenuItem>
                   </TextField>
                 </Grid>
                 {pageCount > 1 &&(
                <Grid item>
                    <Pagination count={pageCount} page={page} showFirstButton showLastButton onChange={handleChangePage} color="primary" shape="rounded" />
                </Grid>
                 )}
              
            </Grid>
             )}
     
                    </>

                    }

                </div>


                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: "right"
                    }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message="Deleted successfully"
                    action={action}
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: "right"
                    }}
                    open={open1}
                    autoHideDuration={3000}
                    onClose={handleClose1}
                    message="Status update successfully"
                    action={action1}
                />

                <Drawer
                    anchor='right'
                    open={fieldDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >
                    <div style={{ width: "350px" }}>

                        <div style={{ marginTop: "20%", marginLeft: "10%", marginRight: "10%" }}>

                            <TextField
                                select
                                placeholder='Status'
                                label='Status'
                                fullWidth
                                value={statuss}
                                onChange={(e) => setstatuss(e.target.value)}
                            >
                                {statuscheck.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <Button variant='contained' style={{ marginTop: "20%", position: "relative", left: "70%" }} onClick={() => {
                            handlestatus()
                            setFieldDialog(false)
                        }}>
                            update
                        </Button>

                    </div>
                </Drawer>

                <ConfirmationDialog openDialog={confirmDialog} setOpenDialog={setConfirmDialog} triggerConfirm={ deleteId !== '' ? deletePowerDialer : deleteBulkPowerDialer} message={confirmDialogMessage} />

            </div>
        </>
    )
}

export default PowerDialer