import PropTypes from 'prop-types';
// @mui
import { List, Box, Typography } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
//
import { ListSubheaderStyle } from './style';
import NavList from './NavList';
import axiosInstance from 'src/utils/axios';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import './style.css'
import { useDispatch } from 'react-redux';
import { incrementvoicenumber } from 'src/redux/Voicenumber';
import { useSelector } from 'react-redux';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { PATH_DASHBOARD } from 'src/routes/paths';
// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};
const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  number: getIcon('ic_number'),
  contact: getIcon('ic_contact'),
  voicemail: getIcon('ic_voicemail'),
  voice: getIcon('ic_voice'),
  folder: getIcon('ic_folder'),
  whatsapp: getIcon('ic_whatsapp'),
  sms: getIcon('ic_sms'),
  ivrstudio: getIcon('ic_ivrstudio'),
  tickets: getIcon('ic_tickets'),
  livesupport: getIcon('ic_livesupport'),
  helpdocs: getIcon('ic_helpdocs'),
  feedback: getIcon('ic_feedback')
};


export default function NavSectionVertical({ navConfig, isCollapse, ...other }) {
  const { translate } = useLocales();
  const [user, setuser] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const refreshinboxsetting = localStorage.getItem('refreshinboxsetting')
  const selecteduser = useSelector(state => state.refreshinboxslice.value)
  const selecteduserRefresh = useSelector(state => state.Refreshpageslice.value)
  const [nav_data, setNavData] = useState(navConfig);
  const {show_ticket, list_app, ticket_app} = useSelector((state) => state.user)

  
  const getapi = () => {

    const authid = localStorage.getItem('authID')
    axiosInstance.get(`/inbox-view?AccountSid=${authid}`)
      .then((res) => {
        setuser(res.data.data.data)
        console.log("getapi respo", res.data.data.data)
        localStorage.setItem('getapiinbox', res.data.data.data.length)
        console.log("getapi respo number", res.data.data.data[0].data[0].number)
        console.log("getapi respo item", res.data.data.data.length)

        localStorage.setItem('isWhatsappActive', true)

      }).catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    getapi()
  }, [])
  useEffect(() => {
    getapi()
  }, [selecteduser, selecteduserRefresh])

  const handlechange = () => {
    navigate(`/dashboard/onboarding/channel`)
    // inbox / voice / search / 912235316002
  }

  const handlesearch = (value) => {
    console.log(value)
    localStorage.setItem('voicename', value.InboxName)
    if (value.data[0].number) {


      dispatch(incrementvoicenumber(value.data[0].number))
      localStorage.setItem('assignedNumbervoice', value.data[0].number)
      navigate(`/dashboard/inbox/voice/${value.InboxName}/${value.data[0].number}`)
    } else if (value.data[0].phoneNumberId) {
      navigate(`/dashboard/inbox/whatsapp/${value.InboxName}/${value.data[0].phoneNumberId}`)
      // navigate(`/dashboard/inbox/whatsapp`)
      localStorage.setItem('whatsappNumber', value.data[0].whatsAppNumber)
      localStorage.setItem('whatsappName', value.InboxName)
      localStorage.setItem('whatsappNumberid', value.data[0].phoneNumberId)
    }
  }



     // permission
     const [userRolePermissions, setUserRolePermissions] = useState(
      {
        Workflow: {
            create: false,
            delete: false,
            edit: false,
            view: false
        },
        ContactsManage: {
          create: false,
          delete: false,
          edit: false,
          view: false
        },
        ContactsNotes: {
          create: false,
          delete: false,
          edit: false,
          view: false
        },
        ContactsGroups: {
          create: false,
          delete: false,
          edit: false,
          view: false
        },
        VoicemailBox: {
          create: false,
          delete: false,
          edit: false,
          view: false
        },
        Inbox: {
          create: false,
          delete: false,
          edit: false,
          view: false
        }
    }
    )
     const roleData = useSelector(state => state.userRole.roleData)
     let is_admin = localStorage.getItem('is_admin')

     

     useEffect(() => {
      
      if(roleData.status === true && roleData.status !== undefined){
        setUserRolePermissions(roleData.data[0].userPermission)
      }
    }, [roleData])
     
       // permission


  const inboxobject = [



    {
      // subheader: 'general',



      items: [

        // contacts
        {
          title: 'inbox',
          path: 'dashboard/inbox',
          icon: ICONS.folder,
          children: [

          ],
        },



      ],
    },

  ];
  var objects = {};

  // for (var x = 0; x < 10; x++) {
  //   objects[x] = { name: x, profile: "v" };
  // }

  user.map((item, index) => (
    // objects[index] = { title: item.InboxName, path: item.InboxIcon }
    <>
      {/* {item.data[0].number ? <>
        {localStorage.setItem('assignedNumbervoice', item.data[0].number)}
      </> : <>
        {localStorage.setItem('whatsappNumber', item.data[0].whatsAppNumber)}
        {localStorage.setItem('whatsappName', item.InboxName)}
        {console.log("whatsappnamename", item.InboxName)}
        {localStorage.setItem('whatsappNumberid', item.data[0].phoneNumberId)}
      </>} */}
      {(item.Status == 'active' || item.Status == 'Active') && (
        <>

          {inboxobject[0].items[0].children[index] = {
            title: item.InboxName,
            icons: item.InboxIcon,
            whatsAppNumber: item.data[0].whatsAppNumber,
            whatsappphoneid: item.data[0].phoneNumberId,
            voicenumber: item.data[0].number,
            whatsappname: item.InboxName,
            path: item.data[0].number ? `/dashboard/inbox/voice/${item.InboxName ? (item.InboxName.includes(" ") ? item.InboxName.split(" ")[0] + item.InboxName.split(" ")[1] : item.InboxName) : item.InboxName}/${item.data[0].number}` : item.data[0].phoneNumberId ? `/dashboard/inbox/whatsapp/${item.InboxName}/${item.data[0].phoneNumberId}` : item.InboxType == 'telegram' ? `/dashboard/inbox/telegram/${item.InboxName}/${item.data[0].Bot_token}` : item.InboxType == 'viber' ? `/dashboard/inbox/viber/${item.InboxName}/${item.data[0].Bot_token}` : item.InboxType == 'messenger' ? `/dashboard/inbox/messenger/${item.InboxName}` : item.InboxType == 'instagram' ? `/dashboard/inbox/insta/${item.InboxName}` : `/dashboard/inbox/sms/${item.InboxName}`
          }}
        </>
      )}


    </>

  ))
  // console.log(objects)
  if(userRolePermissions.Inbox.create === true || is_admin === 'true'){
  inboxobject[0].items[0].children[inboxobject[0].items[0].children.length + 1] = {
    title: "Create Inbox",
    icons: "bi bi-folder-plus",
    path: '/dashboard/onboarding/channel'
  }}
  // inboxobject[0].items[0].children.push(inboxnew)
  console.log("inboxobject", inboxobject)
  console.log("inboxobjectitem",)

  return (
    <Box {...other} sx={{ mt: 4, pb: 15, marginBottom: '20px' }}>
      {nav_data.map((group) => (
        <List key={group.subheader} disablePadding
          sx={{ px: 2 }}
        >
          {group.items.slice(0, 1).map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
              
            />
          ))}
        </List>
      ))}

      <List disablePadding
        sx={{ px: 2 }}
      >
        <NavList
          key={inboxobject[0].items[0].title + inboxobject[0].items[0].path}
          data={inboxobject[0].items[0]}
          depth={1}
          hasChildren={!!inboxobject[0].items[0].children}
          isCollapse={isCollapse}

        />
      </List>


      {nav_data.map((group) => (
        <List key={group.subheader} disablePadding
          sx={{ px: 2 }}
        >


          {/* <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {translate(group.subheader)}
          </ListSubheaderStyle> */}








          {group.items.slice(1).map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))
      }


    </Box >
  );
}
