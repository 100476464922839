import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from '../../utils/axios';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------


const initialState= {
    isLoading: false,
    error: null,
    addTagUser: {},
    getTagsUserData: {},
    deleteTagResponse: {}
}

const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        
        // GET USER DATA SSUCCESS
        tagsPatchSuccess(state, action) {
          state.addTagUser = action.payload;
          state.isLoading = false;
          console.log('redux user tag post data', state.addTagUser)
        },

        tagsGetSuccess(state, action) {
            state.getTagsUserData = action.payload;
            state.isLoading = false;
            console.log('redux user tag get data', state.getTagsUserData)
          },

          tagsDeleteSuccess(state, action) {
            state.deleteTagResponse = action.payload;
            state.isLoading = false;
            console.log('redux user tag delete data', state.deleteTagResponse)
          },

      
       

    }
})

export const getAllTags = (state) => state.tags;

export default tagsSlice.reducer

// Actions


  export function patchTagsUser(id, data) {
    return async () => {
      dispatch(tagsSlice.actions.startLoading());
      try {
        const response = await axios.patch(`users/addTag/${id}`, data);
          dispatch(tagsSlice.actions.tagsPatchSuccess(response.data));
          dispatch(getTagsUser(id))
      } catch (error) {
        dispatch(tagsSlice.actions.hasError(error));
      }
    };
  }


  export function getTagsUser(id) {
    console.log('trigger getTagsUser', id)
    return async () => {
      dispatch(tagsSlice.actions.startLoading());
      try {
        const response = await axios.get(`users/getTags/${id}`);
          dispatch(tagsSlice.actions.tagsGetSuccess(response.data.tags));
          console.log('trigger getTagsUser res', response)
      } catch (error) {
        dispatch(tagsSlice.actions.hasError(error));
      }
    };
  }

  export function deleteTagUser(id, name) {
    console.log('trigger deleteTag', id)
    return async () => {
      dispatch(tagsSlice.actions.startLoading());
      try {
        const response = await axios.patch(`users/delTag/${id}/${name}`);
          dispatch(tagsSlice.actions.tagsDeleteSuccess(response.data));
          console.log('trigger deleteTag res', response)
      } catch (error) {
        dispatch(tagsSlice.actions.hasError(error));
      }
    };
  }