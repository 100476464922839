import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Avatar, TextField, Typography, Autocomplete, Chip, Button, InputAdornment, ClickAwayListener } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import axiosInstance, { baseUrl } from '../../../utils/axios';
import InputStyle from 'src/components/InputStyle';
import { useSelector } from 'react-redux';
import useResponsive from 'src/hooks/useResponsive';
import { useLocation, useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';
import ChatSearchResults from './ChatSearchResults';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 3),
}));

const AutocompleteStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    width: 240,
    marginLeft: theme.spacing(2),
    '&.Mui-focused .MuiAutocomplete-inputRoot': {
      boxShadow: theme.customShadows.z8,
    },
    [theme.breakpoints.up('sm')]: {
      width: 280,
    },
  },
  '& .MuiAutocomplete-inputRoot': {
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  },
}));

// ----------------------------------------------------------------------

ChatHeaderCompose.propTypes = {
  contacts: PropTypes.array,
  recipients: PropTypes.array,
  onAddRecipients: PropTypes.func,
};

export default function ChatHeaderCompose({ contacts, recipients, onAddRecipients }) {
  const [query, setQuery] = useState('');
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  // console.log('selectedRecipients', selectedRecipients);
  // console.log('ChatHeaderCompose', contacts, recipients, onAddRecipients);
  const handleAddRecipients = (recipients) => {
    console.log("value recipients", recipients)


    setQuery('');
    onAddRecipients(recipients);
    setSelectedRecipients(recipients);
  };

  const sendFirstMessageToRecipients = async (e) => {
    e.preventDefault();
    const From = localStorage.getItem('whatsappNumber');
    const phoneNumberId = '105217022203896';
    const messagingProduct = 'whatsapp';
    const recipient_number = selectedRecipients[0].phone;
    const type = 'template';
    const template = {
      "name": "account_is_created",
      "language": {
        "code": "en"
      }
    }
    const data = {
      "from": From,
      "phone_number_id": phoneNumberId,
      "messaging_product": messagingProduct,
      "to": recipient_number,
      "type": type,
      "template": template
    }


    const response = await axiosInstance.post('/whatsapp/send/first_time', data)
    console.log('response send message template first time : ', response);
  }

  const handlechange = (e) => {
    console.log('value change', e.target.value)
    // axiosInstance.get(`${baseUrl}/contacts?phoneNumber=${e.target.value}`)
    //   .then((res) => {
    //     console.log(res)

    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  }







  const theme = useTheme();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [openSidebar, setOpenSidebar] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');

  const [searchResults, setSearchResults] = useState([]);

  const [isSearchFocused, setSearchFocused] = useState(false);

  const { conversations, activeConversationId } = useSelector((state) => state.chat);

  const isDesktop = useResponsive('up', 'md');

  const displayResults = searchQuery && isSearchFocused;

  const isCollapse = isDesktop && !openSidebar;

  useEffect(() => {
    if (!isDesktop) {
      return handleCloseSidebar();
    }
    return handleOpenSidebar();
  }, [isDesktop, pathname]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!openSidebar) {
      return setSearchFocused(false);
    }
  }, [openSidebar]);

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleToggleSidebar = () => {
    setOpenSidebar((prev) => !prev);
  };

  const handleClickAwaySearch = () => {
    setSearchFocused(false);
    setSearchQuery('');
  };

  const handleChangeSearch = async (event) => {

    try {

      const { value } = event.target;
      function onlyNumbers(str) {
        return /^[0-9]*$/.test(str);
      }
      function onlyLetters(str) {
        return /^[A-Za-z]*$/.test(str);
      }
      setSearchQuery(value);

      console.log('searchbar', typeof value)
      // if (value == 'a' || value == 'A' || value == 'b' || value == 'B' || value == 'c' || value == 'C' || value == 'd' || value == 'D' || value == 'e' || value == 'E' || value == 'f' || value == 'F' || value == 'G' || value == 'g' || value == 'H' || value == 'h' || value == 'I' || value == 'i' || value == 'j' || value == 'J' || value == 'k' || value == 'K' || value == 'l' || value == 'L' || value == 'M' || value == 'm' || value == 'N' || value == 'n' || value == 'o' || value == 'O' || value == 'p' || value == 'P' || value == 'q' || value == 'R' || value == 's' || value == 'S' || value == 't' || value == 'T' || value == 'u' || value == 'U' || value == 'v' || value == 'V' || value == 'w' || value == 'x' || value == 'X' || value == 'Y' || value == 'y' || value == 'z' || value == 'Z') {
      if (onlyLetters(value)) {
        axiosInstance.get(`${baseUrl}/contacts?firstName=${value}&limit=10`)
          .then((res) => {
            console.log("contactsearch", res.data.data.data)
            console.log("contactsearchvikalp")
            // setname(res.data.data.data)
            setSearchResults(res.data.data.data);
          })
          .catch((err) => {
            console.log(err)
          })
      }
      // if (value == 0 || value == 1 || value == 2 || value == 3 || value == 4 || value == 5 || value == 6 || value == 7 || value == 8 || value == 9)
      if (onlyNumbers(value)) {

        axiosInstance.get(`${baseUrl}/contacts?phoneNumber=${value}&limit=10`)
          .then((res) => {
            console.log("contactsearch", res.data.data.data)
            console.log("contactsearchvikalp")
            // setname(res.data.data.data)
            setSearchResults(res.data.data.data);
          })
          .catch((err) => {
            console.log(err)
          })

      }
      // if (value) {
      //   const response = await axios.get('/api/chat/search', {
      //     params: { query: value },
      //   });
      //   setSearchResults(response.data.results);
      //   console.log('searchbar', response.data.results)
      // } else {
      //   setSearchResults([]);

      // }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchFocus = () => {
    setSearchFocused(true);
  };

  const handleSearchSelect = (item) => {
    setSearchFocused(false);
    setSearchQuery('');
    const whatsappnumber = localStorage.getItem('whatsappNumber')
    const whatsappName = localStorage.getItem('whatsappName')
    const whatsappNumberid = localStorage.getItem('whatsappNumberid')
    console.log("whatsappitem", item.phoneNumber)
    const conversationKey = whatsappnumber - item.phoneNumber
    const encryptedConversationKey = window.btoa(`${whatsappnumber}-${item.phoneNumber}`);
    console.log("encryptedConversationKey", encryptedConversationKey)
    // navigate(PATH_DASHBOARD.chat.view(username));
    navigate(`/dashboard/inbox/whatsapp/${whatsappName}/${whatsappNumberid}/${encryptedConversationKey}`);
  };

  const handleSelectContact = (result) => {
    if (handleSearchSelect) {
      handleSearchSelect(result);
    }
    console.log("handleselectcontact", result)
  };
  const whatsappName = localStorage.getItem('whatsappName')
  const whatsappNumberid = localStorage.getItem('whatsappNumberid')



  return (
    <RootStyle>
      <Typography variant="subtitle2" sx={{ color: 'text.secondary', position: "absolute", top: "25px" }}>
        To :
      </Typography>

      <AutocompleteStyle>
        {/* <Autocomplete
          multiple
          size="small"
          disablePortal
          popupIcon={null}
          noOptionsText={<SearchNotFound searchQuery={query} />}
          onChange={(event, value) => handleAddRecipients(value)}
          onInputChange={(event, value) => setQuery(value)}
          options={contacts}
          getOptionLabel={(recipient) => recipient.name}
          renderOption={(props, recipient, { inputValue, selected }) => {
            const { name, avatar } = recipient;
            const matches = match(name, inputValue);
            const parts = parse(name, matches);
            return (
              <Box component="li" sx={{ p: '12px !important' }} {...props}>
                <Box
                  sx={{
                    mr: 1.5,
                    width: 32,
                    height: 32,
                    overflow: 'hidden',
                    borderRadius: '50%',
                    position: 'relative',
                  }}
                >
                  <Avatar alt={name} src={avatar} />
                  <Box
                    sx={{
                      top: 0,
                      opacity: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      position: 'absolute',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                      transition: (theme) =>
                        theme.transitions.create('opacity', {
                          easing: theme.transitions.easing.easeInOut,
                          duration: theme.transitions.duration.shorter,
                        }),
                      ...(selected && {
                        opacity: 1,
                        color: 'primary.main',
                      }),
                    }}
                  >
                    <Iconify icon="eva:checkmark-fill" width={20} height={20} />
                  </Box>
                </Box>

                {parts.map((part, index) => (
                  <Typography key={index} variant="subtitle2" color={part.highlight ? 'primary' : 'textPrimary'}>
                    {part.text}
                  </Typography>
                ))}
              </Box>
            );
          }}
          renderTags={(recipients, getTagProps) =>
            recipients.map((recipient, index) => {
              const { id, name, avatar } = recipient;
              return (
                <Chip
                  {...getTagProps({ index })}
                  key={id}
                  size="small"
                  label={name}
                  color="info"
                  avatar={<Avatar alt={name} src={avatar} />}
                />
              );
            })
          }
          renderInput={(params) => <TextField {...params} placeholder={recipients.length === 0 ? 'Recipients' : ''} onChange={(e) => handlechange(e)} />}
        /> */}
        <ClickAwayListener
          onClickAway={handleClickAwaySearch}

        >
          <InputStyle
            fullWidth
            size="small"
            value={searchQuery}
            onFocus={handleSearchFocus}
            onChange={handleChangeSearch}
            style={{ marginLeft: "35px", marginTop: "-3px" }}
            placeholder="Search contacts..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
            sx={{ mt: 2 }}
          />
        </ClickAwayListener>



        {!displayResults ? (
          <>

          </>
        ) : (
          <div style={{ marginLeft: "35px" }}>
            <ChatSearchResults query={searchQuery} results={searchResults} onSelectContact={handleSelectContact} />
          </div>
        )}

      </AutocompleteStyle>

      {selectedRecipients.length > 0 ? <Button color='primary' onClick={sendFirstMessageToRecipients}>Send Template</Button> : null}
    </RootStyle>
  );
}
