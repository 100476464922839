import { useEffect } from 'react';
import io from "socket.io-client";
// @mui
import { Card, Container } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getViberConversations, getContacts, getUpdatedConversationFromSocket, checkIfConversationIsNew } from '../../redux/slices/chat';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { ViberChatSidebar, ViberChatWindow } from '../../sections/@dashboard/chat';
import { REACT_APP_BASE_URL } from '../../utils/axios'
import { addMessageById } from '../../redux/slices/TelegramSocketSlice';
import { addConversationInList } from '../../redux/slices/WhatsappConversationListSlice';
import { useParams } from 'react-router';
// ----------------------------------------------------------------------



export default function InboxTelegram() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { token } = useParams()
  const no_of_fetch_count = useSelector((state) => state.WhatsappConversationList.no_of_fetch_count);

  const updatedConversation = useSelector((state) => state.WhatsappConversationList.list);

  const changeInConversation = useSelector((state) => state.WhatsappConversationList.changeInConversation)

  const newConversationLength = useSelector((state) => state.WhatsappConversationList.no_of_fetched_conversations);

  console.log("newConversationLength : ", newConversationLength);
  console.log("changeInConversation InboxWhataspp : ", changeInConversation, no_of_fetch_count)
  const { name } = useParams()
  useEffect(() => {
    const baseUrl = REACT_APP_BASE_URL;
    //const baseUrl = 'https://mibsback.vibtree.com';

    const viberSocket = io(`${baseUrl}/viber`, {

      path: "/websockets",
      transports: ["polling", "websocket"],
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: "ApiKey <Key>",
          },
        },
      },
    });


    const chat_hostname = "SN-CALLBACK-06_"

    viberSocket.emit("join_room", {
      request: "chats",
      token: chat_hostname,
    });


    viberSocket.emit(`viberDashboard_${chat_hostname}`)

    viberSocket.on(`viber_data`, (data) => {
      console.log("viberSocket data only of this account : ", data);
      if (data.Direction === "Inbound") {
        const converstion_id = data.message.chat.id.toString();
        dispatch(addMessageById({ id: converstion_id, message: data }));
      }
    })

    viberSocket.on('conversation_list', (data) => {
      console.log("viberSocket realtime_conversation list data :: ", data)
      dispatch(checkIfConversationIsNew(data.change))
    });

    viberSocket.on('incomingMessage', (data) => {
      console.log("public data : ", data);
    })


    if (no_of_fetch_count === 0) {
      dispatch(getViberConversations(token));
      dispatch(getContacts());
    }

    if (no_of_fetch_count >= 1) {
      dispatch(getUpdatedConversationFromSocket(updatedConversation));
    }

  }, [dispatch, newConversationLength]);

  const names = name.slice(0, 1).toUpperCase() + name.slice(1)
  return (
    <Page title={names}>
      {/* <Container maxWidth={themeStretch ? false : 'xl'}> */}

      <Card sx={{ height: '100vh', display: 'flex' }}>
        <ViberChatSidebar />
        <ViberChatWindow />
      </Card>
      {/* </Container> */}
    </Page>
  );
}
