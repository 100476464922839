import React, { useState, useEffect } from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate, createSearchParams } from "react-router-dom";
import {
  Button,
  Drawer,
  Stack,
  Box,
  Typography,
  Divider,
  IconButton,
  Grid,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Collapse,
  Select,
  MenuItem,
  InputLabel,
  Switch,
  ListItemIcon,
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  Avatar,
  FormControl,
  TextField,
  Radio

} from '@mui/material'
import axiosInstance from '../../../utils/axios';
import Iconify from '../../Iconify';



// redux
import { useSelector, useDispatch } from 'react-redux';
import { getFilter, getIvrVoice, selectAllInboxData } from '../../../redux/slices/Inbox/voice';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default function FilterDrawer({

}) {
  const { height, width } = useWindowDimensions();

  console.log("height", width)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inboxVoiceFilterTags = localStorage.getItem('inboxVoiceFilterTags')

  const [openDrawer, setOpenDrawer] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [timeFormat, setTimeFormat] = useState(localStorage.getItem('voiceTimeFormate') === 'true' ? true : false)
  const [cloudNumber, setCloudNumber] = useState([])
  const [selectedCloudNumber, setSelectedCloudNumber] = useState('')
  const [cloudNumberArray, setCloudNumberArray] = useState()
  const [numbersFilter, setNumbersFilter] = useState(null);
  const [receiver, setReceiver] = useState(null)
  const [selectedFlowList, setSelectedFlowList] = useState(null)
  const [flowList, setFlowList] = useState([])
  const [collapseIVRFlow, setCollapseIVRFlow] = React.useState(false);
  const [selectedFlowName, setSelectedFlowName] = useState('')
  const [selectedFlowIvrName, setSelectedFlowIvrName] = useState('')
  const [selectIVRName, setSelectIVRName] = useState('');
  const [selectIVR, setSelectIVR] = useState(null);
  const [collapseNumber, setCollapseNumber] = React.useState(false);
  const [collapseNumber2, setCollapseNumber2] = React.useState(false);
  const [typeofnum, setTypeofnum] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [selectedTags, setSelectedTags] = useState([])
  const [allTags, setAllTags] = useState([])
  const [callStatus, setCallStatus] = useState([])
  const [dataLoading, setDataLoading] = useState(false)
  const [clearLoading, setClearLoading] = useState(false)
  const [stateStatus, setStateStatus] = React.useState({
    completed: false,
    noanswer: false,
  });
  const handleStatusChange = (event) => {
    setStateStatus({
      ...stateStatus,
      [event.target.name]: event.target.checked,
    });
  };
  const { completed, noanswer } = stateStatus;
  const [statusType, setstatusType] = useState('open')
  const [status, setStatus] = React.useState({
    open: false,
    closed: false,
  });
  const handleStatus = (event) => {
    setStatus({
      ...status,
      [event.target.name]: event.target.checked,
    });

  };
  const { open, closed } = status;
  const [callBoundType, setCallBoundType] = useState('inbound')
  const [callType, setCallType] = React.useState({
    inbound: false,
    outbound: false,
  });


  const handleCallType = (event) => {
    setCallType({
      ...callType,
      [event.target.name]: event.target.checked,
    });

  };
  const { inbound, outbound } = callType;

  useEffect(() => {
    if (callType.inbound && !callType.outbound) {
      setCallBoundType('inbound')
      console.log('inbound')

    } else
      if (!callType.inbound && callType.outbound) {
        setCallBoundType('outbound-api')
        console.log('out bound')

      } else
        if (callType.inbound && callType.outbound) {
          setCallBoundType('')

        } else
          if (!callType.inbound && !callType.outbound) {
            setCallBoundType('')

          }

  }, [callType])


  useEffect(() => {
    if (stateStatus.completed && !stateStatus.noanswer) {
      setCallStatus(['completed'])

    } else
      if (!stateStatus.completed && stateStatus.noanswer) {
        setCallStatus(['no-answer'])

      } else
        if (!stateStatus.completed && !stateStatus.noanswer) {
          setCallStatus([''])

        } else
          if (stateStatus.completed && stateStatus.noanswer) {

            setCallStatus(['completed,no-answer'])


          }
  }, [stateStatus])



  useEffect(() => {
    if (status.open && !status.closed) {
      setstatusType('open')
    } else
      if (!status.open && status.closed) {
        setstatusType('closed')
      } else
        if (!status.open && !status.closed || status.open && status.closed) {
          setstatusType('')
        }
  }, [status])


  const { isLoadingFilter, isLoading } = useSelector(state => state.inboxVoice)

  useEffect(() => {
    if (isLoadingFilter) {
      setDataLoading(true)
    } else {
      setDataLoading(false)
      setOpenDrawer(false)

    }
  }, [isLoadingFilter])





  const [tagLocal, setTagLocal] = useState([])
  const [tagList, setTagList] = useState([])
  const [clearTags, setClearTags] = useState(false)

  // hacking
  const [state, setState] = useState({});


  useEffect(() => {
    console.log('allTags lenght', allTags.length)
    if (allTags.length > 0) {

      setTagLocal([])
      //let mutableJSON = JSON.parse(JSON.stringify(allTags));
      allTags.map((item) => {
        tagLocal.push({ name: item.name, backgroundColor: item.backgroundColor, checked: false })
      })

      setTagList(tagLocal)

    }

    //   console.log('allTags tagLocal', tagLocal)



  }, [allTags, clearTags])

  // useEffect(() => {
  //   localStorage.setItem('inboxVoiceFilterTags', JSON.stringify(tagLocal))
  // }, [tagLocal])


  // useEffect(() => {
  //   if(JSON.parse(inboxVoiceFilterTags).length > 0) {
  //     setTagList(JSON.parse(inboxVoiceFilterTags))
  //   }

  //   console.log('inboxVoiceFilterTags', JSON.parse(inboxVoiceFilterTags))
  // }, [inboxVoiceFilterTags])






  const handleTags = (event, index) => {


    tagList[index].checked = event.target.checked

    //setTagLocal(tagLocal)



    // localStorage.setItem('inboxVoiceFilterTags', JSON.stringify(tagList))
    // setTagList(tagList)
    let find = selectedTags.findIndex(item => item === event.target.value)

    if (find > -1) {
      selectedTags.splice(find, 1)
    } else {
      selectedTags.push(event.target.value)
    }

    console.log('handleTags', event.target.value, index, tagList, selectedTags)

    // hackline
    setState({ ...state, [event.target.name]: event.target.checked });


  }



  const handleCloseFilter = () => {
    setOpenDrawer(false)
    setClearLoading(false)
  }

  const handleOpenFilter = () => {
    setOpenDrawer(true)
    setClearLoading(false)
    fetchAllCloudNumber()
    fetchIvrFlow()
    fetchUserDetails()
  }


  // change time formate
  const changeTimeFormat = () => {
    setTimeFormat(!timeFormat)

    localStorage.setItem('voiceTimeFormate', !timeFormat)
  }

  const hendlChangeIvrFlow = (event) => {

    let name = event.target.value
    setSelectedFlowList(event.target.value)
    setSelectedFlowName(name.name)
    if (event.target.value) {
      setCollapseIVRFlow(true)
    } else {
      setCollapseIVRFlow(false)
    }
  }


  const hendlChangeSelectIVR = (event) => {

    setSelectIVR(event.target.value.id)
    setSelectIVRName(event.target.value)
    setSelectedFlowIvrName(event.target.value.data.ivrName)
    if (event.target.value) {
      setCollapseNumber(true)
    } else {
      setCollapseNumber(false)
    }
  };


  const hendlChangeNumber = (event) => {
    setTypeofnum(event.target.value)
    if (event.target.value) {
      setCollapseNumber2(true)
    } else {
      setCollapseNumber2(false)
    }
  };



  const fetchAllCloudNumber = () => {
    axiosInstance.post(`/getavailablenumbers`)
      .then((res) => {
        setCloudNumber(res.data.data.data)

      })
      .catch((err) => {
        console.log("err ", err)
      })
  }

  const fetchIvrFlow = () => {
    axiosInstance.get(`/ivrFlow233323`)
      .then((res) => {
        setFlowList(res.data.data)

      })
      .catch((err) => {
        console.log("err ", err)
      })
  }

  const fetchUserDetails = async () => {
    axiosInstance.get('/users')
      .then((res) => {

        setAllTags(res.data.data[0].Tags)
      })
      .catch((err) => {
        console.log("err ", err)
      })
  }


  let ivrFlowname = selectedFlowList?.input.filter(item => item.type === "ivrNode");
  let ivrFlownum = selectedFlowList?.input.filter(item => item.source === selectIVR);

  const handleChangenum = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleCloudNumber = (event) => {
    setSelectedCloudNumber(event.target.value)
    console.log('handleCloudNumber', event.target.value)
    if (event.target.value === 'all') {
      setCloudNumberArray('')
    } else {
      setCloudNumberArray(event.target.value)
    }



  }





  const clearFilter = () => {
    dispatch(getIvrVoice(0));
    // 

    setStartDate('')
    setEndDate('')
    setSelectedCloudNumber('')
    setNumbersFilter('')
    setReceiver('')
    status.open = false
    status.closed = false
    callType.inbound = false
    callType.outbound = false
    stateStatus.completed = false
    stateStatus.noanswer = false
    setSelectIVRName('')
    setSelectedFlowList(null)
    setClearTags(!clearTags)
    setSelectedTags([])
    setCloudNumberArray('')
    setDataLoading(false)


  }

  const paramsData = { flowName: selectedFlowName, ivrName: selectedFlowIvrName, digit: selectedValue, callStatus: callStatus, caller: numbersFilter === null ? '' : numbersFilter, reciever: receiver === null ? '' : receiver, number: cloudNumberArray === undefined ? '' : cloudNumberArray, startDate: startDate, endDate: endDate, tags: selectedTags.lenght === 0 ? '' : JSON.stringify(selectedTags), CallType: callBoundType, documentStatus: statusType, offsetCount: 0, limit: 10 }

  const applyFilter = () => {
    // localStorage.setItem('voicefiltertag', JSON.stringify(selectedTags))
    
    console.log('applyFilter', createSearchParams(paramsData))
    setDataLoading(true)

    navigate({
      pathname: '/dashboard/inbox/voice/filter',
      search: `?${createSearchParams(paramsData)}`,
    });

    //  navigate('/dashboard/inbox/voice/filter');
    //  localStorage.setItem('filterInboxVoiceData', JSON.stringify(paramsData))

    // let data= `flowName=${selectedFlowName}&ivrName=${selectedFlowIvrName}&digit=${selectedValue}&callStatus=${callStatus}&caller=${numbersFilter}&reciever=${receiver}&number=["${cloudNumberArray}"]&startDate=${startDate}&endDate=${endDate}&tags=${selectedTags}&CallType=${callBoundType}&documentStatus=${statusType}`
    // navigate(`/dashboard/inbox/filter(/${selectedFlowName})(/${selectedFlowIvrName})(/${selectedValue})(/${callStatus})(/${numbersFilter})(/${receiver})(/${cloudNumberArray})(/${startDate})(/${endDate})(/${selectedTags})(/${callBoundType})(/${statusType})`)
    // navigate(`/dashboard/inbox/filter?flowName=${selectedFlowName}&ivrName=${selectedFlowIvrName}&digit=${selectedValue}&callStatus=${callStatus}&caller=${numbersFilter}&reciever=${receiver}&number=["${cloudNumberArray}"]&startDate=${startDate}&endDate=${endDate}&tags=${selectedTags}&CallType=${callBoundType}&documentStatus=${statusType}`)
    //
  }



  return (
    <>
      {width < 500 ? <>

        <Button
          variant="contained"
          startIcon={<Iconify icon={'cil:filter'} />}
          onClick={handleOpenFilter}
        >

        </Button>
      </> : <>
        <Button
          variant="contained"
          startIcon={<Iconify icon={'cil:filter'} />}
          onClick={handleOpenFilter}
        >
          Filters
        </Button>
      </>}




      <Drawer anchor="right" open={openDrawer} onClose={handleCloseFilter} >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
          <Box>
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
              Filters
            </Typography>
            <Typography variant="caption" sx={{ flexGrow: 1 }}></Typography>
          </Box>
          <IconButton onClick={handleCloseFilter}>
            <Iconify icon={'eva:close-fill'} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />

        <div style={{ maxWidth: '600px', padding: '30px 30px' }}>




          <FormControl>
            <FormLabel component='legend'>Call Type</FormLabel>

            <FormGroup row>
              <FormControlLabel onChange={handleCallType} control={<Checkbox checked={inbound} name='inbound' />} label="Inbound" />
              <FormControlLabel onChange={handleCallType} control={<Checkbox checked={outbound} name='outbound' />} label="Outbound" />
            </FormGroup>



          </FormControl>


          <Typography variant="subtitle1" style={{ margin: "30px 0 0px 0px" }} >Select Date and Time</Typography>

          <Grid container direction="row" spacing={2} style={{ marginTop: "0px", textAlign: "center", }}>
            <Grid item xs={5}>
              <TextField id="outlined-basic" fullWidth type="datetime-local" size="small" variant="outlined" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </Grid>
            <Grid item xs={2} style={{ marginTop: "10px" }} >
              <Typography >To</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField id="outlined-basic" fullWidth type="datetime-local" size="small" variant="outlined" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </Grid>
          </Grid>



          <Typography variant="subtitle1" style={{ margin: "30px 0 0px 0px" }}>Advance Filter</Typography>





          <Grid container spacing={2}>

            <Grid item xs={12}>

              <List className='inbox-filter'>

                <ListItem
                  secondaryAction={
                    <FormControlLabel
                      style={{ width: "120px" }}
                      control={<Switch checked={timeFormat} onChange={changeTimeFormat} name="checkedA" />}
                      label={timeFormat === true ? 'Standard' : 'Relative'}
                    />
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Iconify icon={'uiw:date'} width={20} height={20} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Change Time Format"
                  />
                </ListItem>

              </List>


            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="reports">Select your number</InputLabel>
                <Select
                  labelId="reports"
                  id="demo-simple-select-outlined"
                  label="Select your number"
                  size="small"
                  fullWidth

                  value={selectedCloudNumber}
                  onChange={handleCloudNumber}
                >
                  <MenuItem value='all'>All</MenuItem>
                  {
                    cloudNumber?.map((number) => {
                      return (
                        <MenuItem value={number.phone_number}>{number.phone_number}</MenuItem>
                      )
                    })
                  }
                  <MenuItem ></MenuItem>

                </Select>
              </FormControl>
            </Grid>

            <Grid sm={6} item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Caller"
                value={numbersFilter}
                onChange={(event) => setNumbersFilter(event.target.value)}
                variant="outlined"
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControl variant="outlined" size="small" fullWidth >

                <TextField
                  label="Reciever"
                  size="small"
                  fullWidth
                  value={receiver}
                  onChange={(event) => setReceiver(event.target.value)}
                  variant="outlined"
                />

              </FormControl>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormControl variant="outlined" size="small" fullWidth >
                <InputLabel id="reports">Flow</InputLabel>
                <Select
                  labelId="reports"
                  id="demo-simple-select-outlined"
                  size="small"
                  fullWidth
                  label="Flow"
                  value={selectedFlowList}

                  onChange={hendlChangeIvrFlow}
                >
                  <MenuItem ></MenuItem>
                  {flowList.map((item) => (
                    <MenuItem value={item} >{item.name}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>


            {collapseIVRFlow && (
              <Grid item xs={6}>
                <FormControl variant="outlined" size="small" fullWidth >
                  <InputLabel id="reports">Select IVR</InputLabel>
                  <Select
                    labelId="reports"
                    id="demo-simple-select-outlined"
                    size="small"
                    fullWidth
                    label="Select IVR"
                    value={selectIVRName}
                    onChange={hendlChangeSelectIVR}
                  >
                    {ivrFlowname?.map((item) => (
                      <MenuItem value={item} >{item.data.ivrName}</MenuItem>
                    ))}

                    <MenuItem ></MenuItem>

                  </Select>
                </FormControl>
              </Grid>
            )}

            {collapseNumber && (
              <Grid item xs={6}>
                <FormControl variant="outlined" size="small" fullWidth >
                  <InputLabel id="reports">Number</InputLabel>
                  <Select
                    labelId="reports"
                    id="demo-simple-select-outlined"
                    size="small"
                    fullWidth
                    label="Number"
                    value={typeofnum}
                    onChange={hendlChangeNumber}
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="Numbers">Numbers</MenuItem>

                    <MenuItem ></MenuItem>

                  </Select>
                </FormControl>
              </Grid>
            )}

            {collapseNumber2 && (
              <Grid item xs={12} >
                <FormControl component="fieldset">
                  <FormLabel component="legend">Number</FormLabel>
                  <RadioGroup row aria-label="position" name="position" defaultValue="top" value={selectedValue} onChange={handleChangenum}>
                    {ivrFlownum?.map((item) => (
                      <FormControlLabel
                        value={item.sourceHandle}
                        control={<Radio color="primary" />}
                        label={item.sourceHandle}
                        labelPlacement="end"
                      />

                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}



            <Grid item xs={12}>
              <FormControl>
                <FormLabel component='legend'>Tags</FormLabel>
                <FormGroup
                  color='primary'
                  row
                  defaultValue="completed"

                >
                  {
                    tagList?.map((tag, index) => {
                      return (
                        <>
                          <FormControlLabel value={tag.name} onChange={(event) => handleTags(event, index)} control={<Checkbox color='primary' checked={tag.checked} />} label={tag.name} />

                        </>
                      )
                    })
                  }
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>

              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">Call Status</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    onChange={handleStatusChange}
                    control={
                      <Checkbox checked={completed} name="completed" />
                    }
                    label="Completed"
                  />

                  <FormControlLabel
                    onChange={handleStatusChange}
                    control={
                      <Checkbox checked={noanswer} name="noanswer" />
                    }
                    label="Missed"
                  />

                </FormGroup>

              </FormControl>



            </Grid>

            <Grid item xs={12}>

              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">Status</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    onChange={handleStatus}
                    control={
                      <Checkbox checked={open} name="open" />
                    }
                    label="Open"
                  />
                  <FormControlLabel
                    onChange={handleStatus}
                    control={
                      <Checkbox checked={closed} name="closed" />
                    }
                    label="Closed"
                  />

                </FormGroup>

              </FormControl>



            </Grid>

            <Grid item xs={12}>

              <LoadingButton style={{ marginRight: "10px" }} variant="contained" color="primary" loading={dataLoading} onClick={applyFilter}>
                {dataLoading ? 'Loading...' : 'Update'}

              </LoadingButton>

              <LoadingButton variant="outlined" color="primary" loading={clearLoading} onClick={clearFilter}>
                Clear Filter
              </LoadingButton>
            </Grid>







          </Grid>
        </div>

      </Drawer>


    </>
  )
}