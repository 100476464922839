import { FormControl, Grid, InputAdornment, TextField, FormLabel, FormHelperText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { incrementauthid } from 'src/redux/Authidslice';
import { incrementChannelinbox } from 'src/redux/CreateChannelInbox'
import { incrementshowsnackbaroopsslice } from 'src/redux/showsnackoops';
import { incrementshowsnackbarslice } from 'src/redux/snackbarslice';

import { incrementiconpicker } from 'src/redux/Iconpickerimg'

import SvgIconStyle from '../SvgIconStyle';
import IconPicker from './../IconPicker/Index'
// import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
// import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
// import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
// import Iconpicker from './Iconpicker';
const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: getIcon('ic_banking'),
    booking: getIcon('ic_booking'),
    invoice: getIcon('ic_invoice'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    menuItem: getIcon('ic_menu_item'),
    number: getIcon('ic_number'),
    contact: getIcon('ic_contact'),
    voicemail: getIcon('ic_voicemail'),
    voice: getIcon('ic_voice'),
    whatsapp: getIcon('ic_whatsapp'),
    sms: getIcon('ic_sms'),
    ivrstudio: getIcon('ic_ivrstudio'),
    tickets: getIcon('ic_tickets'),
    livesupport: getIcon('ic_livesupport'),
    helpdocs: getIcon('ic_helpdocs'),
    feedback: getIcon('ic_feedback')
};


const CreateInbox = ({inboxNameRequired, setInboxNameRequired, setInboxName, inboxName, setInboxIcon, inboxIcon}) => {
    const dispatch = useDispatch()

    const reduxIcon = useSelector(state => state.iconpickerslice.value)
    const authid = localStorage.getItem('authID')
    const [name, setname] = useState('')
    const [icon, setIcon] = useState('archive-fill')
    const handlename = (value) => {
        setInboxName(value.target.value)
        localStorage.setItem('onboardingname', value.target.value)
        dispatch(incrementChannelinbox(value.target.value))
        if(value.target.value === ''){
            setInboxNameRequired(true)
        }else{
            setInboxNameRequired(false)
        }
        
    }

    useEffect(() => {
      if(reduxIcon === ''){
        dispatch(incrementiconpicker('archive-fill'))
      }
    }, [reduxIcon, dispatch])
    

    useEffect(() => {
        console.log('setInboxIcon', icon)
        setInboxIcon(icon)
        dispatch(incrementiconpicker(icon))
              
    }, [icon])

    useEffect(() => {
      if(icon !== ''  && reduxIcon !== icon && reduxIcon !== icon){
        setIcon(reduxIcon)
       
      }
    }, [reduxIcon])
    
    



    const objurl = [
        'https://vibtree.com/appfiles/assets/icons/navbar/ic_blog.svg',
        'https://vibtree.com/appfiles/assets/icons/navbar/ic_cart.svg',
        'https://vibtree.com/appfiles/assets/icons/navbar/ic_chat.svg',
        'https://vibtree.com/appfiles/assets/icons/navbar/ic_mail.svg',
        'https://vibtree.com/appfiles/assets/icons/navbar/ic_user.svg',
        'https://vibtree.com/appfiles/assets/icons/navbar/ic_kanban.svg',
        'https://vibtree.com/appfiles/assets/icons/navbar/ic_banking.svg',
        'https://vibtree.com/appfiles/assets/icons/navbar/ic_booking.svg',
        'https://vibtree.com/appfiles/assets/icons/navbar/ic_invoice.svg',
        'https://vibtree.com/appfiles/assets/icons/navbar/ic_calendar.svg',
        'https://vibtree.com/appfiles/assets/icons/navbar/ic_analytics.svg',

    ]
    useEffect(() => {
        dispatch(incrementauthid(authid))

    }, [])
    useEffect(() => {
        dispatch(incrementshowsnackbarslice(false))

    }, [])
    useEffect(() => {
        dispatch(incrementshowsnackbaroopsslice(false))

    }, [])

    return (
        <div>
            <Grid container style={{marginTop: '50px'}}>
                <Grid item xs></Grid>
                <Grid item md={6} xs={12}>


                    <FormControl fullWidth>
                    <FormLabel>Inbox name</FormLabel>
                    <TextField
                        name='Inbox name'
               
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconPicker setIcon={setIcon} icon={icon} />
                            </InputAdornment>,
                          }}
                        value={inboxName}
                        onChange={handlename}
                    />
                    {inboxNameRequired && (
                        <FormHelperText error>Inbox Name Required</FormHelperText>
                    )}
                    </FormControl>
                </Grid>
                <Grid item xs></Grid>
               

            </Grid>

        </div >
    )
}

export default CreateInbox