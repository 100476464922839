// hooks
import { useEffect, useState } from 'react';
import axiosInstance, { baseUrl } from 'src/utils/axios';
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const [imageurl, setimageurl] = useState('')
  const getdata = async () => {

    const res = await axiosInstance.get(`${baseUrl}/getAuth`)
    console.log('getauth', res.data.data)

    localStorage.setItem('FirstName', res.data.data.FirstName)
    localStorage.setItem('LastName', res.data.data.LastName)
    localStorage.setItem('email', res.data.data.email)
    localStorage.setItem('phone', res.data.data.phone)
    localStorage.setItem('country', res.data.data.country_allowed[0].code)
    localStorage.setItem('_id', res.data.data._id)
    localStorage.setItem('user_logo', res.data.data.user_logo)
    setimageurl(res.data.data.user_logo)

    //  .then((res) => {
    //       console.log(res.data.data)

    //       setfirstname(res.data.data.FirstName)
    //       setlastname(res.data.data.LastName)
    //       setemail(res.data.data.email)
    //       setphonenumber(res.data.data.phone)
    //       localStorage.setItem('FirstName', res.data.data.FirstName)
    //       localStorage.setItem('LastName', res.data.data.LastName)
    //       localStorage.setItem('email', res.data.data.email)
    //       localStorage.setItem('phone', res.data.data.phone)
    //       localStorage.setItem('country', res.data.data.country_allowed[0].code)
    //       localStorage.setItem('_id', res.data.data._id)

    //     }).catch((err) => {
    //       console.log(err)
    //     })

  }
  useEffect(() => {
    getdata()
  }, [])
  console.log('imageurl', imageurl)
  const imagedp = localStorage.getItem('user_logo')
  // const url = "https://dev.vibtree.com/api/download/user_logo?fileLocation=" + imagedp
  const url = `${baseUrl}/download/user_logo?fileLocation=` + imageurl
  return (
    <Avatar
      src={url}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
}
