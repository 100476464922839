import React, { useState, memo, useEffect } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import './style.css'
import { useLocation, useNavigate, useParams, createSearchParams } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Slide, Popper, TextField, InputAdornment, ClickAwayListener, IconButton, MenuItem, Grid } from '@mui/material';
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';


import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { getIvrVoiceSearch, getIvrVoice } from '../../../redux/slices/Inbox/voice';

//hooks
import useResponsive from '../../../hooks/useResponsive';

// utils
import cssStyles from '../../../utils/cssStyles';
import flattenArray from '../../../utils/flattenArray';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import SearchNotFound from '../../../components/SearchNotFound';
//
import NavConfig from '../navbar/NavConfig';
import { setSelectedTimeStamp } from '../../../redux/slices/dashboard/dashboardCallDurationStats'
import { SearchCalls } from 'src/redux/slices/contacts/contacts';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const autocompleteStyle = {
  width: 1,
  height: 1,
  '& .MuiFormControl-root, .MuiOutlinedInput-root, .MuiAutocomplete-input': {
    height: 1,
    p: '0 !important',
    fontWeight: 'fontWeightBold',
    '& fieldset': { display: 'none' },
  },
};

const PopperStyle = styled((props) => <Popper {...props} />)(({ theme }) => ({
  left: `8px !important`,
  top: `${APPBAR_MOBILE + 8}px !important`,
  width: 'calc(100% - 16px) !important',
  transform: 'none !important',
  [theme.breakpoints.up('md')]: {
    top: `${APPBAR_DESKTOP + 8}px !important`,
  },
  '& .MuiAutocomplete-paper': {
    padding: theme.spacing(1, 0),
  },
  '& .MuiListSubheader-root': {
    '&.MuiAutocomplete-groupLabel': {
      ...cssStyles(theme).bgBlur({ color: theme.palette.background.neutral }),
      ...theme.typography.overline,
      top: 0,
      margin: 0,
      lineHeight: '48px',
      borderRadius: theme.shape.borderRadius,
    },
  },
  '& .MuiAutocomplete-listbox': {
    '& .MuiAutocomplete-option': {
      padding: theme.spacing(0.5, 2),
      margin: 0,
      display: 'block',
      border: `dashed 1px transparent`,
      borderBottom: `dashed 1px ${theme.palette.divider}`,
      '&:hover': {
        border: `dashed 1px ${theme.palette.primary.main}`,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
      },
    },
  },
}));

// ----------------------------------------------------------------------

function Searchbar() {
  const getLocation = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const getParams = useParams();
  const [searchData, setSearchData] = useState('');

  const [showFileterdData, setShowFileterdData] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [open, setOpen] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [offsetCount, setOffsetCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [week, setWeek] = useState('');
  const [dateValue, setDateValue] = React.useState([null, null]);

  const [searchQuery, setSearchQuery] = useState('');
  let voiceSearch = pathname.includes("/inbox/voice");
  let managecontact = pathname.includes("/contacts/manage");
  let voicedatavoice = pathname.includes("/inbox/voice/");
  let voicedatafiltervoice = pathname.includes("inbox/voice/filter");


  const reduceItems = NavConfig.map((list) => handleLoop(list.items, list.subheader)).flat();

  const [dashFilter, setdashFilter] = useState(false)

  const allItems = flattenArray(reduceItems).map((option) => {
    const group = splitPath(reduceItems, option.path);

    return {
      group: group && group.length > 1 ? group[0] : option.subheader,
      title: option.title,
      path: option.path,
    };
  });

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
    console.log('pathname', pathname)

    let pathCheck = pathname.includes("inbox/voice/search");



    if (pathCheck) {
      setOpen(true);
      setSearchData(getParams.number)
    }
    if (pathname === "/dashboard/matrix") {
      setShowSearchIcon(false)
      setdashFilter(true)
    } else {
      setShowSearchIcon(true)
      setdashFilter(false)
    }


    if (pathname === '/dashboard/contacts/manage/search') {

      console.log('getParams', getParams.offsetCount, getParams.limit)

      if (getParams.offsetCount !== undefined) {
        setOffsetCount(getParams.offsetCount)
      } else {
        setOffsetCount(0)
      }

      if (getParams.limit !== undefined) {
        setRowsPerPage(getParams.limit)
      } else {
        setRowsPerPage(10)
      }



      if (getParams.data !== undefined) {
        setPhoneNumber(getParams.data)
      } else {
        setPhoneNumber('')
      }

    }


  }, [pathname, getParams]);

  const handleClick = (path) => {
    if (path.includes('http')) {
      window.open(path);
    } else {
      navigate(path);
    }
    setOpen(false);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleClick(searchQuery);
    }
  };

  const handleSearchValue = (value) => {

    setSearchData(value)
  }



  const handleSearchNavigate = () => {
    if (voicedatavoice) {
      navigate(`/dashboard/inbox/voice/filter?flowName=&ivrName=&digit=&callStatus=&caller=${searchData}&reciever=&number=%5B"${getParams.number}"%5D&startDate=&endDate=&tags=%5B%5D&CallType=&documentStatus=&offsetCount=0&limit=10`)
      console.log("getparam number", getParams)
    }
    else if (voicedatafiltervoice) {
      navigate(`/dashboard/inbox/voice/filter?flowName=&ivrName=&digit=&callStatus=&caller=${searchData}&reciever=&number=%5B"${getParams.number}"%5D&startDate=&endDate=&tags=%5B%5D&CallType=&documentStatus=&offsetCount=0&limit=10`)
      console.log("getparam number", getParams.number)
    }
    else {

      console.log("hello")
      navigate(`/dashboard/inbox/voice/search/${searchData}`)
    }
  }
  const handleContactSearchNavigate = () => {
    // navigate(`/dashboard/inbox/voice/search/${searchData}`)
    // navigate(`/dashboard/contacts/manage/${searchData}`)
    const paramsData = { offsetCount: offsetCount, limit: rowsPerPage, data: phoneNumber }

    navigate({
      pathname: '/dashboard/contacts/manage/search',
      search: `?${createSearchParams(paramsData)}`,
    });



  }
  const handleSearchClear = () => {
    navigate(`/dashboard/inbox/voice`)
  }
  const handlecontactSearchClear = () => {
    navigate(`/dashboard/contacts/manage?offsetCount=0&limit=10`)
  }
  const handleSelectTimeStampInDashBoard = (event) => {

    let tempObj = { timeStamp: event.target.value, startDate: '', endDate: '' }
    setWeek(tempObj.timeStamp)
    dispatch(setSelectedTimeStamp(tempObj))
  }

  return (





    <>



      <ClickAwayListener onClickAway={() => setOpen(false)}>


        <div style={{ width: "100%" }}>

          {showSearchIcon && (
            <>
              {!open && (
                <IconButtonAnimate onClick={() => setOpen((prev) => !prev)}>
                  <Iconify icon={'eva:search-fill'} width={20} height={20} />
                </IconButtonAnimate>
              )}
            </>
          )}





          <Slide direction="down" in={open} mountOnEnter unmountOnExit>
            <SearchbarStyle>


              {/* {showSearchIcon === false && (
          <>

            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  label='Numbers'
                  style={{ width: "150px" }}
                  size='small'
                  value={numbers}
                  onChange={(event) => setNumbers(event.target.value)}
                  select>
                  <MenuItem value=''>-select-</MenuItem>
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  label='Week'
                  style={{ width: "150px" }}
                  size='small'
                  value={week}
                  // onChange={(event) => setWeek(event.target.value)}
                  onChange={handleSelectTimeStampInDashBoard}
                  select>
                  <MenuItem value='This Week'>This Week</MenuItem>
                  <MenuItem value='Last Week'>Last Week</MenuItem>
                  <MenuItem value='This Month'>This Month</MenuItem>
                  <MenuItem value='Last Month'>Last Month</MenuItem>
                  <MenuItem value='custom'>Custom</MenuItem>
                </TextField>
              </Grid>
              {week === 'custom' ? (
                <Grid item xs>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{ start: 'From', end: 'To' }}
                  >
                    <DateRangePicker
                      value={dateValue}
                      onChange={(newValue) => {
                        setDateValue(newValue);
                      }}
                      renderInput={(startProps, endProps) => (
                        <React.Fragment>
                          <TextField {...startProps} style={{ width: "150px" }} size='small' />
                          <Box sx={{ mx: 2, color: '#aaa' }}> to </Box>
                          <TextField {...endProps} style={{ width: "150px" }} size='small' />
                        </React.Fragment>
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              ) : ''}


            </Grid>

          </>
        )} */}
              {managecontact && (
                <>
                  <TextField

                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}

                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        // dispatch(SearchCalls(phoneNumber, firstName, lastName,  parseInt(pageOffSet, 10), rowsPerPage));
                        setShowFileterdData(true)
                        handleContactSearchNavigate()
                      }
                    }}
                    className='search-input'
                    id="outlined-basic"
                    label="Search Contacts"
                    fullWidth


                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          {!showFileterdData ?
                            <IconButton size="small"
                              onClick={() => {


                                // dispatch(SearchCalls(phoneNumber, 0));
                                // setShowFileterdData(true)
                                handleContactSearchNavigate()

                              }}
                            >
                              <Iconify icon={'eva:search-fill'} width={20} height={20} />
                            </IconButton>
                            :
                            <>
                              <IconButton size="small" onClick={() => {

                                // dispatch(SearchCalls(phoneNumber, 0, 10));
                                // setShowFileterdData(true)
                                handleContactSearchNavigate()

                              }}>
                                <Iconify icon={'eva:search-fill'} width={20} height={20} />
                              </IconButton>
                              <IconButton size="small" onClick={() => {

                                //  dispatch(SearchCalls(0));

                                // setShowFileterdData(false)
                                handlecontactSearchClear()
                                //  setPhoneNumber('');

                              }}>
                                <Iconify icon={'eva:close-fill'} width={20} height={20} />
                              </IconButton>
                            </>}
                        </InputAdornment>
                      )
                    }}
                  />


                </>

              )}

              {voiceSearch && (
                <TextField
                  value={searchData}
                  onChange={e => handleSearchValue(e.target.value)}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      dispatch(getIvrVoiceSearch(searchData, 0));
                      setShowFileterdData(true)
                      handleSearchNavigate()
                    }
                  }}
                  className='search-input'
                  id="outlined-basic"
                  label="Search Calls"
                  fullWidth


                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        {!showFileterdData ?
                          <IconButton size="small"
                            onClick={() => {

                              if (searchData.length > 0) {
                                dispatch(getIvrVoiceSearch(searchData, 0));
                                setShowFileterdData(true)
                                handleSearchNavigate()
                              }
                            }}
                          >
                            <Iconify icon={'eva:search-fill'} width={20} height={20} />
                          </IconButton>
                          :
                          <>
                            <IconButton size="small" onClick={() => {
                              if (searchData.length > 0) {
                                dispatch(getIvrVoiceSearch(searchData, 0, 10));
                                setShowFileterdData(true)
                                handleSearchNavigate()
                              }
                            }}>
                              <Iconify icon={'eva:search-fill'} width={20} height={20} />
                            </IconButton>
                            <IconButton size="small" onClick={() => {
                              if (searchData.length > 0) {
                                dispatch(getIvrVoice(0));
                                setSearchData('');
                                setShowFileterdData(false)
                                handleSearchClear()
                              }
                            }}>
                              <Iconify icon={'eva:close-fill'} width={20} height={20} />
                            </IconButton>
                          </>}
                      </InputAdornment>
                    )
                  }}
                />
              )}

            </SearchbarStyle>
          </Slide>
        </div>
      </ClickAwayListener>

    </>
  );
}

export default memo(Searchbar);

// ----------------------------------------------------------------------

function splitPath(array, key) {
  let stack = array.map((item) => ({
    path: [item.title],
    currItem: item,
  }));

  while (stack.length) {
    const { path, currItem } = stack.pop();

    if (currItem.path === key) {
      return path;
    }

    if (currItem.children?.length) {
      stack = stack.concat(
        currItem.children.map((item) => ({
          path: path.concat(item.title),
          currItem: item,
        }))
      );
    }
  }
  return null;
}

// ----------------------------------------------------------------------

function handleLoop(array, subheader) {
  return array?.map((list) => ({
    subheader,
    ...list,
    ...(list.children && {
      children: handleLoop(list.children, subheader),
    }),
  }));
}