import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import ImagePhoto from 'https://vibtree.com/appfiles/assets/vv1.png'
// import ImagePhoto2 from 'https://vibtree.com/appfiles/assets/vv2.png'
// import ImagePhoto3 from 'https://vibtree.com/appfiles/assets/vv3.png'
// import ImagePhoto4 from 'https://vibtree.com/appfiles/assets/vv4.png'
const dataSet = [
    'https://vibtree.com/appfiles/assets/vv3.png',
    'https://vibtree.com/appfiles/assets/vv4.png',
    'https://vibtree.com/appfiles/assets/vv2.png',
    'https://vibtree.com/appfiles/assets/vv1.png',
]

const CarouselSection = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div style={{ padding: "0px 0px", width: "95%" }}>


            <Carousel
                slidesToSlide={1}
                swipeable
                renderDotsOutside={false}
                showDots
                responsive={responsive}
                removeArrowOnDeviceType={["mobile", "desktop"]}
                autoPlay
                infinite
            >
                {
                    dataSet.map((banner, index) => (
                        <div style={{ width: "100%", height: "100%" }} key={index}>
                            <img style={{ width: "90%", height: "90%", cursor: "pointer" }} src={banner} alt="" />
                        </div>
                    ))
                }
            </Carousel>
        </div>
    )
}

export { CarouselSection }