import React, { useEffect, useState } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Skeleton, Snackbar, TextField, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useSelector } from 'react-redux';
import axiosInstance, { baseUrl } from 'src/utils/axios';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import validator from "validator";

import AssignRole from './AssignRole'

const Teammanagement = () => {
    const [TeamData, setTeamData] = useState([])
    const [loading, setLoading] = useState(false)
    // const [loading, setLoading] = useState(true)
    const { usersData } = useSelector(state => state.getCompany)
    console.log("usersData", usersData.data)
    const listingdata = () => {

        axiosInstance.get(`${baseUrl}/users_of_company`).then((res) => {
            console.log('listingdata', res.data.data)
            setTeamData(res.data.data || {})
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        listingdata()
    }, [])
    useEffect(() => {
        setTeamData(usersData.data || {})
    }, [usersData])


    const getAssignRole = (params) => {



        return (
            <>

                <AssignRole props={params} />



            </>
        )
    }



    const columns = [


        {
            field: 'fullName',
            headerName: 'Name',
            editable: true,
            flex: 1
        },
        // {
        //   field: 'number',
        //   headerName: 'Number',
        //   editable: false,
        //   flex: 1
        // },
        {
            field: 'email',
            headerName: 'email',
            editable: false,
            flex: 1
        },
        {
            field: 'status',
            headerName: 'status',
            editable: false,
            flex: 1
        },

        {
            field: "role",
            headerName: "Role",
            sortable: false,
            width: 250,
            renderCell: getAssignRole,
        }



    ];
    const LoadingSkeleton = () => (
        <>
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </>
    );

    const handleCellEditCommit = React.useCallback(
        ({ id, field, value }) => {

            const getId = TeamData.find(x => x.number === id)._id;
            // console.log('edited field', id, field, value, getId)
            // if(getId !== undefined){
            //     dispatch(editName(getId, value))
            // }
        },
        [TeamData],
    );
    const renderedNumbers = (
        <>
            <DataGridPro
                rows={TeamData}
                columns={columns}
                rowHeight={48}
                disableSelectionOnClick
                getRowId={(r) => r._id}
                style={{ height: '550px' }}

                initialState={{
                    pinnedColumns: {
                        left: [''], right: ['action']
                    },

                }}

                components={{
                    LoadingOverlay: LoadingSkeleton
                }}
                loading={loading}   // you need to set your boolean loading
                hideFooter
                onCellEditCommit={handleCellEditCommit}

            />
        </>
    )
    const [openDialog, setOpenDialog] = useState(false);
    const [emailExists, setEmailExists] = useState(true);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [open4, setOpen4] = useState(false);
    const authID = localStorage.getItem('authID')
    const authSecretId = localStorage.getItem('authSecretId')
    const companyID = localStorage.getItem('companyID')
    const company_name = localStorage.getItem('company_name')
    const phone = localStorage.getItem('phone')
    const handleClose4 = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen4(false);
    };

    const handleClick4 = () => {
        setOpen4(true);
    };

    const action4 = (
        <>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose4}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    const [isEmailValid, setEmailValidity] = useState(null);
    const emailCheck = () => {
        axiosInstance.get(`/email_exist?email_address=${email}`)
            .then(response => {
                console.log('email_exist', response.data.data)
                if (response.data.data === true) {
                    setEmailExists(false)
                } else if (response.data.data === false) {
                    setEmailExists(true)
                }
            })
            .catch(err => console.log("Error ", err))
    }


    useEffect(() => {
        return (
            emailCheck()
        )
    }, [email])

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const inviteobj = {
        confirmed: false,
        blocked: false,
        username: email,
        email: email,
        fullName: firstName + " " + lastName,
        phone: phone,
        provider: "",
        createdAt: 1639062170610,
        updatedAt: 1639062170610,
        company: "",
        role: "user",
        account_type: "ent",
        company_name: company_name,
        company_id: companyID,
        is_admin: false,
        auth_id: authID,
        auth_secret: authSecretId,
        FirstName: firstName,
        LastName: lastName
    }

    const sendInvite = () => {

        axiosInstance.post(`${baseUrl}/invite/user`, inviteobj).then((res) => {
            console.log("invite user ", res)
            handleClick4()
            handleCloseDialog()
        }).catch((err) => {
            console.log(err)
        })
    }



    return (
        <div style={{ marginTop: "3%" }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginRight: "3%" }}>
                <h1></h1>
                <Button variant="contained" color="primary"
                    onClick={handleOpenDialog}
                // style={{ display: "flex", position: "relative", left: "90%", marginBottom: "10px" }}
                >
                    Invite Users
                </Button>
            </div>
            <div style={{ marginLeft: "3%", marginRight: "3%" }}>
                {renderedNumbers}
            </div>


            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
                maxWidth="sm"
            >
                <DialogTitle id="responsive-dialog-title">{"Invite User"}</DialogTitle>
                <DialogContent>
                    <Grid container direction="row" spacing={2} style={{ width: "300px",   marginTop: '10px' }}>
                        <Grid item xs={12}>
                            <TextField
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                // InputProps={{
                                //     startAdornment: (
                                //         <InputAdornment position="start">
                                //             <PermIdentityOutlinedIcon />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                id="outlined-basic"
                                label="First Name" variant="outlined"
                                fullWidth
                            />


                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                // InputProps={{
                                //     startAdornment: (
                                //         <InputAdornment position="start">
                                //             <PermIdentityOutlinedIcon />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                id="outlined-basic"
                                label="Last Name" variant="outlined"
                                fullWidth
                            />
                        </Grid>




                        <Grid item xs={12}>
                            <TextField
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailValidity(validator.isEmail(e.target.value));
                                }}

                                // InputProps={{
                                //     startAdornment: (
                                //         <InputAdornment position="start">
                                //             <EmailOutlinedIcon />
                                //         </InputAdornment>
                                //     ),
                                //     endAdornment: (
                                //         <InputAdornment>
                                //             {(() => {
                                //                 if (isEmailValid === true && emailExists === true) {
                                //                     return <DoneIcon />;
                                //                 }
                                //                 return <CloseIcon />;
                                //             })()}
                                //         </InputAdornment>
                                //     ),
                                // }}

                                id="outlined-basic"
                                label="Enter Email Here.." variant="outlined"
                                fullWidth
                            />

                            {emailExists === false ? (
                                <Typography
                                    style={{
                                        fontWeight: "300",
                                        marginTop: ".3rem",
                                        fontSize: ".8rem",
                                        color: "red",
                                    }}
                                    variant="body2"
                                >
                                    Email ID already Registered
                                </Typography>
                            ) : ''}

                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "right" }}>
                            <Button autoFocus onClick={handleCloseDialog} color="primary">
                                Cancel
                            </Button>
                            <Button color="primary" variant="contained" autoFocus

                                // onClick={() => {
                                //     dispatch(createUserInCompany(email))
                                //     setOpenDialog(false);
                                // }}

                                disabled={
                                    !(isEmailValid
                                        && emailExists)  || firstName === '' || lastName === ''
                                }

                                onClick={sendInvite}
                            >
                                Send Invitation
                            </Button>
                        </Grid>
                    </Grid>


                </DialogContent>



            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: "right"
                }}
                open={open4}
                autoHideDuration={3000}
                onClose={handleClose4}
                message="verification mail send successfully "
                action={action4}

            />


        </div >
    )
}

export default Teammanagement