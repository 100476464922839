import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react'
import { Link as RouterLink, useNavigate, useParams, useSearchParams, useLocation, createSearchParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Button, FormControlLabel, Switch, Grid, Tab, Tabs } from '@mui/material';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { getIvrVoice, refreshVoiceData, getfilterShowExport } from '../../../redux/slices/Inbox/voice';

// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import DashboardFilter from './DashboardFilter';

import ExportDrawer from '../../../components/InboxVoice/components/ExportDrawer'
import FilterDrawer from '../../../components/InboxVoice/components/FilterDrawer'

import CreateVoiceMail from 'src/components/VoiceMailBox/CreateVoiceMail';

import CreateGroup from '../../../components/ContactsGroups/CreateGroup'
import ManageContactFilter from 'src/pages/dashboard/ManageContactFilter';

import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// ----------------------------------------------------------------------





const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {

  

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {


  const params = useParams()

  const { height, width } = useWindowDimensions();
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const [searchParams, setSearchParams] = useSearchParams();
  const isDesktop = useResponsive('up', 'lg');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate()

  
    

     // permission
     const [userRolePermissions, setUserRolePermissions] = useState(
      {
        Workflow: {
            create: false,
            delete: false,
            edit: false,
            view: false
        },
        ContactsManage: {
          create: false,
          delete: false,
          edit: false,
          view: false
        },
        ContactsNotes: {
          create: false,
          delete: false,
          edit: false,
          view: false
        },
        ContactsGroups: {
          create: false,
          delete: false,
          edit: false,
          view: false
        },
        VoicemailBox: {
          create: false,
          delete: false,
          edit: false,
          view: false
        }
    }
    )
     const roleData = useSelector(state => state.userRole.roleData)
     let is_admin = localStorage.getItem('is_admin')

     useEffect(() => {
        
      if(roleData.status === true && roleData.status !== undefined){
        setUserRolePermissions(roleData.data[0].userPermission)
      }
    }, [roleData])
    
   
     // permission




  const [count, setCount] = useState(0);
  const [intervalId, setIntervalId] = useState(0);

  const [offsetCount, setOffsetCount] = useState(0)


  const [contactTabs, setContactTabs] = useState(0)



  let isInboxVoice = location.pathname.includes("inbox/voice");
  let isVoiceMailBox = location.pathname.includes("voicemail/voicemailbox");
  let isDashboard = location.pathname.includes("dashboard/matrix")
  let isManageContact = location.pathname.includes("dashboard/contacts/manage")
  let isAddContact = location.pathname.includes("dashboard/contacts/manage/new")
  let isEditContact = location.pathname.includes("dashboard/contacts/manage/account")
  let isContactGroup = location.pathname.includes("dashboard/contacts/group")
  let isprofilemanage = location.pathname.includes("dashboard/profile")
  let isInboxVoiceExport = location.pathname.includes("inbox/voice/export");




  const handleClick = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(0);
      console.log('trigger stop')
      return;
    }

    const newIntervalId = setInterval(() => {
      setCount(prevCount => prevCount + 1);

      //setFlag(!flag)
      dispatch(getIvrVoice(searchParams.get("offsetCount"), searchParams.get("limit")))

    }, 15000);
    setIntervalId(newIntervalId);
  };


  const refresh = () => {
    dispatch(refreshVoiceData())
  }

  const importContact = () => {
    navigate('/dashboard/contacts/import')
  }

  const addContact = () => {
    navigate('/dashboard/contacts/manage/new')
  }

  const navigateExport = () =>{
    navigate('/dashboard/inbox/voice/export')
  }

  const handleExportFilter = () =>{
    dispatch(getfilterShowExport())
  }

  const handleContactTabs = (event, newValue) =>{
    setContactTabs(newValue)
    console.log('contact tabs', newValue)
    if(newValue === 0){
      navigate(`/dashboard/contacts/manage/account/${params.id}/general`)
    }else
    if(newValue === 1){
      navigate(`/dashboard/contacts/manage/account/${params.id}/notes`)
    }else
    if(newValue === 2){
      navigate(`/dashboard/contacts/manage/account/${params.id}/conversations`)
    }
  }

  useEffect(() => {
    if(isEditContact){
      if(location.pathname.includes("/general")){
        setContactTabs(0)
      }else
      if(location.pathname.includes("/notes")){
        setContactTabs(1)
      }else
      if(location.pathname.includes("/conversations")){
        setContactTabs(2)
      }
    }
    
  }, [location])
  


  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout} className='header-appbar'>
      {/* <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      > */}
      {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

      {!(isDesktop || isManageContact || isInboxVoice) && (
        <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButtonAnimate>
      )}



 {isEditContact && (
                  <Tabs
                    value={contactTabs}
                    onChange={handleContactTabs}
                    aria-label="icon position tabs example"
                  >
                    <Tab icon={<Iconify icon={'ic:round-account-box'} width={20} height={20} />} iconPosition="start" label="General" />
                    <Tab icon={<Iconify icon={'uil:clipboard-notes'} width={20} height={20} />} iconPosition="start" label="Notes" />
                    <Tab icon={<Iconify icon={'bi:chat-dots'} width={20} height={20} />} iconPosition="start" label="Conversation" />
                    
                  </Tabs>
                ) }

      {isManageContact && (
        <Grid container >
          {width < 500 ? <>
            <Grid item xs style={{ display: "flex", justifyContent: "space-between", position: "relative", left: "-30px" }}>
              <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                <Iconify icon="eva:menu-2-fill" />
              </IconButtonAnimate>
              <div style={{ position: "absolute", right: "-60px" }}>
                {isAddContact || isEditContact ? '' : <Searchbar /> }

               
                
                
              </div>
            </Grid>
          </> : <>
            <Grid item xs >
              {isAddContact || isEditContact ? '' : <Searchbar /> }
                
            </Grid>
          </>}

          <div style={{ display: "flex", flexWrap: "nowrap" }}>

          {!isAddContact && !isEditContact && (

<>
            <Grid item style={{ display: "flex" }}>

            {is_admin === 'true' ? (
              <>
              {width < 500 ? <>
                <ManageContactFilter />
                <Button
                  variant="contained"
                  startIcon={<Iconify icon={'ant-design:user-add-outlined'} />}
                  onClick={addContact}
                  style={{ marginRight: '10px' }}
                >

                </Button>
              </> : <>

              
                <ManageContactFilter />
                <Button
                  variant="contained"
                  startIcon={<Iconify icon={'ant-design:user-add-outlined'} />}
                  onClick={addContact}
                  style={{ marginRight: '10px' }}
                >
                  Add Contact
                </Button>

                <Button
                    variant="outlined"
                    startIcon={<Iconify icon={'tabler:database-import'} />}
                    onClick={importContact}
                    style={{ marginRight: '10px' }}
                  >
                    Import Contacts

                  </Button>

              </>}

              </>
            ) : (

              <>

              {width < 500 ? <>
                {userRolePermissions.ContactsManage.view === true ? (
                  <ManageContactFilter />
                ) : ''}
                {userRolePermissions.ContactsManage.create === true ? (

                  <>
                  <Button
                    variant="contained"
                    startIcon={<Iconify icon={'ant-design:user-add-outlined'} />}
                    onClick={addContact}
                    style={{ marginRight: '10px' }}
                  >

                </Button>

<Button
variant="outlined"
startIcon={<Iconify icon={'tabler:database-import'} />}
onClick={importContact}
style={{ marginRight: '10px' }}
>

</Button>

</>

                ) : ''}
                
                
              </> : <>

              {userRolePermissions.ContactsManage.view === true ? (
                <ManageContactFilter />
              ) : ''}

              {userRolePermissions.ContactsManage.create === true ? (
                <>
                <Button
                  variant="contained"
                  startIcon={<Iconify icon={'ant-design:user-add-outlined'} />}
                  onClick={addContact}
                  style={{ marginRight: '10px' }}
                >
                  Add Contact
                </Button>

<Button
variant="outlined"
startIcon={<Iconify icon={'tabler:database-import'} />}
onClick={importContact}
style={{ marginRight: '10px' }}
>
  Import Contacts

</Button>
</>

              ) : ''}
              
                
                
              </>}



                
              </>
              
            )}


              
            </Grid>


            </>
            )}
            
          </div>

        </Grid>

      )}

      {isDashboard && (
        <>
          <div style={{ position: "absolute", top: "20px", right: "20px" }}>
            <DashboardFilter />
          </div>

        </>
      )}
      
      {isInboxVoiceExport && (

        <Grid container spacing={2} style={{ display: "flex", flexWrap: "nowrap", position: "relative", left: "-30px" }}>
          <Grid item xs>
            
          </Grid>
          <Grid item>


            <Button
              variant="contained"
              startIcon={<Iconify icon={'cil:filter'} />}
              onClick={handleExportFilter}
            >
             Export Filters
            </Button>
          </Grid>
        </Grid>
        
      )}



      {isInboxVoice &&  !isInboxVoiceExport ? (


        <Grid container spacing={2} >
          {width < 500 ? <>
            <Grid item xs style={{ display: "flex", justifyContent: "space-between", position: "relative", left: "-30px" }}>
              <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                <Iconify icon="eva:menu-2-fill" />
              </IconButtonAnimate>
              <div style={{ position: "absolute", right: "-60px" }}>
                <Searchbar />
              </div>
            </Grid>
          </> : <>
            <Grid item xs>
              <Searchbar />
            </Grid>
          </>}
          {width < 500 ? <>
            <Grid container spacing={1} style={{ display: "flex", position: "relative", left: "17px" }}>

              {localStorage.getItem('viewType') === 'All' && (
                <Grid item>

                <Button
                  variant="contained"
                  startIcon={<Iconify icon={'cil:filter'} />}
                  onClick={navigateExport}
                >
                Export
                </Button>

                </Grid>
              )}
              

             
              
              <Grid item>
                <FilterDrawer />
              </Grid>
              <Grid item>
                {
                  width < 500 ? <>
                    <Button
                      variant="outlined"
                      startIcon={<Iconify icon={'ep:refresh-right'} />}
                      onClick={refresh}

                    >

                    </Button>
                  </> : <>
                    <Button
                      variant="outlined"
                      startIcon={<Iconify icon={'ep:refresh-right'} />}
                      onClick={refresh}

                    >
                      Refresh
                    </Button>
                  </>
                }


              </Grid>
              <Grid item>



                {
                  width < 500 ? <>

                    <Button
                      onClick={handleClick}
                      variant="outlined"
                      style={{ width: '30px' }}
                    >

                      <FormControlLabel control={<Switch size='small' checked={intervalId} color='primary' />} />


                    </Button>

                  </> : <>
                    <Button
                      onClick={handleClick}
                      variant="outlined"
                      style={{ width: '170px' }}
                    >

                      <FormControlLabel control={<Switch size='small' checked={intervalId} color='primary' />} label="Auto Refresh" />


                    </Button>
                  </>
                }


              </Grid>
            </Grid>
          </> : <>
          
            {localStorage.getItem('viewType') === 'All' && (
                <Grid item>

                <Button
                  variant="contained"
                  startIcon={<Iconify icon={'cil:filter'} />}
                  onClick={navigateExport}
                >
                Export
                </Button>

                </Grid>
              )}
            <Grid item>
              <FilterDrawer />
            </Grid>
            <Grid item>
              {
                width < 500 ? <>
                  <Button
                    variant="outlined"
                    startIcon={<Iconify icon={'ep:refresh-right'} />}
                    onClick={refresh}

                  >

                  </Button>
                </> : <>
                  <Button
                    variant="outlined"
                    startIcon={<Iconify icon={'ep:refresh-right'} />}
                    onClick={refresh}

                  >
                    Refresh
                  </Button>
                </>
              }


            </Grid>
            <Grid item>



              {
                width < 500 ? <>

                  <Button
                    onClick={handleClick}
                    variant="outlined"
                    style={{ width: '30px' }}
                  >

                    <FormControlLabel control={<Switch size='small' checked={intervalId} color='primary' />} />


                  </Button>

                </> : <>
                  <Button
                    onClick={handleClick}
                    variant="outlined"
                    style={{ width: '170px' }}
                  >

                    <FormControlLabel control={<Switch size='small' checked={intervalId} color='primary' />} label="Auto Refresh" />


                  </Button>
                </>
              }


            </Grid>
            {/* </Grid> */}
          </>}

        </Grid>



      ) : ''}

      {isVoiceMailBox && (

        <Grid container spacing={2}>
          <Grid item xs>
            {/* <h2 style={{ color: '#000' }}>Voicemail Box</h2> */}
          </Grid>
          <Grid item>

          {is_admin === 'true' ? (
            <CreateVoiceMail />
          ): (
            <>
            {userRolePermissions.VoicemailBox.create && (
              <CreateVoiceMail />
            )}
            </>
          )}
            
          </Grid>

        </Grid>

      )}



      {isContactGroup && (
        <>

          <Grid container spacing={2}>
            <Grid item xs>

            </Grid>
            <Grid item>
            {is_admin === 'true' ? (
              <CreateGroup />
            ) : (
              <>
              {userRolePermissions.ContactsGroups.create && (
                <CreateGroup />
              )}
              </>
            )}

              
            </Grid>

          </Grid>

        </>
      )}



      {/* </Toolbar> */}
    </RootStyle>
  );
}
