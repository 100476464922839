import React, { useContext, useState } from "react";
import styled from "styled-components";
// import { Button, Typography, CircularProgress, Snackbar, Grid } from "@mui/material";
import OtpInput from "react-otp-input";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Grid, Snackbar, Typography } from "@mui/material";
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import Group from './../../../assets/Group.png';
import { LoadingButton } from "@mui/lab";
import axiosInstance, { baseUrl } from "src/utils/axios";
import { useDispatch } from "react-redux";
import { incrementauthid } from "src/redux/Authidslice";
// import Swal from "sweetalert2";
// import { Redirect, Link } from "react-router-dom";

// import "./css/Signupsuccess.css";
// import vibLogo from "../assets/vibtree-logo.png";
// import { useSelector } from "react-redux";
// import emailOtp from "../assets/email-otp.svg";
// import { MainContext } from "../contexts/MainContext";
// import axios from "../utils/axiosinst";
// import typeax from "../utils/typeax";
// import {
//     FormFieldsContainer,
//     MainFormLayout,
//     RightSectionLayout
// } from "../components/LayoutComponents";
// import SlideComponent from "../components/Slides";
// import './css/Login.css';
// import LiveHelpIcon from '@mui/icons-material/LiveHelp';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 80vw;
`;

const OTPContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  width: 400px;
  background: #fff;
  padding: 20px 40px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
  position: relative;
`;


export default function VerifyLoginOtp({ step, setstep }) {
    const navigates = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [tempObj, setTempObj] = useState({});
    const [navigate, setNavigate] = useState(false);
    const dispatch = useDispatch()
    const [showSnack, setShowSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnack(false);
    };

    React.useEffect(() => {
        setTempObj(JSON.parse(localStorage.getItem("email-data") || "{}"));
    }, []);

    if (navigate) {
        // return <Redirect to="/onboarding" push />;
    }

    const email = tempObj.Email;

    const [open, setOpen] = useState(false);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const otpsend = () => {
        setloadings(true)
        axiosInstance.post(`${baseUrl}/login/send-otp`,
            {
                "number": phone
            }).then((res) => {
                setloadings(false)
                console.log(res.data.data[0].uniqueId)
                console.log(res.data.status)
                localStorage.setItem('statuslogin', res.data.status)
                localStorage.setItem('uniqueId', res.data.data[0].uniqueId)
                localStorage.setItem('phone', phone)
                localStorage.setItem('vikalp', "vikalp")
                setstep(step + 1)
            }).catch((err) => {
                setloadings(false)
                console.log(err)
                handleClick()
            })
    }

    const action = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    const [open2, setOpen2] = useState(false);

    const handleClose2 = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen2(false);
    };

    const handleClick2 = () => {
        setOpen2(true);
    };

    const action2 = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose2}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    const uniqueId = localStorage.getItem('uniqueId')
    const phone = localStorage.getItem('phone')


    const EmailID = JSON.parse(localStorage.getItem('Email'))
    console.log(EmailID)
    const [loadings, setloadings] = useState(false);

    const verifyotp = () => {


        if (otp.length == 6) {
            setloadings(true)
        }

        axiosInstance.post(`${baseUrl}/login/verify-otp`, {
            "phone": phone,
            "id": uniqueId,
            "otp": otp
        }).then((res) => {
            console.log(res.data.data)
            localStorage.setItem('redux-token', res.data.data.token)
            localStorage.setItem('authId', res.data.data.authId)
            dispatch(incrementauthid(res.data.data.authId))
            localStorage.setItem('auth_secret', res.data.data.auth_secret)
            localStorage.setItem('sip_user', res.data.data.sip_user)
            localStorage.setItem('sip_password', res.data.data.sip_password)
            localStorage.setItem('accessToken', res.data.data.token);
            setloadings(false)
            // localStorage
            if (res.data.status === true) {
                const login_data = res.data.data.token;
                axios({
                    method: "GET",
                    url: `${baseUrl}/getAuth`,
                    headers: {
                        authorization: login_data
                    }
                }).then((res) => {
                    console.log("verifylogin", res)
                    localStorage.setItem('customerIdInZoho', res.data.data.customerIdInZoho)
                    localStorage.setItem('FirstName', res.data.data.FirstName)
                    localStorage.setItem('LastName', res.data.data.LastName)
                    localStorage.setItem('email', res.data.data.email)
                    localStorage.setItem('phone', res.data.data.phone)
                    localStorage.setItem('country', res.data.data.country_allowed[0].code)
                    localStorage.setItem('_id', res.data.data._id)
                    localStorage.setItem('user_logo', res.data.data.user_logo)
                    navigates('/dashboard/matrix');
                    if (res.data.status === 200) {
                        const whatsappNumber = resultAuth.data.data.whatsAppNumber;
                        const phoneNumberId = resultAuth.data.data.phoneNumberId;
                        const isWhatsappActive = resultAuth.data.data.isWhatsappActive;
                        localStorage.setItem('isWhatsappActive', isWhatsappActive)
                        localStorage.setItem('whatsappNumber', whatsappNumber);
                        localStorage.setItem('phoneNumberId', phoneNumberId)
                        localStorage.setItem("lineForwardAppId", resultAuth.data.data.lineForwardAppId)
                        localStorage.setItem("CloudPhoneAppId", resultAuth.data.data.CloudPhoneAppId)
                        localStorage.setItem("IvrStudioAppId", resultAuth.data.data.IvrStudioAppId)
                        localStorage.setItem("CancelNumberAppId", resultAuth.data.data.CancelNumberAppId)
                        // dispatch(incrementauthid(resultAuth.data.data.auth_id))
                        localStorage.setItem('authID', resultAuth.data.data.auth_id);
                        localStorage.setItem('userID', resultAuth.data.data._id);
                        localStorage.setItem("companyID", resultAuth.data.data.company_id);
                        localStorage.setItem('authSecretId', resultAuth.data.data.auth_secret);
                        localStorage.setItem("phone", resultAuth.data.data.phone)
                        localStorage.setItem('timeZone', resultAuth.data.data.timeZone)

                        let tokenOfVibtree = 'EAAOdOeKzYdkBAAjoshNzqleILw5QveXm2swoNVCJzW0urYaxsqaT878bbfzt0y6Io7ECl4XiLs3lyZBsPZBrnyr40i2WJkQdirAi0T7hSsz6sYJ21x1scKdAZApWpH8CzzPZCJFBjUWF0ddny6OMEbmFXzUfBC28rrtVjbwoDUBpp1yVbQVB';
                        localStorage.setItem("whatsappToken", tokenOfVibtree)
                        if (resultAuth.data.data.whatsappToken !== undefined && resultAuth.data.data.whatsappToken !== null) {
                            localStorage.setItem("whatsappToken", resultAuth.data.data.whatsappToken)
                        }
                        if (resultAuth.data.data.viewType !== undefined && resultAuth.data.data.viewType !== null) {
                            localStorage.setItem("viewType", resultAuth.data.data.viewType)
                        } else {
                            localStorage.setItem("viewType", "All")
                        }
                        let assignedNumber = resultAuth.data.data.assignedNumber;
                        let stringyfyAssignedNumber = JSON.stringify(assignedNumber);
                        localStorage.setItem("assignedNumber", stringyfyAssignedNumber);
                        // this code set the token in header after login 
                        axios.interceptors.request.use(function (config) {
                            config.headers.authorization = accessToken;
                            return config;
                        });
                        localStorage.setItem('isAuthenticated', true);
                        localStorage.setItem('isInitialized', true);
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }
            else {
                handleClick()
            }
            // getauth()

        }).catch((err) => {
            console.log(err)
            handleClick()
            setloadings(false)
        })
    }


    return (
        <>


            <div style={{ padding: "40px", marginTop: "20%" }} className="send-otp-bg">

                <Grid container>
                    <Grid item xs></Grid>
                    <Grid item>
                        <OTPContainer>

                            <Typography
                                variant="h4"
                                style={{ marginTop: "20px", marginBottom: "30px", textAlign: "center", fontWeight: "700", color: "#8A8A8A" }}
                            >
                                Let's Go!
                            </Typography>

                            <Typography
                                variant="h4"
                                style={{ marginTop: "20px", marginBottom: "30px", textAlign: "center", fontWeight: "700", color: "#8A8A8A" }}
                            >
                                {/* <img src={emailOtp} alt="" style={{ marginBottom: "0px", width: "80px", marginLeft: "auto", marginRight: "auto" }} /> */}

                            </Typography>



                            <Typography
                                variant="h5"
                                style={{ marginBottom: "10px", textAlign: "left", fontWeight: "500", color: "#8A8A8A", fontSize: "16px" }}
                            >
                                Verify your Number.
                            </Typography>
                            <Typography
                                variant="h6"
                                style={{ marginBottom: "10px", textAlign: "left", fontWeight: "500", color: "#8A8A8A", fontSize: "14px" }}
                            >
                                Please enter the code.
                            </Typography>

                            <Typography
                                variant="h5"
                                style={{ marginBottom: "10px", textAlign: "left", fontWeight: "500", color: "#8A8A8A", fontSize: "16px" }}
                            >
                                {email}
                            </Typography>

                            <Typography
                                variant="caption"
                                style={{ marginBottom: "20px", textAlign: "left", fontWeight: "400", color: "#8A8A8A", fontSize: "12px" }}
                            >
                                Confirmation Code.
                            </Typography>

                            <OtpInput
                                inputStyle={{ width: "38px ", height: "40px", borderRadius: "0", borderBottom: "1px solid #0045BA", borderWidth: '0 0 1px 0' }}
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                separator={<span style={{ padding: "5px 10px" }}></span>}
                            />
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                loading={loadings}
                                onClick={() => {
                                    verifyotp()
                                }}
                                style={{ height: "40px", marginTop: "20px", display: "block", width: "100%" }}
                            >
                                Verify
                            </LoadingButton>
                            <Typography
                                variant="p" style={{ margin: "0", padding: "0", marginLeft: "120px", color: "#2065D1", cursor: "pointer" }}
                                onClick={() => {
                                    handleClick2()
                                    otpsend()
                                }}>Resend OTP</Typography>

                            <Typography

                                style={{ marginBottom: "30px", textAlign: "center" }}
                            >
                                {isLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <span>
                                        {/* <Link style={{ color: "#0045BA" }} onClick={() => resendEmail()}>Resend Code</Link> or <Link to="/login" style={{ color: "#0045BA" }}>Logout</Link> */}

                                    </span>
                                )}
                            </Typography>

                            {/* <Button className="send-otp-help" color="primary" variant="contained" style={{ display: "inline-block", textAlign: "center" }}>
                            <LiveHelpIcon style={{ display: "block", margin: "0 auto" }} />
                            Help
                        </Button> */}

                        </OTPContainer>
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>





                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: "right"
                    }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message="Please Enter Valid OTP"
                    action={action}

                />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: "right"
                    }}
                    open={open2}
                    autoHideDuration={3000}
                    onClose={handleClose2}
                    message="OTP has been Sent"
                    action={action2}

                />

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={showSnack}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    message={snackMessage}
                    action={
                        <React.Fragment>
                            <Button color="secondary" size="small" onClick={handleSnackClose}>
                                OK
                            </Button>
                        </React.Fragment>
                    }
                />


            </div>

            {/* <img src={Group} alt="" style={{ width: "230px", position: "relative", left: "440px", top: "-70px" }} /> */}
        </>


    );
}
