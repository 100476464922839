import { capitalCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Button } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import { CarouselSection } from './Slider';
import vibtreelogo from '../../assets/vibtree-logo.jpg';
import homepage from './../../assets/homepage.png'
import axiosInstance, { baseUrl } from 'src/utils/axios';
import { useEffect, useState } from 'react';
import OtpLogin from './OtpLogin';
import VerifyLoginOtp from './VerifyLoginOtp';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',

  }
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  // height: "100%",
  maxWidth: 650,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {

  const navigate = useNavigate()

  const { method } = useAuth();

  const [checkingCookie, setCheckingCookie] = useState(true)


  function getCookie(uid) {
    let vibUid = uid + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(vibUid) == 0) {
        return c.substring(vibUid.length, c.length);
      }
    }
    return "";
  }

  

  function checkCookie() {
    let vibUId = getCookie("vib_u_id");
    console.log('checkCookie vibUId', vibUId)
    if (vibUId != "" && vibUId != null) {
      console.log('checkCookie welcome')
      navigate('/dashboard/matrix')
    } else {
       console.log('checkCookie login')
       navigate('/')
       setCheckingCookie(false)
    }
  }

  useEffect(() => {
    checkCookie()
    console.log('checkCookie use')
  }, [])
  



  const [login_type, setLoginType] = useState('email')


  const smUp = useResponsive('up', 'sm');
  const [step, setstep] = useState(0)
  const mdUp = useResponsive('up', 'md');

  // const getdata = () => {

  //   axiosInstance.get(`${baseUrl}/getAuth`).then((res) => {
  //     console.log(res.data.data)
  //     localStorage.setItem('FirstName', res.data.data.FirstName)
  //     localStorage.setItem('LastName', res.data.data.LastName)
  //     localStorage.setItem('email', res.data.data.email)
  //     localStorage.setItem('phone', res.data.data.phone)
  //     localStorage.setItem('customerIdInZoho', res.data.data.customerIdInZoho)
  //     localStorage.setItem('country', res.data.data.country_allowed[0].code)

  //   }).catch((err) => {
  //     console.log(err)
  //   })
  // }
  // useEffect(() => {
  //   getdata()
  // }, [])

  const statuslogin = localStorage.getItem('statuslogin')

  return (
    <>
    {checkingCookie ? '' : (

   
    <Page title="Login">
      <RootStyle
        style={{
          backgroundImage: `url(${homepage})`,
          backgroundRepeat: "no-repeat",
          backgroundPositionY: "bottom",
          backgroundPositionX: "right",
        }}
      >

        {/* <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography>
          )}
        </HeaderStyle> */}

        {mdUp && (
          <SectionStyle>
            {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography> */}
            <CarouselSection />
            {/* <Image visibleByDefault disabledEffect src="/assets/illustrations/illustration_login.png" alt="login" /> */}
          </SectionStyle>
        )}

        <Container maxWidth="sm"


        >
          {step == 0 ? <>
            <ContentStyle>
              <Stack direction="row" alignItems="center"
                sx={{ mb: 1 }}
              >
                <Box sx={{ flexGrow: 1 }} >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={vibtreelogo} alt="" style={{ width: "50%" }} />
                  </div>
                  <br />
                  <Typography variant="h4" gutterBottom style={{ display: "flex", justifyContent: "center", fontSize: "30px", marginBottom: "10px" }}>
                    Sign in to your account
                  </Typography>
                  {/* <br /> */}
                  <Typography sx={{ color: 'text.secondary' }} style={{ display: "flex", justifyContent: "center" }}>
                    {smUp && (
                      <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                        Don’t have an account? {''}
                        <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                          Get started
                        </Link>
                      </Typography>
                    )}
                    {/* Enter your details below. */}
                  </Typography>
                </Box>

                {/* <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image
                    disabledEffect
                    src={`https://minimal-assets-api-dev.vercel.app/assets/icons/auth/ic_${method}.png`}
                    sx={{ width: 32, height: 32 }}
                  />
                </>
              </Tooltip> */}
              </Stack>

              {/* <Alert severity="info" sx={{ mb: 3 }}>
              Use email : <strong>demo@minimals.cc</strong> / password :<strong> demo1234</strong>
            </Alert> */}
              <div style={{ width: "100%", display: "flex", flexDirection: "row", height: 32, width: 150, alignSelf: "center", backgroundColor: "#0046BB", borderRadius: 20, alignItems: "center" }}>
                {login_type === "email" ? (
                  <Button style={{ width: 71, height: 30, backgroundColor: login_type === "email" ? "#FAFAFA" : "#0046BB", borderRadius: 20, marginLeft: 1, shadowColor: '#171717', shadowOpacity: 0.2, shadowRadius: 3, elevation: 2, }} onClick={() => {
                    setLoginType("email")
                  }}>
                    <span style={{ color: login_type === "email" ? "#0046BB" : "#FAFAFA", fontSize: 12, fontWeight: "400" }}>
                      Email
                    </span>
                  </Button>
                ) : (
                  <Button style={{ width: 78, height: 30, backgroundColor: login_type === "email" ? "#FAFAFA" : "#0046BB", marginLeft: 1, borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }} onClick={() => {
                    setLoginType("email")
                  }}>
                    <span style={{ color: login_type === "email" ? "#0046BB" : "#FAFAFA", fontSize: 12, fontWeight: "400" }}>
                      Email
                    </span>
                  </Button>
                )}

                {login_type === "number" ? (
                  <Button style={{ width: 71, height: 30, borderRadius: 20, backgroundColor: login_type === "email" ? "#0046BB" : "#FAFAFA", marginRight: 1, shadowColor: '#171717', shadowOpacity: 0.2, shadowRadius: 3, elevation: 2 }} onClick={() => {
                    setLoginType("number")
                  }}>
                    <span style={{ color: login_type === "email" ? "#FAFAFA" : "#0046BB", fontSize: 12, fontWeight: "400" }}>
                      Number
                    </span>
                  </Button>
                ) : (
                  <Button style={{ width: 78, height: 30, borderTopRightRadius: 20, borderBottomRightRadius: 20, backgroundColor: login_type === "email" ? "#0046BB" : "#FAFAFA" }} onClick={() => {
                    setLoginType("number")
                  }}>
                    <span
                      style={{ color: login_type === "email" ? "#FAFAFA" : "#0046BB", fontSize: 12, fontWeight: "400" }}>
                      Number
                    </span>
                  </Button>
                )}
              </div>
              <br />
              {login_type == "email" ? <LoginForm /> : <OtpLogin step={step} setstep={setstep} />

              }


              {!smUp && (
                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                  Don’t have an account?{' '}
                  <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                    Get started
                  </Link>
                </Typography>
              )}


            </ContentStyle>
          </> : <VerifyLoginOtp />

          }

        </Container>
      </RootStyle>
    </Page>

)}
</>
  );
}
