import { Tab, Tabs, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { capitalCase } from 'change-case'
import { active } from 'd3-transition'
import React, { useState } from 'react'
import Iconify from 'src/components/Iconify'
import UserAccount from 'src/pages/dashboard/UserAccount'
import { AccountGeneral } from 'src/sections/@dashboard/user/account'
import Billing from './Billing'
import General from './General'

const Profile = () => {
    const [active, setActive] = useState('')
    const [currentTab, setCurrentTab] = useState('')
    const ACCOUNT_TABS = [
        {
            value: 'general',
            icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
            // component: <General />,
        },
        {
            value: 'billing',
            icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
            // component: <AccountBilling cards={_userPayment} addressBook={_userAddressBook} invoices={_userInvoices} />,
            // component: <Billing />
        },
        // {
        //     value: 'notifications',
        //     icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
        //     // component: <AccountNotifications />,
        // },
        // {
        //     value: 'social_links',
        //     icon: <Iconify icon={'eva:share-fill'} width={20} height={20} />,
        //     // component: <AccountSocialLinks myProfile={_userAbout} />,
        // },
        // {
        //     value: 'change_password',
        //     icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
        //     // component: <AccountChangePassword />,
        // },
    ];
    console.log(currentTab)
    const handleChange = (value) => {
        console.log(value)
    }
    return (
        <div style={{ marginLeft: "30px" }}>
            {/* <br />
            <br /> */}
            <UserAccount />
            {/* <div >
                <Typography variant='h4'>
                    Account
                </Typography>

                <div style={{ display: "flex" }}>
                    <Typography>Dashboard</Typography> &nbsp;&nbsp;&nbsp;&nbsp;
                    <Typography>User</Typography>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Typography>Account Settings</Typography>
                </div>
            </div>
            <br />
            <div style={{ display: "flex" }} >
                <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }} onClick={() => setActive('General')}>
                    <Iconify icon={'ic:round-account-box'} width={20} height={20} /> &nbsp;
                    <Typography>General</Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }} onClick={() => setActive('Billing')}>
                    <Iconify icon={'ic:round-receipt'} width={20} height={20} /> &nbsp;
                    <Typography>Billing</Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }} onClick={() => setActive('Team')}>
                    <Iconify icon={'ic:round-account-box'} width={20} height={20} /> &nbsp;
                    <Typography>Team</Typography>
                </div>
            </div>
            <br />

            {
                active == "General" ? <General /> : active == "Billing" ? <Billing /> : null
            } */}

        </div>
    )
}

export default Profile