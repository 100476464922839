// @mui
import { useTheme } from '@mui/material/styles';
import { paramCase } from 'change-case';
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Container, Grid, Button, Stack, TableHead, TableBody, TableRow,
    Pagination,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem, FormGroup,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    IconButton,
    CircularProgress,
    Typography,
    Box,


} from '@mui/material';
import { useSnackbar } from 'notistack';
import { DataGridPro } from '@mui/x-data-grid-pro';
// redux
import { useSelector, useDispatch } from 'react-redux';
import CheckStatusvoice from './CheckStatusvoice';

import InviteUsers from 'src/sections/@dashboard/user/account/InviteUsers'



const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const SmsUser = () => {
    const [loading, setLoading] = useState(false)
    const { userOfCompany, isRefresh } = useSelector(state => state.inboxComments)

    console.log("userOfCompany", userOfCompany)
    const [openDialog, setOpenDialog] = useState(false);

    const [userinbox, setuserinbox] = useState([])
    const selectedassignedid = useSelector(state => state.assignedidSlice.value)

    useEffect(() => {
        setuserinbox(userOfCompany.data || [])
    }, [openDialog])

    useEffect(() => {
        setuserinbox(userOfCompany.data || [])
    }, [])

    const checkboxuser = (props) => {
        console.log("checkboxuser", props.row._id)
        console.log("checkboxuserid", selectedassignedid._id)

        return (
            <>{selectedassignedid ? <>
                {
                    selectedassignedid === props.row._id ? <>
                        <Checkbox {...label} defaultChecked disabled />
                    </> : <>
                        <Checkbox {...label} />
                    </>
                }
            </> : <>
                <Checkbox {...label} />
            </>}

            </>
        )
    }

    const viewtypecheck = (params) => {

        return (
            <>
                <CheckStatusvoice params={params} selectedassignedid={selectedassignedid} />
            </>
        )
    }

    const columns = [
        {
            field: "fullName",
            headerName: 'Name',
            editable: false,
            flex: 1,
        },
        {
            field: "viewType",
            headerName: "Conversation Acess",
            editable: false,
            flex: 1,
            renderCell: viewtypecheck
        },
        // {
        //     field: 'export',
        //     headerName: "Export Data",
        //     editable: false,
        //     flex: 1,
        //     renderCell: checkboxuser
        // }
    ]

    const LoadingSkeleton = () => (
        <>
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </>
    );

    const renderedStudio = (
        <>
            <DataGridPro
                rows={userinbox}
                columns={columns}
                rowHeight={48}
                disableSelectionOnClick
                getRowId={(r) => r._id}
                style={{ height: "550px" }}
                components={{
                    LoadingOverlay: LoadingSkeleton
                }}
                loading={loading}
                hideFooter

            />
        </>
    )


    return (
        <div
        style={{ padding: "30px" }}
    >
       <Grid container direction='row'>
            <Grid item xs></Grid>
            <Grid item>
            <InviteUsers openDialog={openDialog} setOpenDialog={setOpenDialog} />
            </Grid>
        </Grid>
        
            {renderedStudio}
        </div>
    )
}

export default SmsUser