import { Grid, TextField, FormHelperText } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { incrementtelegrambottoken } from 'src/redux/Telegrambottoken'

const TelegramListing = ({telegramTokenValidate, setTelegramTokenValidate}) => {
    const [telegram, settelegram] = useState('')
    const dispatch = useDispatch()
    const bottoken = (value) => {
        settelegram(value)
        dispatch(incrementtelegrambottoken(value))
        if(value !== ''){
            setTelegramTokenValidate(false)
        }
    }
    return (
        <div>
            <Grid style={{ marginTop: "10%", width: "500px", marginLeft: "25%" }}>
                <TextField
                    label='Bot Token'
                    fullWidth
                    value={telegram}
                    onChange={(e) => {
                        bottoken(e.target.value)
                    }}
                />
                {telegramTokenValidate &&(
                        <FormHelperText error>
                            Token is Required
                        </FormHelperText>
                    )}
            </Grid>

        </div>
    )
}

export default TelegramListing