import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import BoltIcon from '@mui/icons-material/Bolt';
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import {ICONS} from "src/components/nav-section/vertical";
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import LogoFull from '../../../components/LogoFull';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';

import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  //  height: "100vh",
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {

  const viewType = localStorage.getItem("viewType")
  const {navConfig, navConfigtrail, navConfigOfSubUser, navConfigForWhatsappAdmin} = useSelector((state) => state.navigation)
  const isWhatsappActive = localStorage.getItem("isWhatsappActive")
  const planstatus = localStorage.getItem("planstatus")
  console.log('planstatus', planstatus)

  console.log("View Type 48 : ", viewType, isWhatsappActive)
  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }

  }, [pathname]);

  


  const checkScreenViewPermission = () => {
    if (viewType === "All") {
      if (planstatus == 'trial') {
        return navConfigtrail
      } else {

        if (isWhatsappActive === null) {
          return navConfig
        }
        if (isWhatsappActive === undefined) {
          return navConfig
        }
        if (isWhatsappActive === 'undefined') {
          return navConfig
        }
        if (isWhatsappActive === 'null') {
          return navConfig
        }
        if (isWhatsappActive === 'true') {
          return navConfigForWhatsappAdmin
        }
        if (isWhatsappActive === 'false') {
          return navConfigForWhatsappAdmin
        }

      }

    }
  }
  const renderContent = (
    <Scrollbar
      sx={{
        '& .simplebar-content': { height: "100vh", display: 'flex', flexDirection: 'column', justifyContent: "space-between" },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 4,

          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {isDesktop && isCollapse && (
            <Logo />
          )}
          {isDesktop && !isCollapse && (
            <LogoFull />
          )}

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>

        {/* <NavbarAccount isCollapse={isCollapse} /> */}
      </Stack>

      <div style={{height: 'calc(100vh - 180px', overflowY: 'auto' }}>

      {
        viewType === 'All' ? (
          <NavSectionVertical navConfig={checkScreenViewPermission()} isCollapse={isCollapse} />
        ) : (
          <NavSectionVertical navConfig={navConfigOfSubUser} isCollapse={isCollapse} />
        )
      }

</div>



      {/* <br /> */}
      {/* // <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} /> */}

      <Box
        sx={{ flexGrow: 1 }}


      />
      {<Stack
        spacing={3}
        sx={{
        
          width: '100%',
          // pb: 1,
          // px: 2.5,

          // left: 20,
          bottom: 1,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <NavbarAccount isCollapse={isCollapse} />
      </Stack>}

    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },

        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
