
import { createSlice } from "@reduxjs/toolkit";
import { useSnackbar } from 'notistack';
// utils
import axios from 'axios';

//
import { dispatch } from '../../store';

import {showSnackbar} from '../snackbar'


// ----------------------------------------------------------------------


const initialState= {
    isLoading: false,
    error: null,
    conversations: {},
    conversationId: '',
    conversationIdName: '',
    conversationIdData: '',
}

const whatsappSlice = createSlice({
    name: 'whatsapp',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        
        // GET NUMBER SSUCCESS
        getConversationsSuccess(state, action) {
          state.conversations = action.payload;
          state.isLoading = false;
        },

        selectedConversationId: (state, { payload }) => {
            state.conversationId = payload;
            console.log('selectedConversationId', payload)
        },
        selectedConversationIdName: (state, { payload }) => {
          state.conversationIdName = payload;
          console.log('selectedConversationIdName', payload)
      },

        // GET NUMBER SSUCCESS
        getConversationsIdSuccess(state, action) {
          state.conversationIdData = action.payload;
          state.isLoading = false;
        },

      
    }
})



export const selectWhatsapp = (state) => state.whatsapp;

export const { selectedConversationId, selectedConversationIdName } = whatsappSlice.actions


export default whatsappSlice.reducer

// Actions

export function getConversations(access_token, page) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`https://dev-api.sobot.in/conversations/?page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getConversationsSuccess(response.data));

          console.log('getConversations Success response', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }


  export function getConversationsId(access_token, page, id) {

   
    return async () => {
      dispatch(whatsappSlice.actions.startLoading());
      try {
        const response = await axios.get(`https://dev-api.sobot.in/conversations/${id}/message?page=${page}`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(whatsappSlice.actions.getConversationsIdSuccess(response.data));

          console.log('getConversationsIdSuccess response', response.data)
 
      } catch (error) {
        dispatch(whatsappSlice.actions.hasError(error));
        
      }
    };
  }
