// check the public/index.html page for css link
import React, { useState, useEffect } from 'react';
import './boot-custom-style.css'
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {
    Grid,
    TextField,
    IconButton
} from '@mui/material';


import BootStrapIcons from './bootstrap-icons.json'



export default function Iconpicker({ setIcon, icon }) {

    let BootStrapiconKeys = Object.keys(BootStrapIcons)
    console.log('BootStrapIcons', BootStrapiconKeys)

    const [openDialog, setOpenDialog] = useState(false)
    const [selectedValue, setSelectedValue] = useState('archive-fill')
    const [iconSearch, setIconSearch] = useState('')
    // const [selectedIcon, setSelectedIcon] = useState('')

    const handleIconPicker = () => {
        setOpenDialog(true)
    }
    const handleCloseIconPicker = () => {
        setOpenDialog(false)
        setIconSearch('')
    }

    const handleSaveIcon = () => {
        // localStorage.setItem("iconpng", selectedValue)

        // dispatch(incrementiconpicker(selectedValue))
        setOpenDialog(false)
        setIcon(selectedValue)


        setIconSearch('')
    }


    useEffect(() => {
        console.log("setIcon", icon,)
        if(selectedValue !== icon && selectedValue !== '' && icon !== ''){
            setSelectedValue(icon)
        }
        
    }, [icon])
    

    return (
        <>

            <IconButton onClick={handleIconPicker}>

            <i className={`bi bi-${selectedValue}`} style={{ fontSize: "22px" }} ></i>
            </IconButton>

            <Dialog
                sx={{ '& .MuiDialog-paper': { maxHeight: 435 } }}
                open={openDialog}

            >
                <DialogTitle>
                    <Grid container alignItems='center'>
                        <Grid item xs>
                            Icon Picker
                        </Grid>
                        <Grid item>
                            <TextField
                                value={iconSearch}
                                onChange={(event) => setIconSearch(event.target.value)}
                                size='small'
                                placeholder='search icon here..'

                            />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ borderTop: '1px solid #eee', borderBottom: '1px solid #eee', marginTop: '5px' }}>

                    <FormControl className='bootstrap-icon-picker'>

                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onChange={(event) => { setSelectedValue(event.target.value) }}
                            row
                        >
                            {BootStrapiconKeys
                                .filter((item) => {
                                    if (!iconSearch) return true;

                                    if (item.toLowerCase().includes(iconSearch.toLowerCase())) {
                                        return true;
                                    }
                                })
                                .map((item) => {
                                    return (
                                        <li>
                                            <FormControlLabel value={item} control={<Radio icon={<i className={`bi bi-${item}`} ></i>} checkedIcon={<i className={`bi bi-${item} text-red`} ></i>} />}>
                                                <i className={`bi bi-${item}`}></i>
                                            </FormControlLabel>
                                        </li>
                                    )
                                })}


                        </RadioGroup>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseIconPicker}>
                        Cancel
                    </Button>
                    <Button variant='contained' disabled={selectedValue === ''} onClick={handleSaveIcon}>Save</Button>
                </DialogActions>
            </Dialog>


        </>
    )
}