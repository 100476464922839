import React, { useState, useEffect } from 'react'

import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    Typography,
    IconButton,

} from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { assignRole, getuserRoles } from '../../../../redux/slices/profile/role';

import Iconify from '../../../../components/Iconify';

export default function AssignRole({props}){

    const dispatch = useDispatch();

    let AccountSid = localStorage.getItem('authID')


    useEffect(() => {
        console.log('roleName dispatch')
        dispatch(getuserRoles(AccountSid));
    }, [AccountSid]);


    const [tableData, setTableData] = useState([])
    const [openDrawer, setOpenDrawer] = useState(false)
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState('')

    const [isRoleExist, setIsRoleExist] = useState(true)
    const [roleload, setRoleload] = useState(true)

    const { getRoleResponse, putRoleResponse } = useSelector(state => state.userRole)

      useEffect(() => {
        console.log('getRoleResponse', getRoleResponse)
        if (getRoleResponse.status === true && getRoleResponse.status !== undefined) {
            setTableData(getRoleResponse.data.data)
    
          }
      }, [getRoleResponse])

      useEffect(() => {
        console.log('putRoleResponse', putRoleResponse)
        if(putRoleResponse !== undefined){
            if(putRoleResponse.status === true){
                setLoading(false)
                setOpenDrawer(false)
            }else{
                setLoading(false)
            }
            
        }
      }, [putRoleResponse])
      

    const handleClose = () => {
        setOpenDrawer(false)
        setLoading(false)
    }

    const handleOpen = () => {
        setOpenDrawer(true)
        setLoading(false)
    }

    const handleOpenEdit = (name) =>{
        setOpenDrawer(true)
        setLoading(false)
        setRole(name)
    }

    const handleAssign = () =>{
        dispatch(assignRole(props.row._id, role))
        setLoading(true)
    }

  const [name, setName] = useState('')

  
    

    function roleName(id){

       

       


       
        
        
    }


    useEffect(() => {

        console.log('roleName eff', props.row.role)
        //{roleName(props.row.role)}


        if(props.row.role !== null && props.row.role !== undefined && props.row.role !== '' && Object.keys(tableData).length !== 0){
            
            console.log('roleName in', props.row.role)
         
                let findIndex = tableData.findIndex(item=> item._id === props.row.role)
                console.log('roleName', props.row.role, findIndex)
                if(findIndex > -1){
                    setName(tableData[findIndex].name)
                    setIsRoleExist(true)
                    setRoleload(false)
    
                 
              
            }else{
                setIsRoleExist(false)
                    setRoleload(false)
            }
            
            }else{
                if(props.row.role === null && Object.keys(tableData).length === 0){
                    setIsRoleExist(false)
                    setRoleload(false)
                }
                    
            
           
           }


        //    return () => {
        //     name,
        //     setIsRoleExist(false)
        //     setRoleload(false)

        //   }
        
    }, [tableData])

 



    return(
        <>



{ props.row.is_admin === true ? 'Admin' : (
                <>
{roleload ? 'loading' : (
    <>
   
                {isRoleExist ? (
                  <>
                  {name}

<IconButton size='small' onClick={()=>handleOpenEdit(props.row.role)} variant='outlined'>
    <Iconify icon={'material-symbols:edit-square-outline-rounded'} width={20} height={20} />
</IconButton>
                  </>
                ) :(
                    <Button variant='outlined' size='small' onClick={()=>handleOpen()}>Assign</Button>
                )}

</>
)}
                    {/* {props.row.role !== null && props.row.role !== undefined ? (
                        <>

                        {roleload === true ? (
                            <>loading</>
                        ) : (
                           <>
                            

                            
                            </>
                        )}

                        
                       
                        </>
                    ) : (
                        
                    )} */}

                    
                </>
            )}

<Dialog
            open={openDrawer}
            onClose={handleClose}
            >
            <DialogTitle>
                <Typography>
                    Assign Role
                </Typography>
            </DialogTitle>
            <DialogContent style={{paddingBottom: '0', paddingTop: '10px'}}>

                <TextField
                    select
                    fullWidth
                    value={role}
                    onChange={(event)=>{setRole(event.target.value)}}
                >
                    {tableData.map((item)=>{
                        return(
                            <MenuItem value={item._id}>{item.name}</MenuItem>
                        )
                    })}
                    
                    </TextField>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cancel
                </Button>


                <LoadingButton loading={loading} color="primary" variant="contained" disable={role === '' ? true : false} onClick={handleAssign} autoFocus>
                Submit
                </LoadingButton>

            </DialogActions>

        </Dialog>


        </>
    )
}