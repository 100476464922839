import { useEffect } from 'react';
import io from "socket.io-client";
// @mui
import { Card, Container } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getConversations, getContacts, getUpdatedConversationFromSocket, checkIfConversationIsNew } from '../../redux/slices/chat';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { ChatSidebar, ChatWindow } from '../../sections/@dashboard/chat';
import { REACT_APP_BASE_URL } from '../../utils/axios'
import { addMessageById } from '../../redux/slices/WhatsappSocketSlice';
import { addConversationInList } from '../../redux/slices/WhatsappConversationListSlice';
// ----------------------------------------------------------------------



export default function InboxWhatsAppCompose() {
    const { themeStretch } = useSettings();
    const dispatch = useDispatch();

    const no_of_fetch_count = useSelector((state) => state.WhatsappConversationList.no_of_fetch_count);

    const updatedConversation = useSelector((state) => state.WhatsappConversationList.list);

    const changeInConversation = useSelector((state) => state.WhatsappConversationList.changeInConversation)

    const newConversationLength = useSelector((state) => state.WhatsappConversationList.no_of_fetched_conversations);

    console.log("newConversationLength : ", newConversationLength);
    console.log("changeInConversation InboxWhataspp : ", changeInConversation, no_of_fetch_count)

    useEffect(() => {
        const baseUrl = REACT_APP_BASE_URL;
        // const baseUrl = 'https://mibsback.vibtree.com';
        const socket = io(`${baseUrl}/whatsapp`, {

            path: "/websockets",
            transports: ["polling", "websocket"],
            transportOptions: {
                polling: {
                    extraHeaders: {
                        authorization: "ApiKey <Key>",
                    },
                },
            },
        });

        socket.emit("join_room", {
            request: "chats",
            whatsappNumber: localStorage.getItem("whatsappNumber"),
            token: localStorage.getItem("redux-token"),
        });

        const whatsAppNumber = localStorage.getItem("whatsappNumber");
        socket.emit(`whatsappDashboard_${whatsAppNumber}`)

        //not required for now it get data of
        socket.on(`whatsapp_data`, (data) => {

            //this is commented to avoid displaying double messages
            if (data.Direction === "Inbound") {
                const converstion_id = data.To + "-" + data.From;
                dispatch(addMessageById({ id: converstion_id, message: data }));
            }

            //not required as we are using socket.io for realtime updates
            // if(data.Direction === "Outbound"){
            //   const converstion_id = data.From + "-" + data.To;
            //   dispatch(addMessageById({id : converstion_id, message : data}));
            // }
            console.log("data only of this account : ", data);
        })

        //this connection is fully responsible for updating the conversation list and messages in realtime
        socket.on('conversation_list', (data) => {
            console.log("realtime_conversation list data :: ", data)
            //dispatch(addConversationInList({list : data.allRecords , no_of_fetched_conversations : data.allRecords.length , changeInConversation : data.change}));
            dispatch(checkIfConversationIsNew(data.change))
        });

        // socket.on('incomingMessage' , (data) => {
        //   console.log("public data : ",data);
        // })


        if (no_of_fetch_count === 0) {
            dispatch(getConversations());
            dispatch(getContacts());
        }

        if (no_of_fetch_count >= 1) {
            dispatch(getUpdatedConversationFromSocket(updatedConversation));
        }

    }, [dispatch, newConversationLength]);

    return (
        <Page title="WhatsApp">
            {/* <Container maxWidth={themeStretch ? false : 'xl'}> */}

            <Card sx={{ height: '100vh', display: 'flex' }}>
                <ChatSidebar />
                {/* <ChatWindow /> */}
            </Card>
            {/* </Container> */}
        </Page>
    );
}
