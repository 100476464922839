import { Grid, TextField, FormHelperText } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { incrementwhatsappauthkey } from 'src/redux/Whatsappauthkey'
import { incrementwhatsappnumber } from 'src/redux/Whatsappnumber'
import { incrementwhatsappnumberid } from 'src/redux/Whatsappnumberid'
import { incrementwhatsappwabaid } from 'src/redux/Whatsappwabaid'

const Whatsapplisting = ({
    whatsAppNumberValidate, setWhatsAppNumberValidate,
    whatsappNumberIdValidate, setWhatsAppNumberIdValidate,
    whatsappAuthKeyValidate, setWhatsAppAuthKeyValidate,
    whatsAppWabaAid, setWhatsAppWabaAid,
}) => {
    const dispatch = useDispatch()

    const selectedwpnumber = useSelector(state => state.whatsappnumberSlice.value)
    const selectedwpnumberid = useSelector(state => state.whatsappnumberidSlice.value)
    const selectedwpwabaid = useSelector(state => state.whatsappwabaidSlice.value)
    const selectedwpauthkey = useSelector(state => state.whatsappauthkeySlice.value)


    const [wpnumber, setwpnumber] = useState('')
    const [numberid, setnumberid] = useState('')
    const [authkey, setauthkey] = useState('')
    const [wabaid, setwabaid] = useState('')
    const handlewpnumber = (event) => {
        setwpnumber(event.target.value)
        console.log("wpnumber", event.target.value)
        dispatch(incrementwhatsappnumber(event.target.value))
        localStorage.setItem('wpnumber', event.target.value)
        if(event.target.value !== ''){
            setWhatsAppNumberValidate(false)
        }else{
            setWhatsAppNumberValidate(true)
        }
    }
    const handlenumberid = (event) => {
        setnumberid(event.target.value)
        localStorage.setItem('numberid', event.target.value)
        dispatch(incrementwhatsappnumberid(event.target.value))
        if(event.target.value !== ''){
            setWhatsAppNumberIdValidate(false)
        }else{
            setWhatsAppNumberIdValidate(true)
        }
    }
    const handleauthchange = (event) => {
        setauthkey(event.target.value)
        localStorage.setItem('authkeywp', event.target.value)
        dispatch(incrementwhatsappauthkey(event.target.value))
        if(event.target.value !== ''){
            setWhatsAppAuthKeyValidate(false)
        }else{
            setWhatsAppAuthKeyValidate(true)
        }
    }
    const handlewapaid = (event) => {
        setwabaid(event.target.value)
        localStorage.setItem('wabaid', event.target.value)
        dispatch(incrementwhatsappwabaid(event.target.value))
        if(event.target.value !== ''){
            setWhatsAppWabaAid(false)
        }else{
            setWhatsAppWabaAid(true)
        }
    }

    useEffect(() => {
      if(selectedwpnumber !==''){
        setwpnumber(selectedwpnumber)
      }
    }, [selectedwpnumber])

    useEffect(() => {
        if(selectedwpnumberid !==''){
           setnumberid(selectedwpnumberid)
        }
      }, [selectedwpnumberid])

      useEffect(() => {
        if(selectedwpwabaid !==''){
            setwabaid(selectedwpwabaid)
        }
      }, [selectedwpwabaid])

      useEffect(() => {
        if(selectedwpauthkey !==''){
            setauthkey(selectedwpauthkey)
        }
      }, [selectedwpauthkey])
    

    return (
        <div style={{ marginTop: "30px", marginLeft: "25%" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <TextField
                        label="Whatsapp Number"
                        fullWidth
                        type='number'
                        style={{ width: "500px" }}
                        value={wpnumber}
                        onChange={(e) => handlewpnumber(e)}
                    />
                    {whatsAppNumberValidate && (
                        <FormHelperText error>
                            WhatsApp Number is Required
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Number Id"
                        fullWidth
                        style={{ width: "500px" }}
                        value={numberid}
                        onChange={(e) => handlenumberid(e)}
                    />
                    {whatsappNumberIdValidate && (
                        <FormHelperText error>
                            Number Id is Required
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Auth key"
                        fullWidth
                        style={{ width: "500px" }}
                        value={authkey}
                        onChange={(e) => handleauthchange(e)}
                    />
                    {whatsappAuthKeyValidate && (
                        <FormHelperText error>
                            Auth Key is Required
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="WABA ID"
                        fullWidth
                        style={{ width: "500px" }}
                        value={wabaid}
                        onChange={(e) => handlewapaid(e)}
                    />
                    {whatsAppWabaAid && (
                        <FormHelperText error>
                            WABA Id Required
                        </FormHelperText>
                    )}
                </Grid>
            </Grid>

        </div>
    )
}

export default Whatsapplisting