
import React, { useState, useEffect } from 'react'
import './UserAccount.css'
import { capitalCase } from 'change-case';
import { Link as RouterLink, useNavigate, useParams, useSearchParams, useLocation, createSearchParams, Navigate } from 'react-router-dom';
// @mui
import { Container, Tab, Box, Tabs } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
// _mock_
import { _userPayment, _userAddressBook, _userInvoices, _userAbout } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Diversity3Icon from '@mui/icons-material/Diversity3';
// sections
import {
  AccountGeneral,
  AccountBilling,
  AccountSocialLinks,
  AccountNotifications,
  AccountChangePassword,
} from '../../sections/@dashboard/user/account';
import Teammanagement from 'src/sections/@dashboard/user/account/Teammanagement';
import RoleDataGrid from '../../components/Profile/Role/RoleDataGrid.js'

// ----------------------------------------------------------------------

export default function UserAccount() {

  const { themeStretch } = useSettings();
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();




  const { currentTab, onChangeTab } = useTabs('general');

  useEffect(() => {
    console.log('profile', location.pathname)
    if (location.pathname === '/dashboard/profile/general') {
      setValue('general')
    } else
      if (location.pathname === '/dashboard/profile/billing') {
        setValue('billing')
      } else
        if (location.pathname === '/dashboard/profile/team') {
          setValue('team')
        } else
          if (location.pathname === '/dashboard/profile/role') {
            setValue('role')
          }

  }, [location])



  // onChangeTab = () =>{

  // }



  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 'general') {
      navigate('/dashboard/profile/general')
    } else
      if (newValue === 'billing') {
        navigate('/dashboard/profile/billing')
      } else
        if (newValue === 'team') {
          navigate('/dashboard/profile/team')
        }
    if (newValue === 'role') {
      navigate('/dashboard/profile/role')
    }

  };



  return (
    <Page title="Profile">




      {/* <TabContext value={value} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="General" value="general" />
            <Tab label="Billing" value="billing" />
            {is_admin === 'true' ? (
            
              <Tab label="Team" value="team" />
              ) : ''}
              {is_admin === 'true' ? (
              <Tab label="Role" value="role" />
            
            ) : ''}
            
          </TabList>
        </Box>
        <TabPanel value="general" className='profile-tabs'>
          <AccountGeneral />
        </TabPanel>
        <TabPanel value="billing" className='profile-tabs'>
          <AccountBilling cards={_userPayment} addressBook={_userAddressBook} invoices={_userInvoices} />
        </TabPanel>
        {is_admin === 'true' ? (
          <>
        <TabPanel value="team" className='profile-tabs'>
        <Teammanagement />
        </TabPanel>
        <TabPanel value="role" className='profile-tabs'>
        <RoleDataGrid />
        </TabPanel>
        </>
        ) : ''}
      </TabContext> */}
      <div style={{ marginTop: "5%" }}>


        <AccountGeneral />
      </div>
    </Page>
  );
}
