import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
import moment from 'moment'
// @mui
import { styled } from '@mui/material/styles';
import { Avatar, Tooltip, Box, CardActionArea, Card, CardContent, CardMedia, Typography, Button, Grid } from '@mui/material';
// components
import Image from '../../../Image';
import Iconify from '../../../Iconify';

import TimeZone from 'src/components/TimeZone';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'inline-block',
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

ChatMessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  conversation: PropTypes.object.isRequired,
  onOpenLightbox: PropTypes.func,
};

export default function ChatMessageItem({ message, conversation, onOpenLightbox }) {
  // const sender = conversation.participants.find((participant) => participant.id === message.senderId);

  // const senderDetails =
  //   message.senderId === '8864c717-587d-472a-929a-8e5f298024da-0'
  //     ? { type: 'me' }
  //     : { avatar: sender?.avatar, name: sender?.name };

   const isAgent = message.msg_type === 'agent';

   const isUser = message.msg_type === 'user';

   const isImage = message.fb_msg_type === 'image';

   const isText = message.fb_msg_type === 'text';

   const isVideo = message.fb_msg_type === 'video'

   const istemplate = message.fb_msg_type === 'template'

   const isDocument = message.fb_msg_type === 'document'

  // const firstName = senderDetails.name && senderDetails.name.split(' ')[0];

  return (
    <RootStyle>
      <Box
        sx={{
          display: 'flex',
          // ...(isMe && {
          //   ml: 'auto',
          // }),

          ...(isAgent && {
            marginLeft: 'auto',
            marginRight: 0
          }),
        }}
      >
        {/* {senderDetails.type !== 'me' && (
          <Avatar alt={senderDetails.name} src={senderDetails.avatar} sx={{ width: 32, height: 32, mr: 2 }} />
        )} */}

        <div>
          {/* <InfoStyle
            variant="caption"
            sx={{
              ...(isMe && { justifyContent: 'flex-end' }),
            }}
          >
            {!isMe && `${firstName},`}&nbsp;
            {formatDistanceToNowStrict(new Date(message.createdAt), {
              addSuffix: true,
            })}
          </InfoStyle>
*/}
          <ContentStyle
            sx={{
              ...(isAgent && {
                color: 'grey.800',
                bgcolor: 'primary.lighter',
              }),

              

              ...(isImage && {
                p: 0,
              }),
            }}
          > 
            {isImage && (
              <Image
                alt="attachment"
                src={message.url}
                onClick={() => onOpenLightbox(message.url)}
                sx={{ borderRadius: 1, cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
              />
            )}

            {isText && (
              <Typography variant="body2">
                {message.message}
             
              </Typography>
            )}

            {isVideo && (
              <video width='100%' controls>
                <source src={message.url} type="video/mp4" />
                <source src={message.url} type="video/ogg" />
                Your browser does not support HTML video.
              </video>
            )}

            {istemplate && (
              <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                {message.template.template_type === 'image' && (
                <CardMedia
                  component="img"
                  height="140"
                  image="/static/images/cards/contemplative-reptile.jpg"
                  alt="green iguana"
                />
                )}
                {message.template.template_type === 'TEXT' && (
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" style={{textTransform: 'capitalize'}}>
                      {message.template.entity_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {message.template.template}
                    </Typography>
                  </CardContent>
                )}
                
              </CardActionArea>
            </Card>
            )}


            <Grid container alignItems='center' direction='row'>
              <Grid item xs>
                  <Tooltip title={
                       <React.Fragment>
                          <TimeZone date={message.created_at} />
                       </React.Fragment>
                    }>
                      <label className='date-moment-whatsapp'>
                      {moment(message.created_at).fromNow()}
                      </label>
                    </Tooltip>
              </Grid>
              {isAgent && (

              
              <Grid item style={{minWidth: '50px', textAlign: 'right'}}>

                {message.message_status === 'delivered' && (
                  <>
                <Iconify
                width={14}
                height={14}
                icon={'mdi:tick-all'}
                />
                
                </>
                )}

                {message.message_status === 'send' && (
                <Iconify
                width={14}
                height={14}
                icon={'mdi:tick'}
                />
                )}

                {message.message_status === 'read' && (
                  <>
                <Iconify
                width={14}
                height={14}
                icon={'mdi:tick-all'}
                style={{color: '#11aaff'}}
                />
                
                </>
                )}
             

              </Grid>
              )}
            </Grid>


          </ContentStyle>
        </div>
      </Box>
    </RootStyle>
  );
}
