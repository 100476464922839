import { Button, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import "./callWidget.css";
import React, { useState, useEffect } from "react";
import { REACT_APP_BASE_URL } from "src/utils/axios";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export default function AddBlacklistNumberComponent({setStatus, country_list}) {
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false)
    const [show_country_dropdown, setShowCountryDropDown] = useState(false)
    const [country_list_1, setCountryList1] = useState([]);
    const [selected_country, setSelectedCountry] = useState("")
    const [country_input, setCountryInput] = useState("")
    useEffect(() => {
        if (country_list.length > 0) {
            setSelectedCountry(country_list[0])
        }
        setCountryList1(country_list)
    }, [])

    const search_country = (value) => {
        var country = [];
        setCountryInput(value)
        const regex = /[0-9]/g;
    
        if (regex.test(value) === true) {
          for (let i = 0; i < country_list.length; i++) {
            if (country_list[i].phone.includes(value) === true) {
              country.push(country_list[i])
            }
          }
          setCountryList1(country)
        } else {
          const lower_case = value.toLowerCase();
          for (let i = 0; i < country_list.length; i++) {
            const regionName = regionNames.of(country_list[i].code.slice(0, 2)).toLowerCase();
            if (regionName.includes(lower_case) === true) {
              country.push(country_list[i])
            }
          }
          setCountryList1(country)
        }
      }
    const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
   // const reduxToken = localStorage.getItem('redux-token');
    const change_phone = (value) => {
        if (value.length < 10) {
            setPhone(value)
        } else if (value.length === 10) {
            setPhone(value)
        }
    }

    let authId = localStorage.getItem('authID');

    const add_blacklist = () => {
        if (phone === "") {
            return;
        }
        setLoading(true)
        axios({
            method: "POST",
            url: `${REACT_APP_BASE_URL}/api/blocklist`,
            data: {
                authId: authId,
                number: `+${selected_country.phone}${phone}`
            },
            headers: {
                authorization: localStorage.getItem('redux-token')
            }
        }).then((res) => {
            const response = res.data;
            //console.log("response", response);
            setLoading(false)
            setStatus(false)
        }).catch((err) => {
            //console.log("error_response", err.response.data)
            setLoading(false)
        })
    }
    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", backgroundColor: "#fff" }} className="center-col">
            <div style={{ marginTop: 20, width: "85%", display: "flex", alifnSelf: "center", height: 50, flexDirection: "row", alignItems: "center", justifyContent: "space-between", }}>
            <Button style={{ width: "30%", height: "100%", backgroundColor: "#FAFAFA", borderRadius: 5, boxShadow: "1px 1px 1px 1px lightgrey", display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: "row"}} onClick={(event) => {
                        if (show_country_dropdown === false) {
                          setShowCountryDropDown(true)
                        } else {
                          setShowCountryDropDown(false)
                        }
                }}>
                    {selected_country.code !== undefined && selected_country.code !== null && <img src={`https://flagcdn.com/w80/${selected_country.code.substr(0, 2).toLowerCase()}.png`} style={{height: 25, width: 40 }}/>}
                    <ArrowDropDownIcon color='#0046BB'/>
            </Button>
                {/* <div style={{ width: "60%", height: "100%", backgroundColor: "#FAFAFA", borderRadius: 5, boxShadow: "1px 1px 1px 1px lightgrey", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <input style={{ width: "98%", border: "0px", backgroundColor: "#FAFAFA", height: "100%", borderRadius: 10}} type="number" value={phone} onChange={(e) => change_phone(e.target.value)} placeholder='Mobile Number'/>

                </div> */}
                <TextField
                  placeholder="Mobile Number"
                  id="outlined-size-small"
                  style={{ width: "60%", height: "100%", }}
                  type="number" 
                  value={phone} 
                  onChange={(e) => change_phone(e.target.value)}
                  onSubmit={() => add_blacklist()}
                />
            </div>
            {show_country_dropdown === true && <div className="country-list" style={{ marginTop: 20 }}>
                  <div style={{ backgroundColor: "#fff", padding: "10px 0 6px 10px", position: "sticky", top: 0, zIndex: 2 }}>
                    <input type="search" placeholder="search" value={country_input} autoComplete='off' style={{ border: "1px solid #cacaca", borderRadius: 3, fontSize: 15, height: 30, marginLeft: 6, padding: "3px 8px 5px"}} onChange={(e) => search_country(e.target.value)}/>
                  </div>
                  {country_list_1.map((item, index) => (
                    <Button style={{ width: "100%", height: 45, borderRadius: 2, display: "flex", alignItems: "center", justifyContent: "flex-start"  }} key={index} onClick={() => {
                      setSelectedCountry(item);
                      setShowCountryDropDown(false)
                    }}>
                      <img src={`https://flagcdn.com/w80/${item.code.substr(0, 2).toLowerCase()}.png`} style={{height: 20, width: 30 }}/>
                      <div style={{ marginLeft: 10, fontSize: 13, color: "#000", fontWeight: "normal"}}>
                        {regionNames.of(item.code.slice(0, 2))}
                      </div>
                      <div style={{ marginLeft: 10, fontWeight: "normal", fontSize: 13, color: "grey"}}>
                        +{item.phone}
                      </div>
                    </Button>
                  ))}
                </div>}
            {loading === true ? (
                <CircularProgress color="primary" style={{ marginTop: 20, width: 25, height: 25,}}/>
            ): (
                <Button style={{ width: "85%", height: 50, backgroundColor: "#0046BB", borderRadius: 5, marginTop: 20, display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => add_blacklist()}>
                <div style={{ color: "#fff", fontSize: 15, fontWeight: "bold"}}>
                    Submit
                </div>
                </Button>
            )}
        </div>
    )
}