import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import './style.css'
const Welcome = () => {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate('/dashboard/onboarding/channel')
    }

    return (
        <div>


            <div className="video-responsive">
                <h1 style={{ color: "#2065d1", marginLeft: "20%", fontSize: "50px" }}>Welcome Aboard!</h1>
                <iframe
                    width="800"
                    height="350"
                    src={`https://www.youtube.com/embed/rokGy0huYEA`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
                <p style={{ textAlign: "center", padding: "20px" }}>Let's start by creating an inbox where you can receive conversation details. Check the video above to see the process in action.</p>

                <Button color='primary' variant='contained' style={{ marginLeft: "40%" }} onClick={() => handleClick()}>Create Inbox</Button>
            </div>

        </div>
    )
}

export default Welcome