import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import Profile from 'src/layouts/dashboard/navbar/Profile';
import InvoiceDetailsvik from 'src/sections/@dashboard/user/account/InvoiceDetailsvik';
import UserConfirmation from 'src/sections/@dashboard/user/account/UserConfirmation';
import InvitedSignup from 'src/sections/@dashboard/user/account/InvitedSignup';
import Welcome from 'src/components/Inbox/Welcome';
import ChooseChannel from 'src/components/Inbox/ChooseChannel';
import InboxWhatsAppCompose from 'src/pages/dashboard/InboxWhatsappCompose';
import SmsChat from 'src/pages/dashboard/SmsChat';
import Inbox from 'src/components/Setting/Inbox';
import EditSettings from 'src/components/Setting/EditSettings';
import { AccountBilling } from 'src/sections/@dashboard/user/account';
import { _userAddressBook, _userInvoices, _userPayment } from 'src/_mock';
import Teammanagement from 'src/sections/@dashboard/user/account/Teammanagement';
import RoleDataGrid from 'src/components/Profile/Role/RoleDataGrid';
import WhatsappEdit from 'src/components/Setting/WhatsappEdit';
import SmsEdit from 'src/components/Setting/SmsEdit';
import Edittelegram from 'src/components/Setting/Edittelegram';
import Editviber from 'src/components/Setting/Editviber';
import InboxTelegram from 'src/pages/dashboard/InboxTelegram';
import InboxViber from 'src/pages/dashboard/InboxViber';
import MessengerEdit from 'src/components/Setting/MessengerEdit';
import InboxMessenger from 'src/pages/dashboard/InboxMessenger';
import InstagramEdit from 'src/components/Setting/InstagramEdit';
import InboInstaComments from 'src/pages/dashboard/InboxInstaComments';
import InboxInstagram from 'src/pages/dashboard/InboxInstagram';
import PowerDialer from 'src/components/PoweDialer/PowerDialer';
import Application from 'src/components/Setting/Application';

import WhatsApp from 'src/components/Inbox/Whatsapp/index'
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {

  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          // index: true,
          element: <Login />

        },
        {
          path: 'register',
          element: <Register />

        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'matrix', element: <Dashboard /> },

        {
          path: 'profile',
          children: [
            { element: <Navigate to="/dashboard/profile/general" replace />, index: true },
            { path: 'general', element: <Profile /> },
            { path: 'billing', element: <Profile /> },
            { path: 'team', element: <Profile /> },
            { path: 'role', element: <Profile /> },

          ],
        },

        {
          path: 'number',
          children: [
            { element: <Navigate to="/dashboard/number/buy" replace />, index: true },
            { path: 'buy', element: <NumberBuy /> },
            { path: 'manage', element: <NumberManage /> },
            { path: 'cancelled', element: <NumberCancelled /> },

          ],
        },
        { path: 'powerdialer', element: <PowerDialer /> },
        {
          path: 'settings',
          children: [
            { element: <Navigate to='/dashboard/settings' replace />, index: true },
            { path: 'inbox', element: <Inbox /> },
            { path: "application", element: <Application/>},
            { path: 'inbox/:id', element: <EditSettings /> },
            { path: 'inbox/whatsapp/:id', element: <WhatsappEdit /> },
            { path: 'inbox/sms/:id', element: <SmsEdit /> },
            { path: 'inbox/telegram/:id', element: <Edittelegram /> },
            { path: 'inbox/viber/:id', element: <Editviber /> },
            { path: 'inbox/messenger/:id', element: <MessengerEdit /> },
            { path: 'inbox/instagram/:id', element: <InstagramEdit /> },
            { path: 'customfields', element: <ContactCustomFields /> },
            { path: 'billing', element: <AccountBilling cards={_userPayment} addressBook={_userAddressBook} invoices={_userInvoices} /> },
            { path: 'team', element: <Teammanagement /> },
            { path: 'role', element: <RoleDataGrid /> },
          ]
        },
        {
          path: 'contacts',
          children: [
            { element: <Navigate to="/dashboard/contacts/manage" replace />, index: true },
            { path: 'manage', element: <ContactsManage /> },
            { path: 'manage/search', element: <ContactsManage /> },
            { path: 'manage/:number', element: <ContactsManage /> },
            { path: 'manage/new', element: <ContactsNew /> },
            // { path: 'manage/account/:id', element: <ContactsAccount /> },
            { path: 'manage/account/:id/general', element: <ContactsAccount /> },
            { path: 'manage/account/:id/notes', element: <ContactsAccount /> },
            { path: 'manage/account/:id/conversations', element: <ContactsAccount /> },
            { path: 'manage/account', element: <ContactsAccount /> },
            { path: 'group', element: <ContactsGroup /> },
            { path: 'customfields', element: <ContactCustomFields /> },
            { path: 'import', element: <ContactImport /> }, ,

          ],
        },
        {
          path: 'onboarding',
          children: [
            { element: <Navigate to='/dashboard/onboarding/welcome' replace />, index: true },
            { path: 'welcome', element: <Welcome /> },
            { path: 'channel', element: <ChooseChannel /> },

          ]

        },
        {
          path: 'voicemail',
          children: [
            { element: <Navigate to="/dashboard/voicemail/voicemailbox" replace />, index: true },
            { path: 'voicemailbox', element: <VoiceMailBox /> },

          ],
        },

        {
          path: 'inbox',
          children: [
            { element: <Navigate to="/dashboard/inbox/voice" replace />, index: true },
            // { path: 'voice', element: <InboxVoice /> },
            { path: 'voice/search/:number', element: <InboxVoice /> },
            { path: 'voice/:name/:number', element: <InboxVoice /> },
            { path: 'voice/filter', element: <InboxVoice /> },
            { path: 'voice/export', element: <InboxVoiceExport /> },
            { path: 'voice/notes', element: <InboxVoice /> },
            // { path: 'whatsapp/:name/:phoneNumberId', element: <InboxWhatsApp /> },
            { path: 'whatsapp/:name/:phoneNumberId/new', element: <Chat /> },
            { path: 'whatsapp/:name/:phoneNumberId', element: <InboxWhatsAppCompose /> },
            { path: 'sms/:name', element: <InboxSms /> },
            // { path: 'whatsapp/:conversationKey', element: <Chat /> },
            { path: 'whatsapp/:name/:phoneNumberId/:conversationKey', element: <Chat /> },
            { path: "telegram/:name/:token", element: <InboxTelegram /> },
            { path: "viber/:name/:token", element: <InboxViber /> },
            { path: "messenger/:name", element: <InboxMessenger /> },
            { path: 'messenger/:conversationKey', element: <InboxMessenger /> },
            { path: 'insta/:name', element: <InboxInstagram /> },
            { path: 'insta/:conversationKey', element: <InboxInstagram /> },
            {path:  'whatsappbeta', element: <InboxWhatsappBeta /> },
            {path:  'whatsappbeta/:name', element: <InboxWhatsappBeta /> },

          ],
        },

        {
          path: 'apps',
          children: [
            { element: <Navigate to="/dashboard/apps/ivrstudio" replace />, index: true },
            { path: 'ivrstudio', element: <AppIvrStudio /> },
            { path: 'ivrstudio/flow/:id', element: <AppIvrStudioFlow /> },

          ],
        },

        {
          path: 'support',
          children: [
            { element: <Navigate to="/dashboard/support/tickets" replace />, index: true },
            { path: 'tickets', element: <SupportTickets /> },
            { path: 'livesupport', element: <SupportLiveSupport /> },
            { path: 'helpdocs', element: <SupportHelpDocs /> },
            { path: 'feedback', element: <SupportFeedback /> },

          ],
        },



        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductEdit /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'account/invoiceDetails/:id', element: <InvoiceDetails /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            // { path: ':id', element: <InvoiceDetails /> },
            { path: 'details', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <SmsChat /> },
            // { path: ':conversationKey', element: <Chat /> },
            { path: ':conversationKey', element: <InboxWhatsApp /> },
          ],
        },
        {
          path: 'telegram',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            // { path: ':conversationKey', element: <Chat /> },
            { path: ':conversationKey', element: <InboxTelegram /> },
          ],
        },
        {
          path: 'viber',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            // { path: ':conversationKey', element: <Chat /> },
            { path: ':conversationKey', element: <InboxViber /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
        { path: 'tickets', element: <Tickets /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        // { element: <HomePage />, index: true },
        { element: <Login />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        // { path: "/UserConfirmation/:token", element: <UserConfirmation /> }
      ],
    },
    {
      path: "/",
      children: [
        { element: <Navigate to='/UserConfirmation' />, index: true },
        { path: "/UserConfirmation/:token", element: <UserConfirmation /> },
        { path: "/invitedSignup", element: <InvitedSignup /> }

      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')))

// Number
const NumberBuy = Loadable(lazy(() => import('../pages/dashboard/NumberBuy')));
const NumberManage = Loadable(lazy(() => import('../pages/dashboard/NumberManage')));
const NumberCancelled = Loadable(lazy(() => import('../pages/dashboard/NumberCancelled')));

// Contacts
const ContactsManage = Loadable(lazy(() => import('../pages/dashboard/ContactsManage')));
const ContactsNew = Loadable(lazy(() => import('../pages/dashboard/ContactsNew')));
const ContactsGroup = Loadable(lazy(() => import('../pages/dashboard/ContactsGroup')));
const ContactsAccount = Loadable(lazy(() => import('../pages/dashboard/ContactsAccount')));
const ContactCustomFields = Loadable(lazy(() => import('../pages/dashboard/ContactCustomFields')));
const ContactImport = Loadable(lazy(() => import('../pages/dashboard/ContactImport')));

// Voicemail
const VoiceMailBox = Loadable(lazy(() => import('../pages/dashboard/VoiceMailBox')));

// Inbox
const InboxVoice = Loadable(lazy(() => import('../pages/dashboard/InboxVoice')));
const InboxVoiceExport = Loadable(lazy(() => import('../pages/dashboard/InboxVoiceExport')));
const InboxWhatsApp = Loadable(lazy(() => import('../pages/dashboard/InboxWhatsApp')));
const InboxSms = Loadable(lazy(() => import('../pages/dashboard/InboxSms')));
const CustomFields = Loadable(lazy(() => import('../pages/dashboard/ContactCustomFields')));
const InboxWhatsappBeta = Loadable(lazy(() => import('../components/Inbox/Whatsapp/index')));

// app
const AppIvrStudio = Loadable(lazy(() => import('../pages/dashboard/AppIvrStudio')));
const AppIvrStudioFlow = Loadable(lazy(() => import('../components/IvrStudioApp/index')));

// support
const SupportTickets = Loadable(lazy(() => import('../pages/dashboard/SupportTickets')));
const SupportLiveSupport = Loadable(lazy(() => import('../pages/dashboard/SupportLiveSupport')));
const SupportHelpDocs = Loadable(lazy(() => import('../pages/dashboard/SupportHelpDocs')));
const SupportFeedback = Loadable(lazy(() => import('../pages/dashboard/SupportFeedback')));


// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceProductEdit = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductEdit')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));


// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
// const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));



// Tickets

const Tickets = Loadable(lazy(() => import('../pages/dashboard/Tickets')));
