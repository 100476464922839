import React, { useState , useEffect} from "react";
import { Link as RouterLink, useNavigate, useSearchParams, useLocation, createSearchParams } from 'react-router-dom';
import {
    TextField,
    Button,
    DialogTitle,
    Grid,
    Dialog,
    DialogContent,

} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { createVoiceMail, getVoiceMailBox } from '../../redux/slices/voicemail/voicemailbox';


import Iconify from "../Iconify";

export default function CreateVoiceMail(){ 
  
  const dispatch = useDispatch()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams();
  const {isCreateLoading} = useSelector(state => state.voiceMail)

  const [voiceMailBoxName , setVoiceMailBoxName] = useState("");
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const [offsetCount, setOffsetCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    console.log('location', location, searchParams.get("offsetCount"))
    if(location.pathname === '/dashboard/voicemail/voicemailbox'){
      setOffsetCount(searchParams.get("offsetCount"))
      setRowsPerPage(searchParams.get("limit"))
    }
  }, [searchParams])

  const handleOpenDialog = () =>{
    setOpenDialog(true)
  }

  useEffect(() => {
    if(isCreateLoading){
      setLoading(true)
    }else{
      setLoading(false)
      setOpenDialog(false)
      setVoiceMailBoxName('')
      dispatch(getVoiceMailBox(localStorage.getItem("authID"), offsetCount, rowsPerPage))
    }
  }, [isCreateLoading])
  

  const handleCreate = () =>{
    dispatch(createVoiceMail(voiceMailBoxName, localStorage.getItem("authID")))
  }


    const handleClose = () => {
      setOpenDialog(false);
      setVoiceMailBoxName('')
    };  


    
    

    return (
        <div>

          <Button color='primary'
            variant="contained"
            onClick={handleOpenDialog}
            style={{width:'170px'}}
            >
            <Iconify icon={"akar-icons:plus"} width={16} height={16} /> Create Mail Box
          </Button>

<Dialog
        open={openDialog}
        onClose={handleClose} 
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"New Voicemail"}
        
        </DialogTitle>
                       
        <DialogContent>
          <Grid container spacing={2} direction="row" style={{marginTop: '10px'}}>
            <Grid item xs={12}>
                <TextField  value={voiceMailBoxName} onChange={(e)=> setVoiceMailBoxName(e.target.value)} id="outlined-basic" label="Voice Mail Name" variant="outlined" fullWidth />
            </Grid>

            </Grid>
            <Grid container spacing={2} direction="row" style={{marginTop: '10px'}}>
            <Grid item  xs></Grid>
            <Grid item>       
                <Button onClick={handleClose}>
                Cancel
                </Button>
            </Grid>
          <Grid item>   

          <LoadingButton loading={loading} color="primary" variant="contained" disabled={voiceMailBoxName === ''} onClick={handleCreate} autoFocus>
          Submit
        </LoadingButton>

            </Grid>
            <Grid item  xs={5}></Grid>
          </Grid>
        </DialogContent>
      
      </Dialog>

            
        </div>
     )
    }





