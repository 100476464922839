import { Grid, MenuItem, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import NumberBuy from 'src/pages/dashboard/NumberBuy'
import { incrementChanneladduser } from 'src/redux/ChannelAdduser'
import axiosInstance from 'src/utils/axios'
import Multiselect from 'multiselect-react-dropdown';
import { incrementinboxuserid } from 'src/redux/InboxUserid'
const AddInstagram = () => {

    const [usersel, setusersel] = useState('')
    const selectedadduser = useSelector(state => state.ChanneladduserSlice.value)
    const dispatch = useDispatch()
    // const [userobj, setuserobj] = useState(countries)
    const [code, setcode] = useState({})
    // const [phone, setphone] = useState("")
    const hanldeobj = (e) => {
        // setcode(e)

        dispatch(incrementinboxuserid(e))
        // console.log("selecteduser", code)
    }


    const [user, setuser] = useState([])
    const aduser = () => {
        axiosInstance.get('/users_of_company').then((res) => {
            console.log("user_of_company", res.data.data)
            setuser(res.data.data)
        })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        aduser()
    }, [])
    const handleuser = (value) => {
        console.log("handleuser", value)
        localStorage.setItem('onboardinguserId', value)
        dispatch(incrementChanneladduser(value))
    }

    return (
        <div>
            {/* <NumberBuy /> */}
            <Grid style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "5%" }}>
                <Typography>
                    Here you can add users to manage your newly created inbox. Only these selected users will have access to your inbox.
                </Typography>
                <Typography>
                    Uers which are not part of this inbox will not be able to see or respond to messages in this inbox when they login.
                </Typography>
                <Typography>
                    PS: As an administrator, if you need access to all inboxes,you should add yourself as user to all inboxes that you create.
                </Typography>


            </Grid>

            {/* <Grid style={{ paddingLeft: "10%", paddingRight: "17%", marginTop: "20px" }}>
                <TextField
                    name='Select user'
                    label='Select user'
                    fullWidth
                    select
                    value={usersel}
                    onChange={(e) => setusersel(e.target.value)}
                >
                    {user.map((item) => {
                        return <MenuItem key={item._id} onClick={() => handleuser(item._id)} value={item._id}> {item.FirstName}</MenuItem>
                    })}

                </TextField>
            </Grid> */}

            <Grid style={{ paddingLeft: "10%", paddingRight: "17%", marginTop: "20px" }}>
                <Multiselect
                    options={user}
                    displayValue='FirstName'
                    onSelect={(e) => hanldeobj(e)}
                />
            </Grid>

        </div >
    )
}

export default AddInstagram