import React , {useState, useEffect, useCallback, useRef} from 'react';
import {
    IconButton,
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    ListItem,
    TextField,
    List,
    FormControl,
    ListItemSecondaryAction,
    Fab,
    InputAdornment,
    DialogTitle,
    Tooltip,
    Snackbar,
    Box,
    RadioGroup,
    FormLabel,
    Checkbox,
    FormControlLabel,
    Alert,
    Typography
} from '@mui/material';
import {SET_CALL_HISTORY, SET_MISSED_CALL} from "src/redux/userReducer"
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getIvrVoice} from '../../../../redux/slices/Inbox/voice';


import CloseIcon from '@mui/icons-material/Close';

import AddIcon from '@mui/icons-material/Add';

import CreateIcon from '@mui/icons-material/Create';
import SearchIcon from '@mui/icons-material/Search';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';  
import { TagFacesTwoTone } from '@mui/icons-material';

import _ from 'lodash'; 
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import RefreshIcon from '@mui/icons-material/Refresh';


import Iconify from '../../../Iconify';
import axiosInstance, { REACT_APP_BASE_URL } from '../../../../utils/axios';
import axios from 'axios';

  

export default function TagSelect({
  afterTagAddition,
  tagSelectopen,
  setTagSelectopen,
  originFromWhereTagsIsCalled,
  rowData,
  handleCloseTagSelect,
  tags,
  colorSelectopen, 
  parentId, 
  userId, 
  handleMessageNotify,
   openTagAfterPost,
   handleTagFormDialog, 
   handleNotification,
   handleCloseColorSelect,
   handleOpenColorSelect,
   colorSelectvalue,
   handleChangeColor,
   handleToggleTag,
   checkedTag, 
   handleFormSubmit}){




    const [newTag, setNewTag] = useState('');
    const {callHistory, missed_call} = useSelector((state) => state.user)
    const [tagArr, setTagArr] = useState([]);
    const [selectedTagsAfterListing , setSelectedTagsAfterListing] = useState([]);
    const [ alltagsInUser , setAllTagsInUser ] = useState([]);
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true);
    const [flag, setFlag] = useState(false);

    const flagChange = () => setFlag(!flag);

    const initialValues = {
      name: '',
      backgroundColor: '#fff', 
      checked:false
    }

    let colorCodeObj = {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false
      }
    const [values, setValues] = useState(initialValues)

    const [clrArr, setClrArr] = useState(colorCodeObj)


    const postTag = (user_id) => {
      console.log("I am the data", values)
      axiosInstance.patch(`users/addTag/${user_id}`, values)
      .then(res => {
        handleMessageNotify("Tag added to Body!")
        handleNotification(true)
        afterTagAddition();
        flagChange();
        openTagAfterPost(user_id, parentId);
      }).then(() => setValues(initialValues))
      .catch(err => {
        console.log("err 128 : ", err)
          handleMessageNotify(err.response.data.message)
          handleNotification(true)
      });
    }

    const postTagToParent = (parentId, data) => {
      console.log("I am the data",data)
      axiosInstance.patch(`ivrstudios/addTags/${parentId}`, data)
      .then(() => {
        afterTagAddition();
      })
      .catch(err => console.log("These are ", err))
    } 

    const postTagToCloudPhone = (rowId, data) => {
      console.log("Global data send to cloud-phone Tags ",data)
      let dataToSend = {"Tags": data}
      axiosInstance.patch(`/cp/inbox/${rowId}`, dataToSend)
      .then(() => {
        afterTagAddition();
        closeTab();
      })
      .catch(err => console.log("These are ", err))
    }

    const postTagToLineForward = (rowId, data) => {
      console.log("Global data send to line forward Tags ",data)
      let dataToSend = {"Tags": data}
      axiosInstance.put(`/lf/inbox/${rowId}`, dataToSend)
      .then(() => {
        afterTagAddition();
        closeTab();
      })
      .catch(err => console.log("These are ", err))
    }

    const postTagToCallTracking = (rowId, data) => {
      console.log("Global data send to call tracking Tags ",data)
      let dataToSend = {"Tags": data}
      axiosInstance.put(`/campaign/inbox/${rowId}`, dataToSend)
      .then(() => {
        afterTagAddition();
        closeTab();
      })
      .catch(err => console.log("These are ", err))
    }
    


    const removeTagFromParent = (parentId, name) => {
      axiosInstance.patch(`ivrstudios/removeTag/${parentId}/${name}`)
      .then((res) => {
        console.log("This is the....", res.data)
        afterTagAddition();
      })
      .catch(err => console.log("These are ", err))
    }

    let token = localStorage.getItem('auth_vibcrm');
    const [count, setCount] = useState(0);

    const createUnionCheck = (allTags, checkedTags) => {
      let newArr = [];
      let newUncheck = []; 
      let state = {}//O'n
      console.log("Check for tags", allTags, checkedTags);
        for (let i in checkedTags) {
      
          state = {
            name: checkedTags[i].name,
            backgroundColor: checkedTags[i].backgroundColor,
            checked: checkedTags[i].checked,
            refId: checkedTags[i].refId
          }
          
          newArr.push(state)
        }
        // O(i*j)
        if (allTags.length === 0){
          refreshTags()
        }
        for (let i in allTags) {
            state = {
              name: allTags[i].name,
              backgroundColor: allTags[i].backgroundColor,
              checked: false,
            }

            newUncheck.push(state)
        }

        let result = _.unionBy(newArr, newUncheck, 'name')
        setTagArr(result) 
        setLoading(false)     
      }

    const fetchTags = async (userId) => {
      let allTags;
      let checkedTags;
      console.log("Tags response again ...", userId)
      setLoading(true)

     await axiosInstance.get(`users/getTags/${userId}`)
      .then(res => {
        allTags = res.data.tags 
        setAllTagsInUser(allTags)
        console.log("We contain the Tags...", res.data.tags);
      }).then(
        axiosInstance.get(`ivrstudios/getTags/${parentId}`)
        .then(res => {
          checkedTags = res.data.tags
        }).then(() => 
          createUnionCheck(allTags, checkedTags)
        ).then(() => setLoading(false))
        .catch(err => {
          console.log(err);
          return
        })
      )
      .catch(err => {
        console.log("I contain the Error", err);
        return
      })
    }
    
    const fetchTagsGlobal = async (userId , rowDetails , tags) => {
      let allTags;
      let checkedTags = tags ;
      console.log("Tags response again ...", userId)
      setLoading(true)

     await axiosInstance.get(`users/getTags/${userId}`)
      .then(res => {
        allTags = res.data.tags 
        setAllTagsInUser(allTags)
        console.log("We contain the Tags...", res.data.tags);
        createUnionCheck(allTags, checkedTags)
      }).catch(err => {
        console.log("I contain the Error", err);
        return
      })
    }

    useEffect(() => {
      console.log('Global Tag Select is called', originFromWhereTagsIsCalled , rowData , tags)
      let val = 1 + count
      if(originFromWhereTagsIsCalled === 'Cloud_Phone'){
        fetchTagsGlobal(userId, rowData, tags)
      } else if(originFromWhereTagsIsCalled === 'Line_Forward'){
         fetchTagsGlobal(userId, rowData, tags)
      } else if(originFromWhereTagsIsCalled === 'Call_Tracking'){
        fetchTagsGlobal(userId, rowData, tags)
     }
      else{
        fetchTags(userId)
      }
      setCount(val)
        // fetchTags(userId)
        console.log("My Target", count)
    }, [userId, flag])

  const handleChange = (e) => {
    let value = e.target.value;
    
    setNewTag(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFormSubmit(values)
    postTag(userId)
    // handleCloseTagSelect()
    handleCloseColorSelect()
    refreshTags();
    dispatch(getIvrVoice())
  }

  const closeTab = (e) => {
    handleCloseTagSelect();
    flagChange();
  }

  let buttonStyles = {
          
  }

  const handleToggle = (e) => {
    console.log("Global e.target.value", e.target)
    const {checked, value} = e.target;
    if (checked === false) {
      removeTagFromParent(parentId, value);
    }
      let valToToggle = tagArr.find(val => {return val.name === value})
      let valToFilter = tagArr.filter(val=> {return val.name !== value})
      valToToggle.checked = checked;
      valToFilter.push(valToToggle)
      selectedTagsAfterListing.push(valToToggle)//selectedTagsAfter Listing
      console.log("I am value to Toggle", valToToggle);
      setTagArr(valToFilter);
      if (checked === true) {
        postTagToParent(parentId,valToToggle);
        // if(originFromWhereTagsIsCalled === 'Cloud_Phone'){
        //   let dataWhichIsSelectedToToMarkAsSelectedTags = valToFilter.filter(val => {return val.checked === true})
        //   postTagToCloudPhone(rowData._id,  dataWhichIsSelectedToToMarkAsSelectedTags)
        // }

      }
      console.log("Global after selecting ", originFromWhereTagsIsCalled , parentId ,valToToggle , valToFilter )
    }


    const saveTags = (e) => {
      
      e.preventDefault();
      console.log("Global save tags is called", tagArr)
      let onlySelectedTags = tagArr.filter(val => {return val.checked === true})
      console.log("Global only selected tags", onlySelectedTags)
      if(originFromWhereTagsIsCalled === 'Cloud_Phone'){
        postTagToCloudPhone(rowData._id,  onlySelectedTags)
      
      }
      if(originFromWhereTagsIsCalled === 'Line_Forward'){
        postTagToLineForward(rowData._id,  onlySelectedTags)
      
      }
      if(originFromWhereTagsIsCalled === 'Call_Tracking'){
        postTagToCallTracking(rowData._id,  onlySelectedTags)
       
      }

      handleCloseTagSelect()
      dispatch(getIvrVoice())

      axios({
        method: "GET",
        url: `${REACT_APP_BASE_URL}/api/ivrstudios/flowchart?ParentCallSid=${parentId}`,
        headers: {
          authorization: localStorage.getItem("redux-token")
        }
      }).then((res) => {
        console.log("response_data", res.data)
        const response_data = res.data.data.ivrFlow;
        if (response_data.length > 0) {
          const Tags = response_data[0].Tags;
          const formValues = [...callHistory]
           console.log("callHistory", formValues);

           const findIndex= callHistory.findIndex(value => { return parentId === value.ParentCall.ParentCallSid });
           const missed_formValues = [...missed_call];
           console.log("missed_call", missed_formValues)
           const index = missed_call.findIndex(value => { return parentId === value.ParentCall.ParentCallSid });
           if (index !== -1) {
                  missed_formValues[index].Tags = Tags;
                  dispatch({
                    type: SET_MISSED_CALL,
                    payload: missed_formValues
                  })
                }
      
                if (findIndex !== -1) {
                  formValues[findIndex].Tags = Tags;
                  dispatch({
                    type: SET_CALL_HISTORY,
                    payload: formValues
                  })
                }
        }
      }).catch((err) => {
        console.log("error_response", err)
      })
    }

  const handleInputChange = (e, indx) => {
    const {name, value} = e.target;
    console.log("we are the values", {name, value, indx})
    console.log("Object for Color change",{...clrArr, [indx]: !clrArr.indx})
    setClrArr({
      ...colorCodeObj, 
      [indx]: !clrArr.indx
    })
    setValues({
      ...values,
      [name]: value,
    })
    flagChange()
  }

  const openEdit = (item) => {
    console.log("Parent Tag Data", item);
    handleTagFormDialog(item, userId)
  }
  
  let dialogTitle = {
    "display": "flex",
    "justify-content": "space-between",
    "align-items": "center"
}
const ContrastToBlack = {
  'Dusty Rose': '#DEB3AD',
  Coral: '#DE847B',
  'Tiger Lily': '#B95C50',
  Mauve: '#D3B1C2',
  Lavender: '#C197D2',
  Brass: '#E1C16E',
  Orange: '#F86F15',
  'Baby Blue': '#A2C4E0',
  'Corn Flame': '#52688F',
  'Blue Green': '#39918C',
  'baby Blue': '#D4F1F4',
  'neon Green': '#A3EBB1'
}



const black = "2px solid #000000";


const refreshTags = () => {
  return flagChange()
}

const [showSnack, setShowSnack] = useState(false)
const [snackMessage, setSnackMessage] = useState('')

const [userIdColor, setUserIdColor] = useState('')
const [userIdColorName, setUserIdColorName] = useState('')

const [editName, setEditName] = useState('')
const [editNameField, setEditNameField] = useState('')
const [editChecked, setEditChecked] = useState('')
const [editBackgroundColor, setEditBackgroundColor] = useState('')

const [editShow, setEditShow] = useState(false)
const [updateSuccess, setUpdateSuccess] = useState(false)

const [deleteAlert, setDeleteAlert] = useState(false)

const handleCloseAlert = () =>{
  setDeleteAlert(false)
}


const handleCloseSnack = () =>{
  setShowSnack(false)
}

const deleteApi = () => {
  setDeleteAlert(false)
  axiosInstance.patch(`users/delTag/${userIdColor}/${userIdColorName}`)
  .then(res => console.log("Delete with warn", res))
  .then(() => {
    afterTagAddition()
    setShowSnack(false)
    fetchTags(userIdColor)
    setUpdateSuccess(false)
  })
  .catch(e => console.log("Delete with warn...", e))
}

const deleteWithWarn = (userId, name) => {
  setUserIdColor(userId)
  setUserIdColorName(name)
  setDeleteAlert(true)
  // if (result){
  //   deleteApi(userId, name);
  // }
}

const editTag = (item) =>{
  console.log('edit click', item)
  setEditShow(true)
  setEditName(item.name)
  setEditNameField(item.name)
  setEditChecked(item.checked)
  setEditBackgroundColor(item.backgroundColor)
  
}

const tagValues = {
  name: editNameField,
  checked: editChecked,
  backgroundColor: editBackgroundColor,
}

const editTagSave = () =>{
  console.log('editTagSave', tagValues)
  axiosInstance.patch(`users/updateUser/${userId}/${editName}`, tagValues)
  .then(() => {
    console.log('edit success')
    setEditShow(false)
    setShowSnack(true)
    setSnackMessage(`Updated Successfully?`)
    setUpdateSuccess(true)
    fetchTags(userIdColor)
  })
  .catch(err => console.log("These are", err));
}

const handleInputEditChange = (e, code) => {
  const {name, value} = e.target;
  console.log("Item to click update",code)
  console.log("Item To click update...", {...colorCodeObj, [code]: !clrArr[code]}, {[code] : clrArr[code]});
  setEditBackgroundColor(code)
  // setClrArr({...colorCodeObj, [code]: !clrArr[code] })
  // setValues({
  //   ...values,
  //   [name]: value,
  // })
  
}
 const showlist = () =>{
  setEditShow(false)
  fetchTags(userIdColor)
 }
    return(
      <div>


<Snackbar
      open={showSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        message={snackMessage}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
          }}

          action={
            <React.Fragment>

              
              {updateSuccess === true ? (
                <>
                <Button color="secondary" variant='contained' size="small" onClick={handleCloseSnack}>
                  ok
                </Button>
                </>
                ) : (
                <>
                <Button color="secondary" size="small" onClick={handleCloseSnack}>
                  Cancel
                </Button>

                <Button color="secondary" variant='contained' size="small" onClick={deleteApi}>
                  Confirm
                </Button>
                </>
              )}
              
              
            </React.Fragment>
          }

      />


        <Dialog
              open={tagSelectopen}
              onClose={handleCloseTagSelect}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              
              >


{deleteAlert === true ? (
                      <Alert
                        severity="warning"
                        // action={
                        //   <>
                        //   <Button color="inherit" size="small" onClick={handleCloseAlert}>
                        //     Cancel
                        //   </Button>
                        //   <Button color="primary" variant='contained' size="small" onClick={deleteApi}>
                        //     Confirm
                        //   </Button>
                        //   </>
                        // }
                      >
                        Deleting this tag will remove it from Tasks and Notes also.<br/> Are you sure want to Continue?
                        <br/> 
                        <Typography style={{textAlign: 'right'}}>
                        <Button color="inherit" size="small" onClick={handleCloseAlert}>
                            Cancel
                          </Button>
                          <Button color="primary" variant='contained' size="small" onClick={deleteApi}>
                            Confirm
                          </Button>
                        </Typography>
                        
                      </Alert>
                    ) : (
                      <>



              <DialogTitle disableTypography style={dialogTitle}>
                <h3>Add Tag</h3>
                <Box sx={{display: 'flex', flexDirection: "row"}}>
                {editShow === true ? '' : (
                  <Tooltip title='Refresh Tags'>
                  <IconButton onClick={refreshTags}>
                    <RefreshIcon/>
                  </IconButton>
                </Tooltip>
                ) }
                <Tooltip title="close">
                  <IconButton onClick={closeTab}>
                  <Iconify icon='eva:close-fill'  />
                  </IconButton>
                </Tooltip>
                </Box>
              </DialogTitle>

              {editShow === false ? (
                <>
               

            {colorSelectopen===false?
                    <DialogContent>
                       <Grid
                        container
                        direction='row'
                        alignItems='center'
                        >
                        
                        <Grid item xs>
                        
                        <TextField
                              variant="outlined"
                              id="input-with-icon-textfield"
                              placeholder="Search "
                              fullWidth
                              onChange = {handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
             
                        />
                        </Grid>
                        <Grid item>
                        <IconButton size="small"  onClick={handleOpenColorSelect}>
                        <Fab size="small" color="primary" className="blue" >
                           <AddIcon/>
                           </Fab>
                         </IconButton>
                        </Grid>

        

                    </Grid>

                 
                      <div style={{maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: '30px', marginTop: "10px"}}>

                        {loading === true ? 
                        <>
                        loading..
                        </> : < >
                          { tagArr !== undefined && tagArr.length > 0 ?
                            tagArr.filter(val => {
                            if (newTag == "") {
                              return val; 
                            } else if (val.name.toLowerCase().includes(newTag.toLowerCase())) {
                              return val
                            }
                          }).sort(item => {return item.checked ? -1 : 1}).map((item) => {
                            const labelId = `checkbox-list-label-${item}`;
                            return (
                                      <>
                                      
                                      <Grid container alignItems='center' className='tag-color-list' direction='row' style={{color:item.color,background:item.backgroundColor, width:"300px", marginTop: "10px", borderRadius: "5px"}} >
                                      <Grid item xs style={{padding: "10px", fontWeight: "600", fontSize: "16px"}}>
                                        {item.name}
                                        </Grid>

                                      <Grid item style={{width: "50px", height: "50px"}}>

                                        <Tooltip title='Remove Tag' >
                                          <IconButton  onClick={()=>deleteWithWarn(userId, item.name)} edge="end" aria-label="delete" className='edit-hover'>
                                            <DeleteOutlineOutlinedIcon />
                                          </IconButton>    
                                        </Tooltip>  

                                        
                                        </Grid>

                                      <Grid item style={{width: "50px", height: "50px"}}>

                                        <Tooltip title='Update Tag' >
                                          <IconButton  onClick={()=>editTag(item)} edge="end" aria-label="add color" className='edit-hover'>
                                            <CreateIcon />
                                          </IconButton>    
                                        </Tooltip>  

                                        
                                        </Grid>
                                      <Grid item style={{width: "50px"}}>

                                    
                                        <Checkbox
                                          icon={<PanoramaFishEyeIcon  />} checkedIcon={<CheckCircleIcon style={{color:"#0046BB"}} />} 
                                          edge="start"
                                          onChange={handleToggle}
                                          value={item.name}
                                          checked={item.checked}
                                          // checked={checkedTag.indexOf(item) !== -1}
                                          inputProps={{ 'aria-labelledby': labelId }}
                                          />
                                        </Grid>
                                      </Grid>                             
                                              
                                              
                              
                                      
                        </>
                        )
                        }) : <h4>Tag List is Empty!</h4>}
                        </>}
                        </div>
                        <Button variant='contained' color="primary" onClick={saveTags}>
                        save Changes
                        </Button>
                       

                    

                    
                    


           </DialogContent>:
           <DialogContent style={{background:'#F0F0F0'}}>
           <form>
           <Grid
                            container
                            // spacing={2}
                            alignItems="left"
                            style={{width:"300px"}}
                        >
							
                            <Grid item xs={12}>
           
           <TextField
             id="firstName"
             label="tag name"
             name = "name"
             value = {values.name}
             variant="outlined"
             onChange={handleInputChange}
           style={{width:"300px"}}
             
           />

</Grid>
<Grid item xs={6}><FormLabel component="legend" style={{marginTop:"10px",marginBottom:"10px"}}>Select Color</FormLabel></Grid>
<Grid item xs={12}>
                 <FormControl component="fieldset">
              <RadioGroup 
                row
                aria-label="gender"
                name="backgroundColor"
                style={{justifyContent: "center"}}
                className="feedback-radio" 
                value={values.backgroundColor} 
              onChange={handleInputChange}>
              {
                Object.values(ContrastToBlack).map( (colorCode, i) =>
                  <>
                    <FormControlLabel 
                      value={colorCode} 
                      name='backgroundColor'
                      control= {  
                          <Button 
                            className='Color-radioGroup' 
                            onClick={(e)=>handleInputChange(e, i)}
                            style={clrArr[i] === false ?
                              {backgroundColor: colorCode, margin: '5%'}:
                              {backgroundColor: colorCode, margin: '5%', "border": black}   
                            } 
                        />
                      }
                    />  
                  </>
                )
              }
      </RadioGroup>
    </FormControl>
    </Grid>
        
    </Grid>
    
                        		
                        <DialogActions style={{marginRight:"140px"}}>
						
                           <Button color="primary" variant="contained" disabled={values.name !== '' ? false : true} autoFocus style={{width:"150px",marginTop:"15px"}} type="submit" onClick={handleSubmit}>
                              save data
                            </Button>
                        </DialogActions>
                </form>
   
               </DialogContent>
    
    }

</>
              ) : (
                <>
               
    <>


    <DialogContent style={{background:'#F0F0F0'}}>
      <form>
    <Grid
       container
       // spacing={2}
       alignItems="left"
       style={{width:"300px"}}
       >

       <Grid item xs={12}>

        <TextField
          id="TagForm"
          name = "name"
          value = {editNameField}
          variant="outlined"
          onChange={(event)=>setEditNameField(event.target.value)}
          fullWidth
        />

    </Grid>
     <Grid item xs={6}><FormLabel component="legend" style={{marginTop:"10px",marginBottom:"10px"}}>Select Color</FormLabel></Grid>
    <Grid item xs={12}>
          <FormControl component="fieldset">
          <RadioGroup 
            
            aria-label="gender"
            name="backgroundColor"
            style={{justifyContent: "center"}}
            className="feedback-radio" 
            value={editBackgroundColor} 
            onChange={handleInputChange}>
            <Grid container direction="row" spacing={2}>
              {Object.values(ContrastToBlack).map((colorCode) =>
                    <Grid item xs={4}>
                    <FormControlLabel 
                      value={colorCode} 
                      name='backgroundColor'
                      control= {
                          <Button className='Color-radioGroup'
                            onClick={(e)=>handleInputEditChange(e, colorCode)}
                            style={ editBackgroundColor !== colorCode ?
                              {backgroundColor: colorCode, border: `2px solid ${colorCode}`}:
                              {backgroundColor: colorCode, border: "2px solid #000"}   
                            } 
                            />
                      }
                    />
                    </Grid>
                )
              }
            </Grid>
            
            </RadioGroup>
          </FormControl>
    </Grid> 
    
    </Grid>
    </form>
   
   </DialogContent>
    <DialogActions>
    <Grid container direction="row" spacing={2}>
      <Grid item xs></Grid>
      <Grid item>
        <Button 
            color="primary" 
            onClick={showlist}
          >
              Back
          </Button>

        <Button 
            color="primary" 
            variant="contained" 
            autoFocus 
            onClick={editTagSave}
            disabled = {editNameField === '' ? true : false}
          >
              Update
          </Button>
      </Grid>
      <Grid item xs></Grid>
    </Grid>
          
         </DialogActions>
         
    </>

    </>
              )}

</>
                    )}
                        
        </Dialog>
     
    </div>
    )
}

export const TagForm = ({
  afterTagAddition,userId, 
  handleFormSubmit, tagFormOpen,
  setTagFormOpen, handleCloseTagSelect,
  handleFormDialogClose, handleCloseColorSelect, setColorSelectopen, item}) => {

    const dispatch = useDispatch();

  let colorCodeObj = {
    '#DEB3AD': false,
    '#DE847B': false,
    '#B95C50': false,
    '#D3B1C2': false,
    '#C197D2': false,
    '#E1C16E': false,
    '#F86F15': false,
    '#A2C4E0': false,
    '#52688F': false,
    '#39918C': false,
    '#D4F1F4': false,
    '#A3EBB1': false
  }

  const initialValues = {
    name: '',
    checked: '',
    backgroundColor: '', 
  }
  const [values, setValues] = useState(initialValues)
  const [clrArr, setClrArr] = useState(colorCodeObj);
  const [flag, setFlag] = useState(false)
  const [count, setCount] = useState(0)




  const toggleFlag = () => setFlag(!flag);

  const updateTag = (user_id, name) => {
      console.log("i am running edit", user_id)
      axiosInstance.patch(`users/updateUser/${user_id}/${name}`, values)
      .then(() => {
        afterTagAddition()
        handleCloseTagSelect()
      })
    .catch(err => console.log("These are", err));
  }

  useEffect(() => {
    setCount(count + 1);
    if (count < 2) {
      setValues(item);
      setClrArr({
        ...colorCodeObj,
        [item.backgroundColor]: !clrArr[item.backgroundColor]
      })
    } else {
      console.log("Item to update",clrArr)
    }
  }, [item, flag])

  const closeTab = (e) => {
    handleFormDialogClose();
    setCount(1);
    setTagFormOpen(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("These are my values....", values, userId)
    handleFormSubmit(values)
    updateTag(userId, item.name)
    setCount(1);
    dispatch(getIvrVoice())
    // handleFormDialogClose();

  }
  const handleInputChange = (e, code) => {
    const {name, value} = e.target;
    console.log("Item to click update",code)
    console.log("Item To click update...", {...colorCodeObj, [code]: !clrArr[code]}, {[code] : clrArr[code]});
    setClrArr({...colorCodeObj, [code]: !clrArr[code] })
    setValues({
      ...values,
      [name]: value,
    })
    toggleFlag();
  }

  const deleteApi = (userId, name) => {
    axiosInstance.patch(`users/delTag/${userId}/${name}`)
    .then(res => console.log("Delete with warn", res))
    .then(() => {closeTab(); afterTagAddition()})
    .catch(e => console.log("Delete with warn...", e))
  }

  const deleteWithWarn = (userId, name) => {
  
    // if (result){
    //   deleteApi(userId, name);
    // }
  }

  let dialogTitle = {
    "display": "flex",
    "justify-content": "space-between",
    "align-items": "center"
}

const ContrastToBlack = {
  'Dusty Rose': '#DEB3AD',
  Coral: '#DE847B',
  'Tiger Lily': '#B95C50',
  Mauve: '#D3B1C2',
  Lavender: '#C197D2',
  Brass: '#E1C16E',
  Orange: '#F86F15',
  'Baby Blue': '#A2C4E0',
  'Corn Flame': '#52688F',
  'Blue Green': '#39918C',
  'baby Blue': '#D4F1F4',
  'neon Green': '#A3EBB1'
}



  return (

    <>
    <Dialog
    open={tagFormOpen}
    onClose={handleFormDialogClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    
      <DialogTitle disableTypography style={dialogTitle}>
        <h3>Add A Tag</h3>
        <Box sx={{display: 'flex', flexDirection: "row"}}>
          {/* <Tooltip title='Delete Tag'>
            <IconButton onClick={() => deleteWithWarn(userId, item.name)}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Tooltip> */}
          <Tooltip title='Close'>
            <IconButton onClick={closeTab}>
            <Iconify icon='eva:close-fill'  />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent style={{background:'#F0F0F0'}}>
      <form>
    <Grid
       container
       // spacing={2}
       alignItems="left"
       style={{width:"300px"}}
     >
       <Grid item xs={12}>
    <TextField
      id="TagForm"
      name = "name"
      value = {values.name}
      variant="outlined"
      onChange={handleInputChange}
      fullWidth
    />

</Grid>
<Grid item xs={6}><FormLabel component="legend" style={{marginTop:"10px",marginBottom:"10px"}}>Select Color</FormLabel></Grid>
<Grid item xs={12}>
      <FormControl component="fieldset">
       <RadioGroup 
         row
         aria-label="gender"
         name="backgroundColor"
         style={{justifyContent: "center"}}
         className="feedback-radio" 
         value={values.backgroundColor} 
        onChange={handleInputChange}>
        {Object.values(ContrastToBlack).map((colorCode) =>
              <FormControlLabel 
                value={colorCode} 
                name='backgroundColor'
                control= {
                    <Button className='Color-radioGroup'
                    onClick={(e)=>handleInputChange(e, colorCode)}
                    style={clrArr[colorCode] === false ?
                      {backgroundColor: colorCode, margin: '3px 6px '}:
                      {backgroundColor: colorCode, margin: '5%', "border": "2px solid #000"}   
                    } 
                    />
                }
              />
          )
        }
        </RadioGroup>
      </FormControl>
</Grid>
 
</Grid>

                     
      <DialogActions>
     
          <Button 
            color="primary" 
            variant="contained" 
            autoFocus 
            style={{width:"150px",marginTop:"15px"}} 
            type="submit" 
            onClick={handleSubmit}
          >
              Save Changes
           </Button>
         </DialogActions>
         </form>

      </DialogContent>
    </Dialog>

    

    </>
  )
}
