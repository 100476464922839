import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Avatar, ListItemText, ListItemAvatar, ListItemButton } from '@mui/material';
//
import BadgeStatus from '../../../components/BadgeStatus';
import axiosInstance , {baseUrl} from '../../../utils/axios';
import moment from 'moment';

// ----------------------------------------------------------------------

const AVATAR_SIZE = 48;
const AVATAR_SIZE_GROUP = 32;

const RootStyle = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  transition: theme.transitions.create('all'),
}));

const AvatarWrapperStyle = styled('div')({
  position: 'relative',
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  '& .MuiAvatar-img': { borderRadius: '50%' },
  '& .MuiAvatar-root': { width: '100%', height: '100%' },
});

// ----------------------------------------------------------------------

const getDetails = (conversation, currentUserId) => {
  console.log('Viber getDetails', conversation, currentUserId);
  const otherParticipants = conversation.participants.filter((participant) => participant.conversation_id !== currentUserId);
  // console.log('getDetails: otherParticipants', otherParticipants);
  // const displayNames = otherParticipants.reduce((names, participant) => [...names, participant.user_name], []).join(', ');
  //new display names
  let whatsappNumber = localStorage.getItem('whatsappNumber');
  const displayNamesArray = otherParticipants.filter((participant) => participant.number !== whatsappNumber)
  console.log("Viber List displayNamesArray : ", displayNamesArray)
//   const displayNames = displayNamesArray[0] ? `${displayNamesArray[0].user_name ?( displayNamesArray[0].user_name.firstName ? displayNamesArray[0].user_name.firstName : displayNamesArray[0].user_name) : displayNamesArray[0].number} ${displayNamesArray[0].user_name}` : displayNamesArray[0].user_name;
const displayNames = displayNamesArray[0] ? displayNamesArray[0].user_name  : "";
  const displayNumber = displayNamesArray[1] ? displayNamesArray[1].number : displayNamesArray[0].number;
  let displayText = '';
  const lastMessage = conversation.messages[conversation.messages.length - 1];
  
  let sender
  if (lastMessage) {
    sender = lastMessage.Direction === 'Inbound' ? conversation.participants[0].user_name : 'You';
    let message
    if(lastMessage.Direction === 'Inbound'){
      message = lastMessage.messageBody
    }
    if(lastMessage.Direction === 'Outbound'){
      if(lastMessage.messageBody){
        if(lastMessage.messageBody.length > 20){
          message = lastMessage.messageBody.slice(-10) + '...'
        
        }else{
          message = lastMessage.messageBody
        }
      }else{
        message = lastMessage.templateName.substring(0,15) + '...'
    
      }

    }
    // const message = lastMessage.Direction === 'Inbound' ? lastMessage.messageBody : lastMessage.templateName;
    //  displayText = `${sender} - ${message}`;
    displayText = `${message}`;
  }
  console.log('List getDetails', conversation, currentUserId , displayNames, displayText , sender , displayNumber);
  return { otherParticipants, displayNames, displayText , sender , displayNumber };
};

const getImageLink = async (participant) => {
//console.log('getImageLink', participant);
if(participant.user_id === null ) return null
if(participant.user_id.photo){
  const link = `${baseUrl}/contact/download?fileLocation=${participant.user_id.photo}`;
  return link;
}else{
  // const contactsDetails = await axiosInstance.get(`/contacts/${participant.user_id._id}`);
  // console.log('getImageLink: contactsDetails', contactsDetails.data.data , contactsDetails.data.data.length );
  // if(contactsDetails.data.data.length > 0){
  //   let link = null
  //   if(contactsDetails.data.data[0].photo){
  //     link = `${baseUrl}/contact/download?fileLocation=${contactsDetails.data.data[0].photo}`;
  //   }
  //   return link;
  // }
  return null;
}

}

ViberChatConversationItem.propTypes = {
  isSelected: PropTypes.bool,
  conversation: PropTypes.object.isRequired,
  isOpenSidebar: PropTypes.bool,
  onSelectConversation: PropTypes.func,
};

export default function ViberChatConversationItem({ isSelected, conversation, isOpenSidebar, onSelectConversation }) {
  const details = getDetails(conversation, '912235316004-918420014466--');
 /// console.log("ChatConversationItem.js: conversation", conversation , details);
  // const displayLastActivity = conversation.messages[conversation.messages.length - 1].createdAt ? ; conversation.messages[conversation.messages.length - 1].createdAt ? "2022-08-04T03:58:03.143Z"
  // const displayLastActivity = "2022-08-04T03:58:03.143Z"
  const displayLastActivity = conversation.updatedAt ? conversation.updatedAt : Date.now()

  const isGroup = details.otherParticipants.length > 2;
  const isUnread = conversation.unreadCount > 0;
  const isOnlineGroup = isGroup && details.otherParticipants.map((item) => item.status).includes('online');

  return (
    <RootStyle
      onClick={onSelectConversation}
      sx={{
        ...(isSelected && { bgcolor: 'action.selected' }),
      }}
    >
      <ListItemAvatar>
        <Box
          sx={{
            ...(isGroup && {
              position: 'relative',
              width: AVATAR_SIZE,
              height: AVATAR_SIZE,
              '& .avatarWrapper': {
                position: 'absolute',
                width: AVATAR_SIZE_GROUP,
                height: AVATAR_SIZE_GROUP,
                '&:nth-of-type(1)': {
                  left: 0,
                  zIndex: 9,
                  bottom: 2,
                  '& .MuiAvatar-root': {
                    border: (theme) => `solid 2px ${theme.palette.background.paper}`,
                  },
                },
                '&:nth-of-type(2)': { top: 2, right: 0 },
              },
            }),
          }}
        >
          {details.otherParticipants.slice(1, 2).map((participant) => (
            <AvatarWrapperStyle className="avatarWrapper" key={participant._id}>
              <Avatar alt={participant.user_name} src={getImageLink(participant)} />
              {!isGroup && (
                <BadgeStatus status={participant.status ? participant.status : 'online' } sx={{ right: 2, bottom: 2, position: 'absolute' }} />
              )}
            </AvatarWrapperStyle>
          ))}

          {isOnlineGroup && <BadgeStatus status="online" sx={{ right: 2, bottom: 2, position: 'absolute' }} />}
        </Box>
      </ListItemAvatar>

      {isOpenSidebar && (
        <>
          <ListItemText
            primary={details.displayNames !== 'unknown' ? details.displayNames : details.displayNumber} 
            // primary={details.displayNames === 'unknown' ? details.sender : details.displayNames} // if you want to display the sender name accoirding to whatsapp name 
            primaryTypographyProps={{
              noWrap: true,
              variant: 'subtitle2',
            }}
            secondary={details.displayText}
            secondaryTypographyProps={{
              noWrap: true,
              variant: isUnread ? 'subtitle2' : 'body2',
              color: isUnread ? 'textPrimary' : 'textSecondary',
            }}
          />

          <Box
            sx={{
              ml: 2,
              height: 44,
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                mb: 1.25,
                fontSize: 12,
                lineHeight: '22px',
                whiteSpace: 'nowrap',
                color: 'text.disabled',
              }}
            >
              {formatDistanceToNowStrict(new Date(displayLastActivity), {
                addSuffix: false,
              }) + ' ago'}
            </Box>
            {isUnread && <BadgeStatus status="unread" size="small" />}
          </Box>
        </>
      )}
    </RootStyle>
  );
}
