import React, { useEffect, useState } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Skeleton, Snackbar, TextField, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useSelector } from 'react-redux';
import axiosInstance, { baseUrl } from 'src/utils/axios';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import validator from "validator";

const InviteUsers = ({openDialog, setOpenDialog}) => {
    const { enqueueSnackbar } = useSnackbar();

    const { usersData } = useSelector(state => state.getCompany)
    console.log("usersData", usersData.data)








    const [emailExists, setEmailExists] = useState(true);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')


    const authID = localStorage.getItem('authID')
    const authSecretId = localStorage.getItem('authSecretId')
    const companyID = localStorage.getItem('companyID')
    const company_name = localStorage.getItem('company_name')
    const phone = localStorage.getItem('phone')
    


    const [isEmailValid, setEmailValidity] = useState(null);
    const emailCheck = () => {
        axiosInstance.get(`/email_exist?email_address=${email}`)
            .then(response => {
                console.log('email_exist', response.data.data)
                if (response.data.data === true) {
                    setEmailExists(false)
                } else if (response.data.data === false) {
                    setEmailExists(true)
                }
            })
            .catch(err => console.log("Error ", err))
    }


    useEffect(() => {
        return (
            emailCheck()
        )
    }, [email])

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const inviteobj = {
        confirmed: false,
        blocked: false,
        username: email,
        email: email,
        fullName: firstName + " " + lastName,
        phone: phone,
        provider: "",
        createdAt: 1639062170610,
        updatedAt: 1639062170610,
        company: "",
        role: "user",
        account_type: "ent",
        company_name: company_name,
        company_id: companyID,
        is_admin: false,
        auth_id: authID,
        auth_secret: authSecretId,
        FirstName: firstName,
        LastName: lastName
    }

    const sendInvite = () => {

        axiosInstance.post(`${baseUrl}/invite/user`, inviteobj).then((res) => {
            console.log("invite user ", res)
            enqueueSnackbar('Invite Send Success!');
            handleCloseDialog()
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar('Invite Send Failed!');
        })
    }



    return (
        <>
           
                <Button variant="contained" color="primary"
                    onClick={handleOpenDialog}
               
                >
                    Add Users
                </Button>
           


            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
                maxWidth="sm"
            >
                <DialogTitle id="responsive-dialog-title">{"Invite User"}</DialogTitle>
                <DialogContent>
                    <Grid container direction="row" spacing={2} style={{ width: "300px", marginTop: '10px' }}>
                        <Grid item xs={12}>
                            <TextField
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                               
                                id="outlined-basic"
                                label="First Name" variant="outlined"
                                fullWidth
                            />


                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                               
                                id="outlined-basic"
                                label="Last Name" variant="outlined"
                                fullWidth
                            />
                        </Grid>




                        <Grid item xs={12}>
                            <TextField
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailValidity(validator.isEmail(e.target.value));
                                }}

                             

                                id="outlined-basic"
                                label="Enter Email Here.." variant="outlined"
                                fullWidth
                            />

                            {emailExists === false ? (
                                <Typography
                                    style={{
                                        fontWeight: "300",
                                        marginTop: ".3rem",
                                        fontSize: ".8rem",
                                        color: "red",
                                    }}
                                    variant="body2"
                                >
                                    Email ID already Registered
                                </Typography>
                            ) : ''}

                        </Grid>

                        <Grid item xs={12} style={{ textAlign: "right" }}>
                            <Button autoFocus onClick={handleCloseDialog} color="primary">
                                Cancel
                            </Button>
                            <Button color="primary" variant="contained" autoFocus

                                disabled={
                                    !(isEmailValid
                                        && emailExists) || firstName === '' || lastName === ''
                                }

                                onClick={sendInvite}
                            >
                                Send Invitation
                            </Button>
                        </Grid>
                    </Grid>


                </DialogContent>



            </Dialog>
            


        </ >
    )
}

export default InviteUsers