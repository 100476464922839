import React, { useEffect, useState } from 'react';
import './style.css'
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, LinearProgress , Typography, Backdrop , Button, Menu, MenuItem, ListItemAvatar } from '@mui/material';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getAuth, getUser } from '../../../redux/slices/getAuth';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import MyAvatar from '../../../components/MyAvatar';
import Iconify from '../../../components/Iconify';

import OnlineOffline from '../../../components/onlineOffline'
import BadgeStatus from 'src/components/BadgeStatus';
import axios from 'axios';
import axiosInstance , { baseUrl } from 'src/utils/axios';
import useAuth from '../../../hooks/useAuth';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout } = useAuth();
  useEffect(() => {
    dispatch(getAuth())
    console.log('calling auth')
  }, [])

  const { getAuthData, getUserData } = useSelector(state => state.getAuth)
  const [userName, setUserName] = useState('')
  const [userType, setUserType] = useState('')
  const [companyName, setCompanyName] = useState('')

  const [backdrop, setBackdrop] = useState(false)

  const authData = getAuthData.data

  useEffect(() => {
    console.log('nav auth', authData)
    if (authData !== undefined) {
      if (getAuthData.data.FirstName !== undefined) {
        setUserName(getAuthData.data.FirstName)
      }
      if (getAuthData.data.user_type !== undefined) {
        setUserType(getAuthData.data.user_type)
      }
      if (getAuthData.data.company_name !== null && getAuthData.data.company_name !== undefined) {
        setCompanyName(getAuthData.data.company_name)
        const vikalp = getAuthData.data.company_name;
        console.log("company name", vikalp.slice(0, 18))
      }
    }



  }, [authData])

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });

  };

  function delete_cookie(uid) {
    document.cookie = uid +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }


  const updateUserStatus = async (data , id) =>{

    const response = await axiosInstance.patch(`/user/status/${id}` , data)
    console.log("Response of user status : ", response )

    return response 

  }

  const createUserAuditLogs = async (data) =>{
   
    try{
        const link = `/user_activity`
        const response = await axiosInstance.post(link , data)
        console.log("Data to audit logs : ", data  , response)
    }catch(error){
        console.log("Error Data to audit logs : ", error)
        dispatch(dashboardSlice.actions.hasError(error));
    }

}

const logoutForSessionManagement = async ( data ) =>{
  
  try{
    const link = `/logout`
    const response = await axiosInstance.post(link , data)
    console.log("Data to logout logs : ", data  , response)
}catch(error){
    console.log("Error Data to logout logs : ", error)
    dispatch(dashboardSlice.actions.hasError(error));
}

}



  const handleLogout = async () => {

    handleClose()
    setBackdrop(true)
    const id = localStorage.getItem("user_status_id")

    const dataToUpdateUserStatus = {
      "status":"away"
     }

    //const response = await updateUserStatus( dataToUpdateUserStatus , id )

    const auth_id = localStorage.getItem("authID")
    const user_id = localStorage.getItem("userID")
    const email = localStorage.getItem('email')

    const dataForLogout = {
      "email":email
  }

    const tempDataToCreateAuditLogs = {
      "auth_id":auth_id,
      "user_id":user_id,
      "type":"login"
  }
  await logoutForSessionManagement(dataForLogout)
   //await createUserAuditLogs(tempDataToCreateAuditLogs)
    await logout();
    navigate('/')
    delete_cookie('vib_u_id')
    localStorage.clear();
    console.clear()
    clearCacheData()
    location.reload();
  }
  const handleProfile = () => {
    navigate('/dashboard/profile')

  }
  const statuscloudphone = useSelector(state => state.UserstatusSlice.value)
  const statusonlineoffline = localStorage.getItem('statusonlineoffline')
  if (statusonlineoffline === "offline") {
    console.log("offline offline")
  } else {
    console.log("offline online")
  }
  console.log('online', <OnlineOffline itIsFrom='navbar' />)
  const imagedp = localStorage.getItem('user_logo')
  const url = `${baseUrl}/download/user_logo?fileLocation=` + imagedp



  const authID = localStorage.getItem("authID")
  const { user, agent_contact, callHistory, missed_call, show_all_list, call_offset, missed_offset, power_dialer_index, power_dialer } = useSelector((state) => state.user)
  const [user_status, setUserStatus] = useState("Available")
  const statuschange = () => {

    axios({
      method: "GET",
      url: `${baseUrl}/user/status`,
      headers: {
        "Content-Type": "application/json",
        "authorization": localStorage.getItem('redux-token')
      },
      data: {
        "authId": authID,
        "userId": user._id
      }
    }).then((res) => {
      const response = res.data.data.data;
      if (response.length > 0) {
        const status = response[0].status;
        setUserStatus(status);
        console.log("statusstatus", status)
      }
    }).catch((err) => {
      console.log("err", err);
    })
  }
  useEffect(() => {
    statuschange()
  }, [])


  return (

    <>
      <Button underline="none" color="inherit"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          bgcolor: '#ffffff',
          width: '100%',
          display: 'block',
          overflow: 'hidden',
          // p: 0,
          // pl: -2,
          // pr: 7,
          ...(isCollapse && {
            ml: 0,
            width: 0,
            textAlign: 'left',
            bgcolor: "#ffffff",
            ml: 0,
            // mt: "25px"

          }),
        }}
        className='account-nav'
      >
        <RootStyle
          sx={{
            bgcolor: '#ffffff',
            pr: 10,
            display: 'flex',
            ...(isCollapse && {
              pr: 0,
            }),
          }}
        >


          {/* <MyAvatar /> */}

          <ListItemAvatar sx={{ position: 'relative' }}>

            <MyAvatar />

            <BadgeStatus
              // status={user_status}
              status={statuscloudphone ? statuscloudphone : user_status}
              sx={{ position: 'absolute', right: 1, bottom: 1 }} />
          </ListItemAvatar>

          {/* <Avatar alt={fName} src={`${baseUrl}/download/user_logo?fileLocation=${imageURL}`} className="yellow" /> */}

          <Box
            sx={{
              ml: 2,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.shorter,
                }),
              ...(isCollapse && {
                ml: 0,
                width: 0,
                textAlign: 'left',
                display:'none' 
              }),
            }}
          >
            <Typography variant="subtitle2" noWrap style={{ textAlign: 'left' }}>
              {userName}
            </Typography>

            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
              {userType === 'ent' ? 'Individual' : companyName.length < 18 ? companyName : companyName.slice(0, 15) + ".."}
              {/* {userType === 'ent' ? 'Individual' : "vibtree technology.."} */}
            </Typography>
            <div style={{ display: "none" }}>
              <OnlineOffline itIsFrom='navbar' />
            </div>

          </Box>
        </RootStyle>
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}

        <MenuItem>
          <a href="https://vibtree.tawk.help/" target="_blank" style={{ textDecoration: "none", color: "black" }}>


            <Iconify icon={'iconoir:chat-bubble-question'} width={21} height={21} style={{ marginRight: '10px' }} /> Contact Support
          </a>
        </MenuItem>
        <MenuItem onClick={handleProfile}><Iconify icon={'heroicons:user'} width={21} height={21} style={{ marginRight: '10px' }} /> Profile Settings</MenuItem>
        <MenuItem onClick={handleLogout}><Iconify icon={'la:power-off'} width={21} height={21} style={{ marginRight: '10px' }} /> Logout</MenuItem>
      </Menu>


      <Backdrop
        sx={{ color: '#fff', zIndex: 3001}}
        open={backdrop}
      > 
      <div style={{display: 'block', width: '300px', textAlign: 'center' }}>
        <i class="bi bi-emoji-frown" style={{fontSize: '42px'}}></i>
        <LinearProgress style={{margin: '20px'}}/>
        <Typography>Your are logging out..</Typography>
        
      </div>
        
      </Backdrop>

     

    </>

  );
}
