import { memo } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Container, AppBar } from '@mui/material';
// config
import { HEADER } from '../../../config';
// components
import BoltIcon from '@mui/icons-material/Bolt';
import { NavSectionHorizontal } from '../../../components/nav-section';

import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ICONS } from 'src/components/nav-section/vertical';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create('top', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1, 0),
  boxShadow: theme.customShadows.z8,
  top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  backgroundColor: theme.palette.background.default,
}));



// ----------------------------------------------------------------------

function NavbarHorizontal() {
  const viewType = localStorage.getItem("viewType")
  const {navConfig, navConfigOfSubUser} = useSelector((state) => state.navigation)

  const checkScreenViewPermission = () => {
    if (viewType === "All") {
      return navConfig;
    } else {
      return navConfigOfSubUser;
    }
  }

  return (
    <RootStyle>
      <Container maxWidth={false}>
      {
        viewType === 'All' ? (
          <NavSectionVertical navConfig={checkScreenViewPermission()} isCollapse={isCollapse} />
        ) : (
          <NavSectionVertical navConfig={checkScreenViewPermission()} isCollapse={isCollapse} />
        )
      }
        {/* <NavSectionHorizontal navConfig={navConfig} /> */}
      </Container>
    </RootStyle>
  );
}

export default memo(NavbarHorizontal);
