import { Button, Typography, LinearProgress, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import localStorage from 'redux-persist/es/storage'
import { incrementrefreshinbox } from 'src/redux/Refreshinbox'
import axiosInstance from 'src/utils/axios'
import inboxready from './../../assets/inboxready.png'
import { buySingleNumber } from '../../redux/slices/numbers/buy';
const Done = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [intype, setintype] = useState('')
    const [inname, setinname] = useState('')
    const [innumber, setinnumber] = useState('')
    const authid = localStorage.getItem('authID')
    const onboardinguserId = localStorage.getItem('onboardinguserId')
    const onboardingphonenumber = localStorage.getItem('onboardingphonenumber')
    const onboardingname = localStorage.getItem('onboardingname')
    const selectuser = useSelector(state => state.ChannelchooseSlice.value)
    const iconpng = localStorage.getItem('iconpng')
    const userselectid = useSelector(state => state.Inboxuseridslice.value)
    const ChannelinboxSlice = useSelector(state => state.ChannelinboxSlice.value)
    const iconpickerslice = useSelector(state => state.iconpickerslice.value)
    const authidin = useSelector(state => state.authidSlice.value)
    const selectedNumberVoice = useSelector(state => state.Selectedvoicenumberslice.value)

    const reduxIcon = useSelector(state => state.iconpickerslice.value)

    const [loading, setLoading] = useState(true)

    var arr = []
    for (var i = 0; i < userselectid.length; i++) {
        arr.push(userselectid[i]._id)
    }

    console.log("userselectedid", userselectid)
    const obj = {
        AccountSid: authidin,
        UserId: arr,
        created_by: arr,
        InboxType: selectuser,
        InboxName: ChannelinboxSlice,
        InboxIcon: reduxIcon,
        data: [
            {
                number: selectedNumberVoice
            }
        ],
        allowed_permission: {
            export: true
        }
    }
    console.log('objfinal', obj)
    localStorage.setItem('refreshinboxsetting', true)
    function buynumber(){
        dispatch(buySingleNumber(selectedNumberVoice))
    }
    const finalapi = () => {
        axiosInstance.post(`/inbox-view`, obj)
            .then((res) => {
                dispatch(incrementrefreshinbox('refreshinbox'))
                console.log("inbox create response", res.data.data)
                
                setinname(res.data.data.InboxName)
                setintype(res.data.data.InboxType)
                
                setinnumber(res.data.data.data[0].number)

                setLoading(false)

            }).catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        finalapi()
        buynumber()
    }, [])
    const handleuser = () => {
        if(intype === 'sms'){
            navigate(`/dashboard/inbox/${intype}/${inname}`)
        }else{
            navigate(`/dashboard/inbox/voice/${inname}/${innumber}`)
        }
        
        dispatch(incrementrefreshinbox(''))
    }
    return (
        
        <Grid container direction='row'>
            <Grid item xs></Grid>
            <Grid item md={6} style={{textAlign: 'center'}}>
                {loading === true ? (
                    <> 
                        <Typography variant='h5' color='primary' style={{marginTop: '150px'}}>
                        Creating Inbox ...
                        </Typography> 
                        <LinearProgress />
                    </>
                ) : (
                    <>
                    <Typography variant='h3' color='primary' style={{marginTop: '100px'}}>
                    Your Inbox is Ready!
                    </Typography>
                    <img src={inboxready} alt="" style={{margin: '20px auto'}} />
                    <Button color='primary' variant='contained' onClick={() => handleuser()} >Let's Start</Button>
                    </>
                )}
            </Grid>
            <Grid item xs></Grid>
        </Grid>
            
            
      
    )
}

export default Done