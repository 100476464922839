import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { Button, Grid, IconButton, Snackbar, Tab, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
// import Iconpicker from '../Inbox/Iconpicker'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useParams } from 'react-router'
import axiosInstance, { baseUrl } from 'src/utils/axios'
import CloseIcon from '@mui/icons-material/Close';
import Whatsappuser from './Whatsappuser'
import './Whatsapp.css'
import { useSelector } from 'react-redux'
import Iconpicker from '../IconPicker/Index'
import { useDispatch } from 'react-redux';
import { incrementiconpicker } from 'src/redux/Iconpickerimg';

const WhatsappEdit = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }

        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };


    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    const [value, setValue] = useState('general')
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
        // if (newValue === 'general') {
        //     navigate('/dashboard/profile/general')
        // } else
        //     if (newValue === 'users') {
        //         navigate('/dashboard/profile/billing')
        //     }
    }
    const [inboxname, setinboxname] = useState('')
    const getParams = useParams()
    const [inboxicon, setinboxicon] = useState('')
    const [loadings, setloadings] = useState(false)
    const [number, setnumber] = useState('')
    const [authid, setauthid] = useState('')
    const [phonenumberid, setphonenumberid] = useState('')
    const [wabaids, setwabaids] = useState('')
    const getuserid = () => {

        console.log("getParamsid", getParams.id)
        axiosInstance.get(`${baseUrl}/users/${getParams.id}`)
            .then((res) => {
                console.log("getParamsid", getParams.id)
                console.log("checkboxuserdata", res.data)
            }).catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        getuserid()
    }, [])

    const getdata = () => {

        const authid = localStorage.getItem('authID')
        axiosInstance.get(`${baseUrl}/inbox-view/?AccountSid=${authid}&_id=${getParams.id}`)
            .then((res) => {
                console.log("getdatauserdata", res.data.data)
                // console.log("getdatauserdatanumber", res.data.data.data[0].data[0].number)
                setinboxname(res.data.data.data[0].InboxName)
                setinboxicon(res.data.data.data[0].InboxIcon)
                dispatch(incrementiconpicker(res.data.data.data[0].InboxIcon))
                setnumber(res.data.data.data[0].data[0].whatsAppNumber)
                setauthid(res.data.data.data[0].data[0].AuthId)
                setphonenumberid(res.data.data.data[0].data[0].phoneNumberId)
                setwabaids(res.data.data.data[0].data[0].wabaid)


            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        getdata()
    }, [])
    const iconpickerslice = useSelector(state => state.iconpickerslice.value)
    const iconpng = localStorage.getItem('iconpng')
    const obj = {


        InboxName: inboxname,
        // InboxIcon: iconpng ? iconpng : inboxicon,
        InboxIcon: iconpickerslice,
        data: [
            {
                AuthId: authid,
                phoneNumberId: phonenumberid,
                wabaid: wabaids,
                whatsAppNumber: number
            }
        ]


    }
    const getapiedit = () => {
        setloadings(true)
        axiosInstance.patch(`inbox-view/${getParams.id}`, obj)
            .then((res) => {
                setloadings(false)
                console.log("finalresponseedit", res)
                handleClick()
            }).catch((err) => {
                setloadings(false)
                console.log(err)
            })
    }


    return (
        <div>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: "4%" }}>
                    <TabList onChange={handleChange}>
                        <Tab label="General" value='general' />
                        <Tab label="Users" value='users' />
                    </TabList>
                </Box>
                <TabPanel value='general'>
                    <Grid className='Inboxname' >

                        <TextField
                            name='Inbox name'
                            label='Inbox name'
                            fullWidth
                            value={inboxname}
                            onChange={(e) => setinboxname(e.target.value)}
                            style={{
                                marginTop: "6px",
                                maxWidth: "340px"
                            }}
                        />

                        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                            {/* <Iconpicker inboxicon={inboxicon} /> */}
                            <Iconpicker setIcon={iconpickerslice} />
                        </div>

                        <div className='phoneinput'>
                            <PhoneInput
                                country={'in'}
                                specialLabel=''
                                enabledSearch={true}
                                value={number}
                                disabled
                                containerStyle={{
                                    width: "100%",
                                    height: "50px"
                                }}

                                inputStyle={{
                                    // background: "lightblue"
                                    width: "100%",
                                    height: "50px",
                                    // marginTop: "6px"

                                }}

                            // inputProps={{
                            //     name: 'phonenumber',
                            //     required: true,
                            // }}
                            />
                        </div>

                    </Grid>
                    <Grid
                        className='Phonenumberid'
                    >

                        <div className='childdiv'>
                            <TextField
                                name='Number Id'
                                label='Number Id'
                                value={phonenumberid}
                                onChange={(e) => setphonenumberid(e.target.value)}
                                fullWidth
                            />

                        </div>
                        <div className='childdiv'>
                            <TextField
                                name='Auth key'
                                label='Auth key'
                                value={authid}
                                onChange={(e) => setauthid(e.target.value)}
                                fullWidth
                            />

                        </div>
                        <div className='childdiv'>
                            <TextField
                                name='WABA ID'
                                label='WABA ID'
                                fullWidth
                                value={wabaids}
                                onChange={(e) => setwabaids(e.target.value)}
                            />

                        </div>
                    </Grid>

                    <div style={{ display: "flex", justifyContent: "center", marginTop: "10%" }}>
                        <LoadingButton variant='contained' loading={loadings} onClick={() => getapiedit()}>Save Changes</LoadingButton>
                    </div>



                </TabPanel>

                <TabPanel value='users'>
                    <Whatsappuser />
                </TabPanel>

            </TabContext>

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: "right"
                }}
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                message="user edit successfully"
                action={action}
            />
        </div>
    )
}

export default WhatsappEdit