import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// @mui
import { Box, Divider, Stack, Typography, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
import {
  addRecipients,
  onSendMessage,
  getConversation,
  getParticipants,
  markConversationAsRead,
  resetActiveConversation,
} from '../../../../redux/slices/chat';

import { getConversationsId } from '../../../../redux/slices/whatsapp/whatsapp';

// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
//
import ChatRoom from './ChatRoom';
import ChatMessageList from './ChatMessageList';
import ChatHeaderDetail from './ChatHeaderDetail';
import ChatMessageInput from './ChatMessageInput';
import ChatHeaderCompose from './ChatHeaderCompose';

// ----------------------------------------------------------------------

const conversationSelector = (state) => {
  const { conversations, activeConversationId } = state.chat;
  const conversation = activeConversationId ? conversations.byId[activeConversationId] : null;
  if (conversation) {
    return conversation;
  }
  const initState = {
    id: '',
    messages: [],
    participants: [],
    unreadCount: 0,
    type: '',
  };
  return initState;
};

const whatsappTokenAccess =  localStorage.getItem('whatsappTokenAccess')

export default function ChatWindow() {
  const dispatch = useDispatch();

  

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { conversationKey } = useParams();

  const { conversationId, conversationIdData, conversationIdName } = useSelector((state) => state.whatsapp)

  const { contacts, recipients, participants, activeConversationId } = useSelector((state) => state.chat);

  const conversation = useSelector((state) => conversationSelector(state));

  const mode = conversationKey ? 'DETAIL' : 'COMPOSE';

  const displayParticipants = participants.filter((item) => item.id !== '8864c717-587d-472a-929a-8e5f298024da-0');

  const [conversationList, setConversationList] = useState([])

  useEffect(() => {
    if(conversationId !== ''){
      dispatch(getConversationsId(whatsappTokenAccess, 1, conversationId))
    }
  }, [conversationId])

  useEffect(() => {
    if(conversationIdData.count > 0){
      setConversationList(conversationIdData)
    }
  }, [conversationIdData])
  
  

  useEffect(() => {
    const getDetails = async () => {
      dispatch(getParticipants(conversationKey));
      try {
        await dispatch(getConversation(conversationKey));
      } catch (error) {
        console.error(error);
        navigate(PATH_DASHBOARD.chat.new);
      }
    };
    if (conversationKey) {
      getDetails();
    } else if (activeConversationId) {
      dispatch(resetActiveConversation());
    }
 
  }, [conversationKey]);

  useEffect(() => {
    if (activeConversationId) {
      dispatch(markConversationAsRead(activeConversationId));
    }
  }, [dispatch, activeConversationId]);

  const handleAddRecipients = (recipients) => {
    dispatch(addRecipients(recipients));
  };

  const handleSendMessage = async (value) => {
    try {
      dispatch(onSendMessage(value));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack sx={{ flexGrow: 1, minWidth: '1px' }}>
      {/* {mode === 'DETAIL' ? (
        <ChatHeaderDetail participants={displayParticipants} />
      ) : (
        <ChatHeaderCompose
          recipients={recipients}
          contacts={Object.values(contacts.byId)}
          onAddRecipients={handleAddRecipients}
        />
      )} */}

      
        <List>
          <ListItem alignItems="flex-start" button style={{alignItems: 'center'}}>
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {conversationIdName !== '' ? conversationIdName : ''}
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
          
      

      <Divider />

      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Stack sx={{ flexGrow: 1 }}>
          <ChatMessageList conversation={conversationList} />

          <Divider />

          <ChatMessageInput
            conversationId={activeConversationId}
            onSend={handleSendMessage}
            disabled={pathname === PATH_DASHBOARD.chat.root || pathname === PATH_DASHBOARD.chat.new}
          />
        </Stack>

        {mode === 'DETAIL' && <ChatRoom conversation={conversation} participants={displayParticipants} />}
      </Box>
    </Stack>
  );
}
