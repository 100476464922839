import { Button, CircularProgress, Grid, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { CarouselSection } from 'src/pages/auth/Slider';
import axiosInstance from 'src/utils/axios';
import styled from 'styled-components';

const MainFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid black; */
  height: 35rem;
`;
const RightSectionLayout = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 10rem;
width: 50vw;
height: 100vh;
/* border: 1px solid black; */
@media (max-width: 768px) {
  width: 100%;
  height: auto;
  }
`;


const UserConfirmation = () => {
    const { token } = useParams();
    const Navigate = useNavigate();
    console.log("token", token)
    const [verifying, setVerifying] = useState(true);
    const [verified, setVerified] = useState(false);
    const [expired, setExpired] = useState(false);

    const [username, setUserName] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [authId, setAuthId] = useState('');
    const [userId, setUserId] = useState('');

    const [authSecret, setAuthSecret] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [companyId, setCompanyId] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(0);
    const [phoneProvider, setPhoneProvider] = useState('');
    const [isCompany, setIsCompany] = useState('');
    const [role, setRole] = useState('');
    const [accountType, setAccountType] = useState('');
    const [data, setdata] = useState(false)
    const tokens = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InZpa2FscHZhcnNobmV5MTIzNDVAZ21haWwuY29tIiwiZW1haWwiOiJ2aWthbHB2YXJzaG5leTEyMzQ1QGdtYWlsLmNvbSIsImlhdCI6MTY2NzAyOTYyMCwiZXhwIjoxNjY3MTE2MDIwfQ.Jn3DvXOSwHSfAZjtlB546pIL0nqhvAEMrCbPoTxE3Us'

    useEffect(() => {
        axiosInstance.get(`/UserConfirmation/${token}`)
            .then(response => {
                //setUser(response);
                console.log('response', response.data.message)
                console.log('status', response)


                if (response.data.status === true) {
                    setVerified(true);
                    setVerifying(false);
                    //console.log("confirmed data", response.data);
                    let data = response.data.data;
                    let datalist = data[0];
                    console.log("confirmed data", datalist);
                    console.log("confirmed data list", datalist._id);
                    setUserName(datalist.username);
                    setFullName(datalist.fullName);
                    setEmail(datalist.email);
                    setAuthId(datalist.auth_id);
                    setUserId(datalist._id);
                    setIsAdmin(datalist.is_admin);
                    setCompanyId(datalist.company_id);
                    setCompanyName(datalist.company_name);
                    setPhoneNumber(datalist.phone);
                    setPhoneProvider(datalist.provider);
                    setIsCompany(datalist.company);
                    setRole(datalist.role);
                    setAccountType(datalist.user_type);
                    setAuthSecret(datalist.auth_secret)
                    setdata(true)


                } else {
                    setExpired(true);
                    setVerifying(false);
                }


            })
            .catch(err => console.log("Error ", err))

    }, [])
    if (data == true) {


        Navigate(`/invitedSignup?userId=${userId}&authId=${authId}&email=${email}&username=${username}&fullName=${fullName}&fullName=${fullName}&isAdmin=${isAdmin}&companyId=${companyId}&companyName=${companyName}&phoneNumber=${phoneNumber}&phoneProvider=${phoneProvider}&isCompany=${isCompany}&role=${role}&accountType=${accountType}&authSecret=${authSecret}`)
    }
    const skeltonLoaders = (
        <>
            <Grid container>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>


            </Grid>
        </>
    )
    return (
        <div style={{ display: "flex" }}>
            {/* <div style={{ width: "40%" }}>
                <CarouselSection />
            </div>
            <RightSectionLayout>
                <form>
                    <MainFormLayout>

                        {verifying === true ? (
                            <Typography
                                style={{
                                    fontWeight: "bold", textAlign: "center", marginBottom: "20px",
                                }}
                                variant="h3"
                                component="h3"
                            >
                                Verifying Your Email


                                <CircularProgress
                                    color="secondary"
                                    style={{
                                        width: "50px", height: "50px", marginRight: "auto", marginLeft: "auto", marginBottom: "10px", display: "block",
                                    }}
                                />

                            </Typography>

                        ) : ""}


                        {verified === true ? (
                            <>
                                <Typography
                                    style={{
                                        fontWeight: "bold", textAlign: "center", marginBottom: "20px",
                                    }}
                                    variant="h3"
                                    component="h3"
                                >
                                    Your Email Verified Successfully
                                </Typography>
                                <span style={{ display: "block", textAlign: "center" }}>
                                    <Button color="primary" variant="contained" size="large"
                                        onClick={accessInvite}
                                    >
                                        Access Account
                                    </Button>
                                </span>
                            </>

                        ) : ''}

                        {expired === true ? (
                            <Typography
                                style={{
                                    fontWeight: "bold", textAlign: "center", marginBottom: "20px",
                                }}
                                variant="h3"
                                component="h3"
                            >
                                Your Invitation Expired
                            </Typography>
                        ) : ''}




                    </MainFormLayout>
                </form>


            </RightSectionLayout> */}
            {skeltonLoaders}

        </div>
    )
}

export default UserConfirmation