import { Grid, MenuItem, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import NumberBuy from 'src/pages/dashboard/NumberBuy'
import Onboadingnumber from 'src/pages/dashboard/Onboardingnumber'
import axiosInstance from 'src/utils/axios'

const ConfigureInbox = () => {


    return (
        <div>
            {/* <Grid style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                <TextField
                    name='Select Country'
                    label='Select Country'
                    select
                    fullWidth
                >
                    <MenuItem>Select Country</MenuItem>
                </TextField>
            </Grid> */}
            {/* <NumberBuy /> */}
            <Onboadingnumber />
        </div>
    )
}

export default ConfigureInbox