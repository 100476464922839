import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import moment from 'moment-timezone';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography, TextField, Switch, Select, MenuItem, Snackbar, Avatar, Link, Button, InputAdornment, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../../hooks/useAuth';
// utils
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { fData } from '../../../../utils/formatNumber';
import { zoneList } from '../../../../utils/Zone';
// _mock
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
// import { countries } from '../../../../_mock';
import { countries } from './Country'
// components
import { FormProvider, RHFSwitch, RHFSelect, RHFTextField, RHFUploadAvatar } from '../../../../components/hook-form';
import axiosInstance, { baseUrl } from 'src/utils/axios';
import { useState } from 'react';
import { async } from '@firebase/util';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HideSourceIcon from '@mui/icons-material/HideSource';
import { NewPasswordForm } from 'src/sections/auth/new-password';
import { Container } from '@mui/system';
import { styled } from '@mui/material/styles';
import OtpInput from 'react-otp-input';
import Image from 'src/components/Image';
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,

  marginLeft: "60px",
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


export default function AccountGeneral() {

  const { height, width } = useWindowDimensions();

  const { enqueueSnackbar } = useSnackbar();
  const files = useSelector(action => action.fileimageupload.value)
  const fileuploadnameSlice = useSelector(action => action.fileuploadnameSlice.value)
  const fileuploadsizeSlice = useSelector(action => action.fileuploadsizeSlice.value)
  const fileuploadtypeSlice = useSelector(action => action.fileuploadtypeSlice.value)
  const fileuploadmodifySlice = useSelector(action => action.fileuploadmodifySlice.value)
  const fileuploadlastmodifySlice = useSelector(action => action.fileuploadlastmodifySlice.value)
  // console.log("selecteduser", selecteduser)
  let timeZoneLocal = localStorage.getItem('timeZone')

  // const countries = [
  //   { code: 'USA', label: 'United States', phone: '1', suggested: true },
  //   { code: 'JPN', label: 'Japan', phone: '81', suggested: true },
  //   { code: 'IND', label: 'India', phone: '91' },
  //   { code: 'GBR', label: 'United Kingdom', phone: '44' },
  // ];

  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [timeZone, setTimeZone] = useState('')

  useEffect(() => {
    if (timeZoneLocal !== undefined && timeZoneLocal !== '') {
      setTimeZone(timeZoneLocal)
    }
  }, [timeZoneLocal])



  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const action = (
    <>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );





  const handleClose2 = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
  };

  const handleClick2 = () => {
    setOpen2(true);
  };

  const action2 = (
    <>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose2}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );





  const handleClose3 = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen3(false);
  };

  const handleClick3 = () => {
    setOpen3(true);
  };

  const action3 = (
    <>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose3}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );


  const handleClose4 = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen4(false);
  };

  const handleClick4 = () => {
    setOpen4(true);
  };

  const action4 = (
    <>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose4}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );


  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Name is required'),
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [saveloadings, setsaveloadings] = useState(false);
  const [loader, setloader] = useState(true)
  const defaultValues = {
    displayName: user?.displayName || '',
    email: user?.email || '',
    photoURL: user?.photoURL || '',
    phoneNumber: user?.phoneNumber || '',
    country: user?.country || '',
    address: user?.address || '',
    state: user?.state || '',
    city: user?.city || '',
    zipCode: user?.zipCode || '',
    about: user?.about || '',
    isPublic: user?.isPublic || false,
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );



  const [pagechange, setpagechange] = useState('first')
  const [firstname, setfirstname] = useState('')
  const [lastname, setlastname] = useState('')
  const [EmailAddress, setemail] = useState('')
  const [phonenumber, setphonenumber] = useState('')
  const [countryname, setcountryname] = useState('')
  const [namedisplay, setnamedisplay] = useState('')

  const [statusV, setStatus] = useState('available')
  const [otp, setOtp] = useState('')
  const getdata = async () => {

    const res = await axiosInstance.get(`${baseUrl}/getAuth`)
    console.log('getauth', res.data.data)
    setfirstname(res.data.data.FirstName)
    setlastname(res.data.data.LastName)
    setemail(res.data.data.email)
    setphonenumber(res.data.data.phone)
    setcountryname(res.data.data.country_allowed[0].code || "")
    setnamedisplay(res.data.data.display_name)
    localStorage.setItem('FirstName', res.data.data.FirstName)
    localStorage.setItem('LastName', res.data.data.LastName)
    localStorage.setItem('email', res.data.data.email)
    localStorage.setItem('phone', res.data.data.phone)
    localStorage.setItem('country', res.data.data.country_allowed[0].code || "")
    localStorage.setItem('_id', res.data.data._id)
    localStorage.setItem('user_logo', res.data.data.user_logo)
    localStorage.setItem('timeZone', res.data.data.timeZone)
    localStorage.setItem('customerIdInZoho', res.data.data.customerIdInZoho)
    localStorage.setItem('display_name', res.data.data.display_name)
    localStorage.setItem('company_name', res.data.data.company_name)
    localStorage.setItem('alternateNumber', res.data.data.alternateNumber)

    setloader(false)
  }
  useEffect(() => {
    getdata()
  }, [])
  console.log("fistname", firstname)
  const FirstName = localStorage.getItem('FirstName')
  const LastName = localStorage.getItem('LastName')
  const email = localStorage.getItem('email')
  const phone = localStorage.getItem('phone')
  const country = localStorage.getItem('country')
  const _id = localStorage.getItem('_id')


  const [users, setuser] = useState({
    FirstName: firstname,
    LastName: lastname,
    email: EmailAddress,
    phone: phonenumber,

  })
  const onInputChange = (e) => {
    console.log(e.target.value);
    setuser({ ...users, [e.target.name]: e.target.value })
  }
  const [phonecode, setphonecode] = useState('')
  const fil = () => {
    const code = countries.filter((item) => {
      return item.code == "IND"
    }).map((item) => item.phone)
    setphonecode(code)
    console.log(code)

  }
  useEffect(() => {
    fil()
  }, [countryname])
  console.log(phonecode[0])

  const customerIdInZoho = localStorage.getItem('customerIdInZoho')
  const display_name = localStorage.getItem('display_name')
  const company_name = localStorage.getItem('company_name')
  const alternateNumber = localStorage.getItem('alternateNumber')
  const [display, setdisplay] = useState(namedisplay === 'undefined' ? company_name : display_name)
  const [alternatenumber, setalternatenumber] = useState(alternateNumber)

  const updateusemanagement = () => {
    setsaveloadings(true)
    console.log(users)
    axiosInstance.put(`${baseUrl}/users/${_id}`,
      {
        FirstName: firstname,
        LastName: lastname,
        email: EmailAddress,
        phone: phonenumber,
        timeZone: timeZone,
        display_name: namedisplay,
        alternateNumber: alternatenumber,
        customerIdInZoho: customerIdInZoho,
        country_allowed: [
          {
            code: countryname,
            phone: phonecode[0]
          }
        ]

      }).then((res) => {
        console.log("updatedata", res)
        handleClick()
        getdata()
        setsaveloadings(false)
      }).catch((err) => {
        console.log(err)
        setsaveloadings(false)
      })
  }
  const uploadimage = localStorage.getItem('uploadimage')

  const jsonimage = JSON.parse(uploadimage)
  console.log("jsonimage", jsonimage)

  // const [image, setImage] = useState(jsonimage)

  // const createPersonalAccount = async () => {

  //   const datas = new FormData();
  //   datas.append("file", image.raw);
  //   var config = {
  //     method: 'post',
  //     url: `${baseUrl}/user/upload`,
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     },
  //     data: datas
  //   };
  //   const s3Key = await axios(config)
  //     .then(function (response) {
  //       return response.data.data
  //     })
  //     .catch(function (error) {
  //       console.log(error);

  //     });
  // }


  const otpsend = () => {
    setloadings(true)
    var obj = {
      email: email
    }
    localStorage.setItem('Email', JSON.stringify(email))
    var payloadobj = JSON.stringify(obj)
    axios.post(`${baseUrl}/send-otp`, obj).then((res) => {
      console.log(res)
      setloadings(false)


    }).catch((err) => {
      console.log(err)
      setloadings(false)
    })
  }


  const verifyotp = () => {
    if (otp.length == 6) {
      setloadings(true)
    }
    const obj = {
      email: email,
      otp: JSON.parse(otp)
    }
    axios.post(`${baseUrl}/auth/verify`, obj).then((res) => {
      console.log(res.data.message)
      if (res.data.message == "success") {
        // setstep(step + 1)
        setpagechange('third')
        setloadings(false);
      } else {
        // handleClick()
        console.log("notfound")
        setloadings(false)
        handleClick2()
      }

    })
  }
  const [passwordValid, setPasswordValid] = useState(null);
  const [username, setusername] = useState('')
  const [password, setpassword] = useState('')

  const changepassword = () => {
    setloadings(true)
    axiosInstance.post(`${baseUrl}/user/reset-password`, {
      email: email,
      username: email,
      password: password
    }).then((res) => {
      console.log(res)
      console.log(res.data.status)
      setloadings(false)
      if (res.data.status === true) {
        handleClick3()
        setpagechange('first')
      }
    }).catch((err) => {
      setloadings(false)
      console.log(err)
    })
  }

  // const fileuploadpath = localStorage.getItem('fileuploadpath')
  // const fileuploadpreview = localStorage.getItem('fileuploadpreview')
  // const fileuploadname = localStorage.getItem('fileuploadname')
  // const fileuploadsize = localStorage.getItem('fileuploadsize')
  // const fileuploadtype = localStorage.getItem('fileuploadtype')


  const file = {
    path: files.path,
    preview: files.preview,
    lastModified: fileuploadmodifySlice,
    lastModifiedDate: fileuploadlastmodifySlice,
    name: fileuploadnameSlice,
    size: fileuploadsizeSlice,
    type: fileuploadtypeSlice,
    webkitRelativePath: ""
  }
  console.log("filefile", file)

  const [image, setImage] = useState({ preview: "", raw: "" });
  // const [image, setImage] = useState('');
  const handleImageChange = e => {
    if (e.target.files.length) {
      // setImage(e.target.files[0]);
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });

      console.log('image', e.target.files);
    }
  };
  // console.log("imagespreview", image.preview)

  const createPersonalAccount = async () => {
    // console.log('filevikalpaa', file)
    // let temp = { preview: "", raw: "" }
    // console.log('insidefile image', file)
    // temp.preview = URL.createObjectURL(file)
    // temp.raw = file

    console.log("image", image)
    const datas = new FormData();
    // datas.append("file", temp.raw);
    datas.append("file", image.raw);
    console.log('imagepreview', image.raw)
    var config = {
      method: 'PUT',
      url: `${baseUrl}/user/upload/${_id}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: datas
    };

    const s3Key = await axios(config)
      .then(function (response) {
        console.log("photodata", response.data.data)
        localStorage.setItem("imageurl", JSON.stringify(response.data.data))
      })
      .catch(function (error) {
        console.log(error);

      });
  }

  const handleClickShowPassword = () => {
    setShowPassword(showPassword => !showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const LoadingSkeleton = () => (
    <>
      <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
      <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
      <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
      <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
    </>
  );
  const skeltonLoaders = (
    <>
      <Grid container>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
          <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </Grid>
      </Grid>
    </>
  )
  const imagedp = localStorage.getItem('user_logo')

  const url = `${baseUrl}/download/user_logo?fileLocation=` + imagedp

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
              <Grid container spacing={3} style={{ marginBottom: '30px' }}>
                <Grid item xs>

                </Grid>
                <Grid item>
                  <TextField
                    select
                    value={statusV}
                    // onChange={(event) => handleStatus(event.target.value)}
                    size='small'
                  >

                    <MenuItem value="available">
                      <div style={{ marginLeft: 5, display: "flex", flexDirection: "row", height: "100%", alignItems: "center" }}>
                        <div style={{ width: 15, height: 15, backgroundColor: "green", borderRadius: 15 }} />
                        <div style={{ marginLeft: 10, fontSize: 12 }}>
                          Available
                        </div>
                      </div>
                      {/* <Typography>
                        <span className='status-card status-red'></span> available
                      </Typography> */}
                    </MenuItem>
                    <MenuItem value="away">
                      <div style={{ marginLeft: 5, display: "flex", flexDirection: "row", height: "100%", alignItems: "center" }}>
                        <ArrowBackIcon style={{ color: "#D28B00", width: 15, height: 15 }} />
                        <div style={{ marginLeft: 10, fontSize: 12 }}>
                          Away
                        </div>
                      </div>

                      {/* <Typography>
                        <span className='status-card status-green'></span> away
                      </Typography> */}
                    </MenuItem>
                    <MenuItem value="Busy">
                      <div style={{ marginLeft: 5, display: "flex", flexDirection: "row", height: "100%", alignItems: "center" }}>
                        <div style={{ width: 15, height: 15, backgroundColor: "#BC0505", borderRadius: 15 }} />
                        <div style={{ marginLeft: 10, fontSize: 12 }}>
                          Busy
                        </div>
                      </div>

                      {/* <Typography>
                        <span className='status-card status-green'></span> Busy
                      </Typography> */}
                    </MenuItem>
                    <MenuItem value="Don't distrub">
                      <div style={{ marginLeft: 5, display: "flex", flexDirection: "row", height: "100%", alignItems: "center" }}>
                        <NotificationsOffIcon style={{ color: "#BC0505", width: 15, height: 15, }} />
                        <div style={{ marginLeft: 10, fontSize: 12 }}>
                          Do not disturb
                        </div>
                      </div>

                      {/* <Typography>
                        <span className='status-card status-red'></span> Don't distrub
                      </Typography> */}

                    </MenuItem>
                    <MenuItem value="offline">
                      <div style={{ marginLeft: 5, display: "flex", flexDirection: "row", height: "100%", alignItems: "center" }}>
                        <HideSourceIcon style={{ color: "#BC0505", width: 15, height: 15, }} />
                        <div style={{ marginLeft: 10, fontSize: 12 }}>
                          offline
                        </div>
                      </div>



                    </MenuItem>
                  </TextField>
                </Grid>

              </Grid>
              <Box sx={{ mb: 5, mt: 5 }}>
                {/* <RHFUploadAvatar
                  name="photoURL"
                  maxSize={3145728}
                  onDrop={handleDrop}

                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >

                    </Typography>
                  }
                /> */}
                <input
                  onChange={handleImageChange}
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="icon-button-file"
                  type="file" />

                {image.preview ? <label htmlFor="icon-button-file" style={{ cursor: 'pointer', color: "#0045BA" }}>
                  <Avatar src={image.preview} style={{ width: '120px', height: "120px", fontSize: '30px', marginLeft: "auto", marginRight: "auto", color: "white", border: "0.5px solid gray" }} />

                </label> : imagedp == "undefined" ? <>
                  <label htmlFor="icon-button-file" style={{ cursor: 'pointer', color: "#0045BA" }}>
                    <Avatar src="/broken-image.jpg" style={{ width: '100px', height: "100px", fontSize: '30px', marginLeft: "auto", marginRight: "auto", background: '#0045BA', color: "white" }} />

                  </label>
                </> :
                  <label htmlFor="icon-button-file" style={{ cursor: 'pointer', color: "#0045BA" }}>
                    <img src={url} style={{ zIndex: '7', height: "120px", fontSize: "30px", width: "120px", marginLeft: "auto", marginRight: "auto", borderRadius: "50%", border: "0.5px solid gray" }} />
                  </label>
                }
                {/* {image.preview ? (
                  <>
                    <Avatar variant="rounded" src={image.preview} style={{ width: '135px', height: "135px", fontSize: '30px', marginLeft: "auto", marginRight: "auto", background: '#0045BA', borderRadius: "10px", color: "white" }} />

                  </>
                ) :
                  <label htmlFor="icon-button-file" style={{ cursor: 'pointer', color: "#0045BA" }}>
                    <Avatar src="/broken-image.jpg" style={{ width: '100px', height: "100px", fontSize: '30px', marginLeft: "auto", marginRight: "auto", background: '#0045BA', color: "white" }} />
                    Add a Photo
                  </label>
                } */}

              </Box>
              <br />
              {/* <RHFSwitch name="isPublic" labelPlacement="start" label="Public Profile" sx={{ mt: 5 }} /> */}
              {/* Public Profile <Switch name="isPublic" labelPlacement="start" label="Public Profile" /> */}
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            {loader == true ? <>
              {skeltonLoaders}
            </> : <>

              {pagechange === 'first' ? <>
                <Card sx={{ p: 2, pb: 2 }}>
                  <Box
                    sx={{
                      display: 'grid',
                      rowGap: 3,
                      columnGap: 1,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >

                    <Grid >
                      First Name
                      <TextField name='FirstName' fullWidth value={firstname} onChange={(e) => setfirstname(e.target.value)} />
                    </Grid>
                    <Grid>
                      Last Name
                      <TextField name='LastName' fullWidth value={lastname} onChange={(e) => setlastname(e.target.value)} />
                    </Grid>
                    <Grid>
                      Email
                      <TextField name='email' disabled value={EmailAddress} fullWidth onChange={(e) => setemail(e.target.value)} />
                    </Grid>
                    <Grid>
                      Phone Number
                      <PhoneInput

                        country={'us'}
                        value={phonenumber}
                        specialLabel=""
                        enableSearch={true}
                        onChange={(e) => setphonenumber(e)}
                        containerStyle={{

                          width: "350px",
                          height: "55px"

                        }}
                        inputStyle={{
                          // background: "lightblue"
                          width: "350px",
                          height: "55px",

                        }}

                      />
                    </Grid>
                    <Grid>
                      Alternate Number
                      <PhoneInput

                        country={'us'}
                        value={alternatenumber}
                        specialLabel=""
                        enableSearch={true}
                        onChange={(e) => setalternatenumber(e)}
                        containerStyle={{

                          width: "350px",
                          height: "55px"

                        }}
                        inputStyle={{
                          // background: "lightblue"
                          width: "350px",
                          height: "55px",

                        }}

                      />
                      {/* <TextField name='alterNumber' fullWidth /> */}
                    </Grid>
                    <Grid>
                      Country
                      <TextField
                        variant='outlined'
                        select
                        name='country'
                        fullWidth
                        value={countryname}
                        onChange={(e) => setcountryname(e.target.value)}
                      >
                        {countries.map((option) => (
                          <MenuItem key={option.code} value={option.code}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid>
                      Display Name
                      <TextField
                        variant='outlined'
                        value={namedisplay}
                        onChange={(e) => {
                          setdisplay(e.target.value)
                          setnamedisplay(e.target.value)
                        }}
                        name='displayName'
                        fullWidth

                      >

                      </TextField>




                    </Grid>

                    <Grid>
                      Time zone
                      <TextField
                        variant='outlined'
                        select
                        name='country'
                        fullWidth
                        value={timeZone}
                        onChange={(e) => setTimeZone(e.target.value)}
                      >
                        {zoneList.map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        )

                        )}
                      </TextField>




                    </Grid>

                    {/* <Select name='country' label='Country' placeholder='Country'>
                <option value="" />
                {countries.map((option) => (
                  <option key={option.code} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </Select> */}
                    {/* <TextField name='state' label='state/Region' />
              <TextField name='city' label='city' />
              <TextField name='zipCode' label='Zip/Code' /> */
                    }

                  </Box>
                  <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>

                    {/* <RHFTextField name="about" multiline rows={4} label="About" /> */}
                    {/* <TextField name="about" fullWidth multiline rows={4} label="About" /> */}
                    <div style={{ display: "flex" }}>
                      <LoadingButton variant='outlined' loading={loadings} onClick={() => {
                        setpagechange('second')
                        otpsend()
                      }} >
                        Change Password
                      </LoadingButton> &nbsp;&nbsp;&nbsp;
                      <LoadingButton type="submit" variant="contained"
                        loading={saveloadings}
                        onClick={() => {
                          createPersonalAccount()
                          updateusemanagement()
                        }}>
                        Save Changes
                      </LoadingButton>
                    </div>

                  </Stack>
                </Card>
              </> : pagechange === 'second' ? <>
                {width < 500 ? <>
                  <Card style={{ marginLeft: "-50px" }}>
                    <Container>
                      <ContentStyle sx={{ textAlign: 'center' }}>
                        {/* <SentIcon sx={{ mb: 5, mx: 'auto', height: 120 }} /> */}

                        <Typography variant="h3" gutterBottom>
                          Request sent successfully!
                        </Typography>

                        <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                          We've sent a 6-digit confirmation email to your email.
                          <br />
                          Please enter the code in below box to verify your email.
                        </Typography>
                        {/* <TextField name='email' label='Email' fullWidth value={email} disabled /> */}
                        <Box sx={{ mt: 3, mb: 3, ml: 5 }}>
                          {/* <NewPasswordForm /> */}

                          <OtpInput
                            inputStyle={{ width: "28px ", height: "40px", borderRadius: "10px", border: "1px solid #0045BA", }}
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            separator={<span style={{ padding: "2px 5px" }}></span>}
                          />
                        </Box>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          loading={loadings}
                          onClick={() => {
                            verifyotp()

                          }}
                          style={{ height: "40px", marginTop: "20px", display: "block", width: "70%", marginLeft: "50px" }}
                        >
                          Verify
                        </LoadingButton>
                        <Typography variant="body2">
                          Don’t have a code? &nbsp;
                          <Link variant="subtitle2" onClick={() => {
                            otpsend()
                            handleClick4()
                          }} style={{ cursor: "pointer" }}>
                            Resend code
                          </Link>
                        </Typography>
                      </ContentStyle>
                    </Container>
                  </Card>
                </> : <>

                  <Card sx={{ p: 1 }}>
                    <Container>
                      <ContentStyle sx={{ textAlign: 'center' }}>
                        {/* <SentIcon sx={{ mb: 5, mx: 'auto', height: 120 }} /> */}

                        <Typography variant="h3" gutterBottom>
                          Request sent successfully!
                        </Typography>

                        <Typography sx={{ color: 'text.secondary', mb: 3 }}>
                          We've sent a 6-digit confirmation email to your email.
                          <br />
                          Please enter the code in below box to verify your email.
                        </Typography>
                        {/* <TextField name='email' label='Email' fullWidth value={email} disabled /> */}
                        <Box sx={{ mt: 3, mb: 3, ml: 7 }}>
                          {/* <NewPasswordForm /> */}

                          <OtpInput
                            inputStyle={{ width: "38px ", height: "40px", borderRadius: "10px", border: "1px solid #0045BA", }}
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            separator={<span style={{ padding: "5px 10px" }}></span>}
                          />
                        </Box>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          loading={loadings}
                          onClick={() => {
                            verifyotp()

                          }}
                          style={{ height: "40px", marginTop: "20px", display: "block", width: "70%", marginLeft: "50px" }}
                        >
                          Verify
                        </LoadingButton>
                        <Typography variant="body2">
                          Don’t have a code? &nbsp;
                          <Link variant="subtitle2" onClick={() => {
                            otpsend()
                            handleClick4()
                          }} style={{ cursor: "pointer" }}>
                            Resend code
                          </Link>
                        </Typography>
                      </ContentStyle>
                    </Container>
                  </Card>
                </>
                }



              </> : pagechange === 'third' ? <>
                <Card sx={{ px: 12, pt: 10, pb: 10 }}>

                  <TextField name='password' label='Password'
                    type={showPassword ? 'text' : 'password'}

                    value={password} onChange={(e) => {
                      setpassword(e.target.value)
                      setPasswordValid(
                        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&]{8,}$/.test(
                          e.target.value,
                        ) && e.target.value.length >= 8,
                      );
                    }

                    }

                    InputProps={{
                      endAdornment: (
                        <InputAdornment>


                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            size="small"
                          >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>

                          {/* {(() => {  if (passwordValid === null) {
                                                    return <img className="input-icon" src={lockIcon} alt="User Icon" />;
                                                } if (passwordValid) {
                                                    return <TickMark />;
                                                }
                                                return <CrossMark />;
                                  })()} */}

                        </InputAdornment>
                      ),
                    }}
                    fullWidth />
                  <br />
                  <br />
                  <TextField name='confirmpassword' label='Confirm password'
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            size="small"
                          >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>

                          {/* {(() => {  if (passwordValid === null) {
                                                    return <img className="input-icon" src={lockIcon} alt="User Icon" />;
                                                } if (passwordValid) {
                                                    return <TickMark />;
                                                }
                                                return <CrossMark />;
                                  })()} */}

                        </InputAdornment>
                      ),
                    }}
                    fullWidth value={username} onChange={(e) => {
                      setusername(e.target.value)

                    }} />
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={loadings}
                    disabled={(!(username === password && passwordValid) || username.length === 0)
                    }
                    onClick={() => changepassword()}
                    style={{ height: "40px", marginTop: "20px", display: "block", width: "100%", }}
                  >
                    Save
                  </LoadingButton>

                </Card>
              </> : "hy"
              }
            </>}

          </Grid>
        </Grid>
      </FormProvider>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: "right"
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="user update successfully"
        action={action}

      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: "right"
        }}
        open={open2}
        autoHideDuration={3000}
        onClose={handleClose2}
        message="Wrong Password"
        action={action2}

      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: "right"
        }}
        open={open3}
        autoHideDuration={3000}
        onClose={handleClose3}
        message="password successfully changed"
        action={action3}

      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: "right"
        }}
        open={open4}
        autoHideDuration={3000}
        onClose={handleClose4}
        message="OTP send successfully "
        action={action4}

      />
    </>
  );
}