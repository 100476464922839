import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Stack,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    List,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItem,
    Alert,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux';
import { getAllUsersOfCompany } from 'src/redux/slices/Setting/Create';
import axiosInstance, { baseUrl } from 'src/utils/axios';
import { incrementRefreshpage } from 'src/redux/Refreshpage';
//components

import Iconify from '../Iconify';


export default function InboxAssignUser({props}){

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [usersData, setUsersData] = useState([])
    const [existingUsers, setExistingUsers] = useState([])
    const [existId, setExistId] = useState([])
    const [search, setSearch] = useState('')
    const [lenghtAlert, setlengthAlert] = useState(false)

    const [openDialog, setOpenDialog] = useState(false)

    // hacking
    const [state, setState] = useState({});



    useEffect(() => {
        dispatch(getAllUsersOfCompany())
    }, [])

    useEffect(() => {
        setExistingUsers(props.row.UserId)
    }, [props])
    

    const { userOfCompany } = useSelector(state => state.inboxComments)

    useEffect(() => {

       if(userOfCompany.status === true && userOfCompany.status !== undefined){
            


            let userDataMerge = []
            let existIds = []

            if(existingUsers.length > 0){
                existingUsers.map(item=>{
                    existIds.push(item._id) 
                })
            }

            setExistId(existIds)

            let userofdata = userOfCompany.data

            userofdata.map((item, index)=>{

                if(existIds.includes(item._id)){
                    userDataMerge.push({data: userofdata[index], isSelected: true })
                }else{
                    userDataMerge.push({data: userofdata[index], isSelected: false })
                }
                
            })

            setUsersData(userDataMerge)

       }
       
    }, [userOfCompany])

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }
    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }


    const updateInbox = (value) => {
        axiosInstance.patch(`/inbox-view/${props.row._id}`, {
            UserId: value
        })
        .then((res) => {
            if(res.data.status === true){
                enqueueSnackbar('Assign user updated!');
                dispatch(incrementRefreshpage(value))
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }


    const toggleUser = (id, isSelected, index) =>{
        
    
        let MutableJson = JSON.parse(JSON.stringify(existId))

        if(isSelected === true){
            usersData[index].isSelected = false
            let find  = MutableJson.findIndex((item)=> item === id)
            MutableJson.splice(find, 1)
            setExistId(MutableJson)
           
        }else{
            usersData[index].isSelected = true
            MutableJson.push(id)
            setExistId(MutableJson)
          
        }

        // hackline
        setState({ ...state, [event.target.name]: event.target.checked });

        if(MutableJson.length > 0){
            setlengthAlert(false)
            updateInbox(MutableJson)
        }else{
            setlengthAlert(true)
        }
        
       
    }



    return(
        <>

               
                {existingUsers.map((item) => {
                    return(
                        <>
                        {item.user_logo !== '' && item.user_logo ? (

                        <Avatar style={{border: '1px solid #eee'}} src={`${baseUrl}/download/user_logo?fileLocation=${item.user_logo}`}  />


                                                
                                                ) : (
                        <Avatar style={{border: '1px solid #eee'}} src=''  />


                        )}
                        </>
                        
                    )
                    
                })}
                    

                <IconButton style={{border:'1px dashed #aaa'}} onClick={handleOpenDialog}>
                    <Iconify icon={'material-symbols:add-rounded'} sx={{ color: 'text.disabled', width: 24, height: 24 }} />
                </IconButton>



                <Dialog fullWidth maxWidth="xs" open={openDialog}>
            <Stack spacing={2} sx={{ p: 2.5, pb: 1 }}>
                <Typography variant="h6">
                    Users <Typography component="span">({userOfCompany.count ? userOfCompany.count : 0})</Typography>
                </Typography>

                <TextField
                    fullWidth
                    value={search}
                    onChange={(event)=>setSearch(event.target.value)}
                    placeholder="Search..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            <DialogContent
                style={{padding: '0 20px'}}
            >



            
                        <List
                   
                        >

                            {usersData.filter((item) => {
                            if (!search) return true;

                            if (item.data.FirstName.toLowerCase().includes(search.toLowerCase())) {
                                return true;
                            }
                            }).map((item, index)=>{
                                return(
                                    <ListItem button onClick={()=>toggleUser(item.data._id, item.isSelected, index)}>
                                    <ListItemAvatar sx={{ position: 'relative' }}>

                                    {item.data.user_logo !== '' && item.data.user_logo ? (

                                        <Avatar style={{border: '1px solid #eee'}} src={`${baseUrl}/download/user_logo?fileLocation=${item.data.user_logo}`}  />


                                                                
                                                                ) : (
                                        <Avatar style={{border: '1px solid #eee'}} src=''  />


                                        )}

                                       
                                    </ListItemAvatar>

                                    <ListItemText
                                        primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                                        secondaryTypographyProps={{ typography: 'caption' }}
                                        primary={`${item.data.FirstName} ${item.data.LastName}`}
                                        secondary={item.data.email}
                                    />

                                    <>
                                        {item.isSelected ? (
                                            <Typography variant='caption'>
                                                 <Iconify icon={'mdi:tick'} sx={{ color: 'text.disabled', width: 20, height: 20, marginBottom: '-6px' }}
                                                    
                                                    />
                                                    Assigned
                                            </Typography>
                                        ) : (
                                            <Typography variant='caption'>
                                            <Iconify icon={'material-symbols:add-rounded'} sx={{ color: 'text.disabled', width: 20, height: 20, marginBottom: '-6px' }}
                                                        
                                                        />
                                                        Assign
                                       </Typography>
                                        )}
                                   
                                            


                                                        
                                    </>
                                    </ListItem>

                                )
                            })}
                            

                        </List>

            </DialogContent>

            {lenghtAlert && (
                <Alert severity="error">Atleast One User Needed</Alert>
            )}

            <DialogActions>
                {!lenghtAlert && (
                    <Button onClick={handleCloseDialog}>
                        Close
                    </Button>
                )}
            </DialogActions>
        </Dialog>


            
        
        </>
    )
}
