import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';

const Onboardingbutton = ({ buyNumberClick, cancelNumberClick, params }) => {

    const selectedvoicenumber = useSelector(state => state.Selectedvoicenumberslice.value)
    return (

        <>

        {selectedvoicenumber === params.row.phone_number ? 
        (
            <Button  variant="contained" color='primary' style={{width: '120px'}} onClick={() => cancelNumberClick(params)} startIcon={<Iconify icon="teenyicons:tick-circle-outline" width={16} height={16} />}>
                Selected
            </Button>
        ) : (
            <Button  variant="outlined" style={{width: '120px'}} onClick={() => buyNumberClick(params)} startIcon={<Iconify icon="teenyicons:circle-outline" width={16} height={16} />}>
                Select
            </Button>
        )}


            
        </>
    );
};

export default Onboardingbutton;