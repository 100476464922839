import React, { useEffect, useState } from 'react'

import {
    Stepper, Typography,
    TextField,
    Button,
    Step,
    StepLabel,
    IconButton,
    Snackbar,
    Grid
} from "@mui/material";
import {
    useForm,
    Controller,
    FormProvider,
    useFormContext,
} from "react-hook-form";

import { useSnackbar } from 'notistack';

import AddUser from './AddUser';
import ConfigureInbox from './ConfigureInbox';
import CreateInbox from './CreateInbox';
import Done from './Done';
import Channel from './Channel';
import { useSelector, useDispatch } from 'react-redux';
import axiosInstance from 'src/utils/axios';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Whatsapplisting from './Whatsapplisting';
import AddWhatsappuser from './AddWhatsappuser';
import WhatsappDone from './WhatsappDone';
import SmsListing from './SmsListing';
import Addsms from './AddSms';
import SmsDone from './SmsDone';
import TelegramListing from './TelegramListing';
import ViberListing from './ViberListing';
import Addtelegramuser from './Addtelegramuser';
import TelegramDone from './TelegramDone';
import AddViber from './AddViber';
import ViberDone from './ViberDone';
import MessengerLisiting from './MessengerLisiting';
import Addmessenger from './Addmessenger';
import MessengerDone from './messengerDone';
import InstagramLisiting from './InstagramLisiting';
import AddInstagram from './AddInstagram';
import InstagramDone from './InstagramDone';
import Onboadingnumber from 'src/pages/dashboard/Onboardingnumber'
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import PermissionDenied from '../PermissionDenied';

// redux
import { incrementviberbottoken, incrementviberbottokenValidate } from 'src/redux/Viberbottoken'
import { incrementfacebookacess, incrementfacebookacessValidate } from 'src/redux/Facebookaceestoken'
import { incrementinboxuserid, incrementinboxuseridValidate } from 'src/redux/InboxUserid'
import { incrementChanneladduser, incrementChanneladduserValidate } from 'src/redux/ChannelAdduser'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#2065d1',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#2065d1',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));


export default function ChooseChannel(){

    const dispatch = useDispatch()

    const method = useForm({
        defaultValues: {
            firstName: "",
            lastName: "",
            password: "",
            emailAddress: "",
            phoneNumber: "",
            alternatePhone: "",
            address1: "",
            address2: "",
            country: "",
            cardNumber: "",
            cardMonth: "",
            cardYear: "",
        },
    });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const selectuser = useSelector(state => state.ChannelchooseSlice.value)
    const selectuserinbox = useSelector(state => state.ChannelinboxSlice.value)
    const buySingleNumber = useSelector(state => state.buyNumber.buySingleNumberResponse.data)
    const selectedNumberVoice = useSelector(state => state.Selectedvoicenumberslice.value)
    console.log('buySingleNumberuseSelector', buySingleNumber)
    console.log("namename", selectuserinbox)
    console.log('choosechoose', selectuser)
    const [activeStep, setActiveStep] = useState(0);
    const [skippedSteps, setSkippedSteps] = useState([]);

    const getSteps = () => {
        return [
            "Choose Channel",
            "Create Inbox",
            "Configure Inbox",
            "Add Users",
            "Done",
        ];
    }


    
     // permission
     const [userRolePermissions, setUserRolePermissions] = useState(
        {
          Workflow: {
              create: false,
              delete: false,
              edit: false,
              view: false
          },
          ContactsManage: {
            create: false,
            delete: false,
            edit: false,
            view: false
          },
          ContactsNotes: {
            create: false,
            delete: false,
            edit: false,
            view: false
          },
          ContactsGroups: {
            create: false,
            delete: false,
            edit: false,
            view: false
          },
          VoicemailBox: {
            create: false,
            delete: false,
            edit: false,
            view: false
          },
          Inbox: {
            create: false,
            delete: false,
            edit: false,
            view: false
          }
      }
      )
       const roleData = useSelector(state => state.userRole.roleData)
       let is_admin = localStorage.getItem('is_admin')
  
       
  
       useEffect(() => {
        
        if(roleData.status === true && roleData.status !== undefined){
          setUserRolePermissions(roleData.data[0].userPermission)
        }
      }, [roleData])
       
         // permission
  
    


    const steps = getSteps();
    const authid = localStorage.getItem('authID')
    const facebookacessSlice = useSelector(state => state.facebookacessSlice.value)
 
    console.log("facebookacessSlice", facebookacessSlice)
    const getapi = () => {
        axiosInstance.get(`/inbox-view?AccountSid=${authid}`)
            .then((res) => {
                console.log("getapi res", res.data.data)
            }).catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        getapi()
    }, [])

    const isStepOptional = (step) => {
        return step === 1 || step === 2;
    };
    const isStepFalied = () => {
        return Boolean(Object.keys(method.formState.errors).length);
    };
    const isStepSkipped = (step) => {
        return skippedSteps.includes(step);
    };

    const [open, setOpen] = useState(false)
    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }

        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };


    // child states
    const [selectedChannel, setSelectedChannel] = useState("")

    const[inboxName, setInboxName] = useState('')
    const[inboxIcon, setInboxIcon] = useState('')
    const [inboxNameRequired, setInboxNameRequired] = useState(false)

    const [defaultCountrySMS, setDefaultCountrySMS] = useState('');
    const [smssender, setsmssender] = useState('')
    const [smspeid, setsmspeid] = useState('')
    const [smsUsers, setSmsUsers] = useState([])
    const [smsCountryRequired, setsmsCountryRequired] = useState(false)
    const [smssenderRequired, setsmssenderRequired] = useState(false)
    const [smspeidRequired, setsmspeidRequired] = useState(false)
    const [userRequired, setUserRequired] = useState(false)

    const [whatsAppNumberValidate, setWhatsAppNumberValidate] = useState(false)
    const [whatsappNumberIdValidate, setWhatsAppNumberIdValidate] = useState(false)
    const [whatsappAuthKeyValidate, setWhatsAppAuthKeyValidate] = useState(false)
    const [whatsAppWabaAid, setWhatsAppWabaAid] = useState(false)

    const [telegramTokenValidate, setTelegramTokenValidate] = useState(false)

    

    useEffect(() => {
      console.log('smsUsers', smsUsers)
    }, [smsUsers])
    




    const getStepContent = (step, setActiveStep) => {
        switch (step) {
            case 0:
                return <Channel setSelectedChannel={setSelectedChannel} selectedChannel={selectedChannel} />;
            case 1:
                return <CreateInbox inboxNameRequired={inboxNameRequired} setInboxNameRequired={setInboxNameRequired} setInboxName={setInboxName} inboxName={inboxName} setInboxIcon={setInboxIcon} inboxIcon={inboxIcon} />;
    
            case 2:
                return <Onboadingnumber handleBack={handleBack} />;
            case 2.1:
                return <Whatsapplisting
                whatsAppNumberValidate={whatsAppNumberValidate} 
                setWhatsAppNumberValidate={setWhatsAppNumberValidate}
                whatsappNumberIdValidate={whatsappNumberIdValidate}
                setWhatsAppNumberIdValidate={setWhatsAppNumberIdValidate}
                whatsappAuthKeyValidate={whatsappAuthKeyValidate}
                setWhatsAppAuthKeyValidate={setWhatsAppAuthKeyValidate}
                whatsAppWabaAid={whatsAppWabaAid}
                setWhatsAppWabaAid={setWhatsAppWabaAid}
                 />
            case 2.2:
                return <SmsListing 
                        setDefaultCountrySMS={setDefaultCountrySMS}
                        defaultCountrySMS={defaultCountrySMS}
                        smssender={smssender}
                        setsmssender={setsmssender}
                        smspeid={smspeid}
                        setsmspeid={setsmspeid}
                        smsCountryRequired={smsCountryRequired}
                        setsmsCountryRequired={setsmsCountryRequired}
                        smssenderRequired={smssenderRequired}
                        setsmssenderRequired={setsmssenderRequired}
                        smspeidRequired={smspeidRequired}
                        setsmspeidRequired={setsmspeidRequired}
                         />
            case 2.3:
                return <TelegramListing 
                        telegramTokenValidate={telegramTokenValidate}
                        setTelegramTokenValidate={setTelegramTokenValidate}
                />
            case 2.4:
                return <ViberListing />
            case 2.5:
                return <MessengerLisiting setActiveStep={setActiveStep} />
            case 2.6:
                return <InstagramLisiting setActiveStep={setActiveStep} />
            case 3:
                return <AddUser userRequired={userRequired} setUserRequired={setUserRequired} setSmsUsers={setSmsUsers} smsUsers={smsUsers} />;
            case 3.1:
                return <AddWhatsappuser />
            // case 3.2:
            //     return <Addsms smsUserRequired={smsUserRequired} setSmsUserRequired={setSmsUserRequired} setSmsUsers={setSmsUsers} smsUsers={smsUsers} />
            case 3.3:
                return <Addtelegramuser />
            case 3.4:
                return <AddViber />
            case 3.5:
                return <Addmessenger />
            case 3.6:
                return <AddInstagram />
            case 4:
                return <Done />;
            case 4.1:
                return <WhatsappDone />
            case 4.2:
                return <SmsDone />
            case 4.3:
                return <TelegramDone />
            case 4.4:
                return <ViberDone />
            case 4.5:
                return <MessengerDone />
            case 4.6:
                return <InstagramDone />
            default:
                return "unknown step";
        }
    }

    useEffect(() => {
      if(activeStep === 0){
        handleNext()
      }
    }, [selectedChannel])

    useEffect(() => {
      if(activeStep === 2 && selectedChannel === 'voice'){
        if(selectedNumberVoice !== ''){
            console.log('selectedNumberVoice', selectedNumberVoice)
            handleNext()
        }
      }
    }, [selectedNumberVoice])
    
    
    

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    const [open1, setOpen1] = useState(false)
    const handleClose1 = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }

        setOpen1(false);
    };

    const handleClick1 = () => {
        setOpen1(true);
    };


    const action1 = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose1}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose1}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    const [open2, setOpen2] = useState(false)
    const handleClose2 = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }

        setOpen2(false);
    };

    const handleClick2 = () => {
        setOpen2(true);
    };


    const action2 = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose2}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose2}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );



    const [open3, setOpen3] = useState(false)
    const handleClose3 = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }

        setOpen3(false);
    };

    const handleClick3 = () => {
        setOpen3(true);
    };


    const action3 = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose3}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose3}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );




    const handleopen = () => {


        setTimeout(() => {
            setOpen(false)
        }, 2000)
    }

    const selectedwpnumber = useSelector(state => state.whatsappnumberSlice.value)
    const selectedwpnumberid = useSelector(state => state.whatsappnumberidSlice.value)
    const selectedwpwabaid = useSelector(state => state.whatsappwabaidSlice.value)
    const selectedwpauthkey = useSelector(state => state.whatsappauthkeySlice.value)
    const selectedsmspeid = useSelector(state => state.smspeidslice.value)
    const selectedsmssender = useSelector(state => state.smssenderidslice.value)
    const telegrambotid = useSelector(state => state.telegrambottokenSlice.value)
    const userselectid = useSelector(state => state.Inboxuseridslice.value)
    const inboxUserValidate = useSelector(state => state.Inboxuseridslice.validate)
    console.log("userselectid", userselectid)

    const onboardinguserId = localStorage.getItem('onboardinguserId')
    const onboardingphonenumber = localStorage.getItem('onboardingphonenumber')
    const onboardingname = localStorage.getItem('onboardingname')
    const selectedadduser = useSelector(state => state.ChanneladduserSlice.value)
    const ViberbottokenValue = useSelector(state => state.ViberbottokenSlice.value)
    

    
    // const facebookacessSlice = useSelector(state => state.facebookacessSlice.value)

    console.log('selectedadduser', selectedadduser)
    const numberselectedcheck = localStorage.getItem('numberselectedcheck')
    const clickclick = localStorage.getItem('click')


    const handleNext = (data) => {
        console.log(data);
        
        if (activeStep == steps.length - 1) {
            if (selectedadduser != '') {
                // setActiveStep(activeStep + 1);
            }
        }
            // fetch("https://jsonplaceholder.typicode.com/comments")
            //   .then((data) => data.json())
            //   .then((res) => {
            //     console.log(res);
            //     setActiveStep(activeStep + 1);
            //   });
        
             else if (selectuserinbox != '' && activeStep == 1 && selectuser == 'voice') {
                setActiveStep(2);
            } 
            // else if (selectuserinbox != '' && activeStep == 1 && selectuser == 'whatsapp') {
            //     setActiveStep(2.1);
            // } 
            // else if (selectuserinbox != '' && activeStep == 1 && selectuser == 'sms') {
                
            //     setActiveStep(2.2);
            // }
            // else if (selectuserinbox != '' && activeStep == 1 && selectuser == 'telegram') {
            //     setActiveStep(2.3);
            // }
            // else if (selectuserinbox != '' && activeStep == 1 && selectuser == 'viber') {
            //     setActiveStep(2.4);
            // }
            // else if (selectuserinbox != '' && activeStep == 1 && selectuser == 'messenger') {
            //     setActiveStep(2.5);
            // }
            // else if (selectuserinbox != '' && activeStep == 1 && selectuser == 'instagram') {
            //     setActiveStep(2.6);
            // }
            // else if (selectedsmspeid != "" && selectedsmssender != "" && activeStep == 2.2) {
            //     setActiveStep(3.2)
            // }
            // else if (telegrambotid != "" && activeStep == 2.3) {
            //     setActiveStep(3.3)
            // }
            // else if (ViberbottokenSlice != "" && activeStep == 2.4) {
            //     setActiveStep(3.4)
            // }
            // else if (facebookacessSlice !== "" && activeStep == 2.5) {
            //     setActiveStep(3.5)
            // }
            // else if (activeStep == 2.1 && selectedwpnumber != "" && selectedwpnumberid != "" && selectedwpauthkey != "") {
            //     setActiveStep(3.1);
            // }


            // else if (userselectid.length != 0 && activeStep == 3.2) {
            //     setActiveStep(4.2);
            // }
            // else if (userselectid.length != 0 && activeStep == 3.3) {
            //     setActiveStep(4.3);
            // }
            // else if (userselectid.length != 0 && activeStep == 3.4) {
            //     setActiveStep(4.4);
            // }
            // else if (userselectid.length != 0 && activeStep == 3.5) {
            //     setActiveStep(4.5);
            // }
            // else if (userselectid.length != 0 && activeStep == 3.6) {
            //     setActiveStep(4.6);
            // }
            // else if (userselectid.length != 0 && activeStep == 3) {
            //     setActiveStep(4);
            // } 
            // else if (activeStep == 3.1 && userselectid.length != 0) {
            //     setActiveStep(4.1)
            // }

            // else if (activeStep == 0) {
            //     handleClick()
            // }
            // else if (activeStep == 1) {
            //     enqueueSnackbar('To Continue, Inbox Name required', { 
            //         variant: 'error'
            //     });
            // }
           




            // new update

            if (activeStep == 0) {
                if(selectedChannel !== ''){
                    setActiveStep(1);
                }
                // }else{
                //     enqueueSnackbar('Please select any of the Channel', { 
                //         variant: 'error'
                //     });
                // }

                
            }



            if(activeStep === 1){
                
                    if(inboxName !== ''){
                        setInboxNameRequired(false)
                        if(selectedChannel === 'SMS'){  
                            setActiveStep(2.2)
                        }else
                        if(selectedChannel === 'whatsapp'){ 
                            setActiveStep(2.1)
                        }else
                        if(selectedChannel === 'telegram'){ 
                            setActiveStep(2.3)
                        }else
                        if(selectedChannel === 'viber'){ 
                            setActiveStep(2.4)
                        }else
                        if(selectedChannel === 'messenger'){ 
                            setActiveStep(2.5)
                        }else
                        if(selectedChannel === 'instagram'){ 
                            setActiveStep(2.6)
                        }
                        if(selectedChannel === 'voice'){ 
                            setActiveStep(2)
                        }

                        

                    }else{
                        setInboxNameRequired(true)
                        
                    }
                }

                if(activeStep === 2 && selectedNumberVoice !== '' && selectedChannel === 'voice'){
                    setActiveStep(3)
                }

       

            if(activeStep === 2.1){

                if(selectedwpnumber !== ''){
                    setWhatsAppNumberValidate(false)
                }else{
                    setWhatsAppNumberValidate(true)
                }

                if(selectedwpnumberid !== ''){
                    setWhatsAppNumberIdValidate(false)
                }else{
                    setWhatsAppNumberIdValidate(true)
                }

                if(selectedwpauthkey !== ''){
                    setWhatsAppAuthKeyValidate(false)
                }else{
                    setWhatsAppAuthKeyValidate(true)
                }

                if(selectedwpwabaid !== ''){
                    setWhatsAppWabaAid(false)
                }else{
                    setWhatsAppWabaAid(true)
                }

                if(selectedwpnumber !== '' && selectedwpwabaid !== '' && selectedwpnumberid !== '' && selectedwpauthkey !== ''){
                    setActiveStep(3);
                }
                
            }


           
                
                if(activeStep === 2.2){

                    console.log('defaultCountrySMS', defaultCountrySMS)

                    if(defaultCountrySMS === ''){
                        // enqueueSnackbar('Please select Country', { 
                        //     variant: 'error'
                        // });
                        setsmsCountryRequired(true)
                    }else{
                        setsmsCountryRequired(false)
                    }
                    if(defaultCountrySMS === 'IND'){
                        if(smssender === ''){
                            setsmssenderRequired(true)
                        }else{
                            setsmssenderRequired(false)
                        }
                        if(smspeid === ''){
                            setsmspeidRequired(true)
                        }else{
                            setsmspeidRequired(false)
                        }
                        if(defaultCountrySMS !== '' && smssender !== '' && smspeid !== ''){
                            setActiveStep(3)
                        }
                    }else{
                        if(defaultCountrySMS !== ''){
                            setActiveStep(3)
                        }
                    }

                   
                    

                   


                    
                }

                if(activeStep === 2.3){
                    console.log('telegram click 2.3', telegrambotid)
                    if(telegrambotid === ''){
                        setTelegramTokenValidate(true)
                    }else{
                        setTelegramTokenValidate(false)
                        setActiveStep(3)
                    }
                }

                if(activeStep === 2.4){
                    console.log('viber click 2.4', ViberbottokenValue)
                    if(ViberbottokenValue !== ''){
                        dispatch(incrementviberbottokenValidate(false))
                        setActiveStep(3)
                    }else{
                        dispatch(incrementviberbottokenValidate(true))
                        
                    }
                    
                }

                if(activeStep === 2.5 || activeStep === 2.5){
                    
                   
                    if(facebookacessSlice !== ""){
                        dispatch(incrementfacebookacessValidate(false))
                        setActiveStep(3)
                    }else{
                        dispatch(incrementfacebookacessValidate(true))
                        
                    }
                    
                }

              
              

                if(activeStep === 3){
                    console.log('user step'. userselectid)
                    if(userselectid.length !== 0){
                        // setUserRequired(false)
                        dispatch(incrementinboxuseridValidate(false))

                        if(selectedChannel === 'voice'){
                            setActiveStep(4)
                        }else
                        if(selectedChannel === 'SMS'){
                            setActiveStep(4.2)
                        }else
                        if(selectedChannel === 'whatsapp'){
                            setActiveStep(4.1)
                        }else
                        if(selectedChannel === 'telegram'){
                            setActiveStep(4.3)
                        }else
                        if(selectedChannel === 'viber'){
                            setActiveStep(4.4)
                        }else
                        if(selectedChannel === 'messenger'){
                            setActiveStep(4.5)
                        }else
                        if(selectedChannel === 'instagram'){
                            setActiveStep(4.6)
                        }


                        
                        // console.log('userRequired channel', userRequired, smsUsers.length)
                    }else{
                        // setUserRequired(true)
                        dispatch(incrementinboxuseridValidate(true))
                        // console.log('userRequired channel', userRequired)
                        
                    }
                }

                console.log('active step', activeStep)
                
           

            // setSkippedSteps(
            //     skippedSteps.filter((skipItem) => skipItem !== activeStep)
            // );
        
    };

    const handleBack = () => {
        if (activeStep == 2  || activeStep == 2.1 || activeStep == 2.2 || activeStep == 2.3 || activeStep == 2.4 || activeStep == 2.5 || activeStep == 2.6){
            setActiveStep(1)
        }
       

        if (activeStep == 1) {
            setActiveStep(0)
        }

        if(selectedChannel === 'SMS'  && activeStep === 2.2){  
            setActiveStep(1)
        }else
        if(selectedChannel === 'whatsapp' && activeStep === 2.1){ 
            setActiveStep(1)
        }else
        if(selectedChannel === 'telegram' && activeStep === 2.3){ 
            setActiveStep(1)
        }else
        if(selectedChannel === 'viber' && activeStep === 2.4){ 
            setActiveStep(1)
        }else
        if(selectedChannel === 'messenger' && activeStep === 2.5){ 
            setActiveStep(1)
        }else
        if(selectedChannel === 'instagram' && activeStep === 2.6){ 
            setActiveStep(1)
        }else
        if(selectedChannel === 'voice' && activeStep === 2){ 
            setActiveStep(1)
        }


        if(selectedChannel === 'SMS'  && activeStep === 3){  
            setActiveStep(2.2)
        }else
        if(selectedChannel === 'whatsapp' && activeStep === 3){ 
            setActiveStep(2.1)
        }else
        if(selectedChannel === 'telegram' && activeStep === 3){ 
            setActiveStep(2.3)
        }else
        if(selectedChannel === 'viber' && activeStep === 3){ 
            setActiveStep(2.4)
        }else
        if(selectedChannel === 'messenger' && activeStep === 3){ 
            setActiveStep(2.5)
        }else
        if(selectedChannel === 'instagram' && activeStep === 3){ 
            setActiveStep(2.6)
        }else
        if(selectedChannel === 'voice' && activeStep === 3){ 
            setActiveStep(2)
        }

    };

    // const handleSkip = () => {
    //     if (!isStepSkipped(activeStep)) {
    //         setSkippedSteps([...skippedSteps, activeStep]);
    //     }
    //     setActiveStep(activeStep + 1);
    // };


    return (

        <>
        {is_admin !== 'true' && !userRolePermissions.Inbox.create ? ( <div style={{marginTop: '150px'}}> 
            
            <PermissionDenied  />

            </div> 
            ) : (

                <>
                
              

        <div>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />} style={{ marginTop: "50px" }}>
                {steps.map((step, index) => {
                    const labelProps = {};
                    const stepProps = {};
                    {/* if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography
                                variant="caption"
                                align="center"
                                style={{ display: "block" }}
                            >
                                optional
                            </Typography>
                        );
                    } */}
                    {/* if (isStepFalied() && activeStep == index) {
                        labelProps.error = true;
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    } */}
                    return (
                        <Step {...stepProps} key={index}>
                            <StepLabel {...labelProps}>{step}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === steps.length ? (
                <Typography variant="h3" align="center">
                    Thank You
                </Typography>
            ) : (
                <>
                    {/* <FormProvider {...method}>
                        <form onSubmit={method.handleSubmit(handleNext)}

                        > */}
                    {getStepContent(activeStep, setActiveStep)}

                    <Grid container style={{ marginTop: "20px" }}>

                        <Grid item xs></Grid>
                        <Grid item>
                        {(activeStep === steps.length - 1 || activeStep == 4.1 || activeStep == 4.2 || activeStep == 4.3 || activeStep == 4.4 || activeStep == 4.5 || activeStep == 4.5) ? <>

                        </> : <>
                        {activeStep !== 0 && (
                            <>
                            {activeStep === 2 && selectedChannel === 'voice' ? '' : (
                            <Button

                                                        
                            onClick={handleBack}
                            style={{ marginBottom: "20px" }}
                            >
                            back
                            </Button>
                            )}
                            </>
                        )}
                           
                        </>}

                        {/* {isStepOptional(activeStep) && (
                                    <Button
                                       
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSkip}
                                        style={{ marginRight: "10px" }}
                                    >
                                        skip
                                    </Button>
                                )} */}

                        {(activeStep === steps.length - 1 || activeStep == 4.1 || activeStep == 4.2 || activeStep == 4.3 || activeStep == 4.4 || activeStep == 4.5 || activeStep == 4.5) ? <>

                        </> : <>
                        {activeStep !== 0 && (
                            <>
                            {activeStep === 2 && selectedChannel === 'voice' ? '' : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    type="submit"
                                    style={{ marginBottom: "20px" }}
                                >
                                {activeStep === steps.length - 1 ? "Finish" : "Next"}
                                </Button>
                            )}
                            
                            </>
                        )}
                        </>}
                        </Grid>
                        <Grid item xs></Grid>
                    </Grid>

                    {/* </form>
                    </FormProvider> */}


                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: "right"
                        }}
                        open={open}
                        autoHideDuration={1000}
                        onClose={handleClose}
                        message="Please Select channel"
                        action={action}
                    />

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: "right"
                        }}
                        open={open1}
                        autoHideDuration={1000}
                        onClose={handleClose1}
                        message="Please fill inbox"
                        action={action1}
                    />


                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: "right"
                        }}
                        open={open2}
                        autoHideDuration={1000}
                        onClose={handleClose2}
                        message="Please Buy number"
                        action={action1}
                    />


                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: "right"
                        }}
                        open={open3}
                        autoHideDuration={1000}
                        onClose={handleClose3}
                        message="Please Select user"
                        action={action3}
                    />
                </>
            )}
        </div>

        </>

        )}

</>
    )
}
