import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import { styled } from '@mui/material/styles';
import { Avatar, Box, Typography } from '@mui/material';
// components
import Image from '../../../components/Image';
import moment from 'moment';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'inline-block',
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

ChatMessageItemMessenger.propTypes = {
  message: PropTypes.object.isRequired,
  conversation: PropTypes.object.isRequired,
  onOpenLightbox: PropTypes.func,
};

export default function ChatMessageItemMessenger({ message, conversation, onOpenLightbox }) {
  //console.log('ChatMessageItem', message, conversation , onOpenLightbox);
  // const sender = conversation.participants.find((participant) => participant.id === message.senderId);
  console.log('messengermessage', message)
  let sender
  if (message.Direction === 'Inbound') {
    for (let i = 0; i < conversation.participants.length; i++) {
      if (conversation.participants[i].number === message.From) {
        sender = conversation.participants[i]
      }
    }
  }
  if (message.Direction === 'Outbound') {
    for (let i = 0; i < conversation.participants.length; i++) {
      if (conversation.participants[i].number === message.To) {
        sender = conversation.participants[i]
      }
    }
  }

  const senderDetails =
    message.senderId === '8864c717-587d-472a-929a-8e5f298024da-0'
      ? { type: 'me' }
      : { avatar: sender?.avatar, name: sender?.user_name };

  // const isMe = senderDetails.type === 'me';

  const isMe = message.Direction === 'Outbound' ? true : false;

  // const isImage = message.contentType === 'image';
  const isImage = false;

  const firstName = conversation.participants[1].user_name

  const checkDateIsValid = (d) => {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d)) { // d.getTime() or d.valueOf() will also work
        // date object is not valid
        return Date.now()
      } else {
        // date object is valid
        return d
      }
    } else {
      // not a date object
      return Date.now()
    }
  }

  const convertUnixTimeToReadableTime = (unix) => {
    const dateString = moment.unix(unix).format();
    console.log("List Message : ", dateString)
    // const result = checkDateIsValid(dateString)
    return dateString
  }
  console.log("datedate", formatDistanceToNowStrict(checkDateIsValid(new Date((message.createdAt)))))
  const checkIfCorrectTimeIsPresent = (message) => {
    let result = ''
    if (message) {
      if (message.status === null) {
        const date = Date.now()
        console.log("List Date : ", date)
        return null
      }
      else if (message.timestamp) {
        result = message.timestamp
        return result
      }
      else if (message.status.delivered) {
        result = message.status.delivered
        return result
      }
      else if (message.status.read) {
        result = message.status.read
        return result
      } else {
        return result
      }
    }
    return result
  }

  return (
    <RootStyle>
      <Box
        sx={{
          display: 'flex',
          ...(isMe && {
            ml: 'auto',
          }),
        }}
      >
        {senderDetails.type !== 'me' && (
          <Avatar alt={senderDetails.name} src={senderDetails.avatar} sx={{ width: 32, height: 32, mr: 2 }} />
        )}

        <div>
          <InfoStyle
            variant="caption"
            sx={{
              ...(isMe && { justifyContent: 'flex-end' }),
            }}
          >
            {!isMe && `${firstName},`}&nbsp;
            {/* add createdAt field in message */}
            {/* {formatDistanceToNowStrict(new Date(message), {
              addSuffix: true,
            })} */}
            {formatDistanceToNowStrict(checkDateIsValid(new Date(message.createdAt)), {
              addSuffix: true,
            })}

          </InfoStyle>

          <ContentStyle
            sx={{
              ...(isMe && {
                color: 'grey.800',
                bgcolor: 'primary.lighter',
              }),
              ...(isImage && {
                p: 0,
              }),
            }}
          >
            {isImage ? (
              <Image
                alt="attachment"
                src={message.body}
                onClick={() => onOpenLightbox(message.messageBody ? message.messageBody : message.templateName)}
                sx={{ borderRadius: 1, cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
              />
            ) : (
              <Typography variant="body2">{message.messageBody ? message.messageBody : message.templateName}</Typography>
            )}
          </ContentStyle>
        </div>
      </Box>
    </RootStyle>
  );
}
