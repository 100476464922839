import React from 'react'
import Instagram from './Instagram'

const InstagramLisiting = ({ setActiveStep }) => {
    return (
        <div>
            <Instagram setActiveStep={setActiveStep} />
        </div>
    )
}

export default InstagramLisiting