import axios from "axios";
import axiosInstance, { baseUrl } from "src/utils/axios";
import {Buffer} from "buffer";
export const SET_USER = "SET_USER";
export const SET_COMPANY = "SET_COMPANY";
export const SET_AGENT_CONTACT = "SET_AGENT_CONTACT";
export const SET_SHOW_ALL_LIST = "SET_SHOW_ALL_LIST";
export const SET_CALL_OFFSET = "SET_CALL_OFFSET";
export const SET_MISSED_OFFSET = "SET_MISSED_OFFSET";
export const SET_CALL_HISTORY = "SET_CALL_HISTORY";
export const SET_MISSED_CALL = "SET_MISSED_CALL";
export const SET_POWER_DIALER_INDEX = "SET_POWER_DIALER_INDEX";
export const SET_POWER_DIALER = "SET_POWER_DIALER";
export const SET_NOTES = "SET_NOTES";
export const SET_APP = "SET_APP";
export const SET_CLOUDPHONE_NUMBER = "SET_CLOUDPHONE_NUMBER";
export const SET_ALLOWED_COUNTRY = "SET_ALLOWED_COUNTRY";
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY";
export const SET_LIST_APP_CONFIG = "SET_LIST_APP_CONFIG"
export const SET_CLOUDPHONE_APP = "SET_CLOUDPHONE_APP";
export const SET_LIST_APP = "SET_LIST_APP";
export const SET_INBOX = "SET_INBOX";
export const SET_SHOW_CLOUDPHONE = "SET_SHOW_CLOUDPHONE";
export const SET_SHOW_TICKET = "SET_SHOW_TICKET";
export const SET_SHOW_POWER_DIALER = "SET_SHOW_POWERDIALER";
export const SET_SHOW_VOICEMAIL = "SET_SHOW_VOICEMAIL";
export const SET_TICKET_APP = "SET_TICKET_APP";
export const SET_POWER_DIALER_APP = "SET_POWER_DIALER_APP";
export const SET_VOICE_MAIL_APP = "SET_VOICE_MAIL_APP";


const initialState = {
    user: {},
    company: [],
    agent_contact: [],
    show_all_list: true,
    call_offset: 0,
    missed_offset: 0,
    callHistory: [],
    missed_call: [],
    power_dialer_index: 0,
    power_dialer: false,
    notes: [],
    app: [],
    cloud_numbers: [],
    selected_country: "",
    allowed_country: [],
    list_app_config: [],
    cloudphone_app: [],
    list_app: [],
    inbox: [],
    show_cloudphone: false,
    show_ticket: false,
    show_powerdialer: false,
    show_voicemail: false,
    ticket_app: [],
    power_dialer_app: [],
    voice_mail_app: []
}

export function userReducer(state = initialState, action) {
   switch (action.type) {
    case SET_USER:
        return {...state, user: action.payload }

    case SET_COMPANY:
        return {...state, company: action.payload }

    case SET_AGENT_CONTACT:
        return {...state, agent_contact: action.payload }

    case SET_SHOW_ALL_LIST:
        return { ...state, show_all_list: action.payload }

    case SET_CALL_OFFSET:
        return { ...state, call_offset: action.payload }

    case SET_MISSED_OFFSET:
        return { ...state, missed_offset: action.payload }

    case SET_CALL_HISTORY:
        return { ...state, callHistory: action.payload }

    case SET_MISSED_CALL:
        return { ...state, missed_call: action.payload }

    case SET_POWER_DIALER_INDEX:
        return { ...state, power_dialer_index: action.payload }

    case SET_POWER_DIALER: 
       return { ...state, power_dialer: action.payload }

    case SET_NOTES:
       return { ...state, notes: action.payload }

    case SET_APP:
        return { ...state, app: action.payload }

    case SET_CLOUDPHONE_NUMBER:
        return { ...state, cloud_numbers: action.payload }

    case SET_ALLOWED_COUNTRY:
        return { ...state, allowed_country: action.payload }

    case SET_SELECTED_COUNTRY:
        return { ...state, selected_country: action.payload }

    case SET_LIST_APP_CONFIG:
        return { ...state, list_app_config: action.payload }

    case SET_CLOUDPHONE_APP:
        return { ...state, cloudphone_app: action.payload }

    case SET_LIST_APP:
        return { ...state, list_app: action.payload }

    case SET_INBOX:
        return { ...state, inbox: action.payload }

    case SET_SHOW_CLOUDPHONE:
        return { ...state, show_cloudphone: action.payload }

    case SET_SHOW_TICKET:
        return { ...state, show_ticket: action.payload }

    case SET_SHOW_POWER_DIALER:
        return { ...state, show_powerdialer: action.payload }

    case SET_SHOW_VOICEMAIL:
        return { ...state, show_voicemail: action.payload }

    case SET_TICKET_APP:
        return { ...state, ticket_app: action.payload }

    case SET_POWER_DIALER_APP:
        return { ...state, power_dialer_app: action.payload }

    case SET_VOICE_MAIL_APP:
        return { ...state, voice_mail_app: action.payload }
        
    default: 
        return state;
   }
}

export const get_app = (auth_id, token) => {
  return new Promise(async(resolve, reject) => {
    await axios({
        method: "GET",
        url: `${baseUrl}/app?populate=app_id&auth_id=${auth_id}&populate=active_by`,
        headers: {
            authorization: token
        }
    }).then((res) => {
        const response_data = res.data;
        if (response_data.status === true) {
            const app_response_data = response_data.data.data;
            resolve(app_response_data);
        }
    }).catch((err) => {
        console.log("error", err);
        reject(err);
    })
  })
}



export const create_app = (auth_id, token, user_id, app_name) => {
    return new Promise(async(resolve, reject) => {
      await axios({
          method: "POST",
          url: `${baseUrl}/app`,
          headers: {
              authorization: token
          },
          data: {
            "auth_id" : auth_id,
            "app_name": app_name,
            "active_by" : user_id
        }
      }).then((res) => {
          const response_data = res.data;
          if (response_data.status === true) {
              const app_response_data = response_data.data;
              resolve(app_response_data);
          }
      }).catch((err) => {
          console.log("error", err);
          reject(err);
      })
    })
  }

export const get_inbox = (auth_id, token, inbox_type) => {
    return new Promise(async(resolve, reject) => {
       await axios({
        method: "GET",
        url: `${baseUrl}/inbox-view?AccountSid=${auth_id}&InboxType=${inbox_type}`,
        headers: {
            authorization: token
        }
       }).then((res) => {
        const response_data = res.data;
        if (response_data.status === true) {
            resolve(response_data.data.data);
        }
       }).catch((err) => {
        console.log("error", err);
        reject(err)
       })
    })
}

export const get_number = (number, token) => {
    return new Promise(async(resolve, reject) => {
        await axios({
            method: "GET",
            url: `${baseUrl}/search_by_number?number=${number}`,
            headers: {
                authorization: token
            }
        }).then((response) => {
            const response_data = response.data.data;
            resolve(response_data);
        }).catch((err) => {
            reject(err)
        })
    })
}

export const edit_app_config = (app_config_id, body, token) => {
    return new Promise(async(resolve, reject) => {
        await axios({
            method: "PATCH",
            url: `${baseUrl}/app-config/${app_config_id}`,
            data: body,
            headers: {
                authorization: token
            }
        }).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            console.log("error", err);
            reject(err)
        })
    })
}

export const edit_app = (app_id, body, token) => {
    return new Promise(async(resolve, reject) => {
        await axios({
            method: "PATCH",
            url: `${baseUrl}/app/${app_id}`,
            headers: {
                authorization: token
            },
            data: body
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err);
        })
    })
}

export const create_sip_account = (token, first_name) => {
    return new Promise(async(resolve, reject) => {
        await axios({
            method: "POST",
            url: `${baseUrl}/create-sip-user`,
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            },
            data: {
                name: first_name
            }
        }).then((res) => {
            resolve(res.data);
        }).catch((err) => {
            reject(err);
        })
    })
}

export const get_app_config = (token, app_name) => {
    return new Promise(async(resolve, reject) => {
        await axios({
            method: "GET",
            url: `${baseUrl}/app-config?populate=user_id&app_name=${app_name}&populate=active_by`,
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            resolve(res.data.data.data);
        }).catch((err) => {
            reject(err);
        })
    })
}

export const saveNoteAndTags = (friendly_name, token, body) => {
    return new Promise(async(resolve, reject) => {
        await axios({
            method: "PUT",
            url: `${baseUrl}/cloudphone/edit/${friendly_name}`,
            headers: {
                authorization: token
            },
            data: body
        }).then((res) => {
            resolve(res);
        }).catch((err) => {
            console.log("error", err)
            reject(err)
        })
    })
}