import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import axiosInstance, { baseUrl } from 'src/utils/axios';
import {useDispatch} from "react-redux"
import { SET_APP, SET_USER } from 'src/redux/userReducer';

// ----------------------------------------------------------------------

export default function LoginForm() {

  localStorage.clear();
  console.clear()


  const navigate = useNavigate();
  const { login, isAuthenticated, isInitialized } = useAuth();
  const dispatch = useDispatch()

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [loader, setloader] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setloader(true);
    localStorage.clear();
    setErrorMessage('')
    try {
      const response = await login(data.email, data.password);
      console.log("response in form 56 : ", response);
      if (response.isAuthenticated) {
        navigate('/dashboard/matrix');
        // setloader(true);
        // getdata()
      }
      // window.location.href = PATH_AFTER_LOGIN;
    } catch (error) {
      console.log("error in loginForm", error)
      console.error(error);
      setErrorMessage(error.errorMsg)
      // setError('afterSubmit', { ...error, message: error.message });
      // console.log("error in loginForm", setError)
      reset();
      setloader(false)

      // if (isMountedRef.current) {

      // }
    }
  };
  const getdata = async () => {

    const res = await axiosInstance.get(`${baseUrl}/getAuth`)
    dispatch({
      type: SET_USER,
      payload: res.data.data
    })

    


    console.log('getauth1', res.data.data)

    const user_data = res.data.data;
    localStorage.setItem('customerIdInZoho', res.data.data.customerIdInZoho)
    localStorage.setItem('FirstName', res.data.data.FirstName)
    localStorage.setItem('LastName', res.data.data.LastName)
    localStorage.setItem('email', res.data.data.email)
    localStorage.setItem('phone', res.data.data.phone)

    localStorage.setItem('_id', res.data.data._id)
    localStorage.setItem('user_logo', res.data.data.user_logo)
   
    if (res.data.data.customerIdInZoho && res.data.data.customerIdInZoho !== undefined) {

      
      planttrailapi(res.data.data.customerIdInZoho)
      await axiosInstance.get(`${baseUrl}/app-config?populate=cloudNumber_allow&user_id=${user_data._id}`).then((res1) => {
        const response_data = res1.data;
        if (response_data.status === true) {
          dispatch({
            type: SET_APP,
            payload: response_data.data.data
          })
        }
      }).catch((err) => {
        console.log("error", err)
      })
    } else {
      navigate('/dashboard/matrix');
      setloader(false);
    }
    console.log('getauthset', res.data.data.subscription_type)
    localStorage.setItem('country', res.data.data.country_allowed[0].code)
  }

  const planttrailapi = (value) => {

    axiosInstance.get(`${baseUrl}/users-plan?customer_id=${value}`).then((res) => {
      console.log('plan trail api', res.data.data)
      localStorage.setItem("planstatus", res.data.data.status)

      const planstatus = res.data.data.status
      console.log("getapi planstatus", planstatus)
     
      const authid = localStorage.getItem('authID')
      axiosInstance.get(`/inbox-view?AccountSid=${authid}`)
        .then((res) => {
          console.log("getapi respo", res.data.data.data)
          localStorage.setItem('getapiinbox', res.data.data.data.length)
        
          console.log("getapi respo item", res.data.data.data.length)
          if (planstatus == 'trial' && res.data.data.data.length === 0) {
            setloader(false);

            navigate('/dashboard/onboarding/welcome');
          }
          else {
            setloader(false);
            console.log("getapi planstatus", planstatus)
            navigate('/dashboard/matrix');
          }
       
        }).catch((err) => {
          navigate('/dashboard/matrix');
          console.log(err)
        })
      
    }).catch((err) => {
    
      setloader(false)
      navigate('/dashboard/matrix');
    })
  }
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {errorMessage !== '' ? <Alert style={{ marginTop: '10px' }} severity="error">Opps Something wrong! please try again</Alert> : ''}

        <RHFTextField name="email"  InputLabelProps={{ shrink: true }} label="Email Address" />

        <RHFTextField
          name="password"
          label="Password"
          InputLabelProps={{ shrink: true }}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loader}

      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
