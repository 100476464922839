import React from 'react'
import Facebook from './Facebook'

const MessengerLisiting = ({ setActiveStep }) => {
    return (
        <div>

            <Facebook setActiveStep={setActiveStep} />
        </div>
    )
}

export default MessengerLisiting