import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

// export const REACT_APP_BASE_URL = 'https://dev.vibtree.com'
 export const REACT_APP_BASE_URL = 'https://mibsback.vibtree.com'
// export const REACT_APP_BASE_URL = 'https://mibsback.vibtree.com'
export const baseUrl = `${REACT_APP_BASE_URL}/api`
// export const baseUrl = `https://mibsback.vibtree.com`
const reduxToken = localStorage.getItem('redux-token')



const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 30000,
  headers: {
    authorization: localStorage.getItem('redux-token'),
    'Access-Control-Allow-Origin': '*'
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
