import { LoadingButton } from '@mui/lab'
import { Snackbar, TextField } from '@mui/material'
import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react'
import { useState } from 'react'
import axiosInstance, { baseUrl } from 'src/utils/axios'
import VerifyLoginOtp from './VerifyLoginOtp'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from 'react-phone-input-2';
const OtpLogin = ({ step, setstep }) => {
    const [phone, setphone] = useState('')
    const [isLoading, setLoading] = useState(false);
    const [loadings, setloadings] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const action = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const otpsend = () => {
        setloadings(true)
        axiosInstance.post(`${baseUrl}/login/send-otp`,
            {
                "number": phone
            }).then((res) => {
                setloadings(false)
                console.log(res.data.data[0].uniqueId)
                console.log(res.data.status)
                localStorage.setItem('statuslogin', res.data.status)
                localStorage.setItem('uniqueId', res.data.data[0].uniqueId)
                localStorage.setItem('phone', phone)
                localStorage.setItem('vikalp', "vikalp")
                setstep(step + 1)
            }).catch((err) => {
                setloadings(false)
                console.log(err)
                handleClick()
            })
    }

    return (
        <div>
            <Stack spacing={3}>
                {/* <TextField
                    name='phonenumber'
                    label='Phone Number'
                    fullWidth
                    type='number'
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                /> */}

                <PhoneInput

                    country={'in'}
                    value={phone}
                    specialLabel=""
                    enableSearch={true}
                    onChange={(e) => {
                        setphone(e)
                        console.log(e)
                    }}

                    containerStyle={{

                        width: "100%",
                        height: "55px"

                    }}
                    inputStyle={{
                        // background: "lightblue"
                        width: "100%",
                        height: "55px",

                    }}

                />
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained"
                loading={loadings}
                onClick={() => otpsend()} style={{ marginTop: "25px" }}

                disabled={
                    phone.length < 12 || phone.length > 12
                }
            >
                Next
            </LoadingButton>

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: "right"
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Please Enter Valid Number"
                action={action}

            />
        </div>
    )
}

export default OtpLogin