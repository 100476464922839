
import React, { useState, useEffect } from "react";
import './style.css'
import { useSelector, useDispatch } from 'react-redux';
import {FormHelperText} from '@mui/material'

// flag asset
import ReactFlagsSelect from "react-flags-select";
import { findFlagUrlByIso3Code } from "country-flags-svg";

import { getAuth } from '../../redux/slices/getAuth';

// use auth
const getCountryISO3 = require("country-iso-2-to-3");
const getCountryISO2 = require("country-iso-3-to-2");
const iso = require('iso-3166-1');
`   `
export default function AllowedCountry({selectedCountry, alreadySelected, setRequired, required}) {

    const dispatch = useDispatch();
    const [defaultCountry, setDefaultCountry] = useState('IND');
    const [callingCountry, setCallingCountry] = useState('');
    const [allowedCountries, setAllowedCountries] = useState([]);
    const [usercountry, setusercountry] = React.useState([]);

    useEffect(() => {
        setCallingCountry(alreadySelected)
    }, [alreadySelected])
    

    const getCountryDetails = (country) =>{
        const countryDetails = iso.whereAlpha3(country);
        console.log("newContries 140 ", country , countryDetails)
        return countryDetails;
    }
    const newCountries = allowedCountries.map((country)=>{
        const x = getCountryDetails(country.code);
        console.log("newCountries 147 : ", country , x)
        return x
      })
    const alpha3code= newCountries.map((item)=> item.alpha2);

    const handleCallingCountry = (code) => {
        console.log("selected country : ", code , getCountryISO3(code) )
        setDefaultCountry(getCountryISO3(code))
        // setCallingCountry(code)
        // selectedCountry(code)
        setCallingCountry(getCountryISO3(code))
        selectedCountry(getCountryISO3(code))
        if(setRequired){
            if(code !== ''){
                setRequired(false)
            }else{
                setRequired(true)
            }
        }
        
      };


    // get user data
    useEffect(() => {
        dispatch(getAuth());
    }, [dispatch]);

    const userAuthData = useSelector(state => state.getAuth)

    useEffect(() => {
        console.log('userAuthData', userAuthData)
        if(userAuthData.getAuthData.code === 200 && userAuthData.getAuthData.code !== undefined){
            setAllowedCountries(userAuthData.getAuthData.data.country_allowed)
        }
      }, [userAuthData])

    return(
        <>
        <ReactFlagsSelect
                        searchPlaceholder="-Search Country-"
                        searchable
                        selected={getCountryISO2(callingCountry)}
                        onSelect={handleCallingCountry}
                        fullWidth
                        className="numberFlag channel"
                        countries={alpha3code}
                        
                        />
                        {required && (
                            <FormHelperText error
                            >
                                Country is Required
                            </FormHelperText>
                        )}
        


        </>
    )
}