import { Grid, FormControl, FormLabel, TextField, FormHelperText } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { incrementsmspeid } from 'src/redux/Smspeid'
import { incrementsmssender } from 'src/redux/SmsSenderId'
import AllowedCountry from '../inputs/allowedCountry'
import { countries } from './Countries'
const SmsListing = ({
    setDefaultCountrySMS, defaultCountrySMS,
    smssender, setsmssender, smspeid, setsmspeid,
    smssenderRequired,
    setsmssenderRequired,
    smspeidRequired,
    setsmspeidRequired,
    smsCountryRequired,
    setsmsCountryRequired
}) => {
    const [countryname, setcountries] = useState('')
    console.log("countries", countryname)
  
    const [defaultCountry, setDefaultCountry] = useState('');
    const [countryRequired, setCountryRequired] = useState(false)
    const dispatch = useDispatch()
    const handlesmssender = (e) => {
        setsmssender(e.target.value)
        dispatch(incrementsmssender(e.target.value))
        if(e.target.value !== ''){
            setsmssenderRequired(false)
        }
    }
    const handlesmspeid = (e) => {
        setsmspeid(e.target.value)
        dispatch(incrementsmspeid(e.target.value))
        if(e.target.value !== ''){
            setsmspeidRequired(false)
        }
    }
    console.log("default country", defaultCountry)

    useEffect(() => {
        setDefaultCountrySMS(defaultCountry)
    }, [defaultCountry])

    useEffect(() => {
        if(setsmsCountryRequired){
            setsmsCountryRequired(countryRequired)
        }
        
    }, [countryRequired])

    useEffect(() => {
        if(smsCountryRequired){
        setCountryRequired(smsCountryRequired)
        }
    }, [smsCountryRequired])

    useEffect(() => {
      if(defaultCountrySMS !== '' && defaultCountrySMS !== defaultCountry){
        setDefaultCountry(defaultCountrySMS)
      }
    }, [defaultCountrySMS])
    
    
    
    
    return (
        <div>
        <Grid container direction='row' justifyContent='center' style={{marginTop: '50px'}}>
        <Grid item md={6}>
            <Grid container direction='column' spacing={3}>
                <Grid item xs>
            <FormControl fullWidth>
                    <FormLabel>Select Country</FormLabel>
                <AllowedCountry selectedCountry={setDefaultCountry} alreadySelected={defaultCountry} setRequired={setCountryRequired} required={countryRequired} />
                
                </FormControl>
               

            </Grid>

            {defaultCountry == "IND" && (
                <>
                <Grid item xs>
                    <FormControl fullWidth>
                    <FormLabel>Sender ID</FormLabel>
                    <TextField
                        name='Sender ID'
                
                        fullWidth
                        error={smssenderRequired}
                        value={smssender}
                        onChange={(e) =>{
                            handlesmssender(e)

                        }}
                    />
                    {smssenderRequired &&(
                        <FormHelperText error>
                            Sender ID is Required
                        </FormHelperText>
                    )}
                    
                    </FormControl>

                    </Grid>
                    <Grid item xs>
                    <FormControl fullWidth>
                    <FormLabel>PE ID</FormLabel>
                    <TextField
                        name='PE ID'
                        error={smspeidRequired}
                        fullWidth
                        value={smspeid}
                        onChange={(e) => handlesmspeid(e)}
                    />
                    {smspeidRequired && (
                        <FormHelperText error>
                            PE ID is Required
                        </FormHelperText>
                    )}
                    
                    </FormControl>
                </Grid>
                
                </>
                
                )
               
            }
             </Grid>
             </Grid>
             </Grid>
                    </div>
    )
}

export default SmsListing