import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
// utils
import axios, { REACT_APP_BASE_URL } from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { incrementToken } from '../redux/slices/token';


// ----------------------------------------------------------------------

const isAuthenticatedLocal = localStorage.getItem('isAuthenticated');
const isInitializedLocal = localStorage.getItem('isInitialized');

const initialState = {
  isAuthenticated: isAuthenticatedLocal,
  isInitialized: isInitializedLocal,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, isAuthenticated, isInitialized } = action.payload;

    // return {
    //   ...state,
    //   isAuthenticated: true,
    //   isInitialized: true,
    //   user,
    // };
    return {
      ...state,
      isAuthenticated,
      isInitialized,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);



  // useEffect(() => {
  //   const initialize = async () => {

  //     try {
  //       const accessToken = localStorage.getItem('accessToken');
  //       console.log("accessToken in 76 ", accessToken)

  //       if (accessToken) {
  //         //setSession(accessToken);

  //         const response = await axios.get('/getAuth');
  //         const  user  = response.data.data;


  //         dispatch({
  //           type: 'INITIALIZE',
  //           payload: {
  //             isAuthenticated: true,
  //             user,
  //           },
  //         });
  //       } else {
  //         dispatch({
  //           type: 'INITIALIZE',
  //           payload: {
  //             // isAuthenticated: false,
  //             isAuthenticated: true,
  //             user: null,
  //           },
  //         });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //       dispatch({
  //         type: 'INITIALIZE',
  //         payload: {
  //           // isAuthenticated: false,
  //           isAuthenticated: true,
  //           user: null,
  //         },
  //       });
  //     }
  //   };

  //   initialize();
  // }, [reRenderAfterLogin]);

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const checkIfUserStatusIsCreatedOrNot = async (id) =>{
    try{

      const response = await axios.get(`/user/status?userId=${id}`)
      console.log("Response in fetching user status : ", response)
      return response

    }catch(err){
      console.log("Error in fetching user status : ", err)
    }
  }

  const updateUserStatus = async (data , id) =>{

    const response = await axios.patch(`/user/status/${id}` , data)
    console.log("Response of user status : ", response )

  }

  const createUserStatus = async (data) =>{

    const response = await axios.post(`/user/status` , data)
    console.log("Response of user status created : ", response )

    localStorage.setItem("user_status_id", response.data.data._id)
  }

   const createUserAuditLogs = async (data) =>{
   
        try{
            const link = `/user_activity`
            const response = await axios.post(link , data)
            console.log("Data to audit logs : ", data  , response)
        }catch(error){
            console.log("Error Data to audit logs : ", error)
            dispatch(dashboardSlice.actions.hasError(error));
        }
    
}

  const login = async (email, password) => {
    let result
    const data = { email_address: email, password };
    localStorage.removeItem('redux_token');
    localStorage.removeItem('accessToken')
    localStorage.removeItem('whatsappNumber');
    localStorage.removeItem("lineForwardAppId")
    localStorage.removeItem("CloudPhoneAppId")
    localStorage.removeItem("IvrStudioAppId")
    localStorage.removeItem("CancelNumberAppId")
    localStorage.removeItem('authID');
    localStorage.removeItem('userID');
    localStorage.removeItem('authSecretId');
    localStorage.removeItem("phone")
    localStorage.removeItem("viewType")
    localStorage.removeItem("companyID");
    const response = await axios.post('/login', data);
    const accessToken = response.data.data.token
    console.log('login response', response.data.data)
    let user
    localStorage.setItem('redux-token', accessToken);
    localStorage.setItem('accessToken', accessToken);
    // localStorage.setItem('whatsappTokenAccess', response.data.data.whatsapp_details.token.access);
    // localStorage.setItem('whatsappTokenRefresh', response.data.data.whatsapp_details.token.refresh)
    dispatch(incrementToken(accessToken))
    const config = {
      method: 'get',
      url: `${REACT_APP_BASE_URL}/api/getAuth`,
      headers: {
        'authorization': accessToken,
      }
    };
    // axios(config)
    // .then(function (response) {
    //   user = response.data.data
    //   console.log("get auth : ",response.data.data);
    //   dispatch({
    //     type: 'LOGIN',
    //     payload: {
    //       user,
    //       isAuthenticated: true,
    //       isInitialized: true,
    //     },
    //   });
    //   result = {isAuthenticated: true};
    //   console.log("result in auth 158 ",result);
    // })
    // .catch(function (error) {
    //   console.log("error in auth",error);     
    //   result = {isAuthenticated: false};
    //   console.log("result in auth 163 ",result);
    // });

    const resultAuth = await axios(config)
    console.log("result in auth 168 ", resultAuth);

    if (resultAuth.data.status) {

      setCookie('vib_u_id', resultAuth.data.data._id, 1)
      const whatsappNumber = resultAuth.data.data.whatsAppNumber;
      const phoneNumberId = resultAuth.data.data.phoneNumberId;
      const isWhatsappActive = resultAuth.data.data.isWhatsappActive;
      localStorage.setItem('is_admin', resultAuth.data.data.is_admin)
      localStorage.setItem('isWhatsappActive', isWhatsappActive)
      localStorage.setItem('whatsappNumber', whatsappNumber);
      localStorage.setItem('phoneNumberId', phoneNumberId)
      localStorage.setItem("lineForwardAppId", resultAuth.data.data.lineForwardAppId)
      localStorage.setItem("CloudPhoneAppId", resultAuth.data.data.CloudPhoneAppId)
      localStorage.setItem("IvrStudioAppId", resultAuth.data.data.IvrStudioAppId)
      localStorage.setItem("CancelNumberAppId", resultAuth.data.data.CancelNumberAppId)
      localStorage.setItem('authID', resultAuth.data.data.auth_id);
      localStorage.setItem('userID', resultAuth.data.data._id);
      localStorage.setItem("companyID", resultAuth.data.data.company_id);
      localStorage.setItem('authSecretId', resultAuth.data.data.auth_secret);
      localStorage.setItem("phone", resultAuth.data.data.phone)
      localStorage.setItem('timeZone', resultAuth.data.data.timeZone)

      let tokenOfVibtree = 'EAAOdOeKzYdkBAAjoshNzqleILw5QveXm2swoNVCJzW0urYaxsqaT878bbfzt0y6Io7ECl4XiLs3lyZBsPZBrnyr40i2WJkQdirAi0T7hSsz6sYJ21x1scKdAZApWpH8CzzPZCJFBjUWF0ddny6OMEbmFXzUfBC28rrtVjbwoDUBpp1yVbQVB';
      localStorage.setItem("whatsappToken", tokenOfVibtree)
      if (resultAuth.data.data.whatsappToken !== undefined && resultAuth.data.data.whatsappToken !== null) {
        localStorage.setItem("whatsappToken", resultAuth.data.data.whatsappToken)
      }
      if (resultAuth.data.data.viewType !== undefined && resultAuth.data.data.viewType !== null) {
        localStorage.setItem("viewType", resultAuth.data.data.viewType)
      } else {
        localStorage.setItem("viewType", "All")
      }
      let assignedNumber = resultAuth.data.data.assignedNumber;
      let stringyfyAssignedNumber = JSON.stringify(assignedNumber);
      localStorage.setItem("assignedNumber", stringyfyAssignedNumber);
      // this code set the token in header after login 
      axios.interceptors.request.use(function (config) {
        config.headers.authorization = accessToken;
        return config;
      });
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('isInitialized', true);

  //     const dataToUpdateUserStatus = {
  //       "status":"available"
  //      }

  //      const dataToCreateUserStatus = {
  //       "authId": resultAuth.data.data.auth_id,
  //       "userId":resultAuth.data.data._id,
  //       "status":"available"
  //   }

  //   const response = await checkIfUserStatusIsCreatedOrNot(resultAuth.data.data._id)


  //   const tempDataToCreateAuditLogs = {
  //     "auth_id": resultAuth.data.data.auth_id,
  //     "user_id": resultAuth.data.data._id,
  //     "type":"login"
  // }
  //  await createUserAuditLogs(tempDataToCreateAuditLogs)

  //   console.log("Response : ", response)
  //   if(response.data.data.totalCount > 0){
  //     localStorage.setItem("user_status_id", response.data.data.data[0]._id)
  //     await updateUserStatus( dataToUpdateUserStatus , response.data.data.data[0]._id )
  //   }else{
  //     await createUserStatus( dataToCreateUserStatus )
  //   }
      

    }

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        isAuthenticated: true,
        isInitialized: true,
      },
    });
    return { isAuthenticated: true };
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {

    // setSession(null);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('isInitialized');
    const id = localStorage.getItem("user_status_id")

    // const dataToUpdateUserStatus = {
    //   "status":"away"
    //  }

    // await updateUserStatus( dataToUpdateUserStatus , id )

    localStorage.removeItem('user_status_id');

    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };