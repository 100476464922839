import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosInstance, { REACT_APP_BASE_URL, baseUrl } from '../../utils/axios';
import { addMessageById } from './WhatsappSocketSlice';
//
import { dispatch } from '../store';
import axios from 'axios'

// ----------------------------------------------------------------------

function objFromArrayForConversation(array, key = 'conversation_id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

function objFromArrayForContacts(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

let previous_data = []

const initialState = {
  isLoading: false,
  error: null,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  newConversationAfterScroll: { byId: {}, allIds: [] },
  activeConversationId: null,
  participants: [],
  recipients: [],
  currentConversation: [],
  nextConversation: []
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;

      state.contacts.byId = objFromArrayForContacts(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;
      state.currentConversation = conversations

      previous_data = [...conversations]


      state.conversations.byId = objFromArrayForConversation(conversations);
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    //Add More Conversation To list 
    addConversationsSuccess(state, action) {
      console.log("Scroll after fetching data i formatted it and same it to new chat ")
      const conversations = action.payload;
      Array.prototype.push.apply(previous_data, conversations);
      const tempState = objFromArrayForConversation(previous_data);
      const tempAllIDs = Object.keys(tempState);
      state.conversations.byId = objFromArrayForConversation(previous_data);
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    //Add new conversation on top 
    addNewConversationsSuccess(state, action) {
      console.log("Scroll after fetching data i formatted it and same it to new chat ")
      const conversations = action.payload;
      Array.prototype.push.apply(conversations, previous_data);
      const tempState = objFromArrayForConversation(previous_data);
      const tempAllIDs = Object.keys(tempState);
      state.conversations.byId = objFromArrayForConversation(previous_data);
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    //Set New Fetch conversation to empty

    setNewConversationsToEmptySuccess() {

      state.newConversationAfterScroll.byId = {};
      state.newConversationAfterScroll.allIds = [];
    },
    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;

      if (conversation) {
        state.conversations.byId[conversation.conversation_id] = conversation;
        state.activeConversationId = conversation.conversation_id;
        if (!state.conversations.allIds.includes(conversation.conversation_id)) {
          state.conversations.allIds.push(conversation.conversation_id);
        }
      } else {
        state.activeConversationId = null;
      }
    },
    //Viber Send Message Function
    onSendViberMessage(state, action) {
      const conversation = action.payload;
      console.log("send message body : ", conversation)
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;
      const body = message;
      // let telegramToken = localStorage.getItem("telegram-token") || '5549169131:AAFe2xPK6G78jCf52wg5MoaCcA5MtxFoKnM'
      let token = '500635c07127dc94-89ba1f60c8137e36-907855a9730bd040' // Auth-Token for viber
      let senderName = 'Danish' //hardcoded for now make it dynamic later
      let senderAvatar = ''
      let messageType = 'text'
      let messageBody = message
      let receiver = conversationId
      const formattedBody = {
        token: token,
        receiver: receiver,
        senderName: senderName,
        senderAvatar: senderAvatar,
        messageType: messageType,
        messageBody: messageBody
      }

      console.log("formattedBody : ", formattedBody)
      axiosInstance.post(`${baseUrl}/viber/send-message`, formattedBody).then(
        (response) => {
          console.log("response after sending message : ", response)
          const data = response.data.data
          //const WaSid = response.data.data.messages[0].id;
          const Direction = 'Outbound'
          const From = receiver
          const To = senderName ? senderName : ''
          const WaSid = data.message_token
          const messageBody = messageBody
          const message = data
          const tempObj = {
            token: token,
            Direction: Direction,
            From: From,
            To: To,
            WaSid: WaSid,
            messageBody: messageBody,
            message: { ...message }
          }
          console.log("Viber tempObj : ", tempObj)
          //   const messageToShowOnScreen = {
          //     "WaSid": WaSid,
          //     "To": To,
          //     "messageType": messageType,
          //     "messageBody": body,
          //     "PhoneNumberId": phoneNumberId,
          //     "Direction": "Outbound",
          //     "From": From,
          //     "status": null
          // }
          //state.conversations.byId[conversationId].messages.push(messageToShowOnScreen);
          dispatch(addMessageById({ id: conversationId, message: tempObj }));//this is commented to avoid displaying double messages
        }
      ).catch(error => {
        console.log("error : ", error)
      });

    },
    onSendFacebookMessage(state, action) {
      const conversation = action.payload;
      console.log("send message body : ", conversation)
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;
      const body = message;

      let token = 'EAAJofgKz32MBALr6gpr4Oob3kTOpHsRzmYmuBhAfmAohE47uPMnnyfdHDJJuDXi1ThSO84ZCorUkPZCRSVsdXD66RqPHdaog0bHTWCk564IknWrPoYPLhtvuZBnZAhA2r0ekoDnbNk9Ro1M0lxcniz1vzYyb2JN2AlTQl4nRHn3QeYYXcV1h'
      let receiver = "6230317820316239"
      let senderName = "Vikalp"
      let senderAvatar = ""
      let messageType = 'text'
      let messageBody = message


      const formattedBody = {
        token: token,
        receiver: receiver,
        senderName: senderName,
        senderAvatar: senderAvatar,
        messageType: messageType,
        messageBody: messageBody
      }

      console.log("formattedBody : ", formattedBody)
      axiosInstance.post(`${baseUrl}/facebook/send-message`, formattedBody)
        .then((response) => {
          console.log("response after sending message : ", response)
          const data = response.data.data
          //const WaSid = response.data.data.messages[0].id;
          const Direction = 'Outbound'
          const From = receiver
          const To = senderName ? senderName : ''
          const WaSid = data.message_token
          const messageBody = messageBody
          const message = data
          const tempObj = {
            token: token,
            Direction: Direction,
            From: From,
            To: To,
            WaSid: WaSid,
            messageBody: messageBody,
            message: { ...message }
          }
          console.log("facebook tempObj : ", tempObj)
          dispatch(addMessageById({ id: conversationId, message: tempObj }));
        })
        .catch((error) => {
          console.log("error", error)
        })
    },

    onSendInstagramMessage(state, action) {
      const conversation = action.payload;
      console.log("send message body : ", conversation)
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;
      const body = message;

      let token = 'EAAMTbxf2lZBsBAHYHMHdYyZBm1ptmYFJ6EbpjM2asLax2ymWNewLj946A4rtHB0tdZCJsGfb9w5TWe7tN4VRwCZBJu4ZA8QJBWHlA0RAgmdaKoAMZAfkJCZBGgHwgu0kugkOvBxmZBl969e3ZAsN5H6Et35LAQQZAT0COmHSzYrZBV9ZCK2cn3DLE0DO'
      let receiver = "6000921636596634"
      let senderName = "Vikalp"
      let senderAvatar = ""
      let messageType = 'text'
      let messageBody = message


      const formattedBody = {
        token: token,
        receiver: receiver,
        senderName: senderName,
        senderAvatar: senderAvatar,
        messageType: messageType,
        messageBody: messageBody
      }

      console.log("formattedBody : ", formattedBody)
      axiosInstance.post(`${baseUrl}/instagram/send-message`, formattedBody)
        .then((response) => {
          console.log("response after sending message : ", response)
          const data = response.data.data
          //const WaSid = response.data.data.messages[0].id;
          const Direction = 'Outbound'
          const From = receiver
          const To = senderName ? senderName : ''
          const WaSid = data.message_token
          const messageBody = messageBody
          const message = data
          const tempObj = {
            token: token,
            Direction: Direction,
            From: From,
            To: To,
            WaSid: WaSid,
            messageBody: messageBody,
            message: { ...message }
          }
          console.log("facebook tempObj : ", tempObj)
          dispatch(addMessageById({ id: conversationId, message: tempObj }));
        })
        .catch((error) => {
          console.log("error", error)
        })
    },
    //Telegram Send Message Function
    onSendTelegramMessage(state, action) {
      const conversation = action.payload;
      console.log("send message body : ", conversation)
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;
      const body = message;
      let telegramToken = localStorage.getItem("telegram-token") || '5549169131:AAFe2xPK6G78jCf52wg5MoaCcA5MtxFoKnM'
      const formattedBody = {
        "token": telegramToken,
        "chat_id": parseInt(conversationId),
        "text": body
      }

      // console.log("formattedBody : ", formattedBody)
      axiosInstance.post(`${baseUrl}/telegram/send-message`, formattedBody).then(
        (response) => {
          console.log("response after sending message : ", response)
          const data = response.data.data
          //const WaSid = response.data.data.messages[0].id;
          const Direction = 'Outbound'
          const From = data.result.from.first_name
          const To = `${data.result.chat.first_name}${data.result.chat.last_name ? `_${data.result.chat.last_name}` : ''}`
          const WaSid = data.result.message_id
          const messageBody = data.result.text
          const message = data.result
          const tempObj = {
            token: token,
            Direction: Direction,
            From: From,
            To: To,
            WaSid: WaSid,
            messageBody: messageBody,
            message: { ...message },
            status: null
          }
          //   const messageToShowOnScreen = {
          //     "WaSid": WaSid,
          //     "To": To,
          //     "messageType": messageType,
          //     "messageBody": body,
          //     "PhoneNumberId": phoneNumberId,
          //     "Direction": "Outbound",
          //     "From": From,
          //     "status": null
          // }
          //state.conversations.byId[conversationId].messages.push(messageToShowOnScreen);
          dispatch(addMessageById({ id: conversationId, message: tempObj }));//this is commented to avoid displaying double messages
        }
      ).catch(error => {
        console.log("error : ", error)
      });

    },

    //GET CONVERSATION
    getConversationsSuccessMessenger(state, action) {
      const conversations = action.payload;

      state.conversations.byId = objFromArray(conversations);
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    onSendMessageMessenger(state, action) {
      const conversation = action.payload;
      console.log("messenger send message body : ", conversation)
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      // state.conversations.byId[conversationId].messages.push(newMessage);

      const From = conversation.conversationId.split('-')[0];
      const To = conversation.conversationId.split('-')[1];
      const phoneNumberId = "105217022203896";
      const messageProduct = "whatsapp";
      const previewUrl = false;
      const recipientType = 'individual';
      const messageType = contentType;
      const body = message;



      //   const formattedBody = {
      //     "from": From,
      //     "phone_number_id": phoneNumberId,
      //     "messaging_product": messageProduct,
      //     "preview_url": previewUrl,
      //     "recipient_type": recipientType,
      //     "to": To,
      //     "type": messageType,
      //     "text": {
      //         "body": body
      //     }
      // }

      const formattedBody = {
        "recipient": {
          "id": conversationId
        },
        "message": {
          "text": message
        }
      }

      // console.log("formattedBody : ", formattedBody)

      // let url = `http://localhost:3000/send-message`
      let url = `https://mibsback.vibtree.com/api/send-message`
      axios.post(url, formattedBody).then(
        (response) => {
          console.log("messenger response after sending message : ", response)
          const WaSid = response.data.data.message_id
          const messageToShowOnScreen = {
            "WaSid": WaSid,
            "To": conversationId,
            "messageType": "text",
            "messageBody": body,
            "PhoneNumberId": "phoneNumberId",
            "Direction": "Outbound",
            "From": "From",
            "status": "null"
          }
          //state.conversations.byId[conversationId].messages.push(messageToShowOnScreen);
          dispatch(addMessageById({ id: conversationId, message: messageToShowOnScreen }));//this is commented to avoid displaying double messages
        }
      ).catch(error => {
        console.log("error : ", error)
      });

    },
    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversation = action.payload;
      // console.log("send message body : ", conversation)
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      // state.conversations.byId[conversationId].messages.push(newMessage);
      const PhoneNumberId = localStorage.getItem('phoneNumberId')
      const whatsappToken = localStorage.getItem("whatsappToken")

      const From = conversation.conversationId.split('-')[0];
      const To = conversation.conversationId.split('-')[1];
      // const phoneNumberId = "105217022203896";
      const phoneNumberId = PhoneNumberId;
      const messageProduct = "whatsapp";
      const previewUrl = false;
      const recipientType = 'individual';
      const messageType = contentType;
      const body = message;

      const formattedBody = {
        "token": whatsappToken,
        "from": From,
        "phone_number_id": phoneNumberId,
        "messaging_product": messageProduct,
        "preview_url": previewUrl,
        "recipient_type": recipientType,
        "to": To,
        "type": messageType,
        "text": {
          "body": body
        }
      }

      // console.log("formattedBody : ", formattedBody)
      axiosInstance.post(`${baseUrl}/whatsapp/send/next_time`, formattedBody).then(
        (response) => {
          console.log("response after sending message : ", response)
          const WaSid = response.data.data.messages[0].id;
          const messageToShowOnScreen = {
            "WaSid": WaSid,
            "To": To,
            "messageType": messageType,
            "messageBody": body,
            "PhoneNumberId": phoneNumberId,
            "Direction": "Outbound",
            "From": From,
            "status": null
          }
          //state.conversations.byId[conversationId].messages.push(messageToShowOnScreen);
          dispatch(addMessageById({ id: conversationId, message: messageToShowOnScreen }));//this is commented to avoid displaying double messages
        }
      ).catch(error => {
        console.log("error : ", error)
      });

    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      console.log("getParticipantsSuccess redux : ", action.payload)
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, onSendMessage, resetActiveConversation, onSendTelegramMessage, onSendViberMessage, onSendMessageMessenger, onSendFacebookMessage, onSendInstagramMessage } = slice.actions;

// ----------------------------------------------------------------------

export function getContacts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get('/contacts');
      const formattedContactsAccordingToMinimalAssetsApi = response.data.data?.map((contact) => {
        const tempObj = {};
        const whatsappNumber = localStorage.getItem('whatsappNumber');
        tempObj.id = whatsappNumber + "-" + contact.phoneNumber;
        tempObj.name = contact.firstName + " " + contact.lastName;
        tempObj.username = contact.email;
        tempObj.avatar = contact.photo ? `${baseUrl}/contact/download?fileLocation=${contact.photo}` : "";
        tempObj.address = contact.address ? contact.address : "";
        tempObj.phone = contact.phoneNumber;
        tempObj.email = contact.email ? contact.email : "";
        tempObj.lastActivity = "2022-08-04T11:58:03.140Z";
        tempObj.status = "away";
        tempObj.position = "Vibtree Developer";
        return tempObj;
      });
      console.log("contacts mode in chat : ", formattedContactsAccordingToMinimalAssetsApi);
      dispatch(slice.actions.getContactsSuccess(formattedContactsAccordingToMinimalAssetsApi));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


//-----------------------------------------------------------------------
export function getConversationsMessenger() {
  return async () => {
    dispatch(slice.actions.startLoading());

    //let url = `http://localhost:3000/conversation`
    let url = `https://mibsback.vibtree.com/api/conversation`
    try {
      const response = await axios.get(url);
      dispatch(slice.actions.getConversationsSuccess(response.data.conversations));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//-----------------------------------------------------------------------
export function getConversationMessenger(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());

    // let url = `http://localhost:3000/conversation`
    let url = ` https://mibsback.vibtree.com/api/conversation`
    try {
      const response = await axios.get(`${url}?conversation_id=${conversationKey}`);
      dispatch(slice.actions.getConversationSuccess(response.data.conversation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getViberConversations(token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const chat_hostname = "SN-CALLBACK-06_"
      const response = await axiosInstance.get(`/viber/chat/conversations?chat_hostname=${token}&populate=ticketId`);
      console.log('getConversations response : ', response.data.data);
      dispatch(slice.actions.getConversationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTelegramConversations(token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let telegramToken = localStorage.getItem("telegram-token") || '5549169131:AAFe2xPK6G78jCf52wg5MoaCcA5MtxFoKnM'
      const response = await axiosInstance.get(`/telegram/chat/conversations?token=${token}&populate=ticketId`);
      console.log('getConversations response : ', response.data.data);
      dispatch(slice.actions.getConversationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getMessengerConversations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const response = await axiosInstance.get(`/facebook/chat/conversations`);
      console.log('getConversations response : ', response.data.data);
      dispatch(slice.actions.getConversationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInstagramConversations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const response = await axiosInstance.get(`/instagram/chat/conversations`);
      console.log('getConversations response : ', response.data.data);
      dispatch(slice.actions.getConversationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getConversations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let whatsappNumber = localStorage.getItem('whatsappNumber')
      const response = await axiosInstance.get(`/whatsapp/chat/conversations?populate=user_id&sort=-updatedAt&limit=10&whatsappNumber=${whatsappNumber}`);
      console.log('getConversations response : ', response.data.data);
      dispatch(slice.actions.getConversationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------
export function getMoreConversations(offset) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log("Scroll fetching more older chats getMoreConversations : ", offset)
      let whatsappNumber = localStorage.getItem('whatsappNumber')
      // let offset = parseInt(limit) - 20

      let link = `/whatsapp/chat/conversations?populate=user_id&sort=-updatedAt&limit=20&whatsappNumber=${whatsappNumber}&offset=${offset}`
      console.log("Scroll link  : ", link)

      const response = await axiosInstance.get(`/whatsapp/chat/conversations?populate=user_id&sort=-updatedAt&limit=20&whatsappNumber=${whatsappNumber}&offset=${offset}`);
      console.log('Scroll getConversations response : ', response.data.data);
      dispatch(slice.actions.addConversationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------

export function setNewFetchConversationsToEmpty(offset) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setNewConversationsToEmptySuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUpdatedConversationFromSocket(conversations) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log("list of updated conversations from socket : ", conversations);
      console.log("previous data in conversation already present : ", previous_data)
      dispatch(slice.actions.getConversationsSuccess(conversations));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}

//-----------------------------------------------------------------------

export function checkIfConversationIsNew(conversations) {
  console.log("Data comes in Dispatch now we will start comparing:")
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log("checkIfConversationIsNew list of updated conversations from socket : ", conversations, previous_data);
      console.log("checkIfConversationIsNew previous data in conversation already present : ", previous_data)

      const previousData = previous_data;
      const newData = conversations

      const updatedList = compareExistingConversationWithChangeComesFromSocket(previousData, newData)
      console.log("checkIfConversationIsNew Updated List : ", updatedList)
      dispatch(slice.actions.getConversationsSuccess(updatedList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}

const findDataInList = (list, id, value) => {
  const isPresent = list.filter((item) => {
    return item.conversation_id === id
  })
  //console.log("IS Present : ", isPresent)

  let result = { status: false, data: {} }

  if (isPresent.length > 0) {
    result = { status: true, data: isPresent }
  } else {
    result = { status: false, data: value }
  }
  return result
}

const removeFoundDataFromList = (list, id) => {
  const excludedList = list.filter((item) => {
    return item.conversation_id !== id
  })
  return excludedList
}

const compareExistingConversationWithChangeComesFromSocket = (previousData, newData) => {
  // console.log("Previous Data : ", previousData)
  // console.log("New Data : ", newData)

  const newDataConversationId = newData.conversation_id

  //const newDataConversationId = "fake-id"

  const isDataPresent = findDataInList(previousData, newDataConversationId, newData)
  //console.log("Result : ", isDataPresent)
  let finalResult = [...previousData]

  if (isDataPresent.status) {
    const excludedList = removeFoundDataFromList(previousData, newDataConversationId)
    //console.log("Exculded Array : ", excludedList)
    finalResult = [newData, ...excludedList]
  }

  if (isDataPresent.status === false && isDataPresent.data._id !== undefined) {
    console.log("New Conversation Need to add in top :")
    finalResult = [newData, ...previousData]
  }

  //console.log("Final Data : ", finalResult)

  return finalResult

}


//--------------------------------------------------------------------

export function getViberConversation(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axiosInstance.get('/api/chat/conversation', {
      //   params: { conversationKey },
      // });
      const limit = 40;
      const offset = 1;
      const sort = '-_id';
      const response = await axiosInstance.get(`/viber/chat/conversations?conversation_id=${conversationKey}&populate=ticketId`);
      dispatch(slice.actions.getConversationSuccess(response.data.data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -------------------------------------------------------------------
export function getTelegramConversation(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axiosInstance.get('/api/chat/conversation', {
      //   params: { conversationKey },
      // });
      const limit = 40;
      const offset = 1;
      const sort = '-_id';
      const response = await axiosInstance.get(`/telegram/chat/conversations?conversation_id=${conversationKey}&populate=ticketId`);
      dispatch(slice.actions.getConversationSuccess(response.data.data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export function getConversation(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axiosInstance.get('/api/chat/conversation', {
      //   params: { conversationKey },
      // });
      const limit = 40;
      const offset = 1;
      const sort = '-_id';
      const response = await axiosInstance.get(`/whatsapp/chat/conversations?conversation_id=${conversationKey}`);
      dispatch(slice.actions.getConversationSuccess(response.data.data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axiosInstance.get('/whatsapp/chat/conversation/mark-as-seen', {
        params: { conversationId },
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get('/whatsapp/chat/participants', {
        params: { conversationKey },
      });
      console.log('getParticipants response : ', response.data.data);
      const tempObj = response.data.data[0]
      const formattedArrayOfParticipants = {
        "address": tempObj.address ? tempObj.address : "",
        "avatar": tempObj.photo ? tempObj.photo : null,
        "email": tempObj.email ? tempObj.email : "",
        "id": conversationKey,
        "lastActivity": tempObj.lastActivity ? tempObj.lastActivity : "",
        "name": tempObj.firstName + " " + tempObj.lastName,
        "phone": tempObj.phoneNumber ? tempObj.phoneNumber : "",
        "position": tempObj.position ? tempObj.position : "",
        "status": tempObj.status ? tempObj.status : "online",
        "username": conversationKey,
      }
      const tempArr = [];
      tempArr.push(formattedArrayOfParticipants);
      const formattedParticipants = { participants: tempArr };
      console.log("formattedParticipants : ", formattedParticipants);
      dispatch(slice.actions.getParticipantsSuccess(formattedParticipants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
