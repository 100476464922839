// @mui
import { useTheme } from '@mui/material/styles';
import { paramCase } from 'change-case';
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Container, Grid, Button, Stack, TableHead, TableBody, TableRow,
    Pagination,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem, FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    TextField,
    Snackbar,
    IconButton,

} from '@mui/material';

import { DataGridPro } from '@mui/x-data-grid-pro';
// flag asset
import { findFlagUrlByIso3Code } from "country-flags-svg";
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getNumbers, selectAllNumbers, buySingleNumber } from '../../redux/slices/numbers/buy';
import { getAuth } from '../../redux/slices/getAuth';
// hooks
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Scrollbar from '../../components/Scrollbar';
import Label from '../../components/Label';
import AllowedCountry from '../../components/inputs/allowedCountry'

import Buynumber from './../../assets/Buynumber.png'
import { LoadingButton } from '@mui/lab';
import { incrementSelectedButton } from 'src/redux/Selectbutton';
import Onboardingbutton from './OnboardingButton';
import { 
    incrementSelectedvoicenumber,
    selectedCountryNumber,
    selectedPageLimitNumber,
    selectedPageOffsetNumber,
  } from 'src/redux/Selectedvoicenumber';
  
import CloseIcon from '@mui/icons-material/Close';
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}



export default function Onboadingnumber({handleBack}) {
    const { height, width } = useWindowDimensions();
    const { themeStretch } = useSettings();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [numberData, setNumberData] = useState([])
    const [type, setType] = React.useState('Local');
    const [states, setstates] = React.useState('All');
    const [selectedRowsBulk, setSelectedRowsBulk] = useState({})
    const [selectedRowsLenght, setselectedRowsLenght] = useState(0)
    // loading
    const [loading, setLoading] = useState(true)
    // country
    const [limit, setlimit] = useState('10')
    const [defaultCountry, setDefaultCountry] = useState('');
    // pagination
    const [count, setCount] = useState(0);
    const pageCount = Math.ceil(count / limit);
    const [page, setPage] = useState(1);
    const [offsetCount, setOffsetCount] = useState(0);
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const showsnackbarslice = useSelector(state => state.showsnackbarSlice.value)
    const showsnackbarsliceoops = useSelector(state => state.showsnackbaroopsSlice.value)


    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }

        setOpen(false);
    };
    const handleClose1 = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }

        setOpen1(false);
    };

    const handleClick = () => {
        setOpen(true);
    };
    const handleClick1 = () => {
        setOpen1(true);
    };
    useEffect(() => {
        if (showsnackbarslice == true) {
            handleClick()
        }

    }, [showsnackbarslice])
    useEffect(() => {
        if (showsnackbarsliceoops == true) {
            handleClick1()
        }

    }, [showsnackbarsliceoops])

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    const action1 = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose1}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose1}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const [state, setState] = React.useState({
        sms: false,
        voice: false,
    });
    const { sms, voice } = state;
    const handleChangeCheck = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const pageOffSet = `${newPage - 1}0`
        if (newPage === 1) {
            setOffsetCount(0)
        }
        else {
            setOffsetCount(parseInt(pageOffSet, 10))
        }
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handlestateChange = (event) => {
        setstates(event.target.value);
    };

    // get numbers
    useEffect(() => {
        setLoading(true)
        console.log('defaultCountry', defaultCountry)
        dispatch(getNumbers(defaultCountry, type, states, offsetCount, limit));
    }, [dispatch, page, defaultCountry, states, limit]);


    const countryobj = [

        {
            name: "West Bengal",
            value: "WestBengal"
        },
        {
            name: "Karnataka",
            value: "Banglore"
        },
        {
            name: "Maharastra",
            value: "Mumbai"
        },
        {
            name: "Rajasthan",
            value: "Rajasthan"
        },
    ]



    const numbers = useSelector(state => state.buyNumber)
    // console.log("numbers", numbers.numberData.data.data)

    useEffect(() => {
        setLoading(true)
        if (numbers.numberData.code === 200 && numbers.numberData.code !== undefined) {
            setNumberData(numbers.numberData.data.data)
            setCount(numbers.numberData.data.totalCount)
            setLoading(false)
        }
        console.log('numbers in', numbers)
    }, [numbers])

    // console.log('numbers', numbers)
    // console.log('numbers in', numbers)



    const returnFlag = (flag) => {
        const value = flag.formattedValue;
        console.log('flag', flag, flag.formattedValue)
        const flagUrl = findFlagUrlByIso3Code(flag.formattedValue);
        return (
            <div className='hovershowhide-parent'>
                <div className='hovershow-child'>
                    {value}
                </div>
                <div className='hoverhide-child'>
                    <img src={flagUrl}
                        alt=''
                        style={{
                            maxHeight: '30px',
                            maxWidth: '30px'
                        }}
                    />
                </div>
            </div>

        )
    }

    const featuresimage = (params) => {
        const value = params.formattedValue;

        return (
            <div>
                <div className='hovershowhide-parent'>
                    <div className='hovershow-child'>
                        {value == "Voice" ? <>Voice</> : value}
                    </div>
                    <div className='hoverhide-child'>


                        <img src={`/assets/icons/navbar/ic_voice.svg`}
                            alt=''
                            style={{
                                maxHeight: '30px',
                                maxWidth: '30px'
                            }}
                        />
                    </div>
                </div>

            </div>
        )
    }

    useEffect(() => {
        console.log('selectedRowsBulk', selectedRowsBulk.size)
        setselectedRowsLenght(selectedRowsBulk.size)
    }, [selectedRowsBulk])

    const loadernumber = localStorage.getItem('Loadernumber')

    const LoadingSkeleton = () => (
        <>
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </>
    );

    const skeltonLoaders = (
        <>
            <Grid container>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
            </Grid>
        </>
    )

    const columns = [

        {
            field: 'country_iso',
            headerName: 'Country',
            width: 110,
            editable: false,
            renderCell: (params) => {
                return <>

                    {returnFlag(params)}
                </>;
            }
        },
        {
            field: 'phone_number',
            headerName: 'Number',
            editable: false,
            flex: 1
        },
        {
            field: 'type',
            headerName: 'Type',
            editable: false,
            flex: 1
        },
        {
            field: 'capability',
            headerName: 'Feature',
            editable: false,
            renderCell: (params) => {
                return <>
                    {featuresimage(params)}
                </>
            },
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={(params.row.status === 'active' && 'success') || 'error'}
                        sx={{ textTransform: 'capitalize' }}
                    >
                        {params.row.status}
                    </Label>
                )
            }
        },
        {
            field: "mrc",
            headerName: "Rental Cost",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return <div>
                    {params.row.mrc.$numberDecimal}
                </div>
            }

        }
        ,
        {
            field: "rps",
            headerName: "Call Charges",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return <div>
                    {params.row.rps.$numberDecimal}
                </div>
            }

        }
        ,
        {
            field: "action",
            headerName: "Action",
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return <Onboardingbutton buyNumberClick={buyNumberClick} cancelNumberClick={cancelNumberClick} params={params} />

                // <LoadingButton variant="contained" loading={selecteduser} onClick={() => buyNumberClick(params)} >Select</LoadingButton>;
            }
        }




    ];



    const renderedNumbers = (

        <>

            <DataGridPro
                // {...numberData}
                // loading={numberData.rows.length === 0}
                rows={numberData}
                columns={columns}
                rowHeight={48}
                // checkboxSelection
                disableSelectionOnClick
                getRowId={(r) => r.phone_number}
                style={{ height: '550px' }}
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    console.log('selected ids', selectedIDs)
                    setSelectedRowsBulk(selectedIDs)
                }}
                components={{
                    LoadingOverlay: LoadingSkeleton
                }}
                loading={loading}   // you need to set your boolean loading
                hideFooter

            />

            {pageCount > 1 && (


                <Grid container spacing={3} style={{ marginBottom: "10px" }} justifyContent='center'>
                    <Grid item> Rows Per Page</Grid>
                    <Grid item>

                        <TextField
                            value={limit}
                            onChange={(event) => {
                                setlimit(event.target.value)



                            }}
                            size='small'
                            select>
                            <MenuItem value={10}>
                                10
                            </MenuItem>
                            <MenuItem value={25}>
                                25
                            </MenuItem>
                            <MenuItem value={50}>
                                50
                            </MenuItem>
                            <MenuItem value={100}>
                                100
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item>
                        <Pagination count={pageCount} page={page} showFirstButton showLastButton onChange={handleChangePage} color="primary" shape="rounded" />
                    </Grid>

                </Grid>
            )}


        </>
    )

    const filterToolBar = (
        <>
            <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                <Grid item xs></Grid>
                <Grid item xs>
                    <AllowedCountry selectedCountry={setDefaultCountry} alreadySelected={defaultCountry} />
                </Grid>

                {
                    defaultCountry != '' ? (
                        <>
                            <Grid item style={{ padddingTop: "0px" }}>
                                <FormControl variant="outlined" fullWidth style={{ minWidth: "100px" }}>
                                    <InputLabel id="selectCountry">State</InputLabel>
                                    <Select
                                        labelId="selectCountry"
                                        id="demo-simple-select-outlined"
                                        label="State"
                                       
                                        name='State'
                                        defaultValue={states}
                                        value={states}
                                        onChange={handlestateChange}
                                    >
                                        <MenuItem value='All'>
                                            All
                                        </MenuItem>
                                        {defaultCountry != "IND" ?
                                            null
                                            : ((
                                                countryobj.map((item) => {
                                                    return <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                })
                                            ))}
                                        {/* <MenuItem value='WestBengal'>West Bengal</MenuItem>
                            <MenuItem value='Banglore'>Karnataka</MenuItem>
                            <MenuItem value='Mumbai'>Maharastra</MenuItem>
                            <MenuItem value='Rajasthan'>Rajasthan</MenuItem> */}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item style={{ padddingTop: "0px" }}>
                                <FormControl variant="outlined" fullWidth style={{ minWidth: "100px" }}>
                                    <InputLabel id="selectCountry">Type</InputLabel>
                                    <Select
                                        labelId="selectCountry"
                                        id="demo-simple-select-outlined"
                                        label="Type"
                                     
                                        defaultValue={type}
                                        value={type}
                                        onChange={handleTypeChange}
                                    >

                                        <MenuItem value='Local'>Local</MenuItem>
                                        <MenuItem value='Mobile'>Mobile</MenuItem>
                                        <MenuItem value='Tollfree'>Toll Free</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    ) : null
                }

               
<Grid item xs></Grid>
<Grid item>
    <Button onClick={handleBack} variant='outlined'>
        <Iconify icon="ion:arrow-back" width={21} height={21} style={{marginRight: '10px'}} />
        Back    
    </Button>
</Grid>
            </Grid>
        </>
    )
    const selecteduser = useSelector(state => state.Selectedbuttonslice.value)
    // buy number single
    const selectedvoicenumber = useSelector(state => state.Selectedvoicenumberslice.value)
    const selectedCountry = useSelector(state => state.Selectedvoicenumberslice.country)
    const selectedPageLimit = useSelector(state => state.Selectedvoicenumberslice.pageLimit)
    const selectedPageOffset = useSelector(state => state.Selectedvoicenumberslice.pageOffset)

    const buyNumberClick = (params) => {

        dispatch(incrementSelectedvoicenumber(params.row.phone_number))
        dispatch(selectedCountryNumber(defaultCountry))
        dispatch(selectedPageLimitNumber(limit))
        dispatch(selectedPageOffsetNumber(page))
    }

    const cancelNumberClick = (params) => {

        dispatch(incrementSelectedvoicenumber(''))
        dispatch(selectedCountryNumber(''))
        dispatch(selectedPageLimitNumber(''))
        dispatch(selectedPageOffsetNumber(''))
    }


    useEffect(() => {
      if(selectedCountry !== '' && selectedCountry !== defaultCountry){
        setDefaultCountry(selectedCountry)
      }
    }, [selectedCountry])

    useEffect(() => {
        if(selectedPageLimit !== '' && selectedPageLimit !== limit){
          setlimit(selectedPageLimit)
        }
      }, [selectedPageLimit])

      useEffect(() => {
        if(selectedPageOffset !== '' && selectedPageOffset !== page){
          setPage(selectedPageOffset)
        }
      }, [selectedPageOffset])

  


    return (
        // <Page title="User: List" style={{ padding: "10px 30px" }}>
        <Page title="Inbox" style={{ padding: "10px 30px" }}>

            <HeaderBreadcrumbs
    
                links={[
      
                    { name: '' },
                ]}
           
            />
            <div style={{padding: '0 50px'}}>
                {filterToolBar}
            </div>
            

            {defaultCountry == '' ? <>
                <Grid container direction='row' style={{margin: "auto", marginTop: "20px" }} >
                    <Grid item xs></Grid>
                    <Grid item md={8} xs={12}>
                    <img src={Buynumber} alt="" style={{ width: "100%", maxWidth: '700px', margin: "auto", }} />
                    <Typography variant='h5' style={{ textAlign: "center", marginBottom: "20px" }}>Buy Number</Typography>
                    <Typography style={{ textAlign: "center", marginBottom: "20px" }}>Setup how to use your virtual or Toll-free number here. Point it to any application like Line Forward, Call Tracking, Contact Center etc. Learn More</Typography>
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>

            </> : <>
                {numbers.isLoading == true ? <>
                    {skeltonLoaders}
                </> : <>
                    {renderedNumbers}
                </>}

            </>}

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: "right"
                }}
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                message="Number has been selected"
                action={action}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: "right"
                }}
                open={open1}
                autoHideDuration={1000}
                onClose={handleClose1}
                message="Oops.. Number selected Failed"
                action={action1}
            />

        </Page>
    )
}