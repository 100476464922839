import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import './style.css'
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import SuccessSnackbar from './components/Snackbar';

import { useSelector, useDispatch } from 'react-redux';
import { get_app, SET_ALLOWED_COUNTRY, SET_APP, SET_CLOUDPHONE_NUMBER, SET_LIST_APP, SET_POWER_DIALER_APP, SET_SELECTED_COUNTRY, SET_SHOW_CLOUDPHONE, SET_SHOW_POWER_DIALER, SET_SHOW_TICKET, SET_SHOW_VOICEMAIL, SET_START_NAV_CHANGE, SET_TICKET_APP, SET_VOICE_MAIL_APP } from './redux/userReducer';
import AppCloudPhone from './pages/dashboard/AppCloudPhone'
import UserPermission from './utils/userPermission'
import OnlineOffline from './components/onlineOffline'
import axios from "axios";
import { baseUrl } from './utils/axios';
import { getAuth, list_app_config, SET_NAV_CONFIG, SET_NAV_CONFIG_SUB_USER, SET_NAV_CONFIG_TRIAL, SET_NAV_CONFIG_WHATSAPP_CONFIG } from './redux/navigation';
import { PATH_DASHBOARD } from './routes/paths';
import { ICONS } from './components/nav-section/vertical';
// ----------------------------------------------------------------------
import BoltIcon from '@mui/icons-material/Bolt';

export default function App() {
  //const nameUrl = window.location.href
  const dispatch = useDispatch();
  const {app, list_app, show_cloudphone, ticket_app, show_ticket, show_voicemail, show_powerdialer, power_dialer_app, voice_mail_app} = useSelector((state) => state.user);
  const {navConfig, navConfigtrail, navConfigOfSubUser, navConfigForWhatsappAdmin} = useSelector((state) => state.navigation)
  const location = useLocation();
  const locationPath = location.pathname
  const navigate = useNavigate();
  const [showCloudPhone, setShowCloudPhone] = useState(false)

  function getCookie(uid) {
    let vibUid = uid + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(vibUid) == 0) {
        return c.substring(vibUid.length, c.length);
      }
    }
    return "";
  }



  function checkCookie() {
    let vibUId = getCookie("vib_u_id");

    if (vibUId != "" && vibUId != null) {
      

    } else {
    
      navigate('/')

    }
  }

  const userID = localStorage.getItem("userID");
  const authID = localStorage.getItem("authID");

  useEffect(() => {
    checkCookie()

    const userID = localStorage.getItem("userID");
    const authID = localStorage.getItem("authID");
    if (userID !== null && userID !== undefined && userID !== "" ) {
      const token = localStorage.getItem("redux-token");
      getAuth(token).then(async(res) => {
        const response_data = res.data;
        dispatch({
          type: "SET_USER",
          payload: response_data.data
        })
        await get_app(authID, token).then(async(resp) => {
          const response_data = resp;
          dispatch({
            type: SET_LIST_APP,
            payload: response_data
          })
          await list_app_config(userID, token).then((response) => {
            const app_response_data = response.data;
            if (app_response_data.status === true) {
              const cloudphone_response_data = app_response_data.data.data.filter((value) => { return value.app_name === "cloudphone" })
              dispatch({
                type: SET_APP,
                payload: cloudphone_response_data
              })

              const ticket_response_data = app_response_data.data.data.filter((value) => { return value.app_name === "ticket" });
              dispatch({
                type: SET_TICKET_APP,
                payload: ticket_response_data
              })
              const power_dialer_response_data = app_response_data.data.data.filter((value) => { return value.app_name === "powerdialer" });
              dispatch({
                type: SET_POWER_DIALER_APP,
                payload: power_dialer_response_data
              })

              const voice_mail_response_data = app_response_data.data.data.filter((value) => { return value.app_name === "voicemail" });
              dispatch({
                type: SET_VOICE_MAIL_APP,
                payload: voice_mail_response_data
              })
            }
          }).catch((err) => {
            console.log("error", err);
          })
        }).catch((err) => {
          console.log("error", err);
        })
      }).catch((err) => {
        console.log("error", err);
      })
    }
  }, [authID])

  useEffect(() => {
    const cloudphone = list_app.filter((value) => { return value.app_id.app_name === "cloudphone" });
    const ticket = list_app.filter((value) => { return value.app_id.app_name === "ticket" })
    const power_dialer = list_app.filter((value) => { return value.app_id.app_name === "powerdialer" });
    const voice_mail = list_app.filter((value) => { return value.app_id.app_name === "voicemail" }); 
    if (cloudphone !== null && cloudphone !== undefined) {
      if (cloudphone.length > 0) {
        const is_active = cloudphone[0].app_id.is_active;
        if (is_active === true) {
          if (app.length > 0) {
            console.log("app_data", app)
            const user_id = localStorage.getItem("userID")
            const app_user_id = app[0].user_id;
            if (app_user_id === user_id) {
              if (app[0].is_active === true) {
                dispatch({
                  type: SET_SHOW_CLOUDPHONE,
                  payload: true
                })
                const cloudNumber = app[0].cloudNumber_allow;
                dispatch({
                  type: SET_CLOUDPHONE_NUMBER,
                  payload: cloudNumber
                })
                const allowed_country = app[0].country_allow;
                dispatch({
                  type: SET_ALLOWED_COUNTRY,
                  payload: allowed_country
                })
                const default_country = app[0].default_country;
                dispatch({
                  type: SET_SELECTED_COUNTRY,
                  payload: default_country
                })
              }
            }
          }
        }
      }
    }

    if (ticket !== null && ticket !== undefined) {
      if (ticket.length > 0) {
        const is_active = ticket[0].app_id.is_active;
        if (is_active === true) {
          if (ticket_app.length > 0) {
            const user_id = localStorage.getItem("userID")
            const app_user_id = ticket_app[0].user_id;
            if (app_user_id === user_id) {
              if (ticket_app[0].is_active === true) {
                dispatch({
                  type: SET_SHOW_TICKET,
                  payload: true
                })
              }
            }
          }
        }
      }
    }

    console.log("voice_mail", voice_mail)

    if (voice_mail !== null && voice_mail !== undefined) {
      if (voice_mail.length > 0) {
        const is_active = voice_mail[0].app_id.is_active;
        if (is_active === true) {
          if (voice_mail_app.length > 0) {
            const user_id = localStorage.getItem("userID")
            const app_user_id = voice_mail_app[0].user_id;
            if (app_user_id === user_id) {
              if (voice_mail_app[0].is_active === true) {
                dispatch({
                  type: SET_SHOW_VOICEMAIL,
                  payload: true
                })
              }
            }
          }
        }
      }
    }

    console.log("power_dialer", power_dialer)
    if (power_dialer !== null && power_dialer !== undefined) {
      if (power_dialer.length > 0) {
        const is_active = power_dialer[0].app_id.is_active;
        if (is_active === true) {
          if (power_dialer_app.length > 0) {
            const user_id = localStorage.getItem("userID")
            const app_user_id = power_dialer_app[0].user_id;
            if (app_user_id === user_id) {
              if (power_dialer_app[0].is_active === true) {
                dispatch({
                  type: SET_SHOW_POWER_DIALER,
                  payload: true
                })
              }
            }
          }
        }
      }
    }
    
  }, [list_app, app, ticket_app, power_dialer_app, voice_mail_app ])

  useEffect(() => {
    const viewType = localStorage.getItem("viewType")
    const isWhatsappActive = localStorage.getItem("isWhatsappActive")
    const planstatus = localStorage.getItem("planstatus")
    if (viewType === "All") {
      if (planstatus == 'trial') {
        change_nav(navConfigtrail, SET_NAV_CONFIG_TRIAL)
      } else {
        if (isWhatsappActive === null) {
          change_nav(navConfig, SET_NAV_CONFIG)
        }
        if (isWhatsappActive === undefined) {
          change_nav(navConfig, SET_NAV_CONFIG)
        }
        if (isWhatsappActive === 'undefined') {
          change_nav(navConfig, SET_NAV_CONFIG)
        }
        if (isWhatsappActive === 'null') {
          change_nav(navConfig, SET_NAV_CONFIG)
        }
        if (isWhatsappActive === 'true') {
          change_nav(navConfigForWhatsappAdmin, SET_NAV_CONFIG_WHATSAPP_CONFIG)
        }

        if (isWhatsappActive === 'false') {
          change_nav(navConfigForWhatsappAdmin, SET_NAV_CONFIG_WHATSAPP_CONFIG)
        }
      }
    } else {
      change_nav(navConfigOfSubUser, SET_NAV_CONFIG_SUB_USER)
    }

  }, [show_ticket, show_powerdialer, show_voicemail])

  const change_nav = (navConfig, nav_config_setting) => {
    var data = [...navConfig];
    const find_ticket = data[0].items.findIndex((value) => { return value.title === "ticket" })
    if (find_ticket !== -1) {
      if (show_ticket === false) {
        data[0].items.splice(find_ticket, 1);
        dispatch({
          type: nav_config_setting,
          payload: data
        })
      }
    } else {
      if (show_ticket === true) {
        data[0].items.push({
          title: 'ticket', path: PATH_DASHBOARD.tickets, icon: ICONS.invoice,
        })
        dispatch({
          type: nav_config_setting,
          payload: data
        })
      }
    }
    const find_power_dialer = data[0].items.findIndex((value) => { return value.title === "Power Dialer" });
    if (find_power_dialer !== -1) {
      if (show_powerdialer === false) {
        data[0].items.splice(find_power_dialer, 1);
        dispatch({
          type: nav_config_setting,
          payload: data
        })
      }
    } else {
      data[0].items.push({
        title: 'Power Dialer', path: PATH_DASHBOARD.powerdialer.root, icon: <BoltIcon />,
      })
      dispatch({
        type: nav_config_setting,
        payload: data
      })
    }

    const find_voice_mail = data[0].items.findIndex((value) => { return value.title === "voicemail" });
    if (find_voice_mail !== -1) {
      if (show_voicemail === false) {
        data[0].items.splice(find_voice_mail, 1);
        dispatch({
          type: nav_config_setting,
          payload: data
        })
      }
    } else {
      data[0].items.push({
        title: 'voicemail',
        path: PATH_DASHBOARD.voicemail.root,
        icon: ICONS.voicemail,
        children: [
          { title: 'voicemailbox', path: PATH_DASHBOARD.voicemail.voicemailbox },
        ],
      })
      dispatch({
        type: nav_config_setting,
        payload: data
      })
    }
  }



  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/auth/register') {
      setShowCloudPhone(false)
    } else {
      
    }

    if (localStorage.getItem('isAuthenticated') !== true && localStorage.getItem('redux-token') !== null && localStorage.getItem('redux-token') !== undefined && localStorage.getItem('redux-token') !== '') {

    } else {
      if (location.pathname === '/auth/register' || location.pathname.includes("/UserConfirmation/") || location.pathname.includes("/invitedSignup")){
      } else {
        navigate('/')
      }

    }



  }, [locationPath])




  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />

            <ChartStyle />
            <ScrollToTop />
            <Router />
            <SuccessSnackbar />


            <OnlineOffline itIsFrom='app' />

            {show_cloudphone === true ? (
              <AppCloudPhone />
            ): ''}

          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
