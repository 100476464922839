import React, {useEffect, useState} from 'react'
import { alpha, styled } from '@mui/material/styles';
// mui
import { Box, Slide, Popper, TextField, InputAdornment, Button, IconButton, MenuItem, Grid, Drawer } from '@mui/material';

// date
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { getIvrVoiceSearch, getIvrVoice } from '../../../redux/slices/Inbox/voice';

// components
import Iconify from '../../../components/Iconify';

import { setSelectedTimeStamp } from '../../../redux/slices/dashboard/dashboardCallDurationStats'


export default function DashboardFilter({}) {


    const isDesktop = useResponsive('up', 'lg');
    const { collapseClick, isCollapse } = useCollapseDrawer();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const getParams = useParams();

    const [numbers, setNumbers] = useState('');
    const [week, setWeek] = useState('This Week');
    const [dateValue, setDateValue] = React.useState([null, null]);

    const [dashFilter, setdashFilter] = useState(false)
    const [filterClass, setFilterClass] = useState('')

    const showFilter = () =>{
        setdashFilter(true)
    }

    const clearFilter = () =>{
        setdashFilter(false)
        setNumbers('')
        setWeek('')
        setDateValue([null, null])
    }


    const handleSelectCustomTimeStampInDashBoard = (event) => {
      console.log("Dashboard custom time Event : ", event)

      setDateValue(event) 
      if(event[1] !== null){
        const startDate = event[0] ? event[0].$d : ''
        const endDate = event[1] ? event[1].$d : ''
        let tempObj = { timeStamp: 'custom', startDate: startDate , endDate: endDate }
        setWeek(tempObj.timeStamp)
        dispatch(setSelectedTimeStamp(tempObj))
      }

  }

    const handleSelectTimeStampInDashBoard = (event) => {
       
        let tempObj = { timeStamp: event.target.value, startDate: '', endDate: '' }
        setWeek(tempObj.timeStamp)
        dispatch(setSelectedTimeStamp(tempObj))
    }

    const triggerFilter = () =>{
        dispatch(setSelectedTimeStamp(tempObj))
        setdashFilter(false)
    }

    const closeFilter = () =>{
        setdashFilter(false)
    }

    useEffect(() => {
      if(collapseClick && isDesktop){
        setFilterClass('dash-filter-drawer normal-width')
      }else
      if(!collapseClick && isDesktop){
        setFilterClass('dash-filter-drawer desktop-width')
      }else
      if(collapseClick && !isDesktop){
        setFilterClass('dash-filter-drawer normal-width')
      }else{
        setFilterClass('dash-filter-drawer')
      }

      
    }, [collapseClick, isDesktop])

  


    return(
        <>

<Drawer
      anchor='top'
      open={dashFilter}
      onClose={closeFilter}
      className={filterClass}
      onWheel = {closeFilter}
    >


            <Grid container spacing={2}>
              {/* <Grid item>

                <TextField
                  label='Numbers'
                  style={{ width: "150px" }}
                  size='small'
                  value={numbers}
                  onChange={(event) => setNumbers(event.target.value)}
                  select>
                  <MenuItem value=''>-select-</MenuItem>
                </TextField>
              </Grid> */}
              <Grid item>
                <TextField
                  label=''
                  style={{ width: "150px" }}
                  size='small'
                  value={week}
                  // onChange={(event) => setWeek(event.target.value)}
                  onChange={handleSelectTimeStampInDashBoard}
                  select>
                  <MenuItem value='This Week'>This Week</MenuItem>
                  <MenuItem value='Last Week'>Last Week</MenuItem>
                  <MenuItem value='This Month'>This Month</MenuItem>
                  <MenuItem value='Last Month'>Last Month</MenuItem>
                  <MenuItem value='custom'>Custom</MenuItem>
                </TextField>
              </Grid>
              {week === 'custom' ? (
                <Grid item>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{ start: 'From', end: 'To' }}
                  >
                    <DateRangePicker
                      value={dateValue}
                      // onChange={(newValue) => {
                      //   setDateValue(newValue);
                      // }}
                      onChange={handleSelectCustomTimeStampInDashBoard}
                      renderInput={(startProps, endProps) => (
                        <React.Fragment>
                          <TextField {...startProps} style={{ width: "150px" }} size='small' />
                          <Box sx={{ mx: 2, color: '#aaa' }}> to </Box>
                          <TextField {...endProps} style={{ width: "150px" }} size='small' />
                        </React.Fragment>
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              ) : ''}

            {/* <Grid item>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={triggerFilter}
                >
                    Update
                </Button>
            </Grid> */}

            <Grid item>
                <Button
                    variant='outlined'
                    color='primary'
                    onClick={clearFilter}
                >
                    Clear Filter
                </Button>
            </Grid>


            </Grid>

    </Drawer>
   

        {dashFilter ? '' : (
    <>
    <Grid container direction='row'>
        <Grid item xs></Grid>
        <Grid item>
        <IconButton onClick={showFilter}>
        <Iconify icon={'bi:filter'} width={24} height={24} />
        </IconButton>
        </Grid>
    </Grid>
    
    </>
    )}

        </>
    )
    
}