import { Grid, TextField, FormHelperText } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { incrementviberbottoken, incrementviberbottokenValidate } from 'src/redux/Viberbottoken'

const ViberListing = ({}) => {

    const ViberbottokenValidate = useSelector(state => state.ViberbottokenSlice.validate)
    const ViberbottokenValue = useSelector(state => state.ViberbottokenSlice.value)

    const [viber, setviber] = useState('')
    const dispatch = useDispatch()
    const bottoken = (value) => {
        setviber(value)
        dispatch(incrementviberbottoken(value))
        if(value !== ''){
            dispatch(incrementviberbottokenValidate(false))
        }else{
            dispatch(incrementviberbottokenValidate(true))
        }
    }

    useEffect(() => {
      if(ViberbottokenValue !== '' && ViberbottokenValue !== viber){
        setviber(ViberbottokenValue)
      }
    }, [ViberbottokenValue])
    
    return (
        <div>
            <Grid container style={{marginTop: "50px"}}>
            <Grid item xs></Grid>
            <Grid item md={6}>
                <TextField
                    label='Bot Token'
                    fullWidth
                    value={viber}
                    onChange={(e) => {
                        bottoken(e.target.value)
                    }}
                />
                {ViberbottokenValidate &&(
                        <FormHelperText error>
                            Token is Required
                        </FormHelperText>
                    )}
                    </Grid>
                    <Grid item xs></Grid>
            </Grid>

        </div>
    )
}

export default ViberListing