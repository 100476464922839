import { Button, FormControl, FormControlLabel, Menu, MenuItem, Radio, RadioGroup, Snackbar, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from "react-redux";
import { edit_app_config } from "src/redux/userReducer";

export function CountryAllowed({ props, setProps }) {
    const [value, setValue] = useState("");
    const [country, setCountry] = useState([])
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [country_code, setCountryCode] = useState("")

    const {user} = useSelector((state) => state.user);
    useEffect(() => {
        if (props !== undefined && props !== null && props !== "") {
            setValue(props.default_country.code)
        }
    }, [props]);
    const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
    const change_default_country = (value) => {
        console.log("value", value)
        setValue(value)
        const findIndex = user.country_allowed.findIndex((item) => { return item.code === value });
        console.log("findIndex", findIndex)
        if (findIndex !== -1) {
            var app = props;
            app.default_country = user.country_allowed[findIndex];
            setProps(app);
            const body = {
                "default_country": user.country_allowed[findIndex]
            }
            edit_app(body)
        }
    }
    const [anchorEl, setAnchorEl] = useState(null);

    const allow_country = (is_allowed, country_code) => {
        var app = props;
        var response = [...country]
        console.log("code", country_code);
        const findIndex = country.findIndex((value) => { return value.code === country_code });
        if (findIndex !== -1) {
            if (is_allowed === "Allowed") {
                response[findIndex].allowed = true
            } else { 
                response[findIndex].allowed = false;
            }
        }
        setCountry(response)
        let country_arr = [];
        for (let i = 0; i < response.length; i++) {
           if (response[i].allowed === true) {
            const country_data = {
                "code": response[i].code,
                "phone": response[i].phone
            }
            country_arr.push(country_data)
           }
        }
        app.country_allow = country_arr;
        setProps(app);
        const body = {
            "country_allow": country_arr
        }
        edit_app(body)
    }

    console.log("country_allowed", user.country_allowed)

    useEffect(() => {
        if (props.default_country !== undefined && props.default_country !== null && props.default_country !== "") {
            if (props.default_country.code !== undefined && props.default_country.code !== null) {
                setValue(props.default_country.code)
            }
        }
        var response = [...user.country_allowed];
        for (let j = 0; j < user.country_allowed.length; j++) {
            const country_code = user.country_allowed[j].code;
            const findIndex = props.country_allow.findIndex((value) => { return value.code === country_code });
            if (findIndex !== -1) {
                response[j].allowed = true
            } else {
                response[j].allowed = false
            }
        }
        setCountry(response);
    }, [])

    const edit_app = (body) => {
        const token = localStorage.getItem("redux-token");
        const config_id = props._id;
        edit_app_config(config_id, body, token).then((res) => {
            setErrorMessage("App config is updated successfully");
            setShowError(true)
        }).catch((err) => {
            setErrorMessage("App config is not updated");
            setShowError(true)
            console.log("error", err);
        })
    }

    const Radio_label = ({ country_data }) => {
        console.log("country_code", country_data)
        return (
            <div style={{ display: "flex", flexDirection: "row", width: 480, alignItems: "center", justifyContent: "space-between"}}>
                <div style={{ display: "flex", flexDirection: "row",}}>
                <img src={`https://flagcdn.com/w80/${country_data.code.substr(0, 2).toLowerCase()}.png`} style={{ width: 45, height: 30, marginLeft: 20, }}/>
                <div style={{ marginLeft: 10, color: "black", fontSize: 15 }}>
                    {regionNames.of(country_data.code.substr(0, 2))}
                </div>
                <div style={{ marginLeft: 10, color: "grey", fontSize: 15}}>
                    (+{country_data.phone})
                </div>
                </div>
                <Select
            labelId="demo-select-small"
            id="demo-select-small"
            sx={{ minHeight: 20, height: 40 }}
            onChange={(event) => allow_country(event.target.value, country_data.code)}
            value={country_data.allowed === true ? "Allowed" : "Not Allowed"}
            >
                <MenuItem value={"Allowed"}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                            Allowed
                        </div>
                    </div>
                </MenuItem>
                <MenuItem value={"Not Allowed"}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                        <div style={{ marginLeft: 10, marginRight: 10}}>
                            Not Allowed
                        </div>
                    </div>
                </MenuItem>
            </Select>
            </div>
        )
    }
    return (
        <div style={{ display: "flex", width: "100%", flexDirection: "column", marginTop: 5 }}>
            <FormControl sx={{ width: "100%"}}>
                <RadioGroup
                sx={{ width: "100%" }}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={(event) => change_default_country(event.target.value)}>
                    {country.map((value, index) => (
                        <FormControlLabel value={value.code} control={<Radio/>} sx={{ width: "100%", marginLeft: 2, marginTop: index === 0 ? 0 : 5}} label={<Radio_label country_data={value}/>} key={index}/>
                    ))}
                </RadioGroup>
            </FormControl>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={showError}
                autoHideDuration={6000}
                onClose={() => setShowError(false)}
                message={errorMessage}
                action={
                    <React.Fragment>
                        <Button color="secondary" size="small" onClick={() => setShowError(false)}>
                            OK
                        </Button>

                    </React.Fragment>
                }
            />
        </div>
    )
}