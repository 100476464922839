import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// @mui
import { Box, Divider, Stack } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import {
    addRecipients,
    onSendMessageMessenger,
    getConversationMessenger,
    getParticipants,
    onSendFacebookMessage,
    onSendInstagramMessage,
    markConversationAsRead,
    resetActiveConversation,
} from '../../../redux/slices/chat';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
//
import ChatRoom from './ChatRoom';
import ChatMessageListMessenger from './ChatMessageListMessenger';
import ChatHeaderDetail from './ChatHeaderDetail';
import ChatMessageInputMessenger from './ChatMessageInputMessenger';
import ChatHeaderCompose from './ChatHeaderCompose';
import ChatMessageListInstagram from './ChatMessageListInstagram';
import ChatMessageInputInstagram from './ChatMessageInputInstagram';



// ----------------------------------------------------------------------

const conversationSelector = (state, conversationKey) => {
    const { conversations, activeConversationId } = state.chat;
    console.log('conversationSelector', conversations, activeConversationId);
    // const conversation = activeConversationId ? conversations.byId[activeConversationId] : null;
    const conversation = conversationKey ? conversations.byId[conversationKey] : null;
    if (conversation) {
        return conversation;
    }
    const initState = {
        id: '',
        messages: [],
        participants: [],
        unreadCount: 0,
        type: '',
    };
    return initState;
};

export default function ChatWindowInstagram() {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { pathname } = useLocation();

    let { conversationKey } = useParams();

    const { contacts, recipients, participants, activeConversationId } = useSelector((state) => state.chat);

    console.log('conversationSelectorid', conversationKey)
    const conversation = useSelector((state) => conversationSelector(state, conversationKey));

    const mode = conversationKey ? 'DETAIL' : 'COMPOSE';

    // const displayParticipants = participants.filter((item) => item.id !== '8864c717-587d-472a-929a-8e5f298024da-0');
    const displayParticipants = participants.participants ? participants.participants[0] : [];
    if (participants.length > 0) {
        displayParticipants.push();
    }
    console.log('ChatWindow.js: displayParticipants', displayParticipants);
    console.log('ChatWindow.js: participants : ', participants);


    useEffect(() => {
        console.log("conversation key  in use effect dycript : ", conversationKey);
        const getDetails = async () => {
            dispatch(getParticipants(conversationKey));
            try {
                await dispatch(getConversationMessenger(conversationKey));
            } catch (error) {
                console.error(error);
                navigate(PATH_DASHBOARD.chat.new);
            }
        };
        if (conversationKey) {
            getDetails();
        }
        //  else if (activeConversationId) {
        //   dispatch(resetActiveConversation());
        // }
        else if (conversationKey) {
            dispatch(resetActiveConversation());
        }

    }, [conversationKey]);

    // useEffect(() => {
    //   if (activeConversationId) {
    //     dispatch(markConversationAsRead(activeConversationId));
    //   }
    //   if (activeConversationId) {
    //     dispatch(markConversationAsRead(activeConversationId));
    //   }
    // }, [dispatch, activeConversationId]);
    useEffect(() => {
        if (conversationKey) {
            dispatch(markConversationAsRead(conversationKey));
        }
        if (conversationKey) {
            dispatch(markConversationAsRead(conversationKey));
        }
    }, [dispatch, conversationKey]);

    const handleAddRecipients = (recipients) => {
        dispatch(addRecipients(recipients));
    };

    const handleSendMessage = async (value) => {
        console.log("messenger send payload : ", value)
        try {
            dispatch(onSendInstagramMessage(value));
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Stack sx={{ flexGrow: 1, minWidth: '1px' }}>
            {mode === 'DETAIL' ? (
                <ChatHeaderDetail participants={[]} />
            ) : (
                <ChatHeaderCompose
                    recipients={recipients}
                    contacts={Object.values(contacts.byId)}
                    onAddRecipients={handleAddRecipients}
                />
            )}

            <Divider />

            <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden', marginBottom: "50px" }}>
                <Stack sx={{ flexGrow: 1 }}>
                    <ChatMessageListInstagram conversation={conversation} />

                    <Divider />

                    {/* <ChatMessageInputMessenger
            conversationId={activeConversationId}
            onSend={handleSendMessage}
            // disabled={pathname === PATH_DASHBOARD.chat.root || pathname === PATH_DASHBOARD.chat.new}
            disabled={false}
          /> */}
                    <ChatMessageInputInstagram
                        conversationId={conversationKey}
                        onSend={handleSendMessage}
                        // disabled={pathname === PATH_DASHBOARD.chat.root || pathname === PATH_DASHBOARD.chat.new}
                        disabled={false}
                    />
                </Stack>

                {/* Hide it for now to make it look better and error free will add soon  */}
                {mode === 'DETAIL' && <ChatRoom conversation={conversation} participants={displayParticipants} />}
            </Box>
        </Stack>
    );
}
