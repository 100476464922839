

import { Button, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import './style.css'
import React, { useRef, useState } from 'react'
import styled from "styled-components";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import SvgIconStyle from '../SvgIconStyle';
import callIcon from './../../assets/call-icon.svg'
import smsIcon from './../../assets/sms-icon.svg'
import instaIcon from './../../assets/instagram-icon.svg'
import viberIcon from './../../assets/viber-icon.svg'

import { useDispatch } from 'react-redux';
import { incrementChannelchoose } from 'src/redux/Channelchoose';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CarouselArrows, CarouselDots } from '../carousel';
import { useTheme } from '@mui/material/styles';

import instagramicon from './../../assets/instagramicon.svg'
import Page from '../Page';

import Iconify from '../Iconify';

const CompanyContainer = styled.div`
  display: block;
  width: 400px;
  margin-left: auto;
  margin-right: auto;

`;

function StyledRadio(props) {
    return (
        <Radio
            color="primary"
            checkedIcon={<CheckCircleIcon />}
            icon={<RadioButtonUncheckedIcon />}
            {...props}
        />
    );
}

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: getIcon('ic_banking'),
    booking: getIcon('ic_booking'),
    invoice: getIcon('ic_invoice'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    menuItem: getIcon('ic_menu_item'),
    number: getIcon('ic_number'),
    contact: getIcon('ic_contact'),
    voicemail: getIcon('ic_voicemail'),
    voice: getIcon('ic_voice'),
    whatsapp: getIcon('ic_whatsapp'),
    sms: getIcon('ic_sms'),
    ivrstudio: getIcon('ic_ivrstudio'),
    tickets: getIcon('ic_tickets'),
    livesupport: getIcon('ic_livesupport'),
    helpdocs: getIcon('ic_helpdocs'),
    feedback: getIcon('ic_feedback')
};



const Channel = ({ selectedChannel, setSelectedChannel }) => {
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,

    //     slidesToScroll: 1
    // };
    const dispatch = useDispatch()
    const [click, setclick] = useState(false);
    

    const handleClick = () => {
        if (click == true) {
            setstep(step + 1)
        }
    }
 
    
    const handleCss = () => {
        setSelectedChannel("voice")
        setclick(true)
        localStorage.setItem('click', true)
        
        dispatch(incrementChannelchoose('voice'))
    }

    const handlesms = () => {
        setSelectedChannel("SMS")
        setclick(true)
       
        dispatch(incrementChannelchoose('sms'))
    }
    const handlewhatsapp = () => {
        setSelectedChannel("whatsapp")
        setclick(true)
       
        dispatch(incrementChannelchoose('whatsapp'))
    }
    const handletelegram = () => {
        setSelectedChannel("telegram")
        setclick(true)
       
        dispatch(incrementChannelchoose('telegram'))
    }
    const handleviber = () => {
        setSelectedChannel("viber")
        setclick(true)
       
        dispatch(incrementChannelchoose('viber'))
    }

    const handlemessenger = () => {
        setSelectedChannel("messenger")
        setclick(true)
        
        dispatch(incrementChannelchoose('messenger'))
    }
    const handleinsta = () => {
        setSelectedChannel("instagram")
        setclick(true)
        
        dispatch(incrementChannelchoose('instagram'))
    }


    const theme = useTheme();
    const carouselRef = useRef(null);
    const settings = {
        // dots: true,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        rtl: Boolean(theme.direction === 'rtl'),
        ...CarouselDots(),
    };
    const handlePrevious = () => {
        carouselRef.current?.slickPrev();
    };

    const handleNext = () => {
        carouselRef.current?.slickNext();
    };
    return (
        <Page title='Create Inbox'>


            <div style={{ padding: '50px 100px' }}>

                <RadioGroup aria-label="gender" name="gender1"

                    value={selectedChannel}
                    row
               
                >
                    

                            <Grid container spacing={5} direction='row'>
                                <Grid item lg={3}>
                                <Button variant="outlined"

                                color='primary'
                                   
                                    className='channel-radio'
                                    onClick={() => {
                                        handleCss()

                                    }}
                                >
                                    <div style={{ display: "block" }}>

                                        <FormControlLabel value="voice" control={<StyledRadio />} style={{ position: "absolute", display: 'none', right: "2px", top: "0px" }} />

                                        <Grid container direction="row">
                                        <Grid item xs></Grid>
                                            <Grid item 
                                           
                                            >

                                                <img src={callIcon} className='channel-icon' />
                                               
                                            </Grid>
                                            <Grid item xs></Grid>
                                        </Grid>
                                        <Grid container direction="row" alignItems="center"
                                       
                                        >
                                           
                                            <Grid item xs>
                                                <Typography
                                                    variant="h6"
                                                    
                                                >
                                                    Voice
                                                </Typography>

                                                <Typography variant="subtitle1">
                                                    Make IVR Inbound & Outbound Calls and many more
                                                </Typography>
                                                {selectedChannel === 'voice' ? <>
                                                    <Typography variant='h5' className='channel-selected'>
                                                        Selected
                                                    </Typography>
                                                </> : <>

                                                </>}

                                            </Grid>
                                        </Grid>
                                    </div>

                                </Button>
                            </Grid>

                            <Grid item lg={3}>
                                <Button variant="outlined"
                                   color='primary'
                                   
                                   className='channel-radio'
                                    onClick={() => {
                                        handlesms()

                                    }}
                                >
                                    <div style={{ display: "block" }}>

                                        <FormControlLabel value="SMS" control={<StyledRadio />} style={{ position: "absolute", display: 'none', right: "2px", top: "0px" }} />

                                        <Grid container direction="row">
                                        <Grid item xs></Grid>
                                            <Grid item
                                                textAlign='center'
                                            >
                                               <img src={smsIcon} className='channel-icon' />
                                            </Grid>
                                            <Grid item xs></Grid>
                                        </Grid>
                                        <Grid container direction="row" alignItems="center">
                                            
                                            <Grid item xs>
                                                <Typography
                                                    variant="h6"
                                                    
                                                >
                                                    SMS
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                Manage SMS with your team
                                                </Typography>
                                                {selectedChannel === 'SMS' ? <>
                                                    <Typography variant='h5' className='channel-selected'>
                                                        Selected
                                                    </Typography>
                                                </> : <>

                                                </>}
                                            </Grid>
                                        </Grid>
                                    </div>

                                </Button>
                                </Grid>

<Grid item lg={3}>
                                <Button variant="outlined"
                                   color='primary'
                                   
                                   className='channel-radio'
                                    onClick={() => {
                                        handlewhatsapp()

                                    }}
                                >
                                    <div style={{ display: "block" }}>

                                        <FormControlLabel value="whatsapp" control={<StyledRadio />} style={{ position: "absolute", display: 'none', right: "2px", top: "0px" }} />

                                        <Grid container direction="row">
                                            <Grid item xs={12}
                                            // style={{ padding: "10px 20px 5px 20px" }}
                                            >
                                                <Iconify icon={'logos:whatsapp-icon'} className='channel-icon' />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" alignItems="center">
                                            {/* <Grid item>
                                <img src={IndianFlag} style={{ width: "50px", marginBottom: "30px", marginLeft: "170px" }} />
                            </Grid> */}
                                            <Grid item xs>
                                                <Typography
                                                    variant="h6"
                                                    
                                                >
                                                    Whatsapp
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    Send & Recieve message through
                                                    whatsapp, run campaigns and many more.
                                                </Typography>
                                                {/* <Typography style={{ marginLeft: "2%", fontSize: "10px", color: "gray" }}>
                                    
                                </Typography> */}
                                                {selectedChannel === 'whatsapp' ? <>
                                                    <Typography variant='h5' className='channel-selected' >
                                                        Selected
                                                    </Typography>
                                                </> : <>

                                                </>}
                                            </Grid>
                                        </Grid>
                                    </div>

                                </Button>
                                </Grid>

<Grid item lg={3}>
                                <Button variant="outlined"
                                   color='primary'
                                   
                                   className='channel-radio'
                                    onClick={() => {
                                        handletelegram()

                                    }}
                                >
                                    <div style={{ display: "block" }}>

                                        <FormControlLabel value="telegram" control={<StyledRadio />} style={{ position: "absolute", display: 'none', right: "2px", top: "0px" }} />

                                        <Grid container direction="row">
                                            <Grid item xs={12}
                                            // style={{ padding: "10px 20px 5px 20px" }}
                                            >
                                                <Iconify icon={'logos:telegram'} className='channel-icon' />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" alignItems="center">
                                            {/* <Grid item>
                                <img src={IndianFlag} style={{ width: "50px", marginBottom: "30px", marginLeft: "170px" }} />
                            </Grid> */}
                                            <Grid item xs>
                                                <Typography
                                                    variant="h6"
                                                    
                                                >
                                                    Telegram
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                Mange telegram bot chats with your team
                                                </Typography>
                                                {/* <Typography style={{ marginLeft: "2%", fontSize: "10px", color: "gray" }}>
                                    
                                </Typography> */}
                                                {selectedChannel === 'telegram' ? <>
                                                    <Typography variant='h5' className='channel-selected'>
                                                        Selected
                                                    </Typography>
                                                </> : <>

                                                </>}
                                            </Grid>
                                        </Grid>
                                    </div>

                                </Button>
                                </Grid>

<Grid item lg={3}>
                                <Button variant="outlined"
                                    color='primary'
                                   
                                    className='channel-radio'
                                    onClick={() => {
                                        handleviber()

                                    }}
                                >
                                    <div style={{ display: "block" }}>

                                        <FormControlLabel value="viber" control={<StyledRadio />} style={{ position: "absolute", display: 'none', right: "2px", top: "0px" }} />

                                        <Grid container direction="row">
                                        <Grid item xs></Grid>
                                            <Grid item
                                                textAlign='center'
                                            >
                                                <img src={viberIcon} className='channel-icon' />
                                            </Grid>
                                            <Grid item xs></Grid>
                                        </Grid>
                                        <Grid container direction="row" alignItems="center">
                                            {/* <Grid item>
                                <img src={IndianFlag} style={{ width: "50px", marginBottom: "30px", marginLeft: "170px" }} />
                            </Grid> */}
                                            <Grid item xs>
                                                <Typography
                                                    variant="h6"
                                                    
                                                >
                                                    Viber
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                Manage viber bot chat with your team
                                                </Typography>
                                                {/* <Typography style={{ marginLeft: "2%", fontSize: "10px", color: "gray" }}>
                                    
                                </Typography> */}
                                                {selectedChannel === 'viber' ? <>
                                                    <Typography variant='h5' className='channel-selected'>
                                                        Selected
                                                    </Typography>
                                                </> : <>

                                                </>}
                                            </Grid>
                                        </Grid>
                                    </div>

                                </Button>
                                </Grid>

<Grid item lg={3}>
                                <Button variant="outlined"
                                   color='primary'
                                   
                                   className='channel-radio'
                                    onClick={() => {
                                        handlemessenger()

                                    }}
                                >
                                    <div style={{ display: "block" }}>

                                        <FormControlLabel value="messenger" control={<StyledRadio />} style={{ position: "absolute", display: 'none', right: "2px", top: "0px" }} />

                                        <Grid container direction="row">
                                            <Grid item xs={12}
                                            // style={{ padding: "10px 20px 5px 20px" }}
                                            >
                                                <Iconify icon={'logos:messenger'} className='channel-icon' />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" alignItems="center">
                                            {/* <Grid item>
                                <img src={IndianFlag} style={{ width: "50px", marginBottom: "30px", marginLeft: "170px" }} />
                            </Grid> */}
                                            <Grid item xs>
                                                <Typography
                                                    variant="h6"
                                                    
                                                >
                                                    Messenger
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    Send & Recieve message through
                                                    Messenger, run campaigns and many more.
                                                </Typography>
                                                {/* <Typography style={{ marginLeft: "2%", fontSize: "10px", color: "gray" }}>
                                    
                                </Typography> */}
                                                {selectedChannel === 'messenger' ? <>
                                                    <Typography variant='h5' className='channel-selected'>
                                                        Selected
                                                    </Typography>
                                                </> : <>

                                                </>}
                                            </Grid>
                                        </Grid>
                                    </div>

                                </Button>
                                </Grid>

<Grid item lg={3}>
                                <Button variant="outlined"
                                    color='primary'
                                   
                                    className='channel-radio'
                                    onClick={() => {
                                        handleinsta()

                                    }}
                                >
                                    <div style={{ display: "block" }}>

                                        <FormControlLabel value="instagram" control={<StyledRadio />} style={{ position: "absolute", display: 'none', right: "2px", top: "0px" }} />

                                        <Grid container direction="row">
                                            <Grid item xs></Grid>
                                            <Grid item
                                                textAlign='center'
                                            >
                                               <img src={instaIcon} className='channel-icon' />
                                            </Grid>
                                            <Grid item xs></Grid>
                                        </Grid>
                                        <Grid container direction="row" alignItems="center">
                                            {/* <Grid item>
                                <img src={IndianFlag} style={{ width: "50px", marginBottom: "30px", marginLeft: "170px" }} />
                            </Grid> */}
                                            <Grid item xs>
                                                <Typography
                                                    variant="h6"
                                                    
                                                >
                                                    Instagram
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    Send & Recieve message through
                                                    Instagram, run campaigns and many more.
                                                </Typography>
                                                {/* <Typography style={{ marginLeft: "2%", fontSize: "10px", color: "gray" }}>
                                    
                                </Typography> */}
                                                {selectedChannel == 'instagram' ? <>
                                                    <Typography variant='h5' className='channel-selected'>
                                                        Selected
                                                    </Typography>
                                                </> : <>

                                                </>}
                                            </Grid>
                                        </Grid>
                                    </div>

                                </Button>
                                </Grid>
                                </Grid>




                </RadioGroup >

            </div >
        </Page>
    )
}

export default Channel