import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
//
import Scrollbar from '../../../Scrollbar';
import LightboxModal from '../../../LightboxModal';
import ChatMessageItem from './ChatMessageItem';

// ----------------------------------------------------------------------

ChatMessageList.propTypes = {
  conversation: PropTypes.object.isRequired,
};

export default function ChatMessageList({ conversation }) {
  const scrollRef = useRef(null);

  const [conversationList, setConversationList] = useState([])

  useEffect(() => {
    if(conversation.count > 0){
      let data = JSON.parse(JSON.stringify(conversation.data))
      setConversationList(data.reverse())
    }
  }, [conversation])
  

  const [openLightbox, setOpenLightbox] = useState(false);

  const [selectedImage, setSelectedImage] = useState(0);


  const scrollMessagesToBottom = () => {

    // scrollRef.current?.scrollIntoView({ behavior: "smooth" })

    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };


  useEffect(() => {
    
    scrollMessagesToBottom();
    console.log('conversationList scroll', scrollRef.current)
  }, [conversationList]);

  const imagesLightbox = conversationList
    .filter((messages) => messages.fb_msg_type === 'image')
    .map((messages) => messages.url);

  const handleOpenLightbox = (url) => {
    // const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(url);
  };

  return (
    <>
      <Scrollbar sx={{ p: 3, height: 1 }}>
        {conversationList.map((message) => (
          <ChatMessageItem
            key={message.id}
            message={message}
            conversation={conversationList}
            onOpenLightbox={handleOpenLightbox}
          />
        ))}

      <div ref={scrollRef} />
      </Scrollbar>

      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </>
  );
}
