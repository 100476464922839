import React, { useState, useEffect } from 'react'
import './role.css'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    FormControl,
    FormLabel,
    TextField,
    Grid,
    Card,
    MenuItem,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Alert,
    FormHelperText

} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';


import Iconify from '../../Iconify';
import { findDOMNode } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { postUserRole } from '../../../redux/slices/profile/role';



export default function RoleCreate({}){


    let AccountSid = localStorage.getItem('authID')
    const dispatch = useDispatch()

    const { isLoading } = useSelector(state => state.userRole)

    const [openDrawer, setOpenDrawer] = useState(false)
    const [roleName, setRoleName] = useState('')
    const [roleDescription, setRoleDescription] = useState('')

    const [loading, setLoading] = useState(false)
    
     // hacking
     const [state, setState] = useState({});

    // calculations
    const [modulesArray, setModulesArray] = useState([])
    const [allmodules, setAllModules] = useState([])
    const [selectedList, setSelectedList] = useState([])
    const [selectedModules, setSelectedModules] = useState([])

    // validations
    const [moduleAlreadySelected, setModuleAlreadySelected] = useState(false)
    const [nameValidate, setNameValidate] = useState(false)
    const [descriptionValidate, setDescriptionValidate] = useState(false)
    

    const [userPermission, setUserPermission]  = useState({
        ContactsGroups : {  view: true, create: true, edit: true, delete: true },
        ContactsManage : {  view: true, create: true, edit: true, delete: true },
        ContactsNotes : {  view: true, create: true, edit: true, delete: true },
        VoicemailBox : {  view: true, create: true, edit: true, delete: true },
        Workflow : {  view: true, create: true, edit: true, delete: true },
        Inbox : {  view: true, create: true, edit: true, delete: true },
    })

    const modules = ['ContactsGroups', 'ContactsManage', 'ContactsNotes', 'VoicemailBox', 'Workflow', 'Inbox']

    useEffect(() => {
      if(isLoading){
        setLoading(true)
      }else{
        setLoading(false)
        setOpenDrawer(false)
      }
    }, [isLoading])
    

 
 


    // useEffect(() => {

    //     let selectedModulesList = []
    //     if(selectedList.length !== 0){
    //         selectedList.map((item)=>{
    //             if(item.module !== ''){
    //                 selectedModulesList.push(item.module)
    //             }
    //         })
    //     }

    //     setSelectedModules(selectedModulesList)

    // }, [selectedList])
    

    // useEffect(() => {
    //   let avaialbleArray = []
    //   allmodules.map(item=>{
    //     if(selectedModules.includes(item) !== true){
    //         avaialbleArray.push(item)
    //     }
    //   })
    //   setModulesArray(avaialbleArray)
    // }, [selectedModules])
    
    

    

    const handleClose = () => {
        setOpenDrawer(false)
    }

    const handleOpen = () => {
        setOpenDrawer(true)
        setLoading(false)
    }

    const handleAddModule = () => {
        selectedList.push({module: '', view: true, create: true, edit: true, delete: true})

        // hackline
        setState({ ...state, [event.target.name]: event.target.checked });
    }

    function mergeWithArray(){
        selectedList.map((item)=>{
            if(item.module === 'ContactsGroups'){
                userPermission.ContactsGroups.view = item.view
                userPermission.ContactsGroups.create = item.create
                userPermission.ContactsGroups.edit = item.edit
                userPermission.ContactsGroups.delete = item.delete
            }
            if(item.module === 'ContactsManage'){
                userPermission.ContactsManage.view = item.view
                userPermission.ContactsManage.create = item.create
                userPermission.ContactsManage.edit = item.edit
                userPermission.ContactsManage.delete = item.delete
            }
            if(item.module === 'ContactsNotes'){
                userPermission.ContactsNotes.view = item.view
                userPermission.ContactsNotes.create = item.create
                userPermission.ContactsNotes.edit = item.edit
                userPermission.ContactsNotes.delete = item.delete
            }
            if(item.module === 'VoicemailBox'){
                userPermission.VoicemailBox.view = item.view
                userPermission.VoicemailBox.create = item.create
                userPermission.VoicemailBox.edit = item.edit
                userPermission.VoicemailBox.delete = item.delete
            }
            if(item.module === 'Workflow'){
                userPermission.Workflow.view = item.view
                userPermission.Workflow.create = item.create
                userPermission.Workflow.edit = item.edit
                userPermission.Workflow.delete = item.delete
            }
            if(item.module === 'Inbox'){
                userPermission.Inbox.view = item.view
                userPermission.Inbox.create = item.create
                userPermission.Inbox.edit = item.edit
                userPermission.Inbox.delete = item.delete
            }
        })

        return userPermission
    }

    const handleChangeModule = (event, index) => {

        let find = selectedList.findIndex(item=> item.module === event.target.value)
        console.log('handleChangeModule', find)
        if(find > -1){
            console.log('already added')
            setModuleAlreadySelected(true)
        }else{
            setModuleAlreadySelected(false)
            selectedList[index].module = event.target.value
            if(event.target.value === 'Inbox'){

            }
        }

        // hackline
        setState({ ...state, [event.target.name]: event.target.checked });

        

        // if(selectedList.filter(item=> item.module === event.target.value)){
        //     console.log('already added')
        // }else{
        //     console.log('handleChangeModule', event.target.value)
        //     selectedList[index].module = event.target.value
        // }
        
    }

    const handleChangeView = (event, index) => {
        selectedList[index].view = event.target.checked

        if(event.target.checked === false){
            selectedList[index].create = event.target.checked
            selectedList[index].edit = event.target.checked
            selectedList[index].delete = event.target.checked
        }

        // hackline
        setState({ ...state, [event.target.name]: event.target.checked });

    }

    const handleChangeCreate = (event, index) => {
        selectedList[index].create = event.target.checked
        if(selectedList[index].module !== 'Inbox'){
            if(event.target.checked === false){
                selectedList[index].edit = event.target.checked
            }
        }
        

        // hackline
        setState({ ...state, [event.target.name]: event.target.checked });

   
    }
    
    const handleChangeEdit = (event, index) => {
        selectedList[index].edit = event.target.checked

        // hackline
        setState({ ...state, [event.target.name]: event.target.checked });

    }

    const handleChangeDelete = (event, index) => {
        selectedList[index].delete = event.target.checked

        // hackline
        setState({ ...state, [event.target.name]: event.target.checked });

        
    }



    const handleSubmit = () =>{
        if(roleDescription !== '' || roleName !== ''){

            mergeWithArray()
            
            setLoading(true)
            const data = {
                AccountSid: AccountSid,
                name: roleName,
                type: roleName,
                description: roleDescription,
                userPermission: userPermission
            }

            dispatch(postUserRole(data))
    
            console.log('handleSubmit', data)


        }else{
            if(roleName === ''){
                setNameValidate(true)
            }
            if(roleDescription === ''){
                setDescriptionValidate(true)
            }
        }
        
    }



    return(
        <>
        <Button onClick={handleOpen} variant='contained'>
            Create Role
        </Button>

        <Dialog
            open={openDrawer}
            onClose={handleClose}
            >
            <DialogTitle>
                <Typography>
                    Create Role
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='row' spacing={2} style={{marginTop: '20px'}}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                        <FormLabel>
                            Role Name
                        </FormLabel>
                            <TextField 
                                value={roleName}
                                onChange={(event)=>{
                                    setRoleName(event.target.value);
                                    event.target.value === '' ?  setNameValidate(true) : setNameValidate(false)
                                }}
                                error={nameValidate}
                            />

                            

                            {nameValidate && (<FormHelperText error>Role Name is Required</FormHelperText>)}
                            
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel>
                                Role Description
                            </FormLabel>
                            <TextField 
                                value={roleDescription}
                                multiline
                                error={descriptionValidate}
                                onChange={(event)=>{
                                    setRoleDescription(event.target.value);
                                    event.target.value === '' ?  setDescriptionValidate(true) : setDescriptionValidate(false)
                                }}
                            />

                        {descriptionValidate && (<FormHelperText error>Role Description Required</FormHelperText>)}
                       
                            
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormLabel>Modules</FormLabel>

                        {moduleAlreadySelected && (
                            <Alert severity="error">Module Already Selected, Please select a different one</Alert>
                        )}
                        
                    </Grid>
                    

                    
                    {selectedList.map((item, index)=>{
                        return(
                            <Grid item xs={12}>
                                <Card className='role-module-cards'>
                                    <Grid container spacing={2} alignItems='center'>
                                        <Grid item xs>
                                    
                                        <TextField select
                                            size='small'
                                            fullWidth
                                            lable='Modules'
                                            value={item.module}
                                            onChange={(event)=>handleChangeModule(event, index)}
                                            >
                                            <MenuItem value=''>-Select Modules-</MenuItem>
                                            {modules.map((item)=>{
                                                return <MenuItem value={item}>{item}</MenuItem>
                                            })}
                                        </TextField>
                                        
                                
                                            
                                        </Grid>
                                        <Grid item>
                                            <FormGroup row>
                                                <FormControlLabel disabled={item.module === 'Inbox'} onChange={(event)=>handleChangeView(event, index)} control={<Checkbox checked={item.view} />} label="View" />
                                                <FormControlLabel disabled={!item.view} onChange={(event)=>handleChangeCreate(event, index)} control={<Checkbox checked={item.create} />} label="Create" />
                                                <FormControlLabel disabled={!item.view || !item.create || item.module === 'Inbox'} onChange={(event)=>handleChangeEdit(event, index)} control={<Checkbox checked={item.edit} />} label="Edit" />
                                                <FormControlLabel disabled={!item.view || item.module === 'Inbox'} onChange={(event)=>handleChangeDelete(event, index)} control={<Checkbox checked={item.delete} />} label="Delete" />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            )
                        })}
                    



                    {selectedList.length < modules.length ? (
                        <Grid item xs={12}>
                            <Button variant='outlined' onClick={handleAddModule}>+ Add Module</Button>
                        </Grid>
                    ) : ''}
                    
                

                

                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cancel
                </Button>


                <LoadingButton loading={loading} color="primary" variant="contained" onClick={handleSubmit} autoFocus>
                Submit
                </LoadingButton>

            </DialogActions>

        </Dialog>
        </>
    )
}