import React from "react";
import Application_component from "./components/Application_component";
import Grid from '@mui/material/Grid';
import {Card, Typography} from "@mui/material"
import "./components/style.css";

export const Tab_Application = ({ list_app, loading_config, callIcon, handleChange, checked_ticket, checked_powerdialer, open_configure, loading_ticket_config, loading_voice_mail_config, checked_voicemail, checked, loading_power_dialer_config }) => {
    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            
            <Card variant="outlined" className="application_header">
              <Grid container item>
                <Grid item lg={3} sm={6} xs={12} sx={{ display: "flex", justifyContent: "center", flexDirection: "column"}}>
                    <img src={require("./banner_application.png")} style={{ width: 300, height: 150, marginLeft: 10, marginTop: 10, marginBottom:10 }}/>
                </Grid>
                <Grid item lg={9} sm={6} xs={12}>
                    <Typography variant='h4' display="block">
                        Install the apps that help you grow.
                    </Typography>
                    <Typography display="block" variant='caption'>
                        Access all the core functionalities you need for your Marketing and Sales, in one place.
                    </Typography>
                    </Grid>
               </Grid>
            </Card>
            <Grid container spacing={1}>
                <Application_component app_name={'Cloud Phone'} loading_config={loading_config} app_detail='Communicate With Clients, Manage Your
                  Call Center And Send Text Messages, All
                  With A Small Widget' checked={checked} open_configure={open_configure} handleChange={handleChange} callIcon={"application_cloudphone"}/>
                  <Application_component app_name={'Ticket'} loading_config={loading_ticket_config} app_detail='Communicate With Clients, Manage Your
                  Call Center And Send Text Messages, All
                  With A Small Widget' checked={checked_ticket} open_configure={open_configure} handleChange={handleChange} callIcon={"application_ticket"}/>
                  <Application_component app_name={'Power Dialer'} loading_config={loading_power_dialer_config} app_detail='Communicate With Clients, Manage Your
                  Call Center And Send Text Messages, All
                  With A Small Widget' checked={checked_powerdialer} open_configure={open_configure} handleChange={handleChange} callIcon={"application_powerdialer"}/>
                  <Application_component app_name={'Voice Mail'} loading_config={loading_voice_mail_config} app_detail='Communicate With Clients, Manage Your
                  Call Center And Send Text Messages, All
                  With A Small Widget' checked={checked_voicemail} open_configure={open_configure} handleChange={handleChange} callIcon={"application_voicemail"}/>
            </Grid>
                <div style={{ display: "flex", width: "80%", alignItems: "center", justifyContent: "space-between", alignSelf: "center"}}>
                  
                </div>
                <div style={{ display: "flex", width: "80%", alignItems: "center", justifyContent: "space-between", alignSelf: "center"}}>
                
                </div>
        </div>
    )
}