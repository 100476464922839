import React, {useEffect, useState} from "react";
import {Switch, TextField, Button, Snackbar} from "@mui/material";
import { edit_app_config } from "src/redux/userReducer";
export const Ticket_General = ({ props, setProps }) => {
    const [tkt_prefix, setTktPrefix] = useState("")
    const [next_ticket_id, setNextId] = useState("")
    useEffect(() => {
        if (props.tkt_prefix === undefined) {
            props.tkt_prefix = ""
        } else {
            setTktPrefix(props.tkt_prefix)
        }

        if (props.create === undefined) {
            props.create = false
        }

        if (props.edit === undefined) {
            props.edit = false
        }

        if (props.delete === undefined) {
            props.delete = false
        }

        if (props.next_tkt_id === undefined) {
            props.next_tkt_id = next_ticket_id;
        } else {
            setNextId(props.next_tkt_id);
        }


    }, [props])
    const [showError, setShowError] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const change_props = (name, value) => {
        var app_details = props;
        app_details[name] = value;
        console.log("value", value);
        console.log("app_details", app_details)
        setProps(app_details)
    }

    const edit_app = (body) => {
        setDisabled(true)
        const token = localStorage.getItem("redux-token");
        const config_id = props._id;
        console.log("props", props)

        console.log("body", body)
        edit_app_config(config_id, body, token).then((res) => {
            setErrorMessage("Ticket config is updated successfully");
            setShowError(true)
            setDisabled(false)
        }).catch((err) => {
            console.log("error", err);
            setErrorMessage("Ticket config is not updated");
            setShowError(true)
            setDisabled(false)
        })
    }
    return (
        <div style={{ display: "flex", width: "100%", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ display: "flex", width: "100%", flexDirection: "row", }}>
                <div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Ticket Prefix
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Next Ticket ID
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Can Add
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Can Edit
                        </div>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <div style={{ fontSize: 15, fontWeight: "600", color: "#000"}}>
                            Can Delete
                        </div>
                    </div>
                </div>
                <div style={{ marginLeft: 40 }}>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <TextField
                        placeholder="Ticket Prefix"
                        id="outlined-size-small"
                        style={{ width: "80%", marginLeft: 10}}
                        value={tkt_prefix}
                        onChange={(e) => {
                            setTktPrefix(e.target.value);
                            change_props("tkt_prefix", e.target.value);
                        }} 
                        size="small"
                        />
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <TextField
                        placeholder="Next Ticket Id"
                        id="outlined-size-small"
                        style={{ width: "80%", marginLeft: 10}}
                        value={next_ticket_id}
                        onChange={(e) => {
                            setNextId(e.target.value);
                            change_props("next_tkt_id", e.target.value);
                        }} 
                        size="small"
                        />
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <Switch checked={props.create} onChange={(e) => change_props("create", e.target.checked)}/>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <Switch checked={props.edit} onChange={(e) => change_props("edit", e.target.checked)}/>
                    </div>
                    <div style={{ height: 40, display: "flex", alignItems: "center", marginTop: 5}}>
                        <Switch checked={props.delete} onChange={(e) => change_props("delete", e.target.checked)}/>
                    </div>
                </div>

            </div>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end", flexDirection: "row", marginTop: 10 }}>
                <Button style={{ marginRight: 20, height: 40, borderRadius: 10, backgroundColor: "#fff", }} variant="outlined" size='small'>
                    <div style={{ color: "#0046BB", marginLeft: 10, marginRight: 10, fontWeight: "600"}}>
                        Cancel
                    </div>
                </Button>
                <Button style={{ marginRight: 20, height: 40, borderRadius: 10, }} variant="contained" size='small' disabled={disabled} onClick={() => {
                    const body = {
                        "tkt_prefix": props.tkt_prefix,
                        "create": props.create,
                        "edit": props.edit,
                        "delete": props.delete,
                        "is_active": props.is_active,
                        "next_tkt_id": next_ticket_id
                    }
                    
                    edit_app(body)
                }}>
                    <div style={{ color: "#fff", marginLeft: 10, marginRight: 10, fontWeight: "600"}}>
                        Save Changes
                    </div>
                </Button>
            </div>
            <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        message={errorMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => setShowError(false)}>
              OK
            </Button>

          </React.Fragment>
        }
      />
        </div>
    )
}