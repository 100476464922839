import axios from 'axios';
import React, { useState } from 'react'
import FacebookLogin from "react-facebook-login";
import { useDispatch, useSelector } from 'react-redux';
import { incrementfacebookacess } from 'src/redux/Facebookaceestoken';
import instagram from './../../assets/instagram.svg'
import './facebookm.css'
const Instagram = ({ setActiveStep }) => {

    const facebookValidate = useSelector(state => state.facebookacessSlice.validate)

    const [isLoggedIn, setisLoggedIn] = useState(false)
    const [userID, setUserID] = useState('')
    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [picture, setpicture] = useState('')
    const componentClicked = (response) => {
        console.log("clicked")
    }
    const dispatch = useDispatch()

    const responseFacebook = (response) => {
        console.log("response", response)
        if (response.status != "unknown") {
            setisLoggedIn(true)
            setActiveStep(3.6)
            axios.get(`https://graph.facebook.com/v15.0/me/accounts?access_token=${response.accessToken}`)
                .then((res) => {
                    // console.log("responseinsta", res.data.data[0].id)

                    axios.get(`https://graph.facebook.com/v15.0/${res.data.data[0].id}?fields=instagram_business_account&access_token=${res.data.data[0].access_token}`)
                        .then((res) => {
                            console.log("responseinstapage", res.data.instagram_business_account.id)
                        })
                        .catch((err) => {
                            console.log(err)
                        })

                }).catch((err) => {
                    console.log(err)
                })
        }
        dispatch(incrementfacebookacess(response))
        localStorage.setItem('instagramaccesstoken', response.accessToken)
        localStorage.setItem('instagrangraphDomain', response.graphDomain)
        localStorage.setItem('instagramUserid', response.userID)
        localStorage.setItem('instagramid', response.id)
        localStorage.setItem('instagramsignedRequest', response.signedRequest)

        setUserID(response.userID)
        setname(response.name)
        setemail(response.email)
        setpicture(response.picture.data.url)
    }


    let fbContent;
    // if (isLoggedIn) {
    //     fbContent = (
    //         <div
    //             style={{
    //                 width: "400px",
    //                 margin: "auto",
    //                 background: "#f4f4f4",
    //                 padding: "20px"

    //             }}
    //         >
    //             {/* <img src={picture} alt={name} /> */}
    //             <h2>Welcome {name}</h2>
    //             {/* <h2>graphDomain: {graphDomain}</h2> */}
    //             {/* <h2>accessToken: {accessToken}</h2> */}
    //             {/* Email: {email} */}
    //         </div>
    //     )
    // }
    //  else {
    fbContent = (
        <>
            <FacebookLogin
                appId="677840373931875"
                autoLoad={false}
                fields="name,email,picture"
                // scope='pages_messaging,pages_show_list,pages_manage_metadata,pages_read_engagement'
                scope='instagram_basic,instagram_manage_messages,'
                onClick={() => componentClicked()}
                callback={responseFacebook}
                textButton={<p>Continue with Instagram</p>}
                icon={<img src={instagram} style={{ width: "50px", marginRight: "20px"}} />}
                cssClass='insta'

            />
        </>

    )
    // }

    return (
        <Grid
        container
        direction='row'
        style={{ marginTop: "50px" }}
    >
        <Grid item xs></Grid>
        <Grid item xs>
                {fbContent}
         
            {facebookValidate && (
                <>
                <FormHelperText error style={{textAlign: 'center'}}>
                    Facebook access key is required
                </FormHelperText>
                
                </>
            )}

            <p style={{ textAlign: 'center', marginTop: '20px'}}>
                PS: By signing in, we only get access to your Page's messages. You private messages can never be accessed by Vibtree.

            </p>

            </Grid>
            <Grid item xs></Grid>
        </Grid>
    )
}

export default Instagram