import React from 'react'
import { useTheme } from '@mui/material/styles';
import { paramCase } from 'change-case';
import { useState, useEffect } from "react";
// import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Container, Grid, Button, Stack, TableHead, TableBody, TableRow,
    Pagination,
    Skeleton,
    FormControl,
    InputLabel,
    Select,
    MenuItem, FormGroup,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    IconButton,
    CircularProgress,
    Typography,
    Box,
    InputAdornment,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Snackbar

} from '@mui/material';
import { useSnackbar } from 'notistack';
import { DataGridPro } from '@mui/x-data-grid-pro';
// redux
import { useSelector, useDispatch } from 'react-redux';
import axiosInstance, { baseUrl } from 'src/utils/axios';

import SvgIconStyle from '../SvgIconStyle';
import voicepng from './../../assets/voice.png'
import smspng from './../../assets/sms.png'
import whatspng from './../../assets/whatsapp.png'
import telegram from './../../assets/telegram.svg'
import telegramnew from './../../assets/telegramnew.svg'
import instagramicon from './../../assets/instagramicon.svg'
import fbmessenger from './../../assets/fbmessenger.svg'
import facebookmessenger from './../../assets/facebookmessenger.svg'
import viber from './../../assets/viber.svg'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import { incrementUserdatashow } from 'src/redux/UserDatashow';
import useToggle from 'src/hooks/useToggle';
import { getAllUsersOfCompany } from 'src/redux/slices/Setting/Create';
import UsersOfCompanyDailog from './UserOfCompanyDialog';
import { incrementassignedid } from 'src/redux/Assignedid';
import StatusCheck from './StatusCheck';
import { incrementcreatebyid } from 'src/redux/CreatedbyId';
import { incrementUseridcheck } from 'src/redux/Useridcheck';
import { incrementrefreshinbox } from 'src/redux/Refreshinbox'

import ConfirmationDialog from '../../components/ConfirmationDialog';

import InboxAssignUser from './InboxAssignUser'

const ITEM_HEIGHT = 64;

const Inbox = () => {

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleClick1 = () => {
        setOpen(true);
    };

    const action = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    const { toggle: openContacts, onOpen: onOpenContacts, onClose: onCloseContacts } = useToggle();
    const [status, setStatus] = useState('Active')
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [drawerOpen1, setDrawerOpen1] = useState(false)

    // confirmation
    const [confirmDialog, setConfirmDialog] = useState(false)
    const [confirmDialogMessage, setConfirmDialogMessage] = useState(false)
    const [idDelete, setIdDelete] = useState('')

    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }
    const handleDrawerClose1 = () => {
        setDrawerOpen1(false)
    }
    const dispatch = useDispatch()
    const handleDrawerOpen = () => {
        setDrawerOpen(true)
    }
    const handleDrawerOpen1 = () => {
        setDrawerOpen1(true)
    }
    const navigate = useNavigate()
    const [inboxsetting, setInboxsetting] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadings, setLoadings] = useState(true)
    const selecteduserid = useSelector(state => state.userdatashowSlice.value)
    const selecteduserRefresh = useSelector(state => state.Refreshpageslice.value)
    console.log("selecteduserid", selecteduserid)
    const getapi = () => {

        const authid = localStorage.getItem('authID')
        axiosInstance.get(`/inbox-view?AccountSid=${authid}&populate=UserId`)
            .then((res) => {

                setLoading(false)
                setInboxsetting(res.data.data.data)
                console.log("getapi respo", res.data.data.data)
                localStorage.setItem('getapiinbox', res.data.data.data.length)
                console.log("getapi respo number", res.data.data.data[0].data[0].number)
                console.log("getapi respo item", res.data.data.data.length)

                localStorage.setItem('isWhatsappActive', true)

            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }
    const getapidelete = () => {
        setConfirmDialog(false)
        setConfirmDialogMessage('')
        dispatch(incrementrefreshinbox(''))
        const authid = localStorage.getItem('authID')
        axiosInstance.delete(`/inbox-view/${idDelete}`)
            .then((res) => {
                handleClick1()
                dispatch(incrementrefreshinbox('refreshinbox'))
                getapi()
                console.log(res)

            }).catch((err) => {
                console.log(err)

            })
    }

    const confirmDelete = () =>{
        setConfirmDialog(true)
        setConfirmDialogMessage('Are you sure want to Delete?')
    }

    useEffect(() => {
        getapi()
    }, [])
    useEffect(() => {
        getapi()
    }, [selecteduserRefresh])
    // useEffect(() => {
    //     getapi()
    // }, [getapidelete])

    useEffect(() => {
        dispatch(getAllUsersOfCompany())
    }, [])

    // const inboxnumberslice = useSelector(state => state.inboxComments.userOfCompany)
    // console.log("inboxComments", inboxnumberslice)
    const InboxTypes = (props) => {

        return (
            <>
                {props.row.InboxType == "voice" ?
                    <div className='hovershowhide-parent'>
                        <div className='hovershow-child'>
                            {props.row.InboxType == "voice" ? <>Voice</> : props.row.InboxType}
                        </div>
                        <div className='hoverhide-child'>

                            <img src={voicepng} style={{ width: "20px" }} alt="" />
                        </div>

                    </div>
                    : props.row.InboxType == "sms" ?
                        <div className='hovershowhide-parent'>
                            <div className='hovershow-child'>
                                {props.row.InboxType == "sms" ? <>SMS</> : props.row.InboxType}
                            </div>
                            <div className='hoverhide-child'>


                                <img src={smspng} style={{ width: "20px" }} alt="" />
                            </div>

                        </div>

                        : props.row.InboxType == "whatsapp" ?
                            <div className='hovershowhide-parent'>
                                <div className='hovershow-child'>
                                    {props.row.InboxType == "whatsapp" ? <>Whatsapp</> : props.row.InboxType}
                                </div>
                                <div className='hoverhide-child'>


                                    <img src={whatspng} style={{ width: "20px" }} alt="" />
                                </div>

                            </div>
                            : props.row.InboxType == "telegram" ?
                                <div className='hovershowhide-parent'>
                                    <div className='hovershow-child'>
                                        {props.row.InboxType == "telegram" ? <>Telegram</> : props.row.InboxType}
                                    </div>
                                    <div className='hoverhide-child'>


                                        <img src={telegramnew} style={{ width: "25px" }} alt="" />
                                    </div>

                                </div> : props.row.InboxType == "viber" ?
                                    <div className='hovershowhide-parent'>
                                        <div className='hovershow-child'>
                                            {props.row.InboxType == "viber" ? <>Viber</> : props.row.InboxType}
                                        </div>
                                        <div className='hoverhide-child'>


                                            <img src={viber} style={{ width: "25px" }} alt="" />
                                        </div>

                                    </div> : props.row.InboxType == "messenger" ?
                                        <div className='hovershowhide-parent'>
                                            <div className='hovershow-child'>
                                                {props.row.InboxType == "messenger" ? <>Messenger</> : props.row.InboxType}
                                            </div>
                                            <div className='hoverhide-child'>


                                                <img src={facebookmessenger} style={{ width: "22px" }} alt="" />
                                            </div>

                                        </div> : props.row.InboxType == "instagram" ?
                                            <div className='hovershowhide-parent'>
                                                <div className='hovershow-child'>
                                                    {props.row.InboxType == "instagram" ? <>Instagram</> : props.row.InboxType}
                                                </div>
                                                <div className='hoverhide-child'>


                                                    <img src={instagramicon} style={{ width: "20px" }} alt="" />
                                                </div>

                                            </div> : ""}
            </>
        )
    }
    const skeltonLoaders = (
        <>
            <Grid container>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                    <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
                </Grid>
            </Grid>
        </>
    )
    const inboxuserid = (props) => {
        // console.log("useridinbox", props.row.UserId[0].user_logo)
        // const [user_logo, setuserlogo] = useState('')
        const [users, setusers] = useState('')

        useEffect(() => {
            // getid()
        }, [])
        // const url = "https://mibsback.vibtree.com/api/download/user_logo?fileLocation=" + props.row.UserId[0].user_logo
        // const handleClickuser = (index) => {
        //     console.log("userinboxid", index)
        //     dispatch(incrementUserdatashow(index))
        // }
        return (
            <>
                {/* <img src={url} style={{ width: "30px", borderRadius: "50%", marginRight: "5px" }} alt="" /> */}

                <div onClick={() => {
                    onOpenContacts()
                    // handleDrawerOpen()
                    // handleClickuser(users)
                }} style={{ backgroundColor: "#f7f7f8", borderRadius: "100%", padding: "2px", borderStyle: "dotted", borderColor: "#e0e3e7", display: "flex", alignItems: "center", color: "#687885", cursor: "pointer" }}>
                    <AddIcon />
                </div>
            </>
        )
    }
    const assignUsers = (props) => {
        // console.log("inboxuserid", props.row.UserId[0] ? props.row.UserId[0].user_logo : "")
        // const url = `${baseUrl}/download/user_logo?fileLocation=${props.row.UserId[0] ? props.row.UserId[0].user_logo : ""}`
        

        // const handleClickuser = (value) => {
        //     dispatch(incrementassignedid(value))
        // }
        // const handleCreateid = (value) => {
        //     dispatch(incrementcreatebyid(value))
        // }
        // const handleuser = (value) => {
        //     dispatch(incrementUseridcheck(value))
        // }
        return (
            <>
                {/* {props.row.UserId.map((item) => {
                    return <img src={`${baseUrl}/download/user_logo?fileLocation=${item.user_logo}`} style={{ width: "30px", borderRadius: "50%", marginRight: "5px" }} alt="" />
                })}


                <div onClick={() => {
                    onOpenContacts()
                    // handleDrawerOpen()
                    // handleuser(props.row.UserId)
                    handleCreateid(props.row)
                    handleClickuser(props.row.UserId[0])
                }} style={{ backgroundColor: "#f7f7f8", borderRadius: "100%", padding: "2px", borderStyle: "dotted", borderColor: "#e0e3e7", display: "flex", alignItems: "center", color: "#687885", cursor: "pointer" }}>
                    <AddIcon />
                </div> */}

                <InboxAssignUser props={props} />
            </>
        )
    }
    
    function utc(myDateTime) {
        let myDate = new Date(myDateTime).toLocaleDateString('en-US');
        let myTime = new Date(myDateTime).toLocaleTimeString('en-US');
        return `${myDate} , ${myTime}`
    }
    const getTime = (params) => {
        console.log('getTime', params.row.createdAt)
        let timeDate = params.row.createdAt
        return (
            <>
                {utc(timeDate)}
            </>
        )
    }

    const handleClickstatus = (params) => {
        dispatch(incrementassignedid(params.row._id))
    }
    const statusactive = (params) => {
        return (
            <>
                <StatusCheck params={params} handleClickstatus={handleClickstatus} />
            </>
        )
    }

    const columns = [
        // {
        //     field: "InboxIcon",
        //     headerName: "Icon",
        //     editable: false,
        //     flex: 1,
        //     headerAlign: "center",
        //     align: "center",
        //     renderCell: (params) => {

        //         return <>
        //             <i class={`bi bi-${params.row.InboxIcon}`}></i>
        //         </>
        //     }
        // },
        {
            field: "InboxName",
            headerName: "Name",
            editable: false,
            flex: 1,
            renderCell: (params) => {

                        return <>
                            <i class={`bi bi-${params.row.InboxIcon}`}></i>&nbsp;&nbsp; {params.row.InboxName}
                        </>
                    }
        },
        {
            field: "InboxType",
            headerName: "Type",
            editable: false,
            flex: 1,
            renderCell: InboxTypes
        },
        {
            field: "createdAt",
            headerName: "Created At",
            editable: false,
            flex: 1,
            renderCell: getTime

        },
        {
            field: "Status",
            headerName: "Status",
            editable: false,
            flex: 1,

            renderCell: statusactive

            // (params) => {

            //     return <>
            //         <Button style={{ backgroundColor: "#b9dace", color: "#1b806a" }} onClick={() => setStatus('InActive')} size='small'>
            //             {status}
            //         </Button>

            //     </>
            // }
        },
        {
            field: "Assigned_user",
            headerName: "Assigned User",
            editable: false,
            flex: 1,
            renderCell: assignUsers
        },
        {
            field: "Action",
            headerName: "Action",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                console.log("editidparams", params.row._id)
                const handleCreateid = (value) => {
                    dispatch(incrementcreatebyid(value))
                }
                return <>
                    <Button variant='outlined' size='small' onClick={() => {

                        if (params.row.InboxType == 'voice') {
                            console.log("vikalp")
                            navigate(`/dashboard/settings/inbox/${params.row._id}`)
                            handleCreateid(props.row)
                        } else if (params.row.InboxType == "whatsapp") {
                            navigate(`/dashboard/settings/inbox/whatsapp/${params.row._id}`)
                            handleCreateid(props.row)
                        }
                        else if (params.row.InboxType == "sms") {
                            navigate(`/dashboard/settings/inbox/sms/${params.row._id}`)
                            handleCreateid(props.row)
                        } else if (params.row.InboxType == 'telegram') {
                            navigate(`/dashboard/settings/inbox/telegram/${params.row._id}`)
                        }
                        else if (params.row.InboxType == 'viber') {
                            navigate(`/dashboard/settings/inbox/viber/${params.row._id}`)
                        }
                        else if (params.row.InboxType == 'messenger') {
                            navigate(`/dashboard/settings/inbox/messenger/${params.row._id}`)
                        }
                        else if (params.row.InboxType == 'instagram') {
                            navigate(`/dashboard/settings/inbox/instagram/${params.row._id}`)
                        }
                        dispatch(incrementassignedid(params.row.UserId[0]._id))

                    }}>Edit</Button>
                    {/* <DeleteIcon /> */}
                    <IconButton onClick={() => {
                        confirmDelete()
                        setIdDelete(params.row._id)
                    }}>
                        <Iconify icon={'fluent:delete-32-regular'} width={20} height={20} />
                    </IconButton>
                </>
            }
        },

    ]

    const LoadingSkeleton = () => (
        <>
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} />
        </>
    );

    // const handleCellEditCommit = React.useCallback(
    //     ({ id, field, value }) => {

    //         let data = {
    //             name: value,
    //         }

    //         // const getId = ivrData.find(x => x.number === id)._id;
    //         console.log('edited field', id, field, value)
    //         if (id !== undefined) {
    //             dispatch(updateFlowName(id, data))
    //         }
    //     },
    //     [ivrData],
    // );


    const renderedStudio = (
        <>
            <DataGridPro
                rows={inboxsetting}
                columns={columns}
                rowHeight={48}
                disableSelectionOnClick
                getRowId={(r) => r._id}
                style={{ height: '550px', }}

                components={{
                    LoadingOverlay: LoadingSkeleton
                }}

                loading={loading}   // you need to set your boolean loading
                hideFooter
            // onCellEditCommit={handleCellEditCommit}
            />
        </>
    )
    const handleClick = () => {
        navigate('/dashboard/onboarding/channel')
    }

    return (
        <div>

            <Button variant="contained" size='small' style={{ margin: "2%", position: "relative", left: "87%" }} onClick={() => handleClick()}>

                <div
                    style={{
                        position: "relative"
                        , top: "4px",
                        marginRight: "4px"
                    }}
                >
                    <AddIcon />
                </div>

                <div>New Inbox</div>
            </Button>
            <div
                style={{ marginLeft: "3%", marginRight: "3%" }}
            >
                {renderedStudio}
            </div>

            <UsersOfCompanyDailog open={openContacts} onClose={onCloseContacts} />

            

            <Dialog
                anchor='right'
                open={drawerOpen}
                onClose={handleDrawerClose}

            >
                {/* <div style={{ width: "350px", height: "400px" }}> */}
                <Stack spacing={2} sx={{ p: 2.5, pb: 1 }} >
                    <Typography variant='h6'>
                        User <Typography component='span' >({selecteduserid.length > 0 ? selecteduserid.length : null}) hwel</Typography>
                    </Typography>

                    <TextField
                        fullWidth
                        // value={filterName}
                        // onChange={handleSearchQuery}
                        placeholder="Search..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                <Scrollbar
                    sx={{
                        height: ITEM_HEIGHT * 6,
                        p: 1,
                        '& .MuiMenuItem-root': {
                            px: 1.5,
                            height: ITEM_HEIGHT,
                            borderRadius: 0.75,
                        },
                    }}
                >
                    {selecteduserid.length == undefined ? (
                        <>
                            hello

                        </>
                    ) : <>
                        {
                            selecteduserid.map((index) => (
                                <MenuItem>
                                    <ListItemAvatar sx={{ position: 'relative' }}>
                                        <Avatar src={`${baseUrl}/download/user_logo?fileLocation=${index.user_logo}`} />
                                    </ListItemAvatar>

                                    <ListItemText
                                        primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                                        secondaryTypographyProps={{ typography: 'caption' }}
                                        primary={index.fullName}
                                        secondary={index.email}

                                    />
                                    <div style={{ display: "flex", alignItems: "center", fontSize: "13px", color: "#212b36" }}><AddIcon sx={{ width: "13px", mr: 0.5, color: "#212b36" }} />Assign</div>
                                </MenuItem>
                            ))}
                    </>}

                </Scrollbar>
                {/* </div> */}
            </Dialog>

            <ConfirmationDialog openDialog={confirmDialog} setOpenDialog={setConfirmDialog} triggerConfirm={getapidelete} message={confirmDialogMessage} />

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: "right"
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Inbox Delete successfully"
                action={action}

            />

        </div>
    )
}

export default Inbox