import { Button, Typography, LinearProgress, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { incrementrefreshinbox } from 'src/redux/Refreshinbox'
import axiosInstance from 'src/utils/axios'
import inboxready from './../../assets/inboxready.png'
const TelegramDone = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [telename, settelename] = useState('')
    const [token, setToken] = useState('')

    const [loading, setLoading] = useState(true)

    const authid = localStorage.getItem('authID')
    const onboardinguserId = localStorage.getItem('onboardinguserId')
    const onboardingname = localStorage.getItem('onboardingname')
    const selectuser = useSelector(state => state.ChannelchooseSlice.value)
    const telegrambottokenSlice = useSelector(state => state.telegrambottokenSlice.value)
    const iconpng = localStorage.getItem('iconpng')
    const userselectid = useSelector(state => state.Inboxuseridslice.value)
    const reduxIcon = useSelector(state => state.iconpickerslice.value)

    var arr = []
    for (var i = 0; i < userselectid.length; i++) {
        arr.push(userselectid[i]._id)
    }

    console.log("userselectedid", userselectid)

    const obj = {
        AccountSid: authid,
        UserId: arr,
        InboxType: selectuser,
        created_by: arr,
        InboxName: onboardingname,
        InboxIcon: reduxIcon,
        data: [
            {
                Bot_token: telegrambottokenSlice

            }
        ],
        allowed_permission: {
            export: true
        }
    }
    console.log('objfinal', obj)
    localStorage.setItem('refreshinboxsetting', true)
    const finalapi = () => {
        axiosInstance.post(`/inbox-view`, obj)
            .then((res) => {
                dispatch(incrementrefreshinbox('refreshinbox'))
                console.log(res)
                setToken(res.data.data.data[0].Bot_token)
                settelename(res.data.data.InboxName)
                setLoading(false)
            }).catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        finalapi()
    }, [])
    const handleuser = () => {
        navigate(`/dashboard/inbox/telegram/${telename}/${token}`)
        dispatch(incrementrefreshinbox(''))
    }
    return (
        <div>
            <Grid container direction='row'>
            <Grid item xs></Grid>
            <Grid item md={6} style={{textAlign: 'center'}}>
                {loading === true ? (
                    <> 
                        <Typography variant='h5' color='primary' style={{marginTop: '50px'}}>
                        Creating Inbox ...
                        </Typography> 
                        <LinearProgress />
                    </>
                ) : (
                    <>
                    <Typography variant='h3' color='primary' style={{marginTop: '100px'}}>
                    Your Inbox is Ready!
                    </Typography>
                    <img src={inboxready} alt="" style={{margin: '20px auto'}} />
                    <Button color='primary' variant='contained' onClick={() => handleuser()} >Let's Start</Button>
                    </>
                )}
            </Grid>
            <Grid item xs></Grid>
        </Grid>

           
        </div>
    )
}

export default TelegramDone