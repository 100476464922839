import PropTypes from 'prop-types';
import { Paper, Typography } from '@mui/material';
import axiosInstance, { baseUrl } from 'src/utils/axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = '', ...other }) {
  console.log("searchQuery", searchQuery)
  const [name, setname] = useState('')
  const navigate = useNavigate()
  const handlechange = () => {
    console.log('value change', searchQuery)

    axiosInstance.get(`${baseUrl}/contacts?phoneNumber=${searchQuery}`)
      .then((res) => {
        console.log("contactsearch", res.data.data.data)
        console.log("contactsearchvikalp")
        setname(res.data.data.data)

      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    handlechange()
  }, [searchQuery])
  const handlenavigate = (item) => {
    const whatsappnumber = localStorage.getItem('whatsappNumber')
    const whatsappName = localStorage.getItem('whatsappName')
    const whatsappNumberid = localStorage.getItem('whatsappNumberid')
    console.log("whatsappitem", item.phoneNumber)
    const conversationKey = whatsappnumber - item.phoneNumber
    const encryptedConversationKey = window.btoa(conversationKey);
    console.log("encryptedConversationKey", encryptedConversationKey)
    // 917827966683
    // navigate(`/dashboard/inbox/whatsapp/${whatsappName}/${whatsappNumberid}/${encryptedConversationKey}`);
  }
  return searchQuery ? (name ? (
    <>
      <Paper {...other}>
        <Typography gutterBottom align="center" variant="subtitle1">
          {name.map((item) => {
            return <div >
              <div onClick={handlenavigate(item)} style={{ cursor: "pointer" }}>
                {item.firstName}
              </div>

            </div>
          })}
        </Typography>

      </Paper>
    </>
  ) : (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Not found
      </Typography>
      <Typography variant="body2" align="center">
        No results found for &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using complete words.
      </Typography>
    </Paper>
  )) : (
    <Typography variant="body2"> Please enter keywords</Typography>
  );
}
