import React from 'react';
import './callWidget.css';
import {
    Fab,
    Tooltip,
 } from '@mui/material';
 // components
 import MakeCallComponent from './MakeCallComponent';
 import axiosInstance from '../../utils/axios';


export default function CallMain(){
    //WebRTC call start from here-------------------

// var g_client = new window.TiniyoWebRTCSDK();
let g_client
//console.log("webrtc ", g_client)
    

var incomingCallAudio = new window.Audio('/dist/media/phonerng.wav');
incomingCallAudio.loop = true;
incomingCallAudio.crossOrigin = "anonymous";



var outgoingProgressCallAudio = new window.Audio('/dist/media/phonerng.wav');
// var outgoingProgressCallAudio = new Audio('https://ivraudiofiles.s3.eu-central-1.amazonaws.com/public/sorry_conference.mp3');
outgoingProgressCallAudio.loop = true;
outgoingProgressCallAudio.crossOrigin = "anonymous";

var outgoingRBTAudio = new window.Audio('/dist/media/rbt.ogg');
outgoingRBTAudio.loop = true;
outgoingRBTAudio.type = 'audio/ogg'
outgoingRBTAudio.crossOrigin = "anonymous";


const  callback=(status, event)=> {
    //console.log("Status :" + status);
    //console.log("Status :" + event);
    
    switch (status) {
      /* Server status */
      case 'connected':
        // sip user websocket connected.
        //console.log("sip user websocket connected.") 
        break;
  
      case 'disconnected':
        // sip user websocket disconnected
        //console.log("sip user websocket disconnected") 
        break;
  
      case 'connecting':
        // sip user registration websdk is connecting websocket.
        //console.log("sip user registration websdk is connecting websocket.") 
        break;
  
      case 'registration_failed':
        // sip registraion failed.
        //console.log("sip registraion failed.")  
        break;
  
      case 'registered':
        // sip user registered. User can make voice call. 
        //console.log("sip user registered. User can make voice call.") 
        // updateUI();
        break;
  
      /* Call status */
      case 'outgoingcall':
        // outgoing call is in progress. caller is not yet ringing. get remote user name using `event.remoteuser`.
        //console.log("call info outgoing ", event.remoteuser)
        //console.log("outgoing call is in progress. caller is not yet ringing. get remote user name using `event.remoteuser`.")
        outgoingProgressCallAudio.play()
   
        break;
  
      case 'incomingcall':
        //console.log("call info incoming ", event.remoteuser)
        // incoming call handling. `event.remoteuser` gives you remote user / callee name. 
        //console.log("incoming call handling. `event.remoteuser` gives you remote user / callee name.")  
        break;
  
      case 'progress':
        //console.log("For the outgoing call remote user / caller is ringing. Play Ring back tone for the callee who makes outgoing call.") 
      // For the outgoing call remote user / caller is ringing. Play Ring back tone for the callee who makes outgoing call.
         outgoingProgressCallAudio.pause();
         outgoingRBTAudio.play();        
        break;
  
      case 'failed':
      case 'ended':
        incomingCallAudio.pause();
        outgoingProgressCallAudio.pause();
        outgoingRBTAudio.pause();
        //console.log("call is ended or failed")
        break;
  
      case 'confirmed':
        incomingCallAudio.pause();
        outgoingProgressCallAudio.pause();
        outgoingRBTAudio.pause();
        //console.log("call is connected. voice should be heard to and from in both direction. ") 
        break;
  
      case 'accepted':
        // inbound call is answered and accepted. 
        //console.log("inbound call is answered and accepted.") 
        break;
  
      case 'ringing':
        // incoming call is there. Play ringing for the local user.
        incomingCallAudio.play();
        //console.log("incoming call is there. Play ringing for the local user.")  
        break;
  
      case 'unmuted':
        // user unmute success.
        //console.log("user unmute success.")  
        break;
  
      case 'muted':
        // user mute success. 
        //console.log(" user mute success.") 
        break;
  
      default:
        // this should never occur. contact support to fix the issue. 
    }
  }


  //this is for india mart 
const SipID = "sip:Subodh1630315475172@phone.vibconnect.io"
const SipPassword = "Tiger1234"
  const register =()=>{
                const user = SipID;
                const pass = SipPassword;
                let ret
                // const ret = g_client.Initialize(user, pass, callback);
                
            if (ret != "success"){
                // Initialization failed handling
                //console.log(" not sucess", ret)
            }else{
                // Initialization success. SIP user registraion is in progress.
                //console.log("sucess", ret)
                //console.log("user ", SipID)
                //console.log("password ", SipPassword)
                // //console.log("registerd ", g_client.IsRegistered())
            } 
            }

    // const classes = CustomStyles();
    const [showcallWidget, setShowcallWidget] = React.useState('');
    //console.log("showcallWidget ", showcallWidget)
    React.useEffect(()=>{
      axiosInstance.get('/getAuth')
        .then(res=>{
          if(res.data.data.phone_allowed === false && res.data.data.browser_allowed === false){
            setShowcallWidget('dont_show')
          }else{
            setShowcallWidget('show')
          }
        })
        .catch(err=>{})
    },[showcallWidget])
    return(
        <div>
          {showcallWidget === 'show' ? <MakeCallComponent/> : null}
      
        </div>
    )
}