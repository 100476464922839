import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
//
import Scrollbar from '../../../components/Scrollbar';
import LightboxModal from '../../../components/LightboxModal';
import ViberChatMessageItem from './ViberChatMessageItem';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

ViberChatMessageList.propTypes = {
  conversation: PropTypes.object.isRequired,
};

export default function ViberChatMessageList({ conversation }) {
  
  const scrollRef = useRef(null);

  const [openLightbox, setOpenLightbox] = useState(false);

  const [selectedImage, setSelectedImage] = useState(0);

  const messages_details = useSelector((state) => state.WhatsappSocketMessages);
  



  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [conversation.messages]);

  const imagesLightbox = conversation.messages
    .filter((messages) => messages.contentType === 'image')
    .map((messages) => messages.body);

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  const displayMessageIfDataInSocket =()=>{
    console.log('Telegram ChatMessageList.js: conversation', conversation);
    console.log('Telegram ChatMessageList.js: messages', messages_details);

    const messagePresentInDB =conversation.messages
    const messagePresentInSocket = messages_details.messages

    //console.log('ChatMessageList.js: messagePresentInDB', messagePresentInDB);
    //console.log('ChatMessageList.js: messagePresentInSocket', messagePresentInSocket);
    const uniqueMessages = messagePresentInDB.filter(function(item) {
      return !messagePresentInSocket.some(function(item2) {
        return item2.id === item.id;
      });
    })

    const mergeMessages = [...messagePresentInDB, ...messagePresentInSocket]
   

    const uniqueIds = [];

      const unique = messagePresentInSocket.filter(element => {
        const isDuplicate = uniqueIds.includes(element.WaSid);

        if (!isDuplicate) {
          uniqueIds.push(element.WaSid);

          return true;
        }

        return false;
      });

    //console.log("mergeMessages : ", mergeMessages)
    console.log(' removeDuplicates', unique);
    //console.log('uniqueMessages', uniqueMessages);

    if(messages_details.messages.length > 0){
      if(messages_details.id === conversation.conversation_id) {
        console.log("push message to chat");
        return unique.map((message) => (
          <ViberChatMessageItem
            key={messages_details.id}
            message={message}
            conversation={conversation}
            onOpenLightbox={handleOpenLightbox}
          />
        ));
      }
    }

  }

  return (
    <>
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, height: 1 }}>
        {conversation.messages.map((message) => (
          <ViberChatMessageItem
            key={message.id}
            message={message}
            conversation={conversation}
            onOpenLightbox={handleOpenLightbox}
          />
        ))}
        {/* {
          displayMessageIfDataInSocket()
        } */}
      </Scrollbar>

      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </>
  );
}
