import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
    Stack,
    Avatar,
    Dialog,
    MenuItem,
    TextField,
    Typography,
    ListItemText,
    ListItemAvatar,
    InputAdornment,
    ListItemSecondaryAction,
    Button,
    DialogActions,
    DialogContent,
    
} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import { _contacts } from 'src/_mock';
import axiosInstance, { baseUrl } from 'src/utils/axios';
import { filter } from 'lodash';
import { incrementRefreshpage } from 'src/redux/Refreshpage';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

UsersOfCompanyDailog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default function UsersOfCompanyDailog({ open, onClose }) {
    const [filterName, setFilterName] = useState('');
    const { userOfCompany, isRefresh } = useSelector(state => state.inboxComments)
    console.log("Ticket User of Company : ", userOfCompany, _contacts)
    const handleSearchQuery = (event) => {
        setFilterName(event.target.value);
    };
    const selectedassignedid = useSelector(state => state.assignedidSlice.value)
    const selectedcreatedid = useSelector(state => state.CreatedbyidSlice.value)
    const CreatedbyidSlice = useSelector(state => state.CreatedbyidSlice.value)
    console.log("selectedassignedid", selectedassignedid)
    console.log("selectedassignedusercompany", userOfCompany)
    console.log("CreatedbyidSlice", CreatedbyidSlice.UserId)
    const dispatch = useDispatch()


    const convertArray = (item) => {
        console.log("TicketSelected", item)

        if (item != undefined) {


            const temp = item.map((items) => {
                return { ...items, isAssigned: false }
            })
            return temp
        }
    }

    const formatUserAccordingToSelection = (all, selected) => {
        console.log("Ticket All User : ", all)
        console.log("TicketSelected : ", all, selected)
        if (all != undefined && selected != undefined) {

            for (let i = 0; i < all.length; i++) {
                for (let j = 0; j < selected.length; j++) {
                    if (selected[j]._id === all[i]._id) {

                        all[i].isAssigned = true
                    }
                }
            }

            // console.log("Result : ", all)
            return all
        }
    }
    const tempArr = convertArray(userOfCompany.data)
    console.log("tempArr", tempArr)

    const sortedUserAccordingToSelection = formatUserAccordingToSelection(tempArr, CreatedbyidSlice.UserId)

    console.log("sortedUserAccordingToSelection", sortedUserAccordingToSelection)










    const dataFiltered = applySortFilter({
        // listData: userOfCompany.data ? userOfCompany.data : [],
        listData: sortedUserAccordingToSelection,
        filterName,
    });


    const getUserlogo = (user) => {
        let src = ''

        if (user.user_logo) {
            src = `${baseUrl}/download/user_logo?fileLocation=${user.user_logo}`
        }
        return src
    }
    const [status, setStatus] = useState('Assign')
    const handleChange = (e) => {
        console.log("vikalpchange")
        // if (e.target.value == 'Assign') {
        //     setStatus('Assigned')
        // } else {
        //     setStatus('Assign')
        // }
        setStatus('Assigned')
    }
    const arr = []
    const handleremoveid = (value) => {
        // CreatedbyidSlice.UserId.filter((item) => {
        //     return item._id != value._id
        // }).map((vv) => {
        //     return arr.push(vv._id)
        // })

        const rem = CreatedbyidSlice.UserId.filter((item) => item._id != value).map((item) => {
            return item._id
        })
        console.log("vvvvvvv", rem)
        // })

        // console.log("vvvvvvvvvvvvvvv", value)
    }

    console.log("arrpush", arr)
    // const obj = {
    //     UserId:
    // }

    const finalinbox = (value) => {
        const arr = []
        CreatedbyidSlice.UserId.map((item) => (
            arr.push(item._id)
        ))
        arr.push(value)

        axiosInstance.patch(`/inbox-view/${CreatedbyidSlice._id}`, {
            UserId: arr
        })
            .then((res) => {
                dispatch(incrementRefreshpage(value))
                console.log("finalresponseedit", res)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const finalinboxass = (value) => {
        const arr = []
        CreatedbyidSlice.UserId.map((item) => (
            arr.push(item._id)
        ))
        arr.push(value)
        const fil = CreatedbyidSlice.UserId.filter((item) => item._id != value).map((item) => {
            return item._id
        })
        if (selectedcreatedid.created_by == value) {

        } else {
            axiosInstance.patch(`/inbox-view/${CreatedbyidSlice._id}`, {
                UserId: fil
            })
                .then((res) => {
                    dispatch(incrementRefreshpage(value))
                    console.log("finalresponseedit", res)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }


    return (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
            <Stack spacing={2} sx={{ p: 2.5, pb: 1 }}>
                <Typography variant="h6">
                    User <Typography component="span">({userOfCompany.count ? userOfCompany.count : 0})</Typography>
                </Typography>

                <TextField
                    fullWidth
                    value={filterName}
                    onChange={handleSearchQuery}
                    placeholder="Search..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            <DialogContent
                sx={{
                   
                    p: 1,
                    '& .MuiMenuItem-root': {
                        px: 1.5,
                        height: ITEM_HEIGHT,
                        borderRadius: 0.75,
                    },
                }}
            >

                {
                    dataFiltered != undefined && dataFiltered.map((contact) => (
                        <MenuItem key={contact.id}
                   
                        >
                            <ListItemAvatar sx={{ position: 'relative' }}>
                                <Avatar src={getUserlogo(contact)} />
                            </ListItemAvatar>

                            <ListItemText
                                primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                                secondaryTypographyProps={{ typography: 'caption' }}
                                primary={contact.fullName}
                                secondary={contact.email}
                            />

                            <ListItemSecondaryAction>
                                <Button
                                    color="secondary"
                                    onClick={(e) => {
                                        if (contact.isAssigned) {
                                            finalinboxass(contact._id)
                                        } else {
                                            finalinbox(contact._id)
                                        }
                                    }}

                                >
                                    {/* {contact.isAssigned ? <Iconify icon={'material-symbols:add-rounded'} sx={{ color: 'text.disabled', width: 20, height: 20 }} /> : <Iconify icon={'mdi:tick'} sx={{ color: 'text.disabled', width: 20, height: 20 }} /> } */}

                                    {
                                        contact.isAssigned ? <>


                                            <Iconify icon={'mdi:tick'} sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                            // onClick={(e) => {
                                            //     finalinboxass(contact._id)
                                            //     console.log("arrpush", arr)
                                            // }}
                                            />
                                            Assigned
                                        </>
                                            :
                                            <>


                                                <Iconify icon={'material-symbols:add-rounded'} sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                                // onClick={(e) => {
                                                //     finalinbox(contact._id)
                                                //     console.log("arrpush", arr)
                                                // }}
                                                />
                                                Assign
                                            </>
                                    }

                                </Button>
                            </ListItemSecondaryAction>

                        </MenuItem>

                    ))}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// ----------------------------------------------------------------------

function applySortFilter({ listData, filterName }) {
    if (filterName) {
        listData = listData.filter(
            (item) =>
                item.fullName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
                item.email.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
        );
    }

    return listData;
}
