
import { Box, Button, CircularProgress, Divider, Drawer, IconButton, Stack, Switch, Typography, Tabs, Tab, Menu, MenuItem, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {useDispatch} from "react-redux"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import InboxAllowed from "./components/CloudPhone/InboxAllowed";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {create_app, edit_app, get_app, get_app_config, SET_APP, SET_CLOUDPHONE_APP, SET_LIST_APP, SET_LIST_APP_CONFIG, edit_app_config, SET_SHOW_CLOUDPHONE, SET_SHOW_TICKET, SET_SHOW_POWER_DIALER, SET_SHOW_VOICEMAIL} from "src/redux/userReducer"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Iconify from 'src/components/Iconify';
import useResponsive from "src/hooks/useResponsive"
import callIcon from './../../assets/call-icon.svg'
import {baseUrl} from "src/utils/axios"
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from "react-redux";
import moment from "moment";
import { General } from "./components/CloudPhone/General";
import { CountryAllowed } from "./components/CloudPhone/CountryAllowed";
import Integration from "./components/CloudPhone/Integration";
import { Tab_Application } from "./Tab_Application";
import { Ticket_General } from "./components/Ticket/Ticket_General";
import { Ticket_Stages } from "./components/Ticket/Ticket_Stages";
import { PowerDialer_General } from "./components/PowerDialer/PowerDialer_General";
import { VoiceMail_General } from "./components/VoiceMail/VoiceMail_General";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function Application() {
    const [checked, setChecked] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    
    const [ticket_app_config, setTicket_app_config] = useState([]);
    const [power_dialer_app, setPowerDialerApp] = useState([]);
    const [power_dialer_app_config, setPowerDialerAppConfig] = useState([]);
    const [voice_mail_app_config, setVoiceMailAppConfig] = useState([])
    const [voice_mail_app, setVoiceMailApp] = useState([])
    const [checked_ticket, setCheckedTicket] = useState(false);
    const [ticket_app, setTicketApp] = useState([])
    const [checked_powerdialer, setCheckedPowerDialer] = useState(false);
    const [checked_voicemail, setCheckedVoiceMail] = useState(false);
    const [loading_ticket_config, setLoadingTicketConfig] = useState(false);
    const [loading_power_dialer_config, setLoadingPowerDialerConfig] = useState(false);
    const [loading_voice_mail_config, setLoadingVoiceMailConfig] = useState(false);
    const open = Boolean(anchorEl)
    const {list_app_config, list_app} = useSelector((state) => state.user)
    const [app_name, setAppName] = useState("Cloud Phone")
    const [cloudphone_app, setCloudPhoneApp] = useState([])
    const [open_application, setOpenApplication] = useState(false)
    const [value, setValue] = React.useState(0);
    const [app_details, setAppDetais] = useState("")
    const [loading_config, setLoadingConfig] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const handleCloseDrawer = () => {
        setDrawerOpen(false);
    }

    useEffect(() => {
        if (list_app !== null && list_app  !== undefined && list_app !== "") {
            const cloudphone = list_app.filter((value) => { return value.app_id.app_name === "cloudphone" });
            const ticket = list_app.filter((value) => { return value.app_id.app_name === "ticket" });
            const voice_mail = list_app.filter((value) => { return value.app_id.app_name === "voicemail" });
            const power_dialer = list_app.filter((value) => { return value.app_id.app_name === "powerdialer" });
            if (power_dialer !== undefined && power_dialer !== null && power_dialer !== "") {
                if (power_dialer.length > 0) {
                    setPowerDialerApp(power_dialer)
                    if (power_dialer[0].app_id.is_active === true) {
                        setCheckedPowerDialer(true);
                    }
               }
            }
            console.log("power_dialer", power_dialer)
            console.log("ticket", ticket)
            
            console.log("voice_mail", voice_mail);
            if (voice_mail !== undefined && voice_mail !== null && voice_mail !== "") {
                if (voice_mail.length > 0) {
                    setVoiceMailApp(voice_mail)
                    if (voice_mail[0].app_id.is_active === true) {
                        setCheckedVoiceMail(true);
                    }
                }
            }
            if (ticket !== undefined && ticket !== null && ticket !== "") {
                if (ticket.length > 0) {
                    setTicketApp(ticket)
                    if (ticket[0].app_id.is_active === true) {
                        setCheckedTicket(true);
                    }
                }
            }
            console.log("cloudphone", cloudphone)
            if (cloudphone !== undefined && cloudphone !== null && cloudphone !== "") {
                if (cloudphone.length > 0) {
                    setCloudPhoneApp(cloudphone);
                    if (cloudphone[0].app_id.is_active === true) {
                        setChecked(true);
                    }
                }
            }
        }
    }, [list_app])
    const dispatch = useDispatch();
    const handleChange = (event, app_name) => {
        const userID = localStorage.getItem("userID");
        const body = {is_active: event.target.checked}
        const authID = localStorage.getItem("authID");
        const token = localStorage.getItem("redux-token");
        if (app_name === "Cloud Phone") {
            setChecked(event.target.checked)
            console.log("checked", event.target.checked);
            if (cloudphone_app.length > 0) {
                const cloudphone_app_id = cloudphone_app[0].app_id._id;
                
                edit_app_config(cloudphone_app_id, body, token).then(async(res) => {
                    console.log("response", res);
                    dispatch({
                        type: SET_SHOW_CLOUDPHONE,
                        payload: event.target.checked
                    })
                    if (event.target.checked === true) {
                        setErrorMessage("Cloudphone is activated");
                        setShowError(true)
                    } else {
                        setErrorMessage("Cloudphone is deactivated");
                        setShowError(true)
                    }
                    await get_app(authID, token).then(async(resp) => {
                        const response_data = resp;
                        dispatch({
                            type: SET_LIST_APP,
                            payload: response_data
                        })
                    }).catch((err) => {
                        console.log("error", err);
                    })
                }).catch((err) => {
                    console.log("error", err)
                })
            } else {
                insert_cloudphone();
            }
        
        } else if (app_name === "Ticket") {
            const ticket = list_app.filter((value) => { return value.app_id.app_name === "ticket" });
            if (ticket.length === 0) {
            
                setCheckedTicket(event.target.checked)
                get_app(authID, token).then(async(res) => {
                    const response = res;
                    console.log("app_response_data", response)
                    const filtered_data = response.filter((value) => { return value.app_id.app_name === "ticket" });
                    if (filtered_data.length === 0) {
                        await create_app(authID, token, userID, "ticket").then(async(res) => {
                            await get_app(authID, token).then(async(resp) => {
                                const response_data = resp;
                                dispatch({
                                    type: SET_LIST_APP,
                                    payload: response_data
                                })
                                const filtered_data_ticket = response.filter((value) => { return value.app_id.app_name === "ticket" });
                                setTicketApp(filtered_data_ticket)
                                setErrorMessage("Ticket is activated");
                                setShowError(true)
                            }).catch((err) => {
                                console.log("error", err);
                            })
                        }).catch((err) => {
                            console.log("error", err);
                        })
                    }
                }).catch((err) => {
                    console.log("error", err);
                })
            } else {
                setCheckedTicket(event.target.checked)
                const ticket_app_id = ticket_app[0].app_id._id;
                
                edit_app_config(ticket_app_id, body, token).then(async(res) => {
                    console.log("response", res);
                    dispatch({
                        type: SET_SHOW_TICKET,
                        payload: event.target.checked
                    })
                    if (event.target.checked === true) {
                        setErrorMessage("Ticket is activated");
                        setShowError(true)
                    } else {
                        setErrorMessage("Ticket is deactivated");
                        setShowError(true)
                    }
                    await get_app(authID, token).then(async(resp) => {
                        const response_data = resp;
                        dispatch({
                            type: SET_LIST_APP,
                            payload: response_data
                        })
                    }).catch((err) => {
                        console.log("error", err);
                    })
                }).catch((err) => {
                    console.log("error", err)
                })
            }
        } else if (app_name === "Power Dialer") {
            if (cloudphone_app.length > 0) {
                setCheckedPowerDialer(event.target.checked)
                if (power_dialer_app.length === 0) {
                get_app(authID, token).then(async(res) => {
                    const response = res;
                    console.log("app_response_data", response)
                    const filtered_data = response.filter((value) => { return value.app_id.app_name === "powerdialer" });
                    if (filtered_data.length === 0) {
                        await create_app(authID, token, userID, "powerdialer").then(async(res) => {
                            await get_app(authID, token).then(async(resp) => {
                                const response_data = resp;
                                dispatch({
                                    type: SET_LIST_APP,
                                    payload: response_data
                                });
                                const filtered_data_powerdialer = response.filter((value) => { return value.app_id.app_name === "powerdialer" });
                                setPowerDialerApp(filtered_data_powerdialer)
                                setErrorMessage("Power Dialer is activated");
                                setShowError(true)
                            }).catch((err) => {
                                console.log("error", err);
                            })
                        }).catch((err) => {
                            console.log("error", err);
                        })
                    }
                }).catch((err) => {
                    console.log("error", err);
                })
              } else {
                const power_dialer_app_id = power_dialer_app[0].app_id._id;

                edit_app_config(power_dialer_app_id, body, token).then(async(res) => {
                    console.log("response", res);
                    dispatch({
                        type: SET_SHOW_POWER_DIALER,
                        payload: event.target.checked
                    })
                    if (event.target.checked === true) {
                        setErrorMessage("Power Dialer is activated");
                        setShowError(true)
                    } else {
                        setErrorMessage("Power Dialer is deactivated");
                        setShowError(true)
                    }
                    await get_app(authID, token).then(async(resp) => {
                        const response_data = resp;
                        dispatch({
                            type: SET_LIST_APP,
                            payload: response_data
                        })
                    }).catch((err) => {
                        console.log("error", err);
                    })
                }).catch((err) => {
                    console.log("error", err)
                })
              }
            } else {
                setErrorMessage("Please enable cloudphone app first");
                setErrorMessage(true)
            }
        } else if (app_name === "Voice Mail") {
            setCheckedVoiceMail(event.target.checked);
            if (voice_mail_app.length === 0) {
                get_app(authID, token).then(async(res) => {
                    const response = res;
                    console.log("app_response_data", response)
                    const filtered_data = response.filter((value) => { return value.app_id.app_name === "voicemail" });
                    if (filtered_data.length === 0) {
                        await create_app(authID, token, userID, "voicemail").then(async(res) => {
                            await get_app(authID, token).then(async(resp) => {
                                const response_data = resp;
                                dispatch({
                                    type: SET_LIST_APP,
                                    payload: response_data
                                });
                                const filtered_data_voicemail = response.filter((value) => { return value.app_id.app_name === "voicemail" });
                                setVoiceMailApp(filtered_data_voicemail)
                                setErrorMessage("Voicemail is activated");
                                setShowError(true)
                            }).catch((err) => {
                                console.log("error", err);
                            })
                        }).catch((err) => {
                            console.log("error", err);
                        })
                    }
                }).catch((err) => {
                    console.log("error", err);
                })
            } else {
                const voice_mail_app_id = voice_mail_app[0].app_id._id;
                edit_app_config(voice_mail_app_id, body, token).then(async(res) => {
                    console.log("response", res);
                    dispatch({
                        type: SET_SHOW_VOICEMAIL,
                        payload: event.target.checked
                    })
                    if (event.target.checked === true) {
                        setErrorMessage("Voicemail is activated");
                        setShowError(true)
                    } else {
                        setErrorMessage("Voicemail is deactivated");
                        setShowError(true)
                    }
                    await get_app(authID, token).then(async(resp) => {
                        const response_data = resp;
                        dispatch({
                            type: SET_LIST_APP,
                            payload: response_data
                        })
                    }).catch((err) => {
                        console.log("error", err);
                    })
                }).catch((err) => {
                    console.log("error", err)
                })
            }
        }
    }

    const open_configure = async (app_name) => {
        setAppName(app_name);
        const token = localStorage.getItem("redux-token")
        const authId = localStorage.getItem("authID")
        setValue(0);
        if (app_name === "Cloud Phone") {
            setLoadingConfig(true)
            const cloudphone = list_app.filter((value) => { return value.app_id.app_name === "cloudphone" });
            console.log("cloudphone", cloudphone_app)
            if (cloudphone.length > 0) {
                const app_id = cloudphone[0].app_id._id;
                await get_app_config(token, "cloudphone").then((res) => {
                    const app_config_response = res.filter((value) => { return value._id === app_id });
                    dispatch({
                        type: SET_LIST_APP_CONFIG,
                        payload: app_config_response
                    })
                    setLoadingConfig(false)
                    setOpenApplication(true)
                }).catch((error) => {
                    console.log("error", error);
                })
            } else {
                setLoadingConfig(false)
            }
        } else if (app_name === "Ticket"){
            setLoadingTicketConfig(true);
            const ticket = list_app.filter((value) => { return value.app_id.app_name === "ticket" });
            console.log("ticket_app", ticket)
            if (ticket.length > 0) {
                const app_id = ticket[0].app_id._id;
                await get_app_config(token, "ticket").then((res) => {
                    console.log("ticket", res)
                    const app_config_response = res.filter((value) => { return value._id === app_id });
                    setTicket_app_config(app_config_response);
                    setLoadingTicketConfig(false);
                    setOpenApplication(true);
                }).catch((error) => {
                    console.log("error", error);
                })
            }
            setOpenApplication(true);
        } else if (app_name === "Power Dialer"){
            setLoadingPowerDialerConfig(true);

            const power_dialer = list_app.filter((value) => { return value.app_id.app_name === "powerdialer" });
            if (power_dialer.length > 0) {
                const app_id = power_dialer[0].app_id._id;
                await get_app_config(token, "powerdialer").then((res) => {
                    const app_config_response = res.filter((value) => { return value._id === app_id });
                    setPowerDialerAppConfig(app_config_response);
                    setLoadingPowerDialerConfig(false);
                    setOpenApplication(true);
                }).catch((error) => {
                    console.log("error", error);
                })
            }
        } else if (app_name === "Voice Mail") {
            setLoadingVoiceMailConfig(true);
            const voice_mail = list_app.filter((value) => { return value.app_id.app_name === "voicemail" });
            if (voice_mail.length > 0) {
                const app_id = voice_mail[0].app_id._id;
                await get_app_config(token, "voicemail").then((res) => {
                    const app_config_response = res.filter((value) => { return value._id === app_id });
                    setVoiceMailAppConfig(app_config_response);
                    setLoadingVoiceMailConfig(false);
                    setOpenApplication(true);
                }).catch((error) => {
                    console.log("error", error);
                })
            }
        }
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const change_active = (is_active) => {
        var app_detail = app_details;
        app_detail.is_active = is_active;
        setAppDetais(app_detail);
        setAnchorEl(null)
    }

    const {user} = useSelector((state) => state.user)
    const insert_cloudphone = () => {
        const userID = localStorage.getItem("userID");
        const authID = localStorage.getItem("authID");
        const token = localStorage.getItem("redux-token");
        if (cloudphone_app.length === 0) {
          get_app(authID, token).then(async(res) => {
            const response = res;
            console.log("app_response_data", response)
            const filtered_data = response.filter((value) => { return value.app_id.app_name === "cloudphone" });
            if (filtered_data.length === 0) {
                await create_app(authID, token, userID, "cloudphone").then(async(res) => {
                    await get_app(authID, token).then(async(resp) => {
                        const response_data = resp;
                        dispatch({
                            type: SET_LIST_APP,
                            payload: response_data
                        })
                        const filtered_data = response_data.filter((value) => { return value.app_id.app_name === "cloudphone" });
                        setCloudPhoneApp(filtered_data)
                        setErrorMessage("Cloudphone is activated");
                        setShowError(true)
                    }).catch((err) => {
                        console.log("error", err);
                    })
                }).catch((err) => {
                    console.log("error", err);
                })
            }
          }).catch((err) => {
            console.log("error", err);
          })
        }
    }

    const columns = [
        {
            field: "Name",
            headerName: "Name",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div style={{ color: "#000", fontSize: 12, fontWeight: "500"}}>
                        {params.row.user_id.FirstName} {params.row.user_id.LastName}
                    </div>
                )
            }
        },
        {
            field: "Status",
            headerName: "Status",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.is_active === true ? (
                            <div style={{ backgroundColor: "rgb(223, 243, 234)",  height: 30, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 10}}>
                                <div style={{ color: "rgb(27, 128, 106)", fontSize: 13, fontWeight: "bold", marginLeft: 10, marginRight: 10 }}>
                                    Active
                                </div>
                            </div>
                        ): (
                            <div style={{ backgroundColor: "#fcd2d2", height: 30, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 10 }}>
                                <div style={{ color: "red", fontSize: 13, fontWeight: "bold", marginLeft: 10, marginRight: 10 }}>
                                    Inactive
                                </div>
                            </div>
                        )}
                    </div>
                )
            }
        },
        {
            field: "Activate On",
            headerName: "Activate On",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                      {moment(params.row.createdAt).format("ddd DD MMM YYYY HH:mm:ss A")}
                    </div>
                )
            }
        },
        {
            field: "Activate By",
            headerName: "Activate By",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        {app_name === "CloudPhone" ? (
                            <>
                              {cloudphone_app.length > 0 && <>
                                <div style={{ color: "#000", fontSize: 13 }}>
                                    {cloudphone_app[0].active_by.FirstName} {cloudphone_app[0].active_by.LastName}
                                </div>
                              </>}
                            </>
                        ): (
                            <>
                              {app_name === "Ticket" ? (
                                <>
                                  {ticket_app.length > 0 && <>
                                    <div style={{ color: "#000", fontSize: 13 }}>
                                        {ticket_app[0].active_by.FirstName} {ticket_app[0].active_by.LastName}
                                    </div>
                                  </>}
                                </>
                              ): (
                                <>
                                  {app_name === "Power Dialer" ? (
                                    <>
                                      {power_dialer_app.length > 0 && <>
                                        <div style={{ color: "#000", fontSize: 13 }}>
                                            {power_dialer_app[0].active_by.FirstName} {power_dialer_app[0].active_by.LastName}
                                        </div>
                                      </>}
                                    </>
                                  ): (
                                    <>
                                      {voice_mail_app.length > 0 && <>
                                        <div style={{ color: "#000", fontSize: 13 }}>
                                            {voice_mail_app[0].active_by.FirstName} {voice_mail_app[0].active_by.LastName}
                                        </div>
                                      </>}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                        )}
                    </div>
                )
            }
        },
        {
            field: "Action",
            headerName: "Action",
            editable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row", }}>
                        <Button style={{ border: "1.5px solid #0046BB", width: 70, height: 30, alignItems: "center", justifyContent: "center", minWidth: 30 }} onClick={() => {
                            setAppDetais(params.row);
                            setDrawerOpen(true)
                        }}>
                            <div style={{ color: "#0046BB", fontWeight: "600", fontSize: 13, }}>
                                Edit
                            </div>
                        </Button>
                        <Button style={{ minWidth: 20, width: 30, height: 30, marginLeft: 10, borderRadius: 20 }}>
                            <Iconify icon={'fluent:delete-32-regular'} width={25} height={25} />
                        </Button>
                    </div>
                )
            }
        }
    ]
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", alignSelf: "center"}}>
            {open_application === false ? (
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
                  <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example" style={{ marginTop: "10px" }}>
                    <Tab label="Apps" {...a11yProps(0)} sx={{ fontSize: 12, marginLeft: 2 }}/>
                    <Tab label="Integrations" {...a11yProps(1)} sx={{ fontSize: 12, marginLeft: -2}}/>
                   </Tabs>
                   <TabPanel index={0} value={value}>

                    <Tab_Application 
                    list_app={list_app} 
                    loading_config={loading_config} 
                    callIcon={callIcon}
                    handleChange={handleChange}
                    checked_ticket={checked_ticket}
                    checked_powerdialer={checked_powerdialer}
                    open_configure={open_configure}
                    loading_ticket_config={loading_ticket_config}
                    loading_power_dialer_config={loading_power_dialer_config}
                    loading_voice_mail_config={loading_voice_mail_config}
                    checked_voicemail={checked_voicemail}
                    checked={checked}
                    
                    />
                   </TabPanel>
                  
                </div>
            ): (
               <div style={{ width: "100%", height: "100%"}}>
                    <div style={{ width: "95%", display: "flex", marginTop: 20, alignItems: "center", flexDirection: "row", justifyContent: "space-between"}}>
                        <Button style={{minWidth:30, width: 40, height: 40, borderRadius: 30, display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }} onClick={() => {
                            setValue(0);
                            setOpenApplication(false)
                        }}>
                            <ArrowBackIcon style={{ color: "#0046BB", width: 30, height: 30, }} />
                        </Button>
                        <Button style={{ backgroundColor: "#0046BB", width: 120, height: 40, borderRadius: 10, marginRight: 10, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly",}}>
                            <AddIcon style={{ width: 20, height: 20, color: "#fff" }} />
                            <div style={{ color: "#fff", fontSize: 13, fontWeight: "bold" }}>
                                Add User
                            </div>
                        </Button>
                    </div>
                 <DataGridPro
                 columns={columns}
                 disableSelectionOnClick
                 rows={app_name === "Cloud Phone" ? list_app_config : app_name === "Ticket" ? ticket_app_config : app_name === "Power Dialer" ? power_dialer_app_config : app_name === "Voice Mail" ? voice_mail_app_config : []}
                 getRowId={(r) => r._id}
                 style={{ height: 550, width: "95%", display: "flex", alignSelf: "center", marginTop: 20, marginLeft: 10 }}
                 />
               </div> 
            )}
            <Drawer open={drawerOpen} onClose={handleCloseDrawer} anchor="right" className="numberSettigns" sx={{ minWidth: 550 }}>
                {app_details !== "" && <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5, minWidth: 550}} >
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {app_details.user_id.user_logo !== null && app_details.user_id.user_logo !== undefined && app_details.user_id.user_logo !== "undefined" ? (
                            <img src={`${baseUrl}/download/user_logo?fileLocation=${app_details.user_id.user_logo}`} style={{width: 50, height: 50, borderRadius: 40, }}/>
                        ): (
                            <div style={{ width: 50, height: 50, borderRadius: 40, backgroundColor: "#0046BB", display: "flex", alignItems: "center", justifyContent: "center"}}>

                            </div>
                        )}
                        <div style={{ marginLeft: 10 }}>
                            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                {app_details.user_id.FirstName} {app_details.user_id.LastName}
                            </Typography>
                            <Typography variant="caption" sx={{ flexGrow: 1 }}>
                                App Id: {app_details._id}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", marginRight: -5, }}>
                        <Button style={{ height: 40, display: "flex", flexDirection: "row", border: "1px solid lightgrey", alignItems: "center",}} onClick={(e) => setAnchorEl(e.currentTarget)}>
                            <div style={{ width: 20, height: 20, backgroundColor: app_details.is_active === true ? "rgb(163, 235, 177)" : "red", borderRadius: 5, marginLeft: 10 }}/>
                            <div style={{ color: "#000", marginLeft: 10 }}>
                                {app_details.is_active === true ? "Active" : "Inactive"}
                            </div>
                            <KeyboardArrowDownIcon style={{ color: "#000", width: 15, height: 15, marginLeft: 10, marginRight: 10}}/>
                        </Button>
                        <Menu 
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl} 
                        onClose={() => setAnchorEl(null)}
                        open={open}
                        sx={{ marginTop: 5, }}
                        anchorOrigin={{ 
                            vertical: 'top', 
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                            <MenuItem onClick={() => change_active(true)}>
                               <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                                <div style={{ width: 20, height: 20, backgroundColor: "rgb(163, 235, 177)", borderRadius: 5, marginLeft: 10 }}/>
                                <div style={{ marginLeft: 15}}>
                                   Active
                                </div>
                               </div>
                            </MenuItem>
                            <MenuItem onClick={() => change_active(false)}>
                               <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
                                <div style={{ width: 20, height: 20, backgroundColor: "red", borderRadius: 5, marginLeft: 10 }}/>
                                <div style={{ marginLeft: 15}}>
                                   Inactive
                                </div>
                               </div>
                            </MenuItem>
                        </Menu>
                        <IconButton onClick={handleCloseDrawer}>
                            <Iconify icon={'eva:close-fill'} width={20} height={20} />
                        </IconButton>
                    </div>
                </Stack>}
                <Divider sx={{ borderStyle: 'dashed' }} />
                {app_name === "Cloud Phone" ? (
                    <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example" style={{ marginTop: "10px" }}>
                        <Tab label="General" {...a11yProps(0)} sx={{ fontSize: 12, marginLeft: 2 }}/>
                        <Tab label="Country Allowed" {...a11yProps(1)} sx={{ fontSize: 12, marginLeft: -2}}/>
                        <Tab label="Inbox Allowed" {...a11yProps(2)} sx={{ fontSize: 12, marginLeft: -2 }}/>
                        <Tab label="Integrations" {...a11yProps(3)} sx={{ fontSize: 12, marginLeft: -2 }}/>
                    </Tabs>
                ): (
                    <>
                      {app_name === "Ticket" ? (
                        <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example" style={{ marginTop: "10px" }}>
                            <Tab label="General" {...a11yProps(0)} sx={{ fontSize: 12, marginLeft: 2 }}/>
                            <Tab label="Stages" {...a11yProps(1)} sx={{ fontSize: 12, marginLeft: -2}}/>
                        </Tabs>
                      ): (
                        <>
                          {app_name === "Power Dialer" ? (
                            <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example" style={{ marginTop: "10px" }}>
                                <Tab label="General" {...a11yProps(0)} sx={{ fontSize: 12, marginLeft: 2 }}/>
                            </Tabs>
                          ): (
                            <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example" style={{ marginTop: "10px" }}>
                                <Tab label="General" {...a11yProps(0)} sx={{ fontSize: 12, marginLeft: 2 }}/>
                            </Tabs>
                          )}
                        </>
                      )}
                    </>
                )}
                {app_name === "Cloud Phone" ? (
                   <>
                     <TabPanel index={0} value={value}>
                        <General props={app_details} setProps={setAppDetais}/>
                     </TabPanel>
                     <TabPanel index={1} value={value}>
                        <CountryAllowed props={app_details} setProps={setAppDetais}/>
                     </TabPanel>
                     <TabPanel index={2} value={value}>
                        <InboxAllowed props={app_details} setProps={setAppDetais}/>
                     </TabPanel>
                     <TabPanel index={3} value={value}>
                        <Integration props={app_details} setProps={setAppDetais}/>
                     </TabPanel>
                   </>
                ): (
                    <>
                      {app_name === "Ticket" ? (
                        <>
                          <TabPanel index={0} value={value}>
                            <Ticket_General props={app_details} setProps={setAppDetais}/>
                          </TabPanel>
                          <TabPanel index={1} value={value}>
                            <Ticket_Stages props={app_details} setProps={setAppDetais}/>
                          </TabPanel>
                        </>
                      ): (
                        <>
                          {app_name === "Power Dialer" ? (
                            <TabPanel index={0} value={value}>
                                <PowerDialer_General props={app_details} setProps={setAppDetais}/>
                            </TabPanel>
                          ): (
                            <TabPanel index={0} value={value}>
                                <VoiceMail_General props={app_details} setProps={setAppDetais}/>
                            </TabPanel>
                          )}
                        </>
                      )}
                    </>
                )}
            </Drawer>
            <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        message={errorMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => setShowError(false)}>
              OK
            </Button>

          </React.Fragment>
        }
      />
        </div>
    )
}



export default Application;